/***
 *
 *   HEADER
 *   Header section with title used in main application (can render children)
 *
 *   PROPS
 *   title: title of the view
 *   children: children to render (optional)
 *
 **********/

import { Animate, AuthContext, MobileNavContext, WhitelabelContext } from 'components/lib';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { clarity } from 'react-microsoft-clarity';
import Style from './header.module.scss';
import Logo from './../../assets/logo-small.png';
import './hovermenu.scss';
import PromotionBar from './promotionbar';
import {
  UilListUl,
  UilSchedule,
  UilCog,
  UilCalender,
  UilEnvelope,
  UilFile,
  UilAnalysis,
  UilCheck,
  UilMessage,
  UilAngleRight,
  UilDashboard,
  UilInbox,
  UilBars 
} from '@iconscout/react-unicons';
import { useAPI } from 'components/lib';
import { useEffect } from 'react';
import { MobileNav } from 'components/nav/mobile_nav/mobile_nav';

function HoverMenuInnerItem({ item, onClick }) {
  let navigator = useNavigate();

  return (
    <div
      className="hoverMenuInnerItemContainer"
      style={{
        '--accent-normal': item?.accents?.normal,
        '--accent-hover': item?.accents?.hover,
      }}
      onClick={() => {
        if (onClick) onClick();
        navigator(item.location);
      }}
    >
      <div className="hoverMenuInnerItem">
        <div className="hoverMenuInnerIcon">{item.icon}</div>
        <div className="hoverMenuInnerBody">
          <h2>{item.title}</h2>
          <span>{item.description}</span>
        </div>
        <div className="arrow">
          <UilAngleRight />
        </div>
      </div>
    </div>
  );
}

function HoverMenuItem({ menuitem }) {
  const [
    /* A state variable that is used to determine if the dropdown menu is visible or not. */
    visible,
    setVisible,
  ] = useState(menuitem.visible);
  let navigator = useNavigate();

  return (
    <>
      <div
        onMouseOver={() => {
          if (!window.location.pathname.includes(menuitem.location))
            setVisible(true);
        }}
        onMouseOut={() => setVisible(menuitem.visible || false)}
        className={menuitem.active ? 'hoverMenuItem active' : 'hoverMenuItem'}
      >
        <div
          style={{ display: 'block', padding: '17px 25px' }}
          onClick={() => navigator(menuitem.location)}
        >
          <div style={{ display: 'flex' }}>
            <div className="hoverMenuItemIcon">{menuitem.icon}</div>
            <div className="hoverMenuItemTitle">{menuitem?.title}</div>
          </div>
        </div>
        {visible && menuitem?.items.length > 0 && (
          <Animate type="slidedown">
            <div className="hmiDropdownMenu">
              <div className="hmiDropdownMenuBody">
                {menuitem?.items.map((item) => (
                  <HoverMenuInnerItem
                    onClick={() => setVisible(false)}
                    item={item}
                    key={item.title}
                  />
                ))}
              </div>
            </div>
          </Animate>
        )}
      </div>
    </>
  );
}

function HoverMenu({ menuitems }) {
  return (
    <>
      <div className="hoverMenu">
        {menuitems.map((menuitem) => (
          <HoverMenuItem menuitem={menuitem} key={menuitem.title} />
        ))}
      </div>
    </>
  );
}

export function Header(props) {
  const location = useLocation();
  let navigator = useNavigate();
  const subscription = useAPI('/api/account/subscription');
  // clarity.init("co5im8sdm8");
  const authContext = useContext(AuthContext);
  const { data: userData, loading: userDataLoading } = useAPI('/api/user');
  const { whitelabelDetails } = useContext(WhitelabelContext);
  const { isOpen, toggleMobileNav } = useContext(MobileNavContext);

  useEffect(() => {
    if (
      !userDataLoading &&
      clarity.hasStarted() &&
      authContext?.user?.accounts?.length > 0
    ) {
      clarity.identify(
        authContext?.user?.accounts[0]?.user_id,
        authContext?.user
      );
      clarity.setTag('email', userData.email);
    }
  }, [userDataLoading]);

  return (
    <>
      {subscription?.data?.status == 'trialing' && (
        <PromotionBar subscription={subscription} />
      )}
      <div className={Style.hamburger_menu}>
        <UilBars onClick={() => toggleMobileNav()} />
      </div>
      <MobileNav />
      <header
        className={Style.header}
      >
        {whitelabelDetails?.headerLogo && 
          <img
            src={whitelabelDetails?.headerLogo}
            alt={`${whitelabelDetails?.agencyName} Logo`}
            width={177}
            style={{ padding: '17px 8px 17px 17px', cursor: 'pointer' }}
            onClick={(e) => navigator('/')}
          />
        }
        <HoverMenu
          menuitems={[
            {
              icon: <UilDashboard />,
              title: 'Dashboard',
              location: '/dashboard',
              active: location?.pathname === '/dashboard',
              items: [
                // {
                //   icon: <UilSunset />,
                //   title: 'Onboarding',
                //   description: 'Learn to use SalesBlink and get booked.',
                //   location: '/dashboard',
                //   accents: {
                //     normal: '#8929E0',
                //     hover: '#E3CCF8',
                //   },
                // },
                // {
                //   icon: <UilCompass />,
                //   title: 'Dashboard',
                //   description: 'Glance over Stats & Progress',
                //   location: '/dashboard',
                //   accents: {
                //     normal: '#03A6FF',
                //     hover: '#DCEBF9',
                //   },
                // },
              ],
            },
            // {
            //   icon: <UilSearch />,
            //   title: 'Leads',
            //   location: '/leads',
            //   active: location?.pathname.includes('/leads'),
            //   items: [
            //     {
            //       icon: <UilUser />,
            //       title: 'People',
            //       description:
            //         'Search for targeted list of people using filters',
            //       location: '/leads/people',
            //       accents: {
            //         normal: '#8929E0',
            //         hover: '#E3CCF8',
            //       },
            //     },
            //     {
            //       title: 'Companies',
            //       icon: <UilBuilding />,
            //       description:
            //         'Search for targeted list of companies using filters',
            //       location: '/leads/companies',
            //       accents: {
            //         normal: '#03A6FF',
            //         hover: '#DCEBF9',
            //       },
            //     },
            //   ],
            // },
            {
              title: 'Outreach',
              icon: <UilMessage />,
              location: '/outreach',
              active: location?.pathname.includes('/outreach'),
              items: [
                {
                  title: 'Sequences',
                  icon: <UilMessage />,
                  description: 'Manage cold email sequences',
                  location: '/outreach/sequences',
                  accents: {
                    normal: '#8929E0',
                    hover: '#E3CCF8',
                  },
                },
                {
                  title: 'Outbox',
                  icon: <UilEnvelope />,
                  description: 'Review emails before sending',
                  location: '/outreach/outbox',
                  accents: {
                    normal: '#03A6FF',
                    hover: '#DCEBF9',
                  },
                },
                {
                  title: 'Lists',
                  icon: <UilListUl />,
                  description: 'Manage your lists of leads',
                  location: '/outreach/lists',
                  accents: {
                    normal: '#E8B105',
                    hover: '#F3E8C4',
                  },
                },
                {
                  title: 'Tasks',
                  icon: <UilCheck />,
                  description: 'Manual tasks for outreach',
                  location: '/outreach/tasks',
                  accents: {
                    normal: '#ED317F',
                    hover: '#FBD6E5',
                  },
                },
                {
                  title: 'Reports',
                  icon: <UilAnalysis />,
                  description: 'Logs & Reports for sequences',
                  location: '/outreach/reports',
                  accents: {
                    normal: '#16B4A1',
                    hover: '#C7EFEA',
                  },
                },
                {
                  title: 'Templates',
                  icon: <UilFile />,
                  description: 'Manage email templates & tasks',
                  location: '/outreach/templates',
                  accents: {
                    normal: '#DD7F33',
                    hover: '#FED3B0',
                  },
                },
                {
                  title: 'Email Senders',
                  icon: <UilEnvelope />,
                  description: 'Manage email addresses',
                  location: '/outreach/email-senders',
                  accents: {
                    normal: '#30A458',
                    hover: '#D3EFDC',
                  },
                },
                {
                  title: 'Settings',
                  icon: <UilCog />,
                  description: 'Manage outreach settings',
                  location: '/outreach/settings',
                  accents: {
                    normal: '#5d5d5d',
                    hover: '#c2c2c2',
                  },
                },
              ],
            },
            {
              icon: <UilInbox />,
              title: 'Inbox',
              location: '/inbox',
              active: location?.pathname.includes('/inbox'),
              items: [],
            },
            {
              title: 'Meet',
              icon: <UilCalender />,
              location: '/meet',
              active: location?.pathname.includes('/meet'),
              items: [
                {
                  title: 'My Calendar',
                  icon: <UilSchedule />,
                  description: 'View your bookings',
                  location: '/meet/my-calendar',
                  accents: {
                    normal: '#8929E0',
                    hover: '#E3CCF8',
                  },
                },
                {
                  title: 'Settings',
                  icon: <UilCog />,
                  description: 'Manage calendar settings',
                  location: '/meet/settings',
                  accents: {
                    normal: '#03A6FF',
                    hover: '#DCEBF9',
                  },
                },
              ],
            },
          ]}
        />
        {props.children}
      </header>
    </>
  );
}
