import { useEffect, useContext, useState } from "react";
import axios from "axios";
import { AuthContext, ViewContext } from "components/lib";
import { useNavigate } from "react-router-dom";
import "./oauthRedirect.scss";
import CustomButton from "components/custom/customButton";

export default function OauthRedirect() {
     const navigate = useNavigate();
     const authContext = useContext(AuthContext);
     const viewContext = useContext(ViewContext);
     const [message, setMessage] = useState({show: false, content: "We are redirecting to your account."});

     useEffect(() => {
          const searchParams = new URLSearchParams(window.location.search);
          const [code, state, name] = ["code", "state", "name"].map((item) => searchParams.get(item));

          if (!code || !state) {
               return navigate("/signup", { replace: true });
          }

          setTimeout(() => {
               connectOauth2(code, state)
          }, 1000)
     }, []);

     async function connectOauth2(code, state) {
          try {
               const provider = state.split(/ {1,}/)[0];
               const response = await axios.post(`/api/oauth/${provider}`, { provider, code });

               response.data["2fa_required"] ? navigate(`/signin/otp?token=${response.data.token}`) : navigate(authContext.signin(response));
          } catch (error) {
               if (error.response.status === 500) {
                    setMessage({show: true, content: "Internal Server Error. Redirecting to login..."});
                    setTimeout(() => {
                         navigate("/signin", { replace: true });
                    }, 5000);
               } else if(error.response.status === 400) {
                    viewContext.handleError(error);
                    navigate("/signin", { replace: true });
               }
          }
     }

     return (
          <div className="oauth-redirect" style={{ marginTop: 100 }}>
               <h1>Please do not close or refresh this page.</h1>
               <h1 className={message.show && "error"}>{message.content}</h1>
               <CustomButton title="Redirect" variant="secondary" className="m-auto" onClick={() => {
                    window.location.href = "/"
               }} />
          </div>
     );
}
