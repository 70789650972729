import { Editor } from '@tinymce/tinymce-react';

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';

export default function BundledEditor(props) {
  const { init, ...rest } = props;
  // note that skin and content_css is disabled to avoid the normal
  // loading process and is instead loaded as a string via content_style
  return (
    <>
      <style>
        {`
          /* TinyMCE Custom Styles */
          .tox-tinymce {
            border-radius: 0;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          .tox-editor-header {
            background-color: #f2f2f2 !important;
          }
          .tox .tox-toolbar__group {
            background-color: #f2f2f2 !important;
          }
          .tox .tox-toolbar-overlord .tox-toolbar:not(.tox-toolbar--scrolling):first-child, .tox .tox-toolbar-overlord .tox-toolbar__primary {
            background-color: #f2f2f2 !important;
          }
          .tox-tinymce .tox-toolbar-overlord button, .tox-tinymce .tox-toolbar__primary button, .tox-tinymce .tox-toolbar__overflow button, .tox-tinymce .tox-toolbar__group button, .tox-editor-container .tox-toolbar-overlord button, .tox-editor-container .tox-toolbar__primary button, .tox-editor-container .tox-toolbar__overflow button, .tox-editor-container .tox-toolbar__group button, .tox-editor-header .tox-toolbar-overlord button, .tox-editor-header .tox-toolbar__primary button, .tox-editor-header .tox-toolbar__overflow button, .tox-editor-header .tox-toolbar__group button {
            background-color: #f2f2f2 !important;
          }
          .tox .tox-tbtn.tox-tbtn--select.tox-tbtn--bespoke{
            width: 70px;
            margin-left: 5px; /* Adjust as needed */
            margin-right: 5px; /* Adjust as needed */
          }
          .tox .tox-tbtn.tox-tbtn--select.tox-tbtn--bespoke.tox-tbtn--active {
            width: 100px;
          }
        `}
      </style>
    <Editor
      init={{
        ...init,
        skin: false,
        content_css: false,
        branding: false,
        content_style: [
          contentCss,
          contentUiCss,
          init.content_style || '',
        ].join('\n'),
      }}
      {...rest}
    />
    </>
  );
}
