import { createContext, useState } from 'react';

export const MobileNavContext = createContext();

export function MobileNavProvider ({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <MobileNavContext.Provider value={{ isOpen, toggleMobileNav }}>
      {children}
    </MobileNavContext.Provider>
  );
};