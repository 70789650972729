import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { messaging} from '../firebase-config';
import { Head } from './partytown';

// service worker
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

// components
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoute, AuthProvider } from './auth';
import { AuthContext, MobileNavProvider, View, WhitelabelProvider } from 'components/lib';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// 404
import { NotFound } from 'views/error/404';

// tailwind css
import '../css/output.css';
import { getToken } from 'firebase/messaging';

// settings
const Settings = require('settings.json');
const StripePromise = loadStripe(
  Settings[process.env.NODE_ENV].stripe.publishableAPIKey
);

const routes = [
  ...require('routes/setup').default,
  ...require('routes/account').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,
  ...require('routes/website').default,
];

// Register the service worker
serviceWorkerRegistration.register();

export default function App(props) {
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const requestNotificationPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          const token = await getToken(messaging, {
            vapidKey: 'BEV6sxBw6kmSjXKiZU2pLlsaa0lnCJ9hyh-c3GZwh8Qw5dTqE0nSztKKUVFF-n6l-p758b7e7hdl7WTVLIxT9nE',
          });
          if (token) {
            // console.log("fpi toke", token)
            const data = {
              token 
            }
            const res = await Axios({
              url: '/api/settokens',
              method: 'post',
              data
            });
          } else {
            await Notification.requestPermission();
          }
        } else {
          // console.log('Notification permission denied.');
        }
      } catch (error) {
        // alert("If using Brave please go to brave://settings/privacy and enable Use Google services for push messaging")   
        // console.error('Error requesting permission or getting token:', error);
      }
    };

    requestNotificationPermission();

    let ipExist = localStorage.getItem('user_ip')
    if(!ipExist) getIP()
    else addIPtoAxios(JSON.parse(ipExist))

    // Read a cookie
    const readCookie = (name) => {
      const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      if (match) return match[2];
      return null;
    };

    const _fbc = readCookie('_fbc');
    const _fbp = readCookie('_fbp');
    const _ga = readCookie('_ga');
    const gclid = readCookie('gclid');
    const gbraid = readCookie('gbraid');
    const wbraid = readCookie('wbraid');
    const ga_click_id = gclid || gbraid || wbraid
    const partnero_partner = readCookie('partnero_partner');

    addFBtoAxios(_fbc, _fbp, _ga, ga_click_id, partnero_partner)
  }, []);

  // remove this before merging into main
  Axios.defaults.baseURL =
    process.env.NODE_ENV === 'production'
      ? window.location.origin
      : Settings[process.env.NODE_ENV].server_url;

  if (user?.token) {
    // add auth token to api header calls
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
  }

  async function getIP() {
    try {
      let { data } = await Axios.get(`https://ipapi.co/json/`)
      // console.log("DATA ", data)
      localStorage.setItem('user_ip', JSON.stringify(data))
      addIPtoAxios(data)
    } catch (error) {
      // console.log("TEST ERR ", error)
    }
  }

  function addIPtoAxios(user_ip) {
    Axios.defaults.headers.common['X-User-IP'] = user_ip.ip;
    Axios.defaults.headers.common['X-User-Country'] = user_ip.country_code;
    Axios.defaults.headers.common['X-User-State'] = user_ip.region_code;
    Axios.defaults.headers.common['X-User-City'] = user_ip.city;
    Axios.defaults.headers.common['X-User-Zipcode'] = user_ip.postal;
    Axios.defaults.headers.common['X-User-Agent'] = navigator.userAgent
  }

  function addFBtoAxios(_fbc, _fbp, _ga, ga_click_id, partnero_partner) {
    if(_fbc) Axios.defaults.headers.common['X-fbc'] = _fbc;
    if(_fbp) Axios.defaults.headers.common['X-fbp'] = _fbp;
    if(_ga) Axios.defaults.headers.common['X-ga'] = _ga;
    if(ga_click_id) Axios.defaults.headers.common['X-ga-click-id'] = ga_click_id;
    if(partnero_partner) Axios.defaults.headers.common['X-partnero-partner'] = partnero_partner;
  }

  // render the routes
  return (
    <>
      <Head />
      <Elements stripe={StripePromise}>
        <MobileNavProvider>
          <AuthProvider>
            <WhitelabelProvider>
              <BrowserRouter>
                <Routes>
                  {routes.map((route) => {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={
                          route.permission ? (
                            <IntercomProvider appId={"jfegr35q"}>
                              <IntercomData>
                                <PrivateRoute permission={route.permission}>
                                  <View
                                    display={route.view}
                                    layout={route.layout}
                                    title={route.title}
                                  />
                                </PrivateRoute>
                              </IntercomData>
                            </IntercomProvider>
                          ) : (
                            <View
                              display={route.view}
                              layout={route.layout}
                              title={route.title}
                            />
                          )
                        }
                      />
                    );
                  })}

                  {/* 404 */}
                  <Route
                    path="*"
                    element={
                      <View display={NotFound} layout="home" title="404 Not Found" />
                    }
                  />
                </Routes>
              </BrowserRouter>
            </WhitelabelProvider>
          </AuthProvider>
        </MobileNavProvider>
      </Elements>
    </>
  );
}

const IntercomData = (props) => {
  const { boot, shutdown, hide, show, update } = useIntercom();
  const authContext = useContext(AuthContext);
  useEffect(() => {
    getUser()
  }, [])

  const planPeriod = (planName) => {
    if(planName.includes('Yearly')) return "yearly"
    if(planName === "LTD") return "lifetime"
    if(planName === "Trial") return "7 days"
    return "monthly"
  }

  const getUser = async () => {
    try {
      const res = await Axios({
        url: '/api/user',
        method: 'get',
      });
      // console.log("RESP ", res.data.data)
      let data = {
        created_at: res.data.data.date_created_timestamp, // Add dates as Unix timestamp
        plan_expire_at: res.data.data.plan_expire_at,
        plan: res.data.data.plan_name,
        period: planPeriod(res.data.data.plan_name),
        impersonate: `https://us-central1-salesblink.cloudfunctions.net/impersonate?id=${res.data.data.id}`
      }
      if(res.data.data.plan_name === "Trial") data['trial-extend'] = `https://us-central1-salesblink.cloudfunctions.net/trial-extend?id=${res.data.data.id}`
      boot({
        app_id: "jfegr35q",
        name: res.data.data.name, 
        email: res.data.data.email,
        customAttributes: data,
        hideDefaultLauncher: window.innerWidth > 991 ? false : true,
      })
      update({
        app_id: "jfegr35q",
        name: res.data.data.name, 
        email: res.data.data.email,
        customAttributes: data
      })
    } catch (err) {
      console.log('ERROR ', err);
      if(err?.response?.data?.message === 'Account not found! Switch workspace to continue.') {
        authContext.signout();
      }
    }
  };

  return <div>
    {props.children}
  </div>
}