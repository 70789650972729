// whitelabel context

import axios from 'axios';
import { Favicon } from 'views/auth/Favicon';
import defaultLogo from '../assets/logo-small.png';
import { createContext, useState, useEffect } from 'react';
import defaultFavicon from '../assets/salesblink-icon-only-logo.png';

export const WhitelabelContext = createContext();

export function WhitelabelProvider({ children }) {
    const [whitelabelDetails, setWhitelabelDetails] = useState(null);
    const [customFaviconPresent, setCustomFaviconPresent] = useState(false);

    const fetchWhitelabelData = async () => {
        try {
            const localStorageItem = JSON.parse(localStorage.getItem('user'));
            const accountID = localStorageItem.account_id;
            const response = await axios.get('/api/whitelabel', { params: {accountID} });
            const data = response.data.data;
            setWhitelabelDetails({
                agencyName: data?.agencyName || 'SalesBlink',
                headerLogo: data?.headerLogo || defaultLogo,
                favicon: data?.favicon || defaultFavicon,
                whitelabelCalendar: data?.whitelabelCalendar || false,
                customDomain: data?.customDomain || '',
                isVerified: data?.isVerified || false,
            });
            if (data?.headerLogo) setCustomFaviconPresent(true);
        } catch (error) {
            console.log('Could not fetch whitelabel data', error);
        }
    };

    useEffect(() => {
        fetchWhitelabelData();
    }, []);

    return (
        <WhitelabelContext.Provider value={{ whitelabelDetails, setWhitelabelDetails, fetchWhitelabelData }}>
            {customFaviconPresent && <Favicon url={whitelabelDetails.favicon} />}
            {children}
        </WhitelabelContext.Provider>
    );
};
