/***
 *
 *   BILLING
 *   Change subscription, update card details or view past invoices
 *
 **********/

import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import Fuse from 'fuse.js';
import { integrationsData } from './../../../data/integrations';
import { UilSearch, UilLink, UilExternalLinkAlt } from '@iconscout/react-unicons';
import NativeIntegration from './NativeIntegrations';
import { useAPI } from 'components/lib';

function IntegrationItem(props) {
  const navigate = useNavigate();

  return (
    <div className="integration-item">
      {props.connected && <UilLink className="zapier-image" />}
      {!props.native && <UilExternalLinkAlt className="zapier-image" />}
      <div
        onClick={() =>
          props.link.includes('/account/integration/') ? navigate(props.link) : window.open(props.link, '_blank')
        }
      >
        <img
          className="image m-auto"
          src={props.image}
          alt={props.title}
          loading="lazy"
        />
        <div className="name mt-2">{props.title}</div>
      </div>
    </div>
  );
}

const Search = ({ data, setResults, setSearched }) => {
  const [query, setQuery] = useState('');

  // Initialize the Fuse instance with your data and search options
  const fuse = new Fuse(data, {
    keys: ['name'], // Customize the keys based on your data structure
    threshold: 0.1, // Customize the search threshold as needed
  });

  const handleSearch = (event) => {
    const value = event.target.value;
    setQuery(value);
    if (value.trim() === '') setSearched(false);
    else setSearched(true);
    // Perform the search and update the results state
    const searchResults = fuse.search(value).splice(0, 6);
    setResults(searchResults.map((result) => result.item));
  };

  return (
    <div className="integration-search-wrapper">
      <div>
        <UilSearch color="#C2C2C2" />
      </div>
      <input
        type="text"
        placeholder="Search Integrations"
        value={query}
        onChange={handleSearch}
      />
    </div>
  );
};

export function Integration(props) {
  const [integration, setIntegration] = useState(null);
  const [results, setResults] = useState([]);
  const [searched, setSearched] = useState(false);
  let { integrationName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [connectedIntegrations, setConnectedIntegration] = useState([]);
  const perPage = 20;
  const [integrationsShown, setIntegrationsShown] = useState(
    integrationsData.slice(0, page * perPage)
  );
  const urls = useAPI('/api/oauthurls');

  useEffect(() => {
    getAllConnectedIntegration()
  }, [])

  useEffect(() => {
    if (integrationName) setIntegration(integrationName);
  }, [integrationName, location]);

  // add more items to integrations on page change
  useEffect(() => {
    setIntegrationsShown(integrationsData.slice(0, page * perPage));
  }, [page]);

  // increment page number when user scrolls to the bottom of the page
  useEffect(() => {
    const bottomOfPage = document.getElementById('bottom-of-page');

    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          !searched &&
          integrationsShown.length < integrationsData.length
        )
          setPage((previousValue) => previousValue + 1);
      },
      { threshold: 0.01 }
    );

    // observe only if the item exists
    bottomOfPage && observer.observe(bottomOfPage);

    return () => bottomOfPage && observer.unobserve(bottomOfPage);
  }, [searched, integrationsShown, integration]);

  async function saveIntegrationProperties(type, properties, check_all_fields) {
    if (!properties || properties.length === 0)
      return Swal.fire({
        icon: 'error',
        title: `No Variables Mapped.`,
        showCancelButton: false,
        confirmButtonText: `Ok`,
      });
    let emailExist = properties?.find(
      (property) => property.local_field === 'Email'
    );
    if (!emailExist)
      return Swal.fire({
        icon: 'error',
        title: `You must add a property for Email in order to send emails.`,
        showCancelButton: false,
        confirmButtonText: `Ok`,
      });
    try {
      await Axios.put(`/api/integration/${type}`, {
        properties,
        check_all_fields,
      });
      Swal.fire({
        icon: 'success',
        title: `Integration properties saved.`,
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
    } catch (err) {
      console.log('ERROR ', err.response);
      Swal.fire({
        icon: 'error',
        title: `${err.response.data?.message || 'Unable to save properties'}`,
        showCancelButton: false,
        confirmButtonText: `Ok`,
      });
    }
  }

  const getAllConnectedIntegration = async () => {
    try {
      let res = await Axios.get(`/api/integration/`);
      setConnectedIntegration(res.data.data)
    } catch (err) {}
  }

  const getLink = (native, slug) => {
    if(slug === "google-calendar") return `${window.location.origin}/meet/settings`
    else if(slug === "microsoft-outlook") return `https://help.salesblink.io/en/articles/9080607-connecting-outlook-email`
    else if(slug === "zoho-mail") return `https://help.salesblink.io/en/articles/9080608-connecting-zoho-email`
    else if(slug === "gmail") return `https://help.salesblink.io/en/articles/9080605-connecting-free-gmail-account-or-gmail-alias`
    else if(slug === "amazon-ses") return `https://help.salesblink.io/en/email-accounts/connecting-amazon-ses`
    else if(slug === "sendgrid") return `https://help.salesblink.io/en/email-accounts/connecting-sendgrid`
    else if(slug === "integrately") return `https://integrately.com/integrations/salesblink`
    else if(slug === "zapier") return `https://zapier.com/developer/public-invite/121176/a7010ccab612be53f3f70975e4922fd2/`
    else if(slug === "pabbly") return `https://www.pabbly.com/connect/integrations/salesblink`
    else if(slug === "make") return `https://us1.make.com/app/invite/065c356189ba0728d234740b5a86183a`
    else if(slug === "slack") return urls?.data?.slackOAuth
    else if(native) return `/account/integration/${slug}`
    else return `/account/integration/${slug}`
  }

  return (
    <div className="account-integrations">
      {!integration && (
        <Search
          data={integrationsData}
          setResults={setResults}
          setSearched={setSearched}
        />
      )}
      <div className="integrations-wrapper">
        {!integration &&
          (results.length > 0 && searched ? (
            <>
              {results.map(({ name, native, logo, slug }) => (
                <IntegrationItem
                  key={name}
                  link={getLink(native, slug)}
                  slug={slug}
                  title={name}
                  native={native}
                  image={logo}
                  connected={connectedIntegrations.find(item => item.type === slug)}
                />
              ))}
            </>
          ) : (
            <>
              {integrationsShown.map((item, index) => (
                <IntegrationItem
                  link={getLink(item.native, item.slug)}
                  slug={item.slug}
                  title={item.name}
                  native={item.native}
                  image={item.logo}
                  connected={connectedIntegrations.find(itm => itm.type === item.slug)}
                />
              ))}
            </>
          ))}
      </div>
      {!integration && <div id="bottom-of-page"></div>}
      {integration && (
        <NativeIntegration
          onClose={() => {
            setIntegration(null);
            setResults([]);
            setSearched(false);
            navigate('/account/integration');
          }}
          onSave={saveIntegrationProperties}
          integrationData={integrationsData?.find(item => item.slug === integration)}
          serviceName={integration}
        />
      )}
    </div>
  );
}
