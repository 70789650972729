import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Label, Error, ClassHelper } from 'components/lib';
import Style from './input.tailwind.js';

export function CardInput(props) {
  const error =
    props.errorMessage || 'Please provide valid credit card details';

  const cardStyle = ClassHelper(Style, {
    textbox: true,
    cardbox: true,
    className: props.className,
    error: props.valid === false,
  });

  return (
    <div className={Style.input}>
      <Label text={props.label} required={props.required} for={props.name} />

      <div style={{ border: '1px solid #D7D7D7', borderRadius: 5 }}>
        <CardElement
          className={cardStyle}
          style={{ base: { fontFamily: 'Rubik', fontSize: '15px' } }}
        />
      </div>

      {props.valid === false && <Error message={error} />}
    </div>
  );
}
