/***
 *
 *   ERROR
 *   Form error message renders below input
 *
 **********/

import React from 'react';
import { ClassHelper } from 'components/lib';
import Style from './error.tailwind.js';

export function Error(props) {
  const errorStyle = ClassHelper(Style, props);

  return <div className={errorStyle}>{props.message}</div>;
}
