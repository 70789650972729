import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
// logo
import Logo from '../src/assets/salesblink-icon-only-logo.png';

const firebaseConfig = {
    apiKey: "AIzaSyD9yTHXP4MXbel2ZqH18PnU_KOPTHzQ85M",
    authDomain: "salesblink.firebaseapp.com",
    projectId: "salesblink",
    storageBucket: "salesblink.appspot.com",
    messagingSenderId: "672501151861",
    appId: "1:672501151861:web:64321a73176fc5dab4a03c"
};

const app = initializeApp(firebaseConfig)

export const messaging = getMessaging(app);
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            const { title, body, actionUrl } = payload.data;
            const options = {
                body,
                icon: Logo
            }
            const notif = new Notification(title,options);
            notif.onclick = () => {
                window.open(actionUrl, '_blank');
            };
            resolve(payload);
        });
    }
);
onMessageListener();