/***
 *
 *   API Keys List
 *   List & manage the API keys
 *
 **********/

import React, {
  useContext,
  useState,
  useEffect,
} from 'react';
import Axios from 'axios';
import {
  ViewContext,
  Button,
  BlankSlateMessage,
  Loader,
  useNavigate,
  useAPI,
  PageTitle,
  CustomTable,
  useLocation,
  WhitelabelContext,
} from 'components/lib';
import {
  UilRedo,
  UilEdit,
  UilTrash,
  UilEye,
  UilCheck,
  UilTimes,
} from '@iconscout/react-unicons';
import { Route, Routes } from 'react-router-dom';
import { APIKeyEditor } from './edit';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

export function APIKeyList() {
  const navigate = useNavigate();
  const viewContext = useContext(ViewContext);
  const fetch = useAPI('/api/key');
  const [keys, setKeys] = useState([]);
  const [columns, setColumns] = useState([]);
  const { whitelabelDetails } = useContext(WhitelabelContext);
  const deviceWidth = useWindowResize();

  useEffect(() => {
    if (fetch?.data?.length) setKeys(fetch.data);
  }, [fetch]);

  function revoke(data) {
    viewContext.modal.show(
      {
        title: 'Revoke API Key ',
        form: {
          active: {
            type: 'hidden',
            value: false,
          },
        },
        text: 'Are you sure you want to revoke this API Key? This action cannot be undone.',
        buttonText: 'Revoke',
        url: `/api/key/${data.id}`,
        destructive: true,
        method: 'PATCH',
      },
      () => {
        setKeys((previousState) =>
          previousState.map((key) => {
            if (key.id === data.id) key.active = false;
            return key;
          })
        );
      }
    );
  }

  function deleteApiKey(data) {
    viewContext.modal.show(
      {
        title: 'Delete API Key ',
        form: {},
        text: 'Are you sure you want to delete this API Key? This action cannot be undone.',
        buttonText: 'Delete API Key',
        url: `/api/key/${data.id}`,
        destructive: true,
        method: 'DELETE',
      },
      () =>
        setKeys((previousState) =>
          previousState.filter((key) => key.id !== data.id)
        )
    );
  }

  async function reveal(data) {
    const response = await Axios.get(`/api/key/${data.id}`);
    setKeys((previousState) =>
      previousState.map((key) => {
        if (key.id === data.id) key.key = response?.data?.data?.[0].key;
        return key;
      })
    );
  }

  // create columns
  useEffect(() => {
    if (!keys.length) return;

    const headers = ['name', 'key', 'active'];
    const cols = [];

    // create columns
    headers.forEach((header) => {
      let globalStyleForThisColumn = {};

      switch (header) {
        case 'name': {
          globalStyleForThisColumn = {
            paddingLeft: '10px',
          };
          break;
        }
        case 'key': {
          globalStyleForThisColumn = {
            textAlign: 'center',
          };
          break;
        }
        case 'active': {
          globalStyleForThisColumn = {
            textAlign: 'center',
            borderRadius: 5,
            paddingInline: '20px',
            maxWidth: 'fit-content',
            minWidth: '125px',
            margin: 'auto',
          };
          break;
        }
        default:
          break;
      }

      cols.push({
        Header: (
          <div
            style={{ ...globalStyleForThisColumn, textTransform: 'capitalize' }}
          >
            {header.replace(/_/g, ' ').split(' ')[0]}
          </div>
        ),
        // disableSortBy: true,
        accessor: header,
        Cell: ({ row, value }) => {
          const { original } = row;

          switch (header) {
            case 'name': {
              return (
                <div
                  style={{
                    ...globalStyleForThisColumn,
                  }}
                >
                  {original[header]}
                </div>
              );
            }
            case 'key': {
              return (
                <div
                  style={{
                    ...globalStyleForThisColumn,
                  }}
                >
                  {original[header]}
                </div>
              );
            }
            case 'active': {
              if (original[header] === true) {
                return (
                  <UilCheck
                    size={25}
                    style={{
                      color: '#3AC569',
                      border: '2px solid currentColor',
                      backgroundColor: '#D3EFDC',
                      borderRadius: 5,
                      margin: 'auto',
                    }}
                  />
                );
              } else if (original[header] === false) {
                return (
                  <UilTimes
                    size={25}
                    style={{
                      color: '#E53A40',
                      border: '2px solid currentColor',
                      backgroundColor: '#F5D3D4',
                      borderRadius: 5,
                      margin: 'auto',
                    }}
                  />
                );
              }

              return (
                <div
                  style={{
                    ...globalStyleForThisColumn,
                    textTransform: 'capitalize',
                  }}
                >
                  {original[header]}
                </div>
              );
            }
            default:
              return original[header];
          }
        },
      });
    });

    // add actions column
    cols.push({
      Header: <div style={{ textAlign: 'center' }}>Actions</div>,
      accessor: 'Actions',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { original } = row;

        return (
          <div
            style={{
              display: 'flex',
              gap: 10,
              justifyContent: 'end',
              maxWidth: '100px',
              margin: 'auto',
            }}
          >
            {original.active === true && (
              <div
                style={{
                  color: '#F9C00C',
                  border: '2px solid currentColor',
                  borderRadius: 5,
                  padding: 2,
                  cursor: 'pointer',
                }}
                onClick={() => revoke(original)}
              >
                <UilRedo size={19} />
              </div>
            )}
            <div
              style={{
                color: '#03A6FF',
                border: '2px solid currentColor',
                borderRadius: 5,
                padding: 2,
                cursor: 'pointer',
              }}
              onClick={() =>
                navigate(`/account/apikeys/edit?id=${original.id}`)
              }
            >
              <UilEdit size={19} />
            </div>
            <div
              style={{
                color: '#03A6FF',
                border: '2px solid currentColor',
                borderRadius: 5,
                padding: 2,
                cursor: 'pointer',
              }}
              onClick={() => reveal(original)}
            >
              <UilEye size={19} />
            </div>
            <div
              style={{
                color: '#E53A40',
                border: '2px solid currentColor',
                borderRadius: 5,
                padding: 2,
                cursor: 'pointer',
              }}
              onClick={() => deleteApiKey(original)}
            >
              <UilTrash size={19} />
            </div>
          </div>
        );
      },
    });

    setColumns(cols);
  }, [keys]);

  return (
    <div className="account-apikeys">
      <Routes>
        <Route path="create" element={<APIKeyEditor setKeys={setKeys} />} />
        <Route path="edit" element={<APIKeyEditor setKeys={setKeys} />} />
        <Route
          path="*"
          element={
            <>
              {fetch.loading ? (
                <Loader />
              ) : (
                <>
                  {keys?.length ? (
                    <>
                      <div className="header">
                        <PageTitle
                          title="Your API Keys"
                          description={`Use your API Key to integrate custom apps with ${whitelabelDetails?.agencyName}.`}
                          headerStyle={{ marginTop: 0 }}
                        />
                        <div id='header-buttons'>
                          <Button
                            small
                            text="View Docs"
                            action={() => window.open("https://salesblink.io/api/#introduction", "_blank")}
                            variant="secondary"
                            className='customButtons'
                          />
                          <Button
                            small
                            text="Generate New API Key"
                            goto="/account/apikeys/create"
                            variant="primary"
                            className='customButtons'
                          />
                        </div>
                      </div>
                      <div id='api-keys-data-table' ><CustomTable columns={columns} data={keys} /></div>
                    </>
                  ) : (
                    <BlankSlateMessage
                      title="Create an API Key"
                      text={`You haven't created any API keys yet. Would you like to create one now?`}
                      buttonText="Create API Key"
                      action={() => navigate('/account/apikeys/create')}
                      marginTop={deviceWidth > 991 ? '-4em' : '10rem'}
                    />
                  )}
                </>
              )}
            </>
          }
        />
      </Routes>
    </div>
  );
}
