import CustomButton from 'components/custom/customButton';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
// import TagManager from 'react-gtm-module'

export default function Subscribed() {
    const [showButton, setShowButton] = useState(false)
    const plan = JSON.parse(sessionStorage.getItem('subscribed_plan'))
    const navigator = useNavigate();

    useEffect(() => {
        if(!plan) window.location.href = '/dashboard'

        // const tagManagerArgs = {
        //     dataLayerName: 'transaction',
        //     dataLayer: {
        //         ecommerce: {
        //             purchase: {
        //               actionField: {
        //                 id: plan?.subscriptionID, 
        //                 revenue: plan?.plan.price / 100,   // Total transaction value (incl. tax and shipping)
        //                 // coupon: 'SUMMER_SALE'
        //               },
        //               products: [{          // List of productFieldObjects.
        //                 name: plan?.plan.name,  // Name or ID is required.
        //                 id: plan?.plan.id,
        //                 price: plan?.plan.price / 100,
        //                 category: plan?.plan.interval,
        //                 quantity: 1,
        //                 coupon: ''  // Optional fields may be omitted or set to empty string.
        //                }] //expand this array if more product exists
        //             }
        //         }
        //     }
        // }

        // TagManager.dataLayer(tagManagerArgs)

        setTimeout(() => {
            setShowButton(true)
        }, 5000)

        setTimeout(() => {
            sessionStorage.removeItem('subscribed_plan')
            window.location.href = '/dashboard'
        }, 15000)
    }, [])

    return (
        <div className='text-center'  style={{ height: '60vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <h2 className='text-xl font-bold mb-4'>Thank you for Subscribing!</h2>
            <h4 className='text-md font-semibold'>You are on {plan?.name} Plan.</h4>
            <p>Redirecting to Dashboard…</p>
            {showButton && <div className='flex justify-center'>
                <CustomButton
                    onClick={() => {
                        sessionStorage.removeItem('subscribed_plan')
                        navigator('/dashboard')
                    }}
                    title="Go To Dashboard"
                    variant="primary"
                    className="layout-reverse"
                    style={{
                        marginTop: 20
                    }}
                />
            </div>}
        </div>
    )
}
