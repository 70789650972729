/***
 *
 *   MESSAGE
 *   Colored feedback message with optional call to action
 *
 *   PROPS
 *   type - info/success/warning/error
 *   title - descriptive string
 *   text - string
 *   closable - boolean to determine if the user can close the message
 *   buttonText - text for the cta button (optional)
 *   buttonLink - url link for the button (optional)
 *
 **********/

import React, { useState } from 'react';
import { Button, useNavigate, ClassHelper } from 'components/lib';
import Style from './message.tailwind.js';
import "./message.scss";
import { UilTimes, UilInfoCircle, UilCheck, UilExclamationTriangle, UilExclamationOctagon } from '@iconscout/react-unicons'

export function Message(props) {
  const navigate = useNavigate();

  // state
  const [closed, setClosed] = useState(false);
  const type = props.type || 'info';

  if (closed) return false;

  const color = {
    info: 'blue',
    success: 'green',
    warning: 'orange',
    error: 'red',
  };

  const svgColor = {
    blue: "#03A6FF",
    green: "#3AC569",
    orange: "#F9C00C",
    red: "#E53A40",
  }

  const icons = {
    info: <UilInfoCircle className={Style.icon} size={45} color={svgColor[color[type]]} />,
    success: <UilCheck className={Style.icon} size={45} color={svgColor[color[type]]} />,
    warning: <UilExclamationTriangle className={Style.icon} size={45} color={svgColor[color[type]]} />,
    error: <UilExclamationOctagon className={Style.icon} size={45} color={svgColor[color[type]]} />,
  }

  // style
  const messageStyle = ClassHelper(Style, {
    message: true,
    [`${color[type]}Bg`]: true,
    className: props.className,
  });

  const titleStyle = ClassHelper(Style, {
    title: true,
    [`${color[type]}Text`]: true,
  });

  return (
    <div className={messageStyle}>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem"}}>
        {icons[type]}
        {props.title && <h1 className={titleStyle} style={{fontSize: 32, fontWeight: 500}}>{props.title}</h1>}
      </div>

      {props.closable && (
        <Button
          icon={<UilTimes />}
          position="absolute"
          className={Style.close}
          action={(e) => setClosed(true)}
        />
      )}

      <section className={Style.content} style={{marginTop: 15}}>
        {props.text && <p style={{textAlign: "center"}}>{props.text}</p>}

        {props.children && props.children}

        {props.buttonLink && (
          <Button
            className={Style.btn}
            color={color[type]}
            text={props.buttonText}
            action={(e) => navigate(props.buttonLink)}
            variant="primary"
            style={{marginBottom: 3, marginLeft: 3}}
          />
        )}
      </section>
    </div>
  );
}
