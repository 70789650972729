import React, { useState, useEffect, useContext } from 'react';
import { Col, Row } from 'react-grid-system';
import Select from 'react-select';
import SelectCreatable from 'react-select/creatable';
import Swal from 'sweetalert2';
import { UilPlus, UilTimes } from '@iconscout/react-unicons';
import CustomButton from 'components/custom/customButton';
import { Checkbox, WhitelabelContext } from 'components/lib';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export default function Mapping({
  properties,
  title,
  existingProperties,
  type,
  onSave,
  deleteIntegration,
  setUnsavedChanges
}) {
  const fieldNames = [
    { label: 'First Name', value: 'First_Name' },
    { label: 'Last Name', value: 'Last_Name' },
    { label: 'Email', value: 'Email' },
    { label: 'Phone', value: 'Phone' },
    { label: 'Company Name', value: 'Company_Name' },
  ];
  const [property, setProperty] = useState({
    local_field: '',
    foreign_field: '',
    foreign_label: '',
  });
  const [checkAll, setCheckAll] = useState(false);
  const [mappedProperties, setMappedProperties] = useState([]);
  const { whitelabelDetails } = useContext(WhitelabelContext);

  const assignedForeignFields = mappedProperties.map(property => property.foreign_field);
  const assignedLocalFields = mappedProperties.map(property => property.local_field);
  const unassignedIntegrationProperties = properties.filter(property => !assignedForeignFields.includes(property.value));
  const unassignedSalesblinkVariables = fieldNames.filter(property => !assignedLocalFields.includes(property.value));

  useEffect(() => {
    if (existingProperties && existingProperties.length > 0)
      setMappedProperties(existingProperties);
  }, [existingProperties]);

  // check if mapped properties have changes and update the parent component
  useEffect(() => {
    if(mappedProperties.length !== existingProperties.length) return setUnsavedChanges(true);

    // check properties
    for(const {local_field, foreign_field} of mappedProperties) {
      const localFieldFoundInExistingProperties = existingProperties.find(prop => prop.local_field === local_field);

      if(!localFieldFoundInExistingProperties) return setUnsavedChanges(true);
      if(localFieldFoundInExistingProperties.foreign_field !== foreign_field) return setUnsavedChanges(true);
    }

    return setUnsavedChanges(false);
  }, [mappedProperties]);

  return (
    <div className="mapping">
      {mappedProperties.length === 0 ||
      !mappedProperties?.find((property) => property.local_field === 'Email') ? (
        <div className="font-semibold mb-5">
          You must add a property for Email in order to send emails.
        </div>
      ) : null}

      <Row className="font-semibold mb-2">
        <Col sm={5.5} style={{ textTransform: 'capitalize' }}>
          {title}
        </Col>
        <Col sm={5.5}>{whitelabelDetails?.agencyName} Variable</Col>
        <Col sm={1}>Action</Col>
      </Row>
      <Row>
        <Col sm={5.5}>
          <Select
            placeholder="Select Property"
            options={unassignedIntegrationProperties}
            isClearable
            onChange={(selected) => {
              if (selected)
                setProperty({ ...property, foreign_field: selected.value, foreign_label: selected.label });
              else setProperty({ ...property, foreign_field: '', foreign_label: '' });
            }}
            value={(function () {
              const value = properties?.find(
                (prop) => prop.value === property.foreign_field
              );
              if (value) return value;
              return null;
            })()}
          />
        </Col>
        <Col sm={5.5}>
          <SelectCreatable
            styles={{
              control: () => ({
                padding: 1,
                width: '100%',
                borderRadius: 5,
                display: 'flex',
                border: `1px solid #e5e5e5`,
              }),
            }}
            placeholder="Start Typing"
            isClearable
            onChange={function (selected) {
              if (selected) {
                // check if this property is already added, if yes then show error
                const local_field = selected.value.replace(/ /g, '_');
                
                if(mappedProperties.find(prop => prop.local_field === local_field)) {
                  return Toast.fire({
                    icon: 'error',
                    title: "Another variable with same name already exists.",
                  });
                }
                
                setProperty({ ...property, local_field });
              }
              else setProperty({ ...property, local_field: '' });
            }}
            options={unassignedSalesblinkVariables}
            value={(function () {
              const value = fieldNames?.find(
                (prop) => prop.value === property.local_field
              );
              if (value) return value;
              if (property.local_field === '') return null;
              return {
                label: property.local_field.replace(/_/g, ' '),
                value: property.local_field,
              };
            })()}
          />
        </Col>
        <Col
          sm={1}
          style={{ justifyContent: 'center', display: 'flex', paddingTop: 5 }}
        >
          <UilPlus
            style={{ cursor: 'pointer', color: '#52BD95', border: "2px solid currentColor", borderRadius: 5 }}
            onClick={() => {
              if (property.foreign_field === '' || property.local_field === '')
                return Toast.fire({
                  icon: 'error',
                  title: 'Both fields needs to be selected.',
                });
              setMappedProperties([...mappedProperties, property]);
              setProperty({ local_field: '', foreign_field: '' });
            }}
          />
        </Col>
      </Row>
      {mappedProperties?.map((item, index) => (
        <Row className="mt-2" key={index}>
          <Col sm={5.5}>
            <Select
              placeholder="Select Property"
              isClearable
              isDisabled
              value={(function () {
                const value = properties?.find(
                  (prop) => prop.value === item.foreign_field
                );
                if (value) return value;
                return null;
              })()}
            />
          </Col>
          <Col sm={5.5}>
            <SelectCreatable
              styles={{
                control: () => ({
                  padding: 1,
                  width: '100%',
                  borderRadius: 5,
                  display: 'flex',
                  border: `1px solid #e5e5e5`,
                }),
              }}
              isClearable
              isDisabled
              // options={fieldNames}
              value={(function () {
                const value = fieldNames?.find(
                  (prop) => prop.value === item.local_field
                );
                if (value) return value;
                return {
                  label: item.local_field.replace(/_/g, ' '),
                  value: item.local_field,
                };
              })()}
            />
          </Col>
          <Col
            sm={1}
            style={{ justifyContent: 'center', display: 'flex', paddingTop: 5 }}
          >
              <UilTimes
                style={{ cursor: 'pointer', color: '#D14343', border: "2px solid currentColor", borderRadius: 5 }}
                onClick={() => {
                  setMappedProperties((oldProperties) => {
                    const newProperties = oldProperties.filter(
                      (_, i) => i !== index
                    );
                    return newProperties;
                  });
                }}
              />
          </Col>
        </Row>
      ))}
      {mappedProperties.length > 0 && (
        <div style={{ marginTop: 20}}>
          <Checkbox
            name="all_matching_variables"
            onChange={(i, checked, option) => setCheckAll(!checkAll)}
            checked={checkAll}
            option="Only Add those contacts to sequences who have values for all matching variables"
          />
        </div>
      )}
      <div className="button-wrapper">
        <CustomButton
          title="Disconnect"
          onClick={deleteIntegration}
          variant="secondary red"
        />
        {mappedProperties.length > 0 && (
          <CustomButton
            title={'Save'}
            onClick={() => {
              setUnsavedChanges(false);
              onSave(type, mappedProperties, checkAll)
            }}
            variant="primary"
          />
        )}
      </div>
    </div>
  );
}
