/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including master).
 *
 **********/

import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  AuthContext,
  Button,
  ViewContext,
  Form,
  Message,
  useLocation,
  useNavigate,
} from 'components/lib';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { UilShield, UilStar } from '@iconscout/react-unicons'
import defaultLogo from '../../../assets/logo-small.png';
import defaultFavicon from '../../../assets/salesblink-icon-only-logo.png';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Favicon } from '../Favicon';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

const jsonToQueryString = (json) =>
  Object.entries(json)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');

const PROVIDERS = {
  google: {
    baseURL: 'https://accounts.google.com/o/oauth2/v2/auth?',
    params: jsonToQueryString({
      client_id: process.env.REACT_APP_OAUTH_GOOGLE_CLIENTID,
      redirect_uri: `${window.location.origin}/signup/oauth-redirect`,
      scope: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
      ].join(' '),
      state: 'google oauth',
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
      include_granted_scopes: true,
    }),
  },
  outlook: {
    baseURL: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?',
    params: jsonToQueryString({
      client_id: process.env.REACT_APP_OAUTH_OUTLOOK_CLIENTID,
      redirect_uri: `${window.location.origin}/signup/oauth-redirect`,
      scope: ['offline_access', 'https://graph.microsoft.com/user.read'].join(
        ' '
      ),
      response_type: 'code',
      response_mode: 'query',
      state: 'outlook oauth',
    }),
  },
  linkedin: {
    baseURL: 'https://www.linkedin.com/oauth/v2/authorization?',
    params: jsonToQueryString({
      client_id: process.env.REACT_APP_OAUTH_LINKEDIN_CLIENTID,
      redirect_uri: `${window.location.origin}/signup/oauth-redirect`,
      scope: ['r_emailaddress', 'r_liteprofile'].join(' '),
      state: 'linkedin oauth',
      response_type: 'code',
      include_granted_scopes: true,
    }),
  },
};

export function Signin(props) {
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const location = useLocation();
  const navigate = useNavigate();
  const recaptchaRef = useRef();

  // state
  const [isLocalOrSalesBlink, setIsLocalOrSalesBlink] = useState(true);
  const [whiteLabelData, setWhiteLabelData] = useState({});
  const [customFaviconPresent, setCustomFaviconPresent] = useState(false);
  const [useMagic, setUseMagic] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const deviceWidth = useWindowResize();
  const [form, setForm] = useState({
    email: {
      label: 'Email',
      type: 'email',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
    },
    forgotpassword: {
      type: 'link',
      url: '/forgotpassword',
      text: 'Forgot your password?',
    },
  });

  // ref
  const widgetRef = useRef(null);

  useEffect(() => {
    if (authContext.user) navigate('/');
  }, [authContext.user]);

  useEffect(() => {
    // was an error message passed from the server router?
    const qs = location.search;

    if (qs.includes('error')) {
      const msg = decodeURIComponent(qs.substring(qs.indexOf('?error=') + 7));
      viewContext.notification.show(msg, 'error');
    }
  }, [location.search, viewContext.notification]);

  // to embed frill widget
  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = window.location.origin.split('//')[1];
        const response = await axios.get('/api/whitelabel', { params: { query } });
        const data = response.data.data;
        setWhiteLabelData({
          agencyName: data?.agencyName || 'SalesBlink',
          headerLogo: data?.headerLogo || defaultLogo,
          favicon: data?.favicon || defaultFavicon,
        });
        if (data?.headerLogo) setCustomFaviconPresent(true);
      } catch (error) {
        console.log('error while fetching whitelabel data', error);
      }
    }

    if(!window.location.href.includes('salesblink') && !window.location.href.includes('localhost')) {
      setIsLocalOrSalesBlink(false);
      fetchData();
    }
    else if (deviceWidth > 991) {
      let widget;
      const config = {
        key: 'f7bf2e48-2880-4d58-b62c-a93859c16935', // <-- Add Widget key here
        container: widgetRef.current,
        callbacks: {
          onReady: (frillWidget) => {
            widget = frillWidget;
          },
        },
      };
  
      window.Frill_Config = window.Frill_Config || [];
      window.Frill_Config.push(config);
  
      if ('Frill' in window) {
        widget = window.Frill.widget(config);
      }

      return () => {
        widget?.destroy();
        if (window.Frill_Config) {
          window.Frill_Config = window.Frill_Config.filter((c) => c !== config);
        }
      };
    }
  }, []);

  function toggleMagicLink(useMagic) {
    setForm((previousState) => {
      previousState.password.type = useMagic ? 'hidden' : 'password';
      previousState.password.required = useMagic ? false : true;
      previousState.forgotpassword.type = useMagic ? 'hidden' : 'link';

      return { ...previousState };
    });

    setUseMagic(useMagic);
  }

  function redirectToOauth(providerName) {
    window.location.href = `${PROVIDERS[providerName].baseURL}${PROVIDERS[providerName].params}`;
  }

  return (
    <div className="auth-signin">
      {customFaviconPresent && <Favicon url={whiteLabelData?.favicon} />}
      {whiteLabelData?.agencyName && <Helmet><title>SignIn | {whiteLabelData?.agencyName}</title></Helmet>}
      <div className="main">
        <div className="header">
          <div className="logo">
            {!isLocalOrSalesBlink && whiteLabelData?.headerLogo && <img src={whiteLabelData?.headerLogo} alt="Salesblink Logo" width={198} height={44} />}
            {isLocalOrSalesBlink && <img src={defaultLogo} alt="Salesblink Logo" width={198} height={44} />}
          </div>
          <p>Sign In</p>
        </div>
        {magicLinkSent ? (
          <div style={{ width: '28em', margin: '0 auto' }}>
            <Message
              title="Check Your Email"
              type="success"
              text="Please click the link in your email inbox to sign in."
            />
          </div>
        ) : (
          <div className="signin-form">
            {isLocalOrSalesBlink && <>
              <div className="oauth">
                <button
                  class="gsi-material-button"
                  onClick={() => redirectToOauth('google')}
                >
                  <div class="gsi-material-button-state"></div>
                  <div class="gsi-material-button-content-wrapper">
                    <div class="gsi-material-button-icon">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        // xmlns:xlink="http://www.w3.org/1999/xlink"
                        style={{ display: 'block' }}
                      >
                        <path
                          fill="#EA4335"
                          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                        ></path>
                        <path
                          fill="#4285F4"
                          d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                        ></path>
                        <path
                          fill="#FBBC05"
                          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                        ></path>
                        <path
                          fill="#34A853"
                          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                        ></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </svg>
                    </div>
                    <span class="gsi-material-button-contents">
                      Sign in with Google
                    </span>
                    <span style={{ display: 'none' }}>Sign in with Google</span>
                  </div>
                </button>
                <button
                  className="button outlook"
                  onClick={() => redirectToOauth('outlook')}
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="icons8-microsoft 2">
                      <path
                        id="Vector"
                        d="M11.4583 11.4583L3.12501 11.4583L3.12501 3.12492L11.4583 3.12492L11.4583 11.4583Z"
                        fill="#FF5722"
                      />
                      <path
                        id="Vector_2"
                        d="M21.875 11.4583L13.5417 11.4583L13.5417 3.12492L21.875 3.12492V11.4583Z"
                        fill="#4CAF50"
                      />
                      <path
                        id="Vector_3"
                        d="M21.875 21.875H13.5417L13.5417 13.5417L21.875 13.5417V21.875Z"
                        fill="#FFC107"
                      />
                      <path
                        id="Vector_4"
                        d="M11.4583 21.875H3.12501L3.12501 13.5417L11.4583 13.5417L11.4583 21.875Z"
                        fill="#03A9F4"
                      />
                    </g>
                  </svg>
                  Sign in with Outlook
                </button>
                <button
                  className="button linkedin"
                  onClick={() => redirectToOauth('linkedin')}
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="icons8-linkedin 1">
                      <path
                        id="Vector"
                        d="M21.875 19.2708C21.875 20.7094 20.7094 21.875 19.2708 21.875H5.72917C4.29115 21.875 3.125 20.7094 3.125 19.2708V5.72917C3.125 4.29062 4.29115 3.125 5.72917 3.125H19.2708C20.7094 3.125 21.875 4.29062 21.875 5.72917V19.2708Z"
                        fill="#0288D1"
                      />
                      <path
                        id="Vector_2"
                        d="M6.25 9.89583H8.85417V18.75H6.25V9.89583ZM7.54427 8.85417H7.52969C6.7526 8.85417 6.25 8.275 6.25 7.55156C6.25 6.8125 6.76823 6.25 7.55937 6.25C8.35156 6.25 8.83958 6.8125 8.85417 7.55156C8.85417 8.27448 8.35156 8.85417 7.54427 8.85417ZM18.75 18.75H16.1458V14.0109C16.1458 12.8661 15.5078 12.0849 14.4833 12.0849C13.7016 12.0849 13.2786 12.612 13.0734 13.1214C12.9984 13.3036 13.0208 13.8078 13.0208 14.0625V18.75H10.4167V9.89583H13.0208V11.2583C13.3964 10.6771 13.9844 9.89583 15.4885 9.89583C17.3521 9.89583 18.7495 11.0677 18.7495 13.6844L18.75 18.75Z"
                        fill="#F9F9F9"
                      />
                    </g>
                  </svg>
                  Sign in with LinkedIn
                </button>
              </div>

              <div className="separator">
                <span>OR</span>
              </div>
            </>}

            <div className="form">
              {useMagic ? (
                <Button
                  text="Use password"
                  color="blue"
                  icon={<UilShield color="#03a6ff" />}
                  iconColor="white"
                  className="mx-auto mb-5 flex justify-around items-center"
                  rounded
                  iconButton
                  small
                  variant="secondary"
                  action={() => {
                    toggleMagicLink(false);
                  }}
                />
              ) : isLocalOrSalesBlink ? 
              <Button
                text="Use magic link instead"
                color="blue"
                icon={<UilStar color="#03a6ff" />}
                iconColor="white"
                className="mx-auto mb-5 flex flex-col justify-around items-center"
                rounded
                iconButton
                small
                variant="secondary"
                action={() => {
                  toggleMagicLink(true);
                }}
              /> : null}
              <Form
                data={form}
                method="POST"
                url={useMagic ? '/api/auth/magic' : '/api/auth'}
                buttonText={useMagic ? 'Send Magic Link' : 'Sign In'}
                callback={(res) => {
                  if (useMagic) setMagicLinkSent(true);
                  else if (res.data['2fa_required'])
                    navigate(`/signin/otp?token=${res.data.token}`);
                  else navigate(authContext.signin(res));
                }}
                type="auth"
                recaptchaRef={recaptchaRef}
                agencyName={whiteLabelData?.agencyName}
              />
            </div>

            <div className="redirect-to-signup mx-auto">
              Don't have an account? <Link to="/signup">Sign Up</Link>
            </div>
          </div>
        )}
        {isLocalOrSalesBlink && <p className="footer">
          Copyright © {whiteLabelData?.agencyName || 'SalesBlink'} {new Date().getFullYear()}, All Rights Reserved
        </p>}
      </div>
      {isLocalOrSalesBlink && deviceWidth > 991 && <div className="details">
        <div className="background">
          <div className="hover-thingy left"></div>
          <div className="hover-thingy top-right"></div>
          <div className="hover-thingy right"></div>
          <div className="hover-thingy bottom"></div>
          <div className="hover-thingy bottom-left"></div>
        </div>
        <div className="content" ref={widgetRef}></div>
      </div>}
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={
          process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY ||
          '6Lckd18nAAAAAPxOvLjxlM-q_1uMbmAxdM4ivuq8'
        }
        size="invisible"
      />
    </div>
  );
}
