import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import 'react-app-polyfill/ie9';
import { createRoot } from 'react-dom/client';
import App from './app/app';

import TagManager from 'react-gtm-module';
import { clarity } from 'react-microsoft-clarity';
import 'reactflow/dist/style.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "./index.scss";

Sentry.init({
  dsn: 'https://dc2907ad11db4193b7dcdf9c8a00f1c8@o127109.ingest.sentry.io/4504360251883520',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

TagManager.initialize({ gtmId: 'GTM-TXW6B42' }); // Google Tag Manager
clarity.init('co5im8sdm8'); // Microsoft Clarity Tracking

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

const welcome = () => console.log('Welcome to SalesBlink 🚀');

welcome('de529c70-eb80-4dfb-9540-5075db7545bf');