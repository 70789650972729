import { Modal } from 'components/lib';

function CustomDialog(props) {
  return (
    <>
      <div
        style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}
      >
        <Modal
          custom={true}
          setModalVisible={props.setModalVisible}
          wide={props.wide}
          title={props.title}
          width={props.width || 878}
          scroll={props.scroll}
        >
          {props.children}
        </Modal>
      </div>
    </>
  );
}

export default CustomDialog;
