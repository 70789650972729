import { Signup } from 'views/auth/signup/account';
import OauthRedirect from 'views/auth/OauthRedirect';
import { SignupUser } from 'views/auth/signup/user';
import { Signin } from 'views/auth/signin';
import { SigninOTP } from 'views/auth/signin/otp';
import { SocialSignin } from 'views/auth/signin/social';
import { ForgotPassword } from 'views/auth/signin/forgotpassword';
import { ResetPassword } from 'views/auth/signin/resetpassword';
import { MagicSignin } from 'views/auth/signin/magic';
import { ImpersonateSignin } from 'views/auth/signin/impersonate';
import PhonePage from 'views/auth/signup/phonepage';

const Routes = [
  {
    path: '/signup',
    view: Signup,
    layout: 'auth',
    title: 'SignUp | SalesBlink',
  },
  {
    path: '/signup/oauth-redirect',
    view: OauthRedirect,
    layout: 'auth',
    title: 'Oauth Redirect',
  },
  {
    path: '/signup/phone',
    view: PhonePage,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard',
  },
  // {
  //   path: '/signup/plan',
  //   view: SignupPlan,
  //   layout: 'auth',
  //   permission: 'owner',
  //   title: 'SignUp | SalesBlink',
  // },
  {
    path: '/signup/user',
    view: SignupUser,
    layout: 'auth',
    title: 'Sign up to SalesBlink',
  },
  {
    path: '/signin',
    view: Signin,
    layout: 'auth',
    title: 'SignIn | SalesBlink',
  },
  {
    path: '/signin/otp',
    view: SigninOTP,
    layout: 'auth',
    title: 'Enter verification code',
  },
  {
    path: '/signin/social',
    view: SocialSignin,
    layout: 'auth',
    title: 'Completing Sign In',
  },
  {
    path: '/magic',
    view: MagicSignin,
    layout: 'auth',
    title: 'Sign In',
  },
  {
    path: '/forgotpassword',
    view: ForgotPassword,
    layout: 'auth',
    title: 'Forgot Password | SalesBlink',
  },
  {
    path: '/resetpassword',
    view: ResetPassword,
    layout: 'auth',
    title: 'Reset Your Password',
  },
  {
    path: '/signin/impersonate',
    view: ImpersonateSignin,
    layout: 'auth',
    title: 'Sign in via Mission Control',
  },
];

export default Routes;
