const frames = {
    oak: {
        bottom: "/images/frames/oak/bottom.png",
        bottomLeft: "/images/frames/oak/bottomLeft.png",
        bottomRight: "/images/frames/oak/bottomRight.png",
        left: "/images/frames/oak/left.png",
        right: "/images/frames/oak/right.png",
        top: "/images/frames/oak/top.png",
        topLeft: "/images/frames/oak/topLeft.png",
        topRight: "/images/frames/oak/topRight.png"
        // Add other parts as needed
    },
    rainbow: {
        bottom: "/images/frames/rainbow/bottom.png",
        bottomLeft: "/images/frames/rainbow/bottomLeft.png",
        bottomRight: "/images/frames/rainbow/bottomRight.png",
        left: "/images/frames/rainbow/left.png",
        right: "/images/frames/rainbow/right.png",
        top: "/images/frames/rainbow/top.png",
        topLeft: "/images/frames/rainbow/topLeft.png",
        topRight: "/images/frames/rainbow/topRight.png"
        // Add other parts as needed
    },
    art1: {
        bottom: "/images/frames/art1/bottom.png",
        bottomLeft: "/images/frames/art1/bottomLeft.png",
        bottomRight: "/images/frames/art1/bottomRight.png",
        left: "/images/frames/art1/left.png",
        right: "/images/frames/art1/right.png",
        top: "/images/frames/art1/top.png",
        topLeft: "/images/frames/art1/topLeft.png",
        topRight: "/images/frames/art1/topRight.png"
        // Add other parts as needed
    },
    art2: {
        bottom: "/images/frames/art2/bottom.png",
        bottomLeft: "/images/frames/art2/bottomLeft.png",
        bottomRight: "/images/frames/art2/bottomRight.png",
        left: "/images/frames/art2/left.png",
        right: "/images/frames/art2/right.png",
        top: "/images/frames/art2/top.png",
        topLeft: "/images/frames/art2/topLeft.png",
        topRight: "/images/frames/art2/topRight.png"
        // Add other parts as needed
    },
    basic: {
        bottom: "/images/frames/basic/bottom.png",
        bottomLeft: "/images/frames/basic/bottomLeft.png",
        bottomRight: "/images/frames/basic/bottomRight.png",
        left: "/images/frames/basic/left.png",
        right: "/images/frames/basic/right.png",
        top: "/images/frames/basic/top.png",
        topLeft: "/images/frames/basic/topLeft.png",
        topRight: "/images/frames/basic/topRight.png"
        // Add other parts as needed
    },
    ebony: {
        bottom: "/images/frames/ebony/bottom.png",
        bottomLeft: "/images/frames/ebony/bottomLeft.png",
        bottomRight: "/images/frames/ebony/bottomRight.png",
        left: "/images/frames/ebony/left.png",
        right: "/images/frames/ebony/right.png",
        top: "/images/frames/ebony/top.png",
        topLeft: "/images/frames/ebony/topLeft.png",
        topRight: "/images/frames/ebony/topRight.png"
        // Add other parts as needed
    },
    grunge1: {
        bottom: "/images/frames/grunge1/bottom.png",
        bottomLeft: "/images/frames/grunge1/bottomLeft.png",
        bottomRight: "/images/frames/grunge1/bottomRight.png",
        left: "/images/frames/grunge1/left.png",
        right: "/images/frames/grunge1/right.png",
        top: "/images/frames/grunge1/top.png",
        topLeft: "/images/frames/grunge1/topLeft.png",
        topRight: "/images/frames/grunge1/topRight.png"
        // Add other parts as needed
    },
    grunge2: {
        bottom: "/images/frames/grunge2/bottom.png",
        bottomLeft: "/images/frames/grunge2/bottomLeft.png",
        bottomRight: "/images/frames/grunge2/bottomRight.png",
        left: "/images/frames/grunge2/left.png",
        right: "/images/frames/grunge2/right.png",
        top: "/images/frames/grunge2/top.png",
        topLeft: "/images/frames/grunge2/topLeft.png",
        topRight: "/images/frames/grunge2/topRight.png"
        // Add other parts as needed
    },
    pine: {
        bottom: "/images/frames/pine/bottom.png",
        bottomLeft: "/images/frames/pine/bottomLeft.png",
        bottomRight: "/images/frames/pine/bottomRight.png",
        left: "/images/frames/pine/left.png",
        right: "/images/frames/pine/right.png",
        top: "/images/frames/pine/top.png",
        topLeft: "/images/frames/pine/topLeft.png",
        topRight: "/images/frames/pine/topRight.png"
        // Add other parts as needed
    }
    // Add other frames as needed
};

export default frames;