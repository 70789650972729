/***
 *
 *   RESET PASSWORD
 *   User can set a new password using the token
 *
 **********/

import axios from 'axios';
import {
  Animate,
  AuthContext,
  Card,
  Form,
  Row,
  useNavigate,
} from 'components/lib';
import { useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import defaultLogo from '../../../assets/salesblink-icon-logo.png';
import defaultFavicon from '../../../assets/salesblink-icon-only-logo.png';
import { Favicon } from '../Favicon';

export function ResetPassword(props) {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const token = new URLSearchParams(window.location.search).get('token');
  const recaptchaRef = useRef();
  const [whiteLabelData, setWhiteLabelData] = useState({});
  const [customFaviconPresent, setCustomFaviconPresent] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = window.location.origin.split('//')[1];
        const response = await axios.get('/api/whitelabel', { params: { query } });
        const data = response.data.data;
        setWhiteLabelData({
          agencyName: data?.agencyName || 'SalesBlink',
          headerLogo: data?.headerLogo || defaultLogo,
          favicon: data?.favicon || defaultFavicon,
        });
        if (data?.headerLogo) setCustomFaviconPresent(true);
      } catch (error) {
        console.log('error while fetching whitelabel data', error);
      }
    }
    if(!window.location.href.includes('salesblink') && !window.location.href.includes('localhost')) fetchData();
  }, [])

  return (
    <Animate type="pop">
      {customFaviconPresent && <Favicon url={whiteLabelData?.favicon} />}
      <Row title="Reset Your Password">
        <Card restrictWidth center>
          <Form
            data={{
              jwt: {
                type: 'hidden',
                value: token,
              },
              email: {
                label: 'Email',
                type: 'email',
              },
              password: {
                label: 'New Password',
                type: 'password',
                required: true,
                complexPassword: true,
              },
            }}
            url="/api/auth/password/reset"
            method="POST"
            buttonText="Set New Password"
            callback={(res) => {
              res.data['2fa_required']
                ? navigate(`/signin/otp?token=${res.data.token}`)
                : context.signin(res);
            }}
            type="auth"
            recaptchaRef={recaptchaRef}
          />
        </Card>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={
            process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY ||
            '6Lckd18nAAAAAPxOvLjxlM-q_1uMbmAxdM4ivuq8'
          }
          size="invisible"
        />
      </Row>
    </Animate>
  );
}
