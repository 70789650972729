import Axios from 'axios';
import { Col, Row } from 'react-grid-system';
import { useNavigate, useParams } from 'react-router-dom';
import CreateTemplate from './createTemplate';
import { PixieProvider } from './pixieContext';
import { PixieEditor } from './pixieEditor';
import BlinkGPT from '../../assets/BlinkGPT-filled.png';
import { WhitelabelContext } from 'app/whitelabel';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

export default function GenerateChatGPTSequence() {
  let navigator = useNavigate();
  let { id } = useParams();
  const { whitelabelDetails } = useContext(WhitelabelContext);
  const deviceWidth = useWindowResize();

  async function handleSubmit(data) {
    try {
      await Axios.put(`/api/sequences/${id}/generate`, {
        template: data,
      });
      navigator(`/outreach/sequences/${id}`);
      return false;
    } catch (e) {
      return false;
    }
  }

  async function handleCandel() {
    try {
      await Axios.delete(`/api/sequences/${id}`);
      navigator(`/outreach/sequences`);
      return false;
    } catch (e) {
      return false;
    }
  }

  return (
    <PixieProvider>
      <Helmet>
        <title>{`Sequence Generate | ${whitelabelDetails?.agencyName}`}</title>
      </Helmet>
      <PixieEditor />
      <div>
        <Row style={{ justifyContent: 'center' }}>
          <Col lg={9}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 16,
                marginBottom: 16,
              }}
            >
              <h1
                style={{ textAlign: 'center', fontSize: 24, fontWeight: 500 }}
              >
                Generate Sequence with BlinkGPT
              </h1>
              <img src={BlinkGPT} width={30} height={30} />
            </div>
            <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 400 }}>
              Use BlinkGPT AI to draft your initial cold email & BlinkGPT AI will then create a high performing cold email sequence along with multiple follow-ups for you.
            </p>
            <div style={{
              marginTop: 20,
              borderRadius: 5,
              paddingBottom: 20,
              border: deviceWidth > 991 ? `1px solid #D7D7D7` : '',
              boxShadow: deviceWidth > 991 ? '-2px 2px 4px rgba(0, 0, 0, 0.05)' : '',
            }}>
              <CreateTemplate
                width="95%"
                marginTop={10}
                paddingBottom={10}
                position="relative"
                top={1}
                // left="-25%"
                generate={true}
                save_button_text="Create Sequence with BlinkGPT"
                onSubmit={(e) => handleSubmit(e)}
                onClose={() => handleCandel()}
              />
            </div>
          </Col>
        </Row>
      </div>
    </PixieProvider>
  );
}
