import React, { useState } from 'react';
import Style from './header.module.scss';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/lib';

const PromotionBar = (props) => {
  let navigator = useNavigate();
  const [diff, setDiff] = useState('');

  if (!props.subscription.loading && diff === '') {
    let current_period_end = DateTime.fromFormat(
      props?.subscription?.data?.object.current_period_end,
      'd MMM yyyy'
    );
    const final_diff = Math.floor(
      current_period_end.diffNow('day').values.days
    );
    setDiff(final_diff);
  }

  return (
    <div className={Style.info}>
      {diff === 0 ? 
        <p>Your Trial has Ended.</p>
      :
        <p>You have {diff} days left in your trial.</p>
      }
      <Button
        variant="primary"
        text={diff === 0 ? "Subscribe to a Plan" : "Subscribe Now"}
        action={() => navigator('/account/billing?type=upgrade')}
        style={{ fontSize: 14, padding: "4px 7px", minWidth: "fit-content" }}
      />
    </div>
  );
};
export default PromotionBar;
