import React, { useContext, useState, useEffect } from 'react';

const FlowchartContext = React.createContext();

export function useFlowchartData() {
  return useContext(FlowchartContext);
}

export function FlowchartProvider({ children }) {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [editNode, setEditNode] = useState(null);
  const [dialogShowing, setDialogShowing] = useState(false);
  const [showCreateTemplate, setShowCreateTemplate] = useState(false);
  const [editTemplateID, setEditTemplateID] = useState(null);
  const [showCreateTaskTemplate, setShowCreateTaskTemplate] = useState(false);
  const [taskType, setTaskType] = useState(null);
  const [showRemoveDialog, setShowRemoveDialog] = useState(null);
  const [isReportMode, setIsReportMode] = useState(false);
  const [sequenceData, setSequenceData] = useState({});
  const [aiPersonalized, setAiPersonalized] = useState(false);

  const getRandomSuffix = () => (Math.random() + 1).toString(36).substring(2);

  useEffect(() => {
    const debounceAdjustPosition = debounce(() => {
      let firstNode = nodes.find(node => node?.type === "source_connect");
      if (firstNode) {
        let maxSourceHeight = 0;

        // Get the height of the source node with the highest height
        nodes.forEach(node => {
          if (node.group === "source") {
            const nodeHeight = node.height || 100;
            if (nodeHeight > maxSourceHeight) maxSourceHeight = nodeHeight;
          }
        });

        // Adjust the position of the "source_connect" node based on maxSourceHeight
        const sourceConnectAdjustedNodes = nodes.map(_n => {
          if (_n.id === firstNode.id) _n.position.y = maxSourceHeight + 180;
          return _n;
        });

        // Adjust the position of every node after the "source_connect" node
        const adjustedNodes = reAdjustNodePosition([...sourceConnectAdjustedNodes], [...edges], firstNode);
        setNodes(adjustedNodes);
      }
    }, 1000);

    const cancelDebounce = () => {
      debounceAdjustPosition.cancel();
    };

    debounceAdjustPosition();

    return () => {
      cancelDebounce();
    };
  }, [nodes]);

  const debounce = (fn, delay) => {
    let timeoutId;
    const debounced = function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
    debounced.cancel = () => {
      clearTimeout(timeoutId);
    };
    return debounced;
  };

  const reAdjustNodePosition = (newNodes, newEdges, currentNode) => {
    const height = currentNode.height || 100;
    const nodeID = currentNode.id;
    const connectedEdgeList = edges.filter(e => e.source === nodeID);

    if (connectedEdgeList.length > 0) {
      for (const _edge of connectedEdgeList) {
        const _node = newNodes.find(n => n.id === _edge.target);

        if (!_node) continue;

        // Update the Y position of connected nodes
        const updatedNodes = newNodes.map(n => {
          if (n.id === _node.id) n.position.y = currentNode.position.y + height + 100; // Add space between nodes
          return n;
        });

        // Recursively adjust connected nodes
        reAdjustNodePosition(updatedNodes, newEdges, _node);
      }
    }

    return newNodes;
  };

  const getParentEdge = (nodeID) => {
    for (let e of edges) {
      if (e.target === nodeID) return e;
    }
  };

  const isInIfBranch = (nodeID) => {
    if (!nodeID) return;
    let firstIfElseNode = getFirstNodeOfType('ifelse');
    if (!firstIfElseNode) return;

    let parentEdge = getParentEdge(nodeID);
    if (parentEdge?.source === firstIfElseNode.id) {
      if (parentEdge?.sourceHandle.includes('left')) return 'left';
      if (parentEdge?.sourceHandle.includes('right')) return 'right';
      return;
    }
  };

  const isNodeTypeInBranch = (nodeID, type) => {
    let currentNode = getNodeByID(nodeID);
    let parentEdge = getParentEdge(nodeID);
    while (currentNode && parentEdge) {
      if (currentNode?.type === type) {
        if (parentEdge) return true;
      }
      currentNode = getNodeByID(parentEdge?.source);
      parentEdge = getParentEdge(parentEdge?.source);
    }
    return false;
  };

  function isFirstEmailNode(nodeId) {
    const incomingEdges = edges.filter((edge) => edge.target === nodeId);
    for (const edge of incomingEdges) {
      const sourceNode = getNodeByID(edge.source);
      if (sourceNode?.type === 'email') return true;
      if (isFirstEmailNode(sourceNode?.id)) return true;
    }
    return false;
  }

  const addPlusNode = (
    sourceID,
    handle,
    _position,
    source,
    keepYPosition,
    unconnected
  ) => {
    let position = { x: selectedNode?.xPos, y: selectedNode?.yPos + 250 };
    // console.log("SRC ", selectedNode, position)
    if (_position) {
      if (!keepYPosition) _position.y += 200;
      position = _position;
    }
    if (source) {
      position.y -= 180;
    }

    // Node Position based on ifelse
    let nodeBranch = isInIfBranch(selectedNode?.id || sourceID); // (left, right, null)

    if (handle === 'left') {
      position.x -= 250;
      position.y += 50;
    } else if (handle === 'right') {
      position.x += 250;
      position.y += 50;
    }

    if (nodeBranch === 'left') {
      position.x -= 120;
    } else if (nodeBranch === 'right') {
      position.x += 120;
    }

    let newPlusNode = {
      id: `node-${getRandomSuffix()}`,
      sourcePosition: 'top',
      isSource: source || false,
      draggable: false,
      type: 'add',
      data: {
        addType: source ? 'source' : undefined,
      },
      position,
      draggable: true,
    };

    let newPlusNodeEdge = {
      id: `edge-${getRandomSuffix()}`,
      animated: false,
      sourceHandle: handle
        ? sourceID + '-src-' + handle
        : unconnected
          ? sourceID + '-output'
          : '',
      source: sourceID,
      target: newPlusNode.id,
      type: 'default',
    };

    if (source) {
      newPlusNodeEdge = {
        id: `edge-${getRandomSuffix()}`,
        animated: false,
        sourceHandle: sourceID + '-src-right',
        source: sourceID,
        target: newPlusNode.id,
        type: 'default',
      };
    }

    // Add New Node and Remove Previous one
    setNodes((oldNodes) => {
      const newNodes = [...oldNodes, newPlusNode];
      return newNodes;
    });
    // Remove Old Edge and Add New One
    setEdges((oldEdges) => {
      const newEdges = [...oldEdges, newPlusNodeEdge];
      return newEdges;
    });
  };

  const updateNode = (nodeID, data) => {
    setNodes((_nodes) => {
      let newNodes = _nodes.map((_node) => {
        if (_node.id === nodeID) _node.data = data;
        return _node;
      });
      return newNodes;
    });
  };

  const hasSourceNodes = () => {
    for (let _node of nodes) {
      if (_node.group === 'source' && _node.type !== "add_source_block") return true;
    }
    return false;
  };

  const getUnconnectedNode = () => {
    const hasSourceEdge = (nodeID) => {
      for (let _edge of edges) {
        if (_edge?.target === nodeID) return true;
      }
      return false;
    };
    for (let _node of nodes) {
      if (_node?.group === 'source' || _node?.type === 'add') continue;
      if (!hasSourceEdge(_node?.id)) return _node;
    }
    return false;
  };

  const reorganizeNodes = (nodeID, newEdges, newNodes, handles) => {
    let edge = newEdges.filter((e) => e.source === nodeID);
    if (edge.length > 0) {
      for (let i = 0; i < edge.length; i++) {
        nodeID = edge[i].target;
        let nodeEdge = newEdges.find((e) => e.source === nodeID);
        newNodes = newNodes.map((oldNode) => {
          if (oldNode.id === nodeID)
            oldNode.position.y = oldNode.position.y + 250;
          if (handles && nodeEdge && oldNode.id === nodeEdge.target)
            oldNode.position.x = oldNode.position.x - 250;
          return oldNode;
        });
        reorganizeNodes(nodeID, newEdges, newNodes, handles);
      }
    } else {
      setNodes(newNodes);
      setEdges(newEdges);
    }
  };

  const reorganizeRemoveNodes = (nodeID, newEdges, newNodes, type) => {
    let edge = newEdges.filter((e) => e.source === nodeID);
    if (edge.length > 0) {
      for (let i = 0; i < edge.length; i++) {
        nodeID = edge[i].target;
        newNodes = newNodes.map((oldNode) => {
          if (oldNode.id === nodeID) {
            oldNode.position.y = oldNode.position.y - 250;
            if (type && type === 'yes')
              oldNode.position.x = oldNode.position.x - 250;
            else if (type && type === 'no')
              oldNode.position.x = oldNode.position.x + 250;
          }
          return oldNode;
        });
        reorganizeRemoveNodes(nodeID, newEdges, newNodes, type);
      }
    } else {
      setNodes(newNodes);
      setEdges(newEdges);
    }
  };

  const addEdgeNode = (type, handles, group, condition, data, edgeData) => {
    let edge = edges.find((e) => e.id === edgeData.edgeId);
    let node = nodes.find((n) => n.id === edgeData.target);
    let newNode = {
      id: `node-${getRandomSuffix()}`,
      sourcePosition: 'top',
      type: type,
      draggable: false,
      group,
      data: { label: type, ...data },
      position: { x: selectedNode?.xPos, y: selectedNode?.yPos + 250 },
    };

    let newEdge = {
      id: `edge-${getRandomSuffix()}`,
      animated: false,
      source: newNode.id,
      sourceHandle: '',
      target: edge.target,
      type: 'buttonedge',
    };

    edge.target = newNode.id;

    if (handles) {
      for (let handle of handles) {
        if (handle === 'left') {
          node.position.x -= 250;
          newEdge.sourceHandle = newNode.id + '-src-' + handle;
        }
      }
    }

    let newNodes = nodes.filter((oldNode) => oldNode.id !== edgeData.target);
    newNodes = [...newNodes, node, newNode];

    let newEdges = edges.filter((oldEdge) => oldEdge.id !== edgeData.edgeId);
    newEdges = [...newEdges, edge, newEdge];

    if (handles) {
      for (let handle of handles) {
        if (handle !== 'left') {
          let newPlusNode = {
            id: `node-${getRandomSuffix()}`,
            sourcePosition: 'top',
            isSource: false,
            draggable: false,
            type: 'add',
            data: {
              addType: undefined,
            },
            position: { x: node.position.x + 550, y: node.position.y },
          };

          let newPlusNodeEdge = {
            id: `edge-${getRandomSuffix()}`,
            animated: false,
            sourceHandle: newNode.id + '-src-' + handle,
            source: newNode.id,
            target: newPlusNode.id,
            type: 'default',
          };
          newNodes = [...newNodes, newPlusNode];
          newEdges = [...newEdges, newPlusNodeEdge];
        }
      }
    }

    reorganizeNodes(newNode.id, newEdges, newNodes, handles);
  };

  const createInitialBlocks = () => {
    let sourceAddNode = {
      id: `node-${getRandomSuffix()}`,
      sourcePosition: 'top',
      type: "add_source_block",
      draggable: false,
      group: "source",
      data: {},
      position: { x: 150, y: 80 },
    }
    let sourceConnectNode = {
      id: `node-${getRandomSuffix()}`,
      sourcePosition: 'top',
      type: "source_connect",
      draggable: false,
      // group: "source",
      data: { first: true },
      position: { x: 150, y: 280 },
    }

    let newPlusNode = {
      id: `node-${getRandomSuffix()}`,
      sourcePosition: 'top',
      isSource: false,
      draggable: false,
      type: 'add',
      data: {},
      position: { x: 255, y: 380 },
    };

    let newPlusNodeEdge = {
      id: `edge-${getRandomSuffix()}`,
      animated: false,
      sourceHandle: '',
      source: sourceConnectNode.id,
      target: newPlusNode.id,
      type: 'default',
    };

    setNodes([sourceAddNode, sourceConnectNode, newPlusNode])
    setEdges([newPlusNodeEdge])
  }

  const addSourceNode = (type, group, condition, data) => {
    console.log("PARAMS ", type, group, data)
    let sourceAddNode = nodes.find(node => node.type === "add_source_block")
    let sourceConnectNode = nodes.find(node => node.type === "source_connect")

    // Add New Node
    let newNode = {
      id: `node-${getRandomSuffix()}`,
      sourcePosition: 'top',
      type: type,
      draggable: false,
      group,
      data: { label: type, condition, ...data },
      position: { x: 788, y: 80 },
    };

    let newEdge = {
      id: `edge-${getRandomSuffix()}`,
      animated: false,
      source: newNode.id,
      sourceHandle: "",
      target: sourceConnectNode?.id,
      type: 'default',
    };

    console.log("SOURCE ", newNode)

    setNodes((oldNodes) => [newNode, ...oldNodes]);
    setEdges((oldEdges) => [...oldEdges, newEdge]);

    setDialogShowing(false);
  }

  const addNode = (type, handles, source, group, condition, data) => {
    // If Source is true create a source node
    if (!source) {
      // console.log("LOG ", selectedNode)
      let newNodeID = `node-${getRandomSuffix()}`;
      let newNode = {
        id: newNodeID,
        sourcePosition: 'top',
        type: type,
        draggable: false,
        group,
        data: { label: type, ...data },
        position: { x: selectedNode?.xPos - 102, y: selectedNode?.yPos },
        draggable: true,
      };

      // Get Parent Edge ID
      let parentEdge = edges.filter(
        (oldEdge) => oldEdge.target === selectedNode.id
      );

      // Get Source Handle
      let newEdge = {
        id: `edge-${getRandomSuffix()}`,
        animated: false,
        source: parentEdge[0]?.source,
        sourceHandle: parentEdge[0]?.sourceHandle,
        target: newNode.id,
        type: 'buttonedge',
      };
      // Add New Node and Remove Previous one
      setNodes((oldNodes) => {
        oldNodes = oldNodes.filter((oldNode) => oldNode.id !== selectedNode.id);
        const newNodes = [...oldNodes, newNode];
        return newNodes;
      });
      // Remove Old Edge and Add New One
      setEdges((oldEdges) => {
        oldEdges = oldEdges.filter(
          (oldEdge) => oldEdge.target !== selectedNode.id
        );
        const newEdges = [...oldEdges, newEdge];
        return newEdges;
      });

      if (!handles) {
        if (group !== 'action') addPlusNode(newNode.id);
      } else {
        for (let handle of handles) {
          addPlusNode(newNode.id, handle);
        }
      }
    } else {
      // Get Source Node
      let prevSourceID = selectedNode
        ? getParentNodeID(selectedNode?.id)
        : null;
      let prevSourceEdge = prevSourceID
        ? edges.find((ed) => ed.source === prevSourceID)
        : null;

      // Add New Node
      let newNodeID = `node-${getRandomSuffix()}`;
      let newNode = {
        id: newNodeID,
        sourcePosition: 'top',
        type: type,
        draggable: false,
        group,
        data: { label: type, condition, ...data },
        position: { x: 788, y: 80 },
      };

      // If First Source Node set `first` in data object
      // if (!hasSourceNodes()) newNode.data?.first = true;
      setNodes((oldNodes) => {
        const newNodes = [...oldNodes, newNode];
        return newNodes;
      });
      if (prevSourceEdge) {
        insertSourceBetweenNodes(
          newNodeID,
          prevSourceEdge?.source,
          selectedNode.id
        );
      } else {
        // if (!handles) {
        //   let unconnectedNode = getUnconnectedNode();
        //   addPlusNode(
        //     newNode.id,
        //     null,
        //     { x: newNode?.position?.x + 300, y: newNode?.position?.y },
        //     true
        //   );
        //   if (!unconnectedNode)
        //     addPlusNode(
        //       newNode.id,
        //       null,
        //       { x: 250, y: 80 },
        //       false,
        //       false,
        //       true
        //     );
        //   else {
        //     let newEdge = {
        //       id: `edge-${getRandomSuffix()}`,
        //       animated: false,
        //       source: newNode.id,
        //       sourceHandle: newNode.id + '-output',
        //       target: unconnectedNode?.id,
        //       type: 'default',
        //     };

        //     setEdges((oldEdges) => {
        //       const newEdges = [...oldEdges, newEdge];
        //       return newEdges;
        //     });
        //   }
        // }
      }
    }

    setDialogShowing(false);
  };

  const insertSourceBetweenNodes = (newNodeID, sourceNodeID, targetNodeID) => {
    let prevSourceToNewSource = {
      id: `edge-${getRandomSuffix()}`,
      animated: false,
      source: sourceNodeID,
      sourceHandle: sourceNodeID + '-src-right',
      target: newNodeID,
      type: 'default',
    };

    let newSourceToPlusEdge = {
      id: `edge-${getRandomSuffix()}`,
      animated: false,
      source: newNodeID,
      sourceHandle: '',
      target: targetNodeID,
      type: 'default',
    };

    setEdges((oldEdges) => {
      return [...oldEdges, prevSourceToNewSource, newSourceToPlusEdge];
    });

    let oldSourceToPlusEdge = edges.find(
      (edge) => edge.source === sourceNodeID && edge.target === targetNodeID
    );

    // Remove Node Between old source node and plus node
    if (oldSourceToPlusEdge) removeEdge(oldSourceToPlusEdge.id);
  };

  const hasTaskNode = () => {
    for (let _node of nodes) {
      if (
        _node.group === 'outreach' ||
        _node.group === 'action' ||
        _node.group === 'condition'
      )
        return true;
    }
    return false;
  };

  const removeSourceNode = (nodeID) => {
    setNodes((oldNodes) => oldNodes.filter((oldNode) => oldNode.id !== nodeID));

    // Remove all edges converging or emenating from nodeID
    setEdges((oldEdges) => oldEdges.filter((oldEdge) => oldEdge.source !== nodeID));

    // Align Items
    alignSources();
  };

  const isFirstNode = (nodeID) => {
    if (nodes.length > 0) {
      if (nodes[0].id === nodeID || nodes[0].data?.first === true) return true;
    }
    return false;
  };

  const getYPosition = (prevHeight, newHeight, positionY) => {
    let offset = Math.round((prevHeight - newHeight) / 2);
    return positionY + offset;
  };

  const getFirstNodeOfType = (type) => {
    // Get First Source Node
    let sourceNode = nodes.find(
      (node) => node.group === 'source' && node.data?.first === true
    );
    if (!sourceNode) return;
    // Get Next Node until `type` is found
    let nextNodeID = getNodeChildren(sourceNode.id, true);
    if (nextNodeID.length > 0) nextNodeID = nextNodeID[0];

    let nextNode = getNodeByID(nextNodeID);
    if (!nextNode) return;
    if (nextNode?.type === type) return nextNode;

    let _nextNodeID = getNodeChildren(nextNode.id, true);
    if (_nextNodeID?.length > 0) _nextNodeID = _nextNodeID[0];
    nextNode = getNodeByID(_nextNodeID);
  };

  const alignSources = () => {
    let nodeWidth = 275;
    let offset = 150;
    let currentNode = 0;
    let previousNode = null;

    setNodes((oldNodes) => {
      // Align source Nodes
      let newNodes = oldNodes.map((oldNode) => {
        if (oldNode.group === 'source') {
          oldNode.position.x = nodeWidth * currentNode + offset;
          if (previousNode && oldNode.height)
            oldNode.position.y = getYPosition(
              previousNode.height,
              oldNode.height,
              previousNode.position?.y
            );
          previousNode = oldNode;
          currentNode += 1;
        }
        return oldNode;
      });
      // Align (+) Node
      newNodes = oldNodes.map((oldNode) => {
        if (oldNode?.data?.addType === 'source') {
          oldNode.position.x = nodeWidth * currentNode + 160;
          if (previousNode && oldNode.height)
            oldNode.position.y = getYPosition(
              previousNode.height,
              oldNode.height,
              previousNode.position.y
            );
          previousNode = oldNode;
        }
        return oldNode;
      });
      return newNodes;
    });
  };

  const removeEdge = (edgeID) => {
    setEdges((oldEdges) => {
      let newEdges = oldEdges.filter((oldEdge) => oldEdge.id !== edgeID);
      return newEdges;
    });
  };

  const getNodeChildren = (nodeID, skipAdd) => {
    let nodeIDs = [];
    for (let _edge of edges) {
      if (!skipAdd && _edge?.source === nodeID) {
        nodeIDs.push(_edge?.target);
        continue;
      }
      if (_edge?.source === nodeID) {
        let childNode = getNodeByID(_edge?.target);
        if (childNode?.type !== 'add') nodeIDs.push(_edge?.target);
      }
    }
    return nodeIDs;
  };

  const removeNode = (nodeID, type) => {
    if (!nodeID) return;
    // Get Node
    let currentNode = nodes.find((_node) => _node.id === nodeID);
    console.log('CURRENT ', currentNode);
    if (currentNode?.isSource && currentNode?.type === 'add') return;

    if (currentNode?.group === 'source') return removeSourceNode(nodeID);

    let nodeChildrenIDs = getNodeChildren(nodeID);
    console.log('MORE CHILDREN ', nodeChildrenIDs);
    if (nodeChildrenIDs.length === 0) {
      let parentEdge = edges.find((_edge) => _edge.target === nodeID);

      let newPlusNode = {
        id: `node-${getRandomSuffix()}`,
        sourcePosition: 'top',
        isSource: false,
        draggable: false,
        type: 'add',
        data: {
          addType: undefined,
        },
        position: {
          x: currentNode.position.x + 102,
          y: currentNode.position.y,
        },
      };

      let newPlusNodeEdge = {
        id: `edge-${getRandomSuffix()}`,
        animated: false,
        sourceHandle: parentEdge.sourceHandle,
        source: parentEdge.source,
        target: newPlusNode.id,
        type: 'default',
      };
      setNodes((oldNodes) => {
        let newNodes = oldNodes.filter((oldNode) => oldNode.id !== nodeID);
        console.log('NEW NODES ', newNodes);
        return [...newNodes, newPlusNode];
      });
      setEdges((oldEdges) => {
        let newEdges = oldEdges.filter((oldEdge) => oldEdge.target !== nodeID);
        return [...newEdges, newPlusNodeEdge];
      });
      return;
    }

    if (nodeChildrenIDs.length === 1) {
      let parentNodeType = getParentNodeGroup(nodeID)
      let parentEdge = edges.find((_edge) => _edge.target === nodeID);
      let childNode = nodes.find((_node) => _node.id === nodeChildrenIDs[0]);
      let childEdge = edges.find((_edge) => _edge.target === childNode.id);
      let newNodes = nodes?.filter((oldNode) => oldNode.id !== nodeID);
      let newEdges = edges.filter((oldEdge) => oldEdge.id !== parentEdge.id);

      newEdges = newEdges.filter((oldEdge) => oldEdge.id !== childEdge.id);
      parentEdge.target = childEdge.target;
      parentEdge.type = childEdge.type;
      newEdges = [...newEdges, parentEdge];
      newNodes = newNodes.map((_node) => {
        if (childNode.id === _node.id)
          _node.position.y = currentNode.position.y;
        return _node;
      });
      reorganizeRemoveNodes(childNode.id, newEdges, newNodes);
      setTimeout(() => {
        if(parentNodeType === "source" && childNode.group === "condition") {
          console.log("CHILD ", childNode)
          if(childNode.type === "delay") removeNode(childNode.id)
          // else removeBothNode(childNode.id) // FOR AB SLIP and IF ELSE
        }
      }, 5000)
      return;
    }

    let childEdges = edges.filter((_edge) => _edge.source === nodeID);
    for (let i = 0; i < childEdges.length; i++) {
      if (type === 'yes' && childEdges[i].sourceHandle.includes('left')) {
        let childEdge = childEdges.find((e) => e.id !== childEdges[i].id);
        removeYesNoChain(currentNode, childEdge, childEdges[i], type);
      } else if (
        type === 'no' &&
        childEdges[i].sourceHandle.includes('right')
      ) {
        let childEdge = childEdges.find((e) => e.id !== childEdges[i].id);
        removeYesNoChain(currentNode, childEdge, childEdges[i], type);
      }
    }
  };

  const removeYesNoChain = (currentNode, keepNode, removeNode, type) => {
    let parentEdge = edges.find((_edge) => _edge.target === currentNode.id);
    let newNodes = nodes?.filter((oldNode) => oldNode.id !== currentNode.id); // Remove Node with NodeID
    let newEdges = edges.filter((oldEdge) => oldEdge.id !== parentEdge.id); // Remove Edges orignating from and to this ID

    newEdges = newEdges.filter(
      (oldEdge) => oldEdge.id !== keepNode.id && oldEdge.id !== removeNode.id
    );
    parentEdge.target = keepNode.target;
    parentEdge.type = keepNode.type;
    newEdges = [...newEdges, parentEdge];

    let childrenIds = [removeNode.target, ...getAllChildren(removeNode.target)];
    // Remove all children nodes and their corresponding edges
    childrenIds.forEach((childId) => {
      newNodes = newNodes.filter((node) => node.id !== childId);
      newEdges = newEdges.filter((edge) => edge.target !== childId);
    });

    reorganizeRemoveNodes(parentEdge.source, newEdges, newNodes, type);
  };

  const getAllChildren = (parentId) => {
    let childrenIds = [];
    edges.forEach((edge) => {
      if (edge.source === parentId) {
        childrenIds.push(edge.target);
        childrenIds = [...childrenIds, ...getAllChildren(edge.target)]; // Recursively get grandchildren and so on
      }
    });
    return childrenIds;
  };

  const removeNodeChain = (nodeID, skipPlus, removePlus) => {
    if (!nodeID) return;
    // Get Node
    let currentNode = nodes.find((_node) => _node.id === nodeID);
    // Don't remove node if it's a source plus node
    if (currentNode?.isSource && currentNode?.type === 'add') return;

    if (currentNode?.group === 'source') return removeSourceNode(nodeID);

    let nodeChildrenIDs = getNodeChildren(nodeID);

    for (let _childID of nodeChildrenIDs) {
      if (currentNode?.group !== 'source') removeNode(_childID, true);
    }

    let plusNodePosition = currentNode?.position;
    let parentNodeID = getParentNodeID(currentNode?.id);
    if (plusNodePosition) plusNodePosition.x = plusNodePosition?.x + 102;
    setNodes((oldNodes) => {
      // Remove Node with NodeID
      let newNodes = oldNodes?.filter((oldNode) => oldNode.id !== nodeID);
      return newNodes;
    });

    setEdges((oldEdges) => {
      // Remove Edges orignating from and to this ID
      let newEdges = oldEdges.filter((oldEdge) => oldEdge.target !== nodeID);
      newEdges = newEdges.filter((oldEdge) => oldEdge.source !== nodeID);
      return newEdges;
    });

    if (!skipPlus) {
      removePlusNodes(nodeID); // Remove Plus Nodes of current node
      addPlusNode(parentNodeID, null, plusNodePosition, null, true); // Add Plus Nodes of parent node
    }

    if (removePlus) removePlusNodes(nodeID);
  };

  const removeBothNode = (nodeID) => {
    if (!nodeID) return;
    let currentNode = nodes.find((_node) => _node.id === nodeID);
    if (!currentNode) return;

    const getChildrenIds = (parentId) => {
      const childrenIds = edges
        .filter((edge) => edge.source === parentId)
        .map((edge) => edge.target);

      const childrensChildrenIds = childrenIds.flatMap((childId) =>
        getChildrenIds(childId)
      );

      return [...childrenIds, ...childrensChildrenIds];
    };

    let oldNodes = nodes;
    let oldEdges = edges;
    let parentEdge = edges.find((_edge) => _edge.target === nodeID);
    let parentNodeID = parentEdge.source;
    oldNodes = oldNodes.filter((_node) => _node.id !== nodeID);

    currentNode.position.x += 102;

    let newPlusNode = {
      id: `node-${getRandomSuffix()}`,
      sourcePosition: 'top',
      isSource: false,
      draggable: false,
      type: 'add',
      position: currentNode.position,
      data: {
        addType: undefined,
      },
    };
    let newPlusNodeEdge = {
      id: `edge-${getRandomSuffix()}`,
      animated: false,
      sourceHandle: '',
      source: parentNodeID,
      target: newPlusNode.id,
      type: 'default',
    };

    let childrenIds = getChildrenIds(nodeID);
    console.log('NODE CHILDREN ', childrenIds);

    const nodesToDelete = oldNodes.filter((node) =>
      childrenIds.includes(node.id)
    );
    const edgesToDelete = oldEdges.filter(
      (edge) =>
        childrenIds.includes(edge.source) || childrenIds.includes(edge.target)
    );

    oldEdges = oldEdges.filter((edge) => edge.target !== nodeID);
    let newNodes = oldNodes.filter((node) => !childrenIds.includes(node.id));
    let newEdges = oldEdges.filter((edge) => !edgesToDelete.includes(edge));

    newNodes = [...newNodes, newPlusNode];
    newEdges = [...newEdges, newPlusNodeEdge];

    setNodes(newNodes);
    setEdges(newEdges);
  };

  const removePlusNodes = (parentNodeID) => {
    // Get Edges orignating from parentNodeID
    let _edges = edges.filter((_edge) => _edge.source === parentNodeID);

    for (let _edge of _edges) {
      let _node = getNodeByID(_edge?.target);
      if (_node?.type === 'add') {
        setNodes((_nodes) => {
          let newNodes = _nodes.filter((n) => n.id !== _node?.id);
          return newNodes;
        });
      }
    }
  };

  const getNodeByID = (nodeID) => {
    return nodes.find((_node) => _node.id === nodeID);
  };

  const getParentNodeID = (nodeID) => {
    for (let e of edges) {
      if (e.target === nodeID) return e.source;
    }
  };

  const getNodesByType = (nodeType) => {
    let node = null;
    let newNodes = [];

    for (let n of nodes) {
      if (n?.type.search(nodeType) !== -1) {
        newNodes.push(n);
      }
    }

    return newNodes;
  };

  const getParentNodeType = (nodeID) => {
    let parentObjectID;
    let parentNodeType;
    for (let i = 0; i < edges.length; i++) {
      if (edges[i].target === nodeID) {
        parentObjectID = edges[i].source;
        break;
      }
    }
    if (!parentObjectID) return false;
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === parentObjectID) {
        parentNodeType = nodes[i].type;
        return parentNodeType;
      }
    }
    return false;
  };

  const getParentNodeGroup = (nodeID) => {
    let parentObjectID;
    let parentNodeGroup;
    for (let i = 0; i < edges.length; i++) {
      if (edges[i].target === nodeID) {
        parentObjectID = edges[i].source;
        break;
      }
    }
    if (!parentObjectID) return false;
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === parentObjectID) {
        parentNodeGroup = nodes[i].group;
        return parentNodeGroup;
      }
    }
    return false;
  };

  const showAddDialog = (sourceNode) => {
    if (!sourceNode) return setDialogShowing(true);
    // Get parent node
    sourceNode.parentNodeType = getParentNodeType(sourceNode.id);
    sourceNode.parentNodeGroup = getParentNodeGroup(sourceNode.id) || "source";
    setDialogShowing(true);
    setSelectedNode(sourceNode);
  };

  const showEdgeAddDialog = (edgeData) => {
    let parentNode = getNodeByID(edgeData.source);
    if (parentNode) {
      setDialogShowing(true);
      setSelectedNode({
        xPos: parentNode.position.x,
        yPos: parentNode.position.y,
        data: {
          edgeAdd: true,
          edgeId: edgeData.id,
          source: edgeData.source,
          target: edgeData.target,
        },
        parentNodeType: parentNode.type,
        parentNodeGroup: parentNode.group,
      });
    }
  };

  return (
    <FlowchartContext.Provider
      value={{
        nodes,
        edges,
        setNodes,
        setEdges,
        addNode,
        addEdgeNode,
        removeBothNode,
        editNode,
        alignSources,
        updateNode,
        setEditNode,
        removeNode,
        dialogShowing,
        showAddDialog,
        selectedNode,
        setSelectedNode,
        setDialogShowing,
        showCreateTemplate,
        setShowCreateTemplate,
        isFirstNode,
        getNodeByID,
        getParentNodeID,
        isNodeTypeInBranch,
        isFirstEmailNode,
        setShowCreateTaskTemplate,
        showCreateTaskTemplate,
        taskType,
        setTaskType,
        showEdgeAddDialog,
        showRemoveDialog,
        setShowRemoveDialog,
        removeNodeChain,
        isReportMode,
        setIsReportMode,
        sequenceData,
        setSequenceData,
        getNodeChildren,
        getParentEdge,
        editTemplateID,
        setEditTemplateID,
        getParentNodeType,
        createInitialBlocks,
        addSourceNode,
        aiPersonalized, 
        setAiPersonalized
      }}
    >
      {children}
    </FlowchartContext.Provider>
  );
}
