/***
 *
 *   USER
 *   shows the current user
 *   if user belongs to more than one account they can switch accounts here
 *
 **********/

import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  AuthContext,
  ViewContext,
  HoverNav,
  Dropdown,
  Button,
  NovuNotification,
  TextInput,
} from 'components/lib';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Style from './user.tailwind.js';
import {
  UilListUl,
  UilFile,
  UilMessage,
  UilUser,
  UilPlusCircle,
  UilAngleDown,
  UilQuestionCircle,
  UilUserCircle,
  UilUsersAlt,
  UilBoltAlt,
  UilUsdCircle,
  UilSignOutAlt,
  UilGift,
  UilPlus,
  UilBuilding
} from '@iconscout/react-unicons';
import './user.scss';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import { actions } from 'react-table';
import CustomDialog from 'components/custom/customDialog.js';
import { setWorkspaceLocalStorage } from 'utils/setWorkspaceLocalStorage.js';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export function User(props) {
  let navigator = useNavigate();
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const [plan, setPlan] = useState(null);
  const accountName = authContext.user?.accounts?.find(
    (x) => x.id === authContext.user?.account_id
  )?.name;
  const [showChangeLog, setChangeLog] = useState(false)
  const [isWorkspaceDialogOpen, setIsWorkspaceDialogOpen] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');
  const ref = useRef(null);
  const workspace_ref = useRef(null);

  const getCurrentPlan = async () => {
    try {
      let result = await axios.get(`/api/active-plan-usage`);
      // console.log("DATA ", result.data.data)

      if (result.data.data) setPlan(result.data.data);
    } catch (err) {
      // console.log("Error in creating sequence ", err)
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setChangeLog(true)
    }, 5000)
  }, [])

  useEffect(() => {
    getCurrentPlan();
  }, []);

  useEffect(() => {
    // We need to keep a reference to the widget instance so we can cleanup
    // when the component unmounts
    let widget;

    // Define our config. You can get the key from the Widget code snippet
    const config = {
      key: '23f18732-e8c8-432d-a7bb-1004129dc051', // <-- Add Widget key here
      callbacks: {
        // This will be called when the widget is loaded
        onReady: (frillWidget) => {
          widget = frillWidget;
        },
      },
    };

    // Let's check if the Frill script has already loaded
    if ('Frill' in window) {
      // If the Frill api is already available we can create the widget now
      widget = window.Frill.widget(config);
    } else {
      // If the Frill api hasn't been loaded, we need to add our config to the list.
      window.Frill_Config = window.Frill_Config || [];
      window.Frill_Config.push(config);
    }

    // Return a cleanup method so we can remove the widget when the component unmounts
    return () => {
      // Check if there is an active widget
      if (widget) {

        widget.destroy();
      }
      // We also need to remove our config from the list so it doesn't get initialised.
      // This would only happen if the had component mounted/unmounted before the Frill api
      // had a chance to load.
      if (window.Frill_Config) {
        window.Frill_Config = window.Frill_Config.filter((c) => c !== config);
      }
    };
  }, []);

  const handleWorkspace = async () => {
    if(workspaceName.trim().length < 4) return Toast.fire({ icon: 'error', title: `The workspace name must be at least four characters long.` });
    try {
      const response = await axios.post('/api/account/workspaces', {workspaceName: workspaceName});
      if(response.status === 201) {
        setWorkspaceLocalStorage(workspaceName);
        authContext.switchAccount(response.data.data.id);
      }
    } catch (error) {
      Swal.fire({
        icon: 'info',
        title: 'Can not create workspace',
        html: error.response.data.message,
        showCancelButton: true,
        confirmButtonText: `Upgrade`,
        confirmButtonColor: '#0066FF',
        reverseButtons: true
      }).then((result) => {
        if(result.isConfirmed) window.location.href = '/account/billing';
      });
    } finally {
      setWorkspaceName('');
      setIsWorkspaceDialogOpen(false);
    }
  }

  return (
    <div
      className="userContainer"
      style={{
        marginLeft: 'auto',
        display: 'flex',
      }}
    >
      {plan?.name === 'Trial' && plan?.emailSendLimit?.limit > 0 && (
        <div className="trial">
          <div className='trial-face'>
            Trial Limits
            <div>
              <UilAngleDown />
            </div>
          </div>
          <div className="trial-dropdown">
            <span>{`Email Sends (${plan.emailSendLimit?.used}/${plan.emailSendLimit?.limit})`}</span>
            {/* <span>{`Contacts (${plan.contactsLimit.used}/${plan.contactsLimit.limit})`}</span> */}
            {/* <span>{`Email Search (${plan.emailSearchLimit?.used}/${plan.emailSearchLimit?.limit})`}</span> */}
            <span>{`Email Senders (${plan.senderCount?.used}/Unlimited)`}</span>
            <span>{`Email WarmUp (${plan.warmUpCount?.used}/Unlimited)`}</span>
            {/* <span>{`Team Members (${plan.userCount.used}/Unlimited)`}</span> */}
            {/* <span>{`Access to all other features`}</span> */}
            <Button
              variant="primary"
              text={`Subscribe Now`}
              action={() => navigator('/account/billing?type=upgrade')}
            />
          </div>
        </div>
      )}
      {isWorkspaceDialogOpen && 
        <CustomDialog close={true} width={500} onClose={() => setIsWorkspaceDialogOpen(false)}>
          <div style={{ padding: 10 }} id="deleteSenderModal">
            <h1
              className="text-2xl text-modal-header"
              style={{ marginTop: '-15px', padding: 0 }}
            >
              New Workspace
            </h1>
            <p className="text-modal-description" style={{ padding: 0 }}>
              Create a new workspace.
            </p>
            <hr className="mt-3 mb-3" />
            <TextInput 
              value={workspaceName}
              onChange={(name, value) => setWorkspaceName(value)}
              placeholder="Enter the name of your workspace"
            />
            <div
              style={{
                display: 'flex',
                marginTop: '1rem',
                gap: '2rem',
                justifyContent: 'end',
                width: '100%',
              }}
            >
              <Button
                variant="primary grey"
                action={() => {
                  setWorkspaceName('');
                  setIsWorkspaceDialogOpen(false);
                }}
                text="Cancel"
              />
              <Button
                variant="primary"
                action={handleWorkspace}
                text={`Create`}
              />
            </div>
          </div>
        </CustomDialog>
      }
      {authContext.user?.accounts?.length >= 1 && (
        <div style={{ marginTop: 3 }}>
          <Dropdown 
            label={accountName}
            variant="secondary"
            icon={<UilBuilding />}
            openDialog={setIsWorkspaceDialogOpen}
            fromWorkspace={true}
            defaultAction={() => {workspace_ref?.current?.close()}}
            items={[]}
            id='workspace-dropdown-button'
          />
          <Tooltip ref={workspace_ref} anchorSelect='#workspace-dropdown-button' place='bottom'>{accountName}</Tooltip>
        </div>
      )}

      <div style={{ marginTop: 3 }} id='create-dropdown'>
        <Dropdown
          dontShowDropArrow={true}
          icon={<UilPlus />}
          variant="secondary"
          defaultAction={() => {ref?.current?.close()}}
          items={[
            {
              title: 'Create New List',
              action: () => {
                navigator('/import-list/new');
              },
              icon: <UilListUl />,
            },
            {
              title: 'Create New Sequence',
              action: () => {
                navigator('/outreach/sequences');
              },
              icon: <UilMessage />,
            },
            // { type: "separator" },
            {
              title: 'Create New Template',
              action: () => {
                navigator('/outreach/templates');
              },
              icon: <UilFile />,
            },
          ]}
          align="right"
        />
      </div>
      <Tooltip ref={ref} anchorSelect={`#create-dropdown`} place="bottom">Create New</Tooltip>

      <div className='change_log cursor-pointer' >
        {showChangeLog && <UilGift />}
      </div>

      <NovuNotification
        userID={authContext?.user?.id}
      />

      {/* <NotificationMenu
        onAction={(action, id) => {
          console.log("ACTION ", action, id)
        }}
        items={[
          // { id: "1", title: "First Sample Notification", desc: "This is an example for a sample notification", read: true, time: "30m" },
          // { id: "2", title: "Second Sample Notification", desc: "This is an example for a sample notification", read: false, time: "30m" },
          // { id: "3", title: "Third Sample Notification", desc: "This is an example for a sample notification", read: true, time: "30m" },
          // { id: "4", title: "Fourth Sample Notification", desc: "This is an example for a sample notification", read: true, time: "30m" },
          // { id: "5", title: "Fifth Sample Notification", desc: "This is an example for a sample notification", read: true, time: "30m" },
        ]} >
      </NotificationMenu> */}

      <Dropdown
        icon={<UilUser />}
        // label={authContext.user?.name}
        style={{ marginTop: '7px' }}
        defaultAction={() => { }}
        fromUser={true}
        items={[
          {
            title: authContext.user?.name, 
            action: () => {
              window.location.href = "../../account/profile"
            },
            icon: <UilUser />
          },
          {
            title: 'Account',
            action: () => {
              window.location.href = '/account/profile';
            },
            icon: <UilUserCircle />
          },
          {
            title: 'Integrations',
            action: () => {
              window.location.href = '/account/integration';
            },
            icon: <UilBoltAlt />
          },
          {
            title: 'Users',
            action: () => {
              window.location.href = '/account/users';
            },
            icon: <UilUsersAlt />
          },
          {
            title: 'Help',
            action: () => {
              window.open('https://help.salesblink.io/en', '_blank');
            },
            icon: <UilQuestionCircle />
          },
          {
            title: 'Billing',
            action: () => {
              window.location.href = '/account/billing';
            },
            icon: <UilUsdCircle />
          },
          { type: 'separator' },
          {
            title: 'Sign Out',
            action: () => {
              authContext.signout();
            },
            icon: <UilSignOutAlt />
          },
        ]}
        align="right"
      />
    </div>
  );
}
