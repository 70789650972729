import React from 'react';
import { ClassHelper } from 'components/lib';
import Style from './legend.tailwind.js';

export function Legend(props) {
  const css = ClassHelper(Style, {
    className: props.className,
    required: props.required,
    error: !props.valid,
  });

  return <legend className={css}>{props.text}</legend>;
}
