import {
  UilAnalysis,
  UilCheck,
  UilCog,
  UilEnvelope,
  UilFile,
  UilMessage,
  UilListUl,
} from '@iconscout/react-unicons';
import { CustomSubNav } from 'components/lib';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Email from './email';
import { EmailSenders } from './emailSenders';
import Reports from './reports';
import Sequences from './sequences';
import Settings from './settings';
import TaskList from './tasklist';
import Templates from './templates';
import Lists from "../leads/lists";
import List from 'views/leads/list';

export default function Engage() {
  const [selectedTab, setSelectedTab] = useState('sequences');
  let { listID, tab } = useParams();
  let navigator = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (tab) setSelectedTab(tab.replace('-', ' '));
  }, [tab, location]);

  return (
    <>
      <CustomSubNav
        selected={selectedTab}
        onChange={(selectedItem) => {
          let selectedPath = selectedItem.title.replace(' ', '-');
          navigator(`/outreach/${selectedPath}`);
        }}
        menuitems={[
          {
            icon: <UilMessage />,
            title: 'sequences',
          },
          {
            icon: <UilEnvelope />,
            title: 'outbox',
          },
          {
            icon: <UilListUl />,
            title: 'lists',
          },
          {
            icon: <UilCheck />,
            title: 'tasks',
          },
          {
            icon: <UilAnalysis />,
            title: 'reports',
          },
          {
            icon: <UilFile />,
            title: 'templates',
          },
          {
            icon: <UilEnvelope />,
            title: 'email senders',
          },
          {
            icon: <UilCog />,
            title: 'settings',
          },
        ]}
      />

      {selectedTab === 'email senders' && <EmailSenders />}

      {selectedTab === 'outbox' && <Email />}
      {/* {selectedTab === 'lists' && <Lists />} */}
      {selectedTab === 'lists' ? listID ? <List /> : <Lists /> : null}


      {selectedTab === 'templates' && <Templates />}
      {selectedTab === 'tasks' && <TaskList />}
      {selectedTab === 'sequences' && <Sequences />}
      {selectedTab === 'reports' && <Reports type={listID} />}
      {selectedTab === 'settings' && <Settings />}
    </>
  );
}
