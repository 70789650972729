import React from 'react';
import { Label } from 'components/lib';
// import Style from './radio.tailwind.js';

export function Radio(props) {
  const option = props.option?.value || props.option;
  const label = props.option?.label || props.option;

  return (
    <div className={'radio'}>
      <div>
        <input
          type="radio"
          name={props.name}
          id={option}
          value={option}
          style={{ ...props.style }}
          // className={Style.input}
          disabled={props.disabled}
          checked={props.checked ? 'checked' : ''}
          onChange={(e) => props.onChange(props.index, props.checked, option)}
        />
      </div>

      {label && (
        <>
          <Label
            text={label}
            required={props.required}
            for={option}
            style={{ marginTop: 3 }}
            // className={Style.label}
          />
          {props.icon}
        </>
      )}
    </div>
  );
}
