/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React, { useState, useEffect } from 'react';
import { Animate } from 'components/lib';
import Swal from 'sweetalert2';
import Cockpit from './cockpit';
import CustomizedCockpit from './customizedCockpit';

export function Dashboard(props) {
  const [selectedTab, setSelectedTab] = useState('Dashboard');

  useEffect(() => {
    let expiredPlan = localStorage.getItem('expiredPlan');
    let noPermission = localStorage.getItem('noPermission');
    if (expiredPlan)
      Swal.fire({
        icon: 'info',
        title: 'No Plan Active',
        html: 'Please contact your account admin.',
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066ff',
      }).then(async (result) => {
        // if (result.isConfirmed) 
        localStorage.removeItem('expiredPlan');
      });
    else if(noPermission) Swal.fire({
        icon: 'info',
        title: 'No Access',
        html: 'You do not have access.',
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066ff',
      }).then(async (result) => {
        localStorage.removeItem('noPermission');
      });
  }, []);

  return (
    <Animate type="pop">
      {/* <CustomSubNav
        selected={selectedTab}
        onChange={(selectedItem) => setSelectedTab(selectedItem.title)}
        menuitems={[
          {
            icon: <UilSunset/>,
            title: 'Onboarding',
          },
          {
            icon: <UilCompass/>,
            title: 'Dashboard',
          },
        ]}
      /> */}

      {/* {selectedTab === 'Onboarding' && <Onboarding />} */}
      {selectedTab === 'Dashboard' && (
        <Cockpit onCustomize={() => setSelectedTab('Customize')} />
      )}

      {selectedTab === 'Customize' && (
        <CustomizedCockpit onCancel={() => setSelectedTab('Dashboard')} onSave={() => setSelectedTab('Dashboard')} />
      )}
    </Animate>
  );
}
