/***
 *
 *   usePlans hook
 *   fetch, format and return the price plans
 *
 **********/

import { useAPI } from 'components/lib';
import { useEffect, useState } from 'react';

export function usePlans() {
  const [state, setState] = useState({ data: null, loading: false });
  const plans = useAPI('/api/account/plans');

  useEffect(() => {
    setState({ loading: true });

    // format plans
    if (plans?.data?.plans) {
      let formatted = [];
      plans.data.plans.forEach((plan) => {
        if (plan.name === 'Trial') {
          let label = '7 Day Trial for $1';
          return formatted.push({
            value: plan.id,
            label: label,
            price: plan.price,
            interval: plan.interval,
            name: plan.name,
          });
        }
      });

      setState({
        data: { list: formatted, active: plans.data.active, raw: plans.data },
        loading: false,
      });
    }
  }, [plans]);

  return state;
}
