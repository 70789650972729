import React, { useEffect, useState } from 'react';
import { UilPlus } from '@iconscout/react-unicons';
import Condition from './condition';
import CustomButton from 'components/custom/customButton';

function Conditions(props) {
  const updateCondition = (obj) => {
    props.updateOrCondition(obj);
  };

  return (
    <div className="andAreaContainer">
      <span className="andLabel">And</span>
      <div className="andArea">
        {props?.item?.conditions?.map((_condition) => (
          <Condition
            taskConditions={props.taskConditions}
            defaultValue={_condition}
            values={_condition}
            onAdd={() => props.addNewOr()}
            onDelete={(orID) => props.onDelete(props.item.id, orID)}
            onChange={(value) => updateCondition(value)}
            conditionFor={props.conditionFor}
          />
        ))}
      </div>
    </div>
  );
}

export default function SourceConditions(props) {
  const [first, setFirst] = useState(true);
  // and conditions
  const [andConditions, setAndConditions] = useState([
    {
      id: `and_${Date.now()}`,
      conditions: [{ id: `or_${Date.now()}`, type: '', value: '' }],
    },
  ]);

  useEffect(() => {
    if (!props?.defaultValue) return;
    if (first) {
      setAndConditions(JSON.parse(JSON.stringify(props.defaultValue)));
      setFirst(false);
    }
  }, [props.defaultValue]);

  useEffect(() => {
    props.onChange(andConditions);
  }, [andConditions]);

  const updateOrCondition = (andID, orObj) => {
    setAndConditions((oldAndConditions) => {
      let currentAnd = oldAndConditions.find(
        (_andCondition) => _andCondition.id === andID
      );
      let currentOR = currentAnd.conditions.find(
        (_orCondition) => _orCondition.id === orObj.id
      );
      if (currentOR) {
        currentOR.type = orObj.type;
        currentOR.value = orObj.value;
      }
      let newAndConditions = oldAndConditions.map((_oldAnd) => _oldAnd);
      return newAndConditions;
    });
  };

  const addOrCondition = (andID) => {
    setAndConditions((oldAndConditions) => {
      let currentAnd = oldAndConditions.find(
        (_andCondition) => _andCondition.id === andID
      );
      currentAnd.conditions.push({
        id: `or_${Date.now()}`,
        type: '',
        value: '',
      });
      let newAnd = oldAndConditions.map((oldAndItem) => oldAndItem);
      return newAnd;
    });
  };

  const addAndCondition = () => {
    setAndConditions((oldAndConditions) => {
      let newConditions = [
        ...oldAndConditions,
        {
          id: `and_${Date.now()}`,
          conditions: [{ id: `or_${Date.now()}`, type: '', value: '' }],
        },
      ];
      return newConditions;
    });
  };

  const deleteItem = (andID, orID) => {
    setAndConditions((oldAndConditions) => {
      let currentAnd = oldAndConditions.find(
        (_andCondition) => _andCondition?.id === andID
      );
      currentAnd.conditions = currentAnd.conditions.filter((_orCondition) => {
        return _orCondition?.id !== orID;
      });

      let newAndConditions = oldAndConditions.filter(
        (_andCondition) => _andCondition?.conditions?.length > 0
      );

      return newAndConditions;
    });
  };

  return (
    <div>
      <label className='font-semibold'>{props.label || "Filter by Events"}</label>
      {andConditions.map((andCondition) => (
        <Conditions
          taskConditions={props.taskConditions}
          addNewOr={() => addOrCondition(andCondition.id)}
          updateOrCondition={(obj) => updateOrCondition(andCondition.id, obj)}
          onDelete={(andID, orID) => deleteItem(andID, orID)}
          item={andCondition}
          conditionFor={props.conditionFor}
        />
      ))}
      <CustomButton
        title="AND"
        icon={<UilPlus size={18} style={{ marginTop: -2 }} />}
        className="layout-reverse"
        style={{ padding: '5px 10px' }}
        variant="secondary"
        onClick={() => addAndCondition()}
      />
    </div>
  );
}
