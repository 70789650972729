/***
 *
 *   API Key Editor
 *   Create or edit a new/existing API key
 *
 **********/

import React, { useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import {
  Form,
  Message,
  TextInput,
  Loader,
  useNavigate,
  useAPI,
} from 'components/lib';
import { UilArrowLeft } from '@iconscout/react-unicons';

export function APIKeyEditor(props) {
  const navigate = useNavigate();
  const scopes = useAPI('/api/scopes');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [newAPIKey, setNewAPIKey] = useState(false);

  const fetch = useCallback(
    async (id) => {
      setLoading(true);
      const res = await Axios.get(`/api/key/${id}`);
      res.data.data.length ? setData(res.data.data[0]) : navigate('/404');
      setLoading(false);
    },
    [navigate]
  );

  useEffect(() => {
    // editing existing key?
    const id = new URLSearchParams(window.location.search).get('id');
    id && fetch(id);
  }, [fetch]);

  if (scopes.loading) return <Loader />;

  return (
    <div className='edit-create'>
      <div
        onClick={() => navigate('/account/apikeys')}
        style={{ marginBottom: '1rem' }}
      >
        <UilArrowLeft
          size={40}
          style={{
            color: '#A0A0A0',
            borderRadius: 5,
            border: '3px solid currentColor',
            cursor: 'pointer',
          }}
        />
      </div>

      {newAPIKey ? (
        <Message
          title="Your New API Key"
          type="warning"
          buttonText="Back to API Keys"
          buttonLink="/account/apikeys"
          text="Your API key is below. Please store this somewhere safe."
        >
          <TextInput value={newAPIKey} />
        </Message>
      ) : (
        <div className='edit-create-form'>
          <h1
            style={{ fontSize: 24, fontWeight: 500, marginBottom: '2rem' }}
          >{`${data ? 'Edit' : 'Create'} API Key`}</h1>
          <Form
            key={data?.id}
            data={{
              name: {
                label: 'Name',
                type: 'text',
                required: true,
                value: data.name,
                errorMessage: 'Please provide a descriptive name for your key.',
              },
            }}
            url={data ? `/api/key/${data.id}` : '/api/key'}
            method={data ? 'PATCH' : 'POST'}
            buttonText={data ? 'Save Changes' : 'Create New API Key'}
            callback={(res) => {
              if (!data) {
                setNewAPIKey(res?.data?.data?.full_key);
                props.setKeys((previousKeys) => [
                  ...previousKeys,
                  res?.data?.data,
                ]);
              } else {
                props.setKeys((previousKeys) =>
                  previousKeys.map((key) =>
                    key._id === data._id
                      ? { ...key, name: res?.data?.name }
                      : key
                  )
                );
                navigate('/account/apikeys');
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
