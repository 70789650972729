import React, { useState, useEffect, useContext } from 'react';
import {
  Checkbox,
  CustomTable,
  Grid,
  Label,
  Radio,
  TextInput,
  Button,
  AuthContext,
} from 'components/lib';
import './customizedCockpit.scss';
import {
  UilTimes,
  UilArrowLeft,
  UilAnalytics,
  UilEdit,
  UilMessage,
  UilClock,
  UilLink,
  UilEnvelopeReceive,
  UilEnvelopeOpen,
} from '@iconscout/react-unicons';
import CustomDialog from 'components/custom/customDialog';
import Axios from 'axios';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import CustomButton from 'components/custom/customButton';
import { DateTime } from 'luxon';
import axios from 'axios';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const getRandomSuffix = () => (Math.random() + 1).toString(36).substring(2);

const CustomDragAndDrop = ({
  items,
  setItems,
  taskTypes,
  setSelectedOption,
  setViewModal,
}) => {
  const [draggedItem, setDraggedItem] = useState(null);

  const removeItem = (item) => setItems(items.filter((i) => i.slug !== item));

  function updateItem(slug, key, value) {
    setItems((oldItems) => {
      let newItems = oldItems.map((item) => {
        if (item.slug === slug) item[key] = value;
        return item;
      });
      return newItems;
    });
  }

  const handleDragStart = (e, item) => {
    setDraggedItem(item);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    const updatedItems = Array.from(items);
    const draggedItemIndex = updatedItems.findIndex(
      (item) => item === draggedItem
    );
    updatedItems.splice(draggedItemIndex, 1);
    updatedItems.splice(targetIndex, 0, draggedItem);
    setItems(updatedItems);
    setDraggedItem(null);
  };

  const sequenceHeader = [
    {
      Header: 'Sequence Name',
      Footer: 'Sequence Name',
      accessor: 'name',
      disableSortBy: true,
    },
    {
      Header: <span className="csCenter">Leads</span>,
      accessor: 'contacts',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return (
          <span
            className="csBadge info csCenter"
            style={{ width: 100, margin: 'auto' }}
          >
            {value}
          </span>
        );
      },
    },
  ];

  const logsHeader = [
    {
      Header: 'Email',
      Footer: 'Email',
      accessor: 'email',
      disableSortBy: true,
    },
    {
      Header: 'Time',
      Footer: 'Time',
      accessor: 'time',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        let scheduledDate = DateTime.now().plus({ days: -1 });
        const day = scheduledDate.day;
        const suffix =
          day % 10 === 1 && day !== 11
            ? 'st'
            : day % 10 === 2 && day !== 12
            ? 'nd'
            : day % 10 === 3 && day !== 13
            ? 'rd'
            : 'th';

        return (
          scheduledDate.toFormat(`d`) +
          suffix +
          scheduledDate.toFormat(` MMM yyyy, hh:mm a`)
        );
      }, // formatting date
    },
  ];

  const sequenceData = [
    {
      name: 'Sequence One',
      contacts: 10,
    },
    {
      name: 'Sequence Two',
      contacts: 20,
    },
    {
      name: 'Sequence Three',
      contacts: 15,
    },
  ];

  const logsData = [
    {
      email: 'example@email.com',
    },
    {
      email: 'example@email.com',
    },
    {
      email: 'example@email.com',
    },
  ];

  const viewDetails = (item) => {
    return (
      <div style={{ marginTop: 10 }}>
        {(item.slug.includes('team-email-activity') || item.slug.includes('email-stats')) || item.slug.includes('team-member-particular-sequence-activity') &&
        item.options.length > 0 ? (
          // <div>
          //   <strong>Options:</strong>{' '}
          //   {item.options.map((option) => (
          //     <span style={{ textTransform: 'capitalize', marginRight: 5 }}>
          //       {option},
          //     </span>
          //   ))}
          // </div>
          <Grid cols="3">
            {item.options.map((option, index) => (
              <StatBox
                key={`${index}`}
                style={{ textTransform: 'capitalize' }}
                title={option}
                value={option.length}
              />
            ))}
          </Grid>
        ) : null}
        {item.slug.includes('task-stats') && item.options.length > 0 ? (
          // <div>
          //   <strong>{item.options.length} Task Stats View</strong>
          // </div>
          <Grid cols="3">
            {item.options.map((option, index) => (
              <StatBox
                key={`${index}`}
                style={{ textTransform: 'capitalize' }}
                title={
                  taskTypes?.find((t) => t?._id === option)?.name ||
                  `Task ${index}`
                }
                value={
                  taskTypes?.find((t) => t?._id === option)?.name.length || 5
                }
              />
            ))}
          </Grid>
        ) : null}

        {item.total > 1 ? (
          <div>
            {(item.slug === 'my-sequence' || item.slug.includes('team-member-sequences')) ? (
              <CustomTable
                pagination={false}
                hasFooter={false}
                columns={sequenceHeader}
                data={sequenceData}
                noBottomMargin={true}
              />
            ) : null}
            {item.slug.includes('my-scheduled-emails') ||
            item.slug === 'replies' ||
            item.slug === 'clicks' ||
            item.slug === 'opens' ? (
              <CustomTable
                pagination={false}
                hasFooter={false}
                columns={logsHeader}
                data={logsData}
                noBottomMargin={true}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="custom-drag-and-drop">
      {items.map((item, index) => (
        <div
          key={index}
          className={`item ${draggedItem === item ? 'dragging' : ''}`}
          draggable
          onDragStart={(e) => handleDragStart(e, item)}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, index)}
        >
          <div className="csGrid-item-content csCard">
            <CardHeader
              item={item}
              onClose={() => removeItem(item.slug)}
              changeFavourite={() => {
                updateItem(
                  item.slug,
                  'favourite',
                  item.favourite ? false : true
                );
              }}
              viewModal={() => {
                setSelectedOption(item.slug);
                setViewModal(true);
              }}
            />
            {viewDetails(item)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default function CustomizedCockpit(props) {
  const [viewModal, setViewModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [dashboardName, setDashboardName] = useState('');
  const [items, setItems] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [isOwnerOrAdmin, setIsOwnerOrAdmin] = useState(false);

  const authContext = useContext(AuthContext);
  
  useEffect(() => {
    getUserCockpit();
    fetchTasks();
    const accountID = authContext.user.account_id;
    const account = authContext.user.accounts.find(account => account.id === accountID);
    if(account.permission === 'admin' || account.permission === 'owner') setIsOwnerOrAdmin(true);
    else setIsOwnerOrAdmin(false);
  }, []);

  const getUserCockpit = async () => {
    try {
      const res = await Axios({
        url: '/api/cockpit',
        method: 'get',
      });
      // console.log("RESP ", res.data.data?.structure)
      setItems(res.data.data?.structure);
      setDashboardName(res.data.data?.name);
    } catch (err) {
      console.log('ERROR ', err.response);
    }
  };

  function checkActive(item) {
    let active = items.find((itm) => itm.slug.includes(item));
    if (active) return true;
    return false;
  }

  const removeItem = (item) => setItems(items.filter((i) => i.slug !== item));

  const onSave = async () => {
    if (items.length === 0)
      return Toast.fire({ icon: 'error', title: 'No cards selected.' });
    if (dashboardName.trim() === '')
      return Toast.fire({ icon: 'error', title: 'Dashboard name required.' });
    try {
      await Axios({
        method: 'post',
        url: '/api/cockpit',
        data: {
          structure: items,
          name: dashboardName,
        },
      });
      Toast.fire({ icon: 'success', title: 'Dashboard saved.' });
      props.onSave();
    } catch (err) {
      console.log('ERROR ', err.response);
    }
  };

  async function fetchTasks() {
    try {
      let result = await Axios.get(`/api/task-types?limit=50`);
      const data = result?.data?.data;
      setTaskTypes(data);
    } catch (err) {
      console.log('Error in getting task types ', err);
    }
  }

  return (
    <div>
      <div id='mobile-device-cockpit-message'>
        <h2>⚠️</h2>
        <h2>This feature is only available on desktop screens!</h2>
        <button
          onClick={() => props.onCancel()}
          className="csBadge"
          style={{ border: '3px solid #A0A0A0', padding: 6, marginTop: 8 }}
        >
          <UilArrowLeft size={26} color="#A0A0A0" />
        </button>
      </div>
      <div id='large-screen-content'>
        {viewModal && (
          <CustomizationModal
            selected={selectedOption}
            existingData={items.find((item) => item.slug === selectedOption)}
            customList={selectedOption === 'task-stats' ? taskTypes : null}
            setSelected={setSelectedOption}
            setView={setViewModal}
            addCard={setItems}
          />
        )}

        <div style={{ display: 'flex' }}>
          <button
            onClick={() => props.onCancel()}
            className="csBadge"
            style={{ border: '3px solid #A0A0A0', padding: 6 }}
          >
            <UilArrowLeft size={26} color="#A0A0A0" />
          </button>
          <div style={{ marginLeft: 10, fontSize: 24, fontWeight: 500 }}>
            Customize Dashboard
          </div>
          <CustomButton
            onClick={() => onSave()}
            title="Save"
            variant="primary"
            image="save"
            className="layout-reverse"
            style={{
              marginLeft: 'auto',
              float: 'right',
              marginTop: 0,
            }}
          />
        </div>
        <div style={{ marginBottom: 20, marginLeft: 55 }}>
          Add remove blocks in your dashboard from right pane.
          <br />
          Only sample data is shown while customizing.
        </div>

        <div id='customizedCockpitRow'>
          <div className='gridItem'>
            <CustomDragAndDrop
              items={items}
              setItems={setItems}
              taskTypes={taskTypes}
              setSelectedOption={setSelectedOption}
              setViewModal={setViewModal}
            />
          </div>
          <div className='gridItem'>
            <div style={{
              borderLeft: '1px solid #D7D7D7',
              paddingLeft: '30px',
              borderRadius: 0
            }}>
              <Label text="Dashboard Name" />
              <TextInput
                value={dashboardName}
                onChange={(name, value) => setDashboardName(value)}
                placeholder="Enter a name for your dashboard"
              />

              <Label text="Sales Rep" />

              <div
                className={`cockpitOption`}
                onClick={() => {
                  setSelectedOption(`email-stats-${getRandomSuffix()}`);
                  setViewModal(true);
                }}
              >
                Email Stats
              </div>

              <div
                className={`cockpitOption`}
                onClick={() => {
                  setSelectedOption(`task-stats-${getRandomSuffix()}`);
                  setViewModal(true);
                }}
              >
                Task Stats
              </div>

              <div
                className={`cockpitOption ${
                  checkActive('my-scheduled-emails') && 'active'
                }`}
                onClick={() => {
                  if (checkActive('my-scheduled-emails'))
                    return removeItem('my-scheduled-emails');
                  setSelectedOption('my-scheduled-emails');
                  setViewModal(true);
                }}
              >
                Scheduled Emails
              </div>

              <div
                className={`cockpitOption ${
                  checkActive('my-sequence') && 'active'
                }`}
                onClick={() => {
                  if (checkActive('my-sequence'))
                    return removeItem('my-sequence');
                  setSelectedOption('my-sequence');
                  setViewModal(true);
                }}
              >
                My Sequences
              </div>

              {/* <div className={`cockpitOption ${checkActive('my-meetings') && 'active'}`} onClick={() => {
                if(checkActive("my-meetings")) return removeItem('my-meetings')
                setSelectedOption("my-meetings")
                setViewModal(true)
              }}>
                My Meetings
              </div> */}

              <div
                className={`cockpitOption ${checkActive('replies') && 'active'}`}
                onClick={() => {
                  if (checkActive('replies')) return removeItem('replies');
                  setSelectedOption('replies');
                  setViewModal(true);
                }}
              >
                Replies
              </div>

              <div
                className={`cockpitOption ${checkActive('opens') && 'active'}`}
                onClick={() => {
                  if (checkActive('opens')) return removeItem('opens');
                  setSelectedOption('opens');
                  setViewModal(true);
                }}
              >
                Opens
              </div>

              <div
                className={`cockpitOption ${checkActive('clicks') && 'active'}`}
                onClick={() => {
                  if (checkActive('clicks')) return removeItem('clicks');
                  setSelectedOption('clicks');
                  setViewModal(true);
                }}
              >
                Email Link Clicks Log
              </div>

              {isOwnerOrAdmin && <Label text="Sales Manager" style={{ marginTop: 20 }} />}

              {isOwnerOrAdmin && 
                <div
                  className={`cockpitOption ${
                    checkActive('team-email-activity') && 'active'
                  }`}
                  onClick={() => {
                    if (checkActive('team-email-activity'))
                      return removeItem('team-email-activity');
                    setSelectedOption('team-email-activity');
                    setViewModal(true);
                  }}
                >
                  Team Email Activity
                </div>
              }

              {isOwnerOrAdmin &&
                <div
                  className={`cockpitOption`}
                  onClick={() => {
                    setSelectedOption(`team-member-sequences-${getRandomSuffix()}`);
                    setViewModal(true);
                  }}
                >
                  Team Member Sequences
                </div>
              }

              {isOwnerOrAdmin && 
                <div
                  className={`cockpitOption`}
                  onClick={() => {
                    setSelectedOption(`team-member-particular-sequence-activity-${getRandomSuffix()}`);
                    setViewModal(true);
                  }}
                >
                  Team Member Particular Sequence Activity
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const CardHeader = ({ item, onClose, changeFavourite, viewModal }) => {
  const showIcons = () => {
    if (
      item.slug.includes('email-stats') ||
      item.slug.includes('task-stats') ||
      item.slug.includes('team-email-activity') ||
      item.slug.includes('team-member-sequences') ||
      item.slug.includes('team-member-particular-sequence-activity')
    )
      return <UilAnalytics />;
    if (item.slug === 'my-sequence') return <UilMessage />;
    if (item.slug === 'opens') return <UilEnvelopeOpen />;
    if (item.slug === 'clicks') return <UilLink />;
    if (item.slug === 'replies') return <UilEnvelopeReceive />;
    if (item.slug.includes('my-scheduled-emails')) return <UilClock />;
  };

  const showTitle = () => {
    if (item.slug.includes('email-stats') || item.slug.includes('task-stats'))
      return (
        <div>
          {item.title}{' '}
          <i
            style={{
              fontSize: 14,
              fontWeight: 400,
              textTransform: 'capitalize',
            }}
          >
            ({item.viewType.replace(/-/g, ' ')})
          </i>
          {item.sequence && <i style={{ fontSize: 14, fontWeight: 400 }}>({item.sequence.name})</i>}
        </div>
      );
    if (item.slug.includes('team-email-activity'))
      return (
        <div>
          <div>{item.title}</div>
          <div style={{ marginTop: -5, display: 'flex' }}>
            <i
              style={{
                fontSize: 14,
                fontWeight: 400,
                textTransform: 'capitalize',
              }}
            >
              ({item.viewType.replace(/-/g, ' ')})
            </i>{' '}
            &nbsp;
            {item.users.length > 0 && (
              <i style={{ fontSize: 14, fontWeight: 400 }}>
                ({item.users.map((user, index) => <span key={index}>
                  {user.email || user.label}
                  {index < item.users.length - 1 && ", "}
              </span>)})
              </i>
            )}
          </div>
        </div>
      );
    if (item.slug.includes('my-scheduled-emails'))
      return (
        <div>
          <div>{item.title}</div>
          {item.sequence && (
            <div style={{ marginTop: -5, display: 'flex' }}>
              <i style={{ fontSize: 14, fontWeight: 400 }}>
                ({item.sequence.name})
              </i>{' '}
              &nbsp;
              <i style={{ fontSize: 14, fontWeight: 400 }}>
                ({item.total} rows)
              </i>
            </div>
          )}
        </div>
      );
    if (item.slug.includes('team-member-sequences'))
      return (
        <div>
          <div>{item.title}</div>
          <div style={{ marginTop: -5, display: 'flex' }}>
            {item.users && (
              <i style={{ fontSize: 14, fontWeight: 400 }}>
                <span key={item.users.value}>
                  {item.users.email || item.users.label}
                </span>
              </i>
            )}
          </div>
        </div>
      );
    if (item.slug.includes('team-member-particular-sequence-activity'))
      return (
        <div>
          <div>{item.title}</div>
          <div style={{ marginTop: -5, display: 'flex' }}>
            <i
              style={{
                fontSize: 14,
                fontWeight: 400,
                textTransform: 'capitalize',
              }}
            >
              ({item.viewType.replace(/-/g, ' ')})
            </i>{' '}
            &nbsp;
            {item.users && (
              <i style={{ fontSize: 14, fontWeight: 400 }}>
                <span key={item.users.value}>
                  {item.users.email || item.users.label}
                </span> 
              </i>
            )}
          </div>
        </div>
      );
    return (
      <div>
        {item.title}{' '}
        {item.sequence && <i style={{ fontSize: 14, fontWeight: 400 }}>({item.sequence.name})</i>}
        <i style={{ fontSize: 14, fontWeight: 400 }}>({item.total} rows)</i>
      </div>
    );
  };

  return (
    <div className="csCardTitle" style={{ marginTop: -10 }}>
      {showIcons()}
      {showTitle()}
      <div style={{ marginLeft: 'auto'}} className='flex gap-2 justify-center items-start'>
        {/* <div className="csBadge primaryOutline" style={{ padding: 1, borderColor: item.favourite?'#c8e8fa':'#03A6FF', backgroundColor: item.favourite?"#03A6FF":"#f9f9f9" }}>
        <UilFavorite 
          size={19}
          color={item.favourite?'#f9f9f9':"#03A6FF"}
          style={{ cursor: 'pointer', margin: 'auto' }}
          onClick={() => changeFavourite()}
        />
      </div> */}
        <div
          className="csBadge warningOutline"
          style={{ padding: 3, borderColor: '#F9C00C', marginLeft: 5 }}
          onClick={() => viewModal()}
        >
          <UilEdit
            size={17}
            color="#F9C00C"
            style={{ cursor: 'pointer', margin: 'auto' }}
            onClick={() => {
              console.log('TEST');
            }}
          />
        </div>
        <div
          className="csBadge dangerOutline"
          style={{ padding: 1, borderColor: '#E53A40', marginLeft: 5 }}
          onClick={() => onClose()}
        >
          <UilTimes
            size={19}
            color="#E53A40"
            style={{ cursor: 'pointer', margin: 'auto' }}
            onClick={() => {
              console.log('TEST');
            }}
          />
        </div>
      </div>
    </div>
  );
};

function StatBox({ title, value, icon, style }) {
  return (
    <div className="statBox" style={{ ...style, width: '32%' }}>
      <h1>
        {icon} {value}
      </h1>
      <p>{title}</p>
    </div>
  );
}

const CustomizationModal = ({
  selected,
  setSelected,
  setView,
  addCard,
  customList,
  existingData,
}) => {
  const [data, setData] = useState({
    slug: selected,
    title: getModalTitle(),
    options: [],
    total: 10,
    favourite: false,
    users: [],
    sequence: null,
  });
  const [list, setList] = useState([]);

  let emailStatOptions = [
    { label: 'Replies', value: 'replies' },
    { label: 'Opens', value: 'opens' },
    { label: 'Clicks', value: 'clicks' },
    { label: 'Unsubscribed', value: 'unsubscribed' },
    { label: 'Sent', value: 'sent' },
    { label: 'Bounced', value: 'bounced' },
    { label: 'Outbox', value: 'outbox' }
  ];

  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (selected !== '') {
      if (customList) setList(customList);
      else fetchData('');

      if (existingData) setData(existingData);
    }
  }, [selected]);

  async function fetchData(inputValue) {
    try {
      let url = '';
      if (selected.includes('my-scheduled-emails'))
        url = `/api/sequences?limit=10&search=${inputValue}`;
      else if (selected.includes('task-stats')) url = `/api/task-types?limit=50`;
      else if (selected.includes('team-email-activity') || selected.includes('team-member-sequences') || selected.includes('team-member-particular-sequence-activity')) url = '/api/users';

      if (url !== '') {
        let result = await Axios.get(url);
        const data = result?.data?.data;
        setList(data);
        return data;
      }
      return [];
    } catch (err) {
      console.log('Error in getting sequences ', err);
      return [];
    }
  }

  function getModalTitle() {
    if(selected.includes('email-stats')) return 'Email Stats'
    if(selected.includes('task-stats')) return 'Task Stats'
    if(selected.includes('my-scheduled-emails')) return 'Scheduled Emails'
    if(selected.includes('my-sequence')) return 'My Sequences'
    if(selected.includes('my-meetings')) return 'My Meetings'
    if(selected.includes('replies')) return 'Replies'
    if(selected.includes('opens')) return 'Opens'
    if(selected.includes('clicks')) return 'Link Clicks'
    if(selected.includes('team-email-activity')) return 'Team Email Activity'
    if(selected.includes('team-member-sequences')) return 'Team Member Sequences'
    if(selected.includes('team-member-particular-sequence-activity')) return 'Team Member Particular Sequence Activity'
    return ''
  }

  function getModalContent() {
    if(selected.includes('email-stats'))
      return (
        <div style={{ margin: 15, width: '100%' }}>
          <SelectSequence data={data} setData={setData} />
          <AnalyticsRange data={data} setData={setData} />
          <CheckBoxOptions
            data={data}
            setData={setData}
            list={emailStatOptions}
            title="Email Stats to View"
            keyVal="value"
            label="label"
          />
        </div>
      );
    if(selected.includes('task-stats'))
      return (
        <div style={{ margin: 15, width: '100%' }}>
          <SelectSequence data={data} setData={setData} />
          <AnalyticsRange data={data} setData={setData} />
          <CheckBoxOptions
            data={data}
            setData={setData}
            list={list}
            title="Task Types to Add"
            keyVal="_id"
            label="name"
          />
        </div>
      );
    if(selected.includes('my-scheduled-emails'))
      return (
        <div style={{ margin: 15, width: '100%' }}>
          <SelectSequence data={data} setData={setData} />
          <div style={{ width: '97%' }}>
            <TotalDataShowComponent data={data} setData={setData} />
          </div>
        </div>
      );
    if(selected === 'my-sequence')
      return (
        <div style={{ margin: 15, width: '97%' }}>
          <TotalDataShowComponent data={data} setData={setData} />
        </div>
      );
    if(selected === 'my-meetings')
      return (
        <div style={{ margin: 15, width: '97%' }}>
          <TotalDataShowComponent data={data} setData={setData} />
        </div>
      );
    if(selected === 'replies')
      return (
        <div style={{ margin: 15, width: '97%' }}>
          <SelectSequence data={data} setData={setData} />
          <TotalDataShowComponent data={data} setData={setData} />
        </div>
      );
    if(selected === 'opens')
      return (
        <div style={{ margin: 15, width: '97%' }}>
          <SelectSequence data={data} setData={setData} />
          <TotalDataShowComponent data={data} setData={setData} />
        </div>
      );
    if(selected === 'clicks')
      return (
        <div style={{ margin: 15, width: '97%' }}>
          <SelectSequence data={data} setData={setData} />
          <TotalDataShowComponent data={data} setData={setData} />
        </div>
      );
    if(selected.includes('team-email-activity'))
      return (
        <div style={{ margin: 15, width: '100%' }}>
          <div style={{ width: '97%' }}>
            <Select
              placeholder="Select User"
              isMulti={true}
              value={data?.users}
              options={(() => {
                const filteredList = list.filter(list => list.id !== authContext.user.id);
                let tempArr = filteredList?.map((userObj) => ({
                  label: userObj?.name,
                  email: userObj?.email,
                  value: userObj?.id,
                }));
                return tempArr;
              })()}
              onChange={(selected) => setData({ ...data, users: selected })}
            />
          </div>
          <AnalyticsRange data={data} setData={setData} />
          <CheckBoxOptions
            data={data}
            setData={setData}
            list={emailStatOptions}
            title="Email Stats to View"
            keyVal="value"
            label="label"
          />
        </div>
      );
    if(selected.includes('team-member-sequences'))
      return (
        <div style={{ margin: 15, width: '100%' }}>
          <div style={{ width: '97%' }}>
            <Select
              placeholder="Select User"
              isMulti={false}
              value={data?.users}
              options={(() => {
                const filteredList = list.filter(list => list.id !== authContext.user.id);
                let tempArr = filteredList?.map((userObj) => ({
                  label: userObj?.name,
                  email: userObj?.email,
                  value: userObj?.id,
                }));
                return tempArr;
              })()}
              onChange={(selected) => setData({ ...data, users: selected })}
            />
            <TotalDataShowComponent data={data} setData={setData} />
          </div>
        </div>
      );
    if(selected.includes('team-member-particular-sequence-activity'))
      return (
        <div style={{ margin: 15, width: '100%' }}>
          <div style={{ width: '97%', marginBottom: '1rem' }}>
            <Select
              placeholder="Select User"
              isMulti={false}
              value={data?.users}
              options={(() => {
                const filteredList = list.filter(list => list.id !== authContext.user.id);
                let tempArr = filteredList?.map((userObj) => ({
                  label: userObj?.name,
                  email: userObj?.email,
                  value: userObj?.id,
                }));
                return tempArr;
              })()}
              onChange={(selected) => setData({ ...data, users: selected })}
            />
          </div>
          <SelectSequence data={data} setData={setData} />
          <AnalyticsRange data={data} setData={setData} />
          <CheckBoxOptions
            data={data}
            setData={setData}
            list={emailStatOptions}
            title="Email Stats to View"
            keyVal="value"
            label="label"
          />
        </div>
      );
  }

  function saveData() {
    if ((selected.includes('team-email-activity') || selected.includes('team-member-sequences') || selected.includes('team-member-particular-sequence-activity')) && data.users.length === 0)
      return Toast.fire({ icon: 'error', title: 'Please select a user.' });
    if ((selected.includes('email-stats') || selected.includes('team-member-particular-sequence-activity')) && data.options.length === 0)
      return Toast.fire({
        icon: 'error',
        title: 'Please select email options.',
      });
    if (selected.includes('task-stats') && data.options.length === 0)
      return Toast.fire({
        icon: 'error',
        title: 'Please select task options.',
      });
    if ((selected.includes('team-email-activity')) && data.options.length === 0)
      return Toast.fire({
        icon: 'error',
        title: 'Please select team email options.',
      });
    if (
      (selected.includes('email-stats') ||
        selected.includes('task-stats') ||
        selected.includes('team-email-activity') ||
        selected.includes('team-member-particular-sequence-activity')) &&
      (!data?.viewType || data?.viewType === '')
    )
      return Toast.fire({ icon: 'error', title: 'Please select view type.' });
    if (
      !selected.includes('email-stats') &&
      !selected.includes('task-stats') &&
      !selected.includes('team-email-activity') &&
      !selected.includes('team-member-particular-sequence-activity') &&
      (!data.total || data.total < 1)
    )
      return Toast.fire({
        icon: 'error',
        title: 'Provied how much data to show.',
      });

    addCard((oldItems) => {
      let newItems = oldItems;
      if (!existingData) newItems.push(data);
      else
        newItems = newItems.map((item) => {
          if (item.slug === existingData.slug) return data;
          return item;
        });
      return newItems;
    });
    setSelected('');
    setView(false);
  }

  return (
    <CustomDialog>
      <h2 className="text-modal-header">{getModalTitle()} Settings</h2>
      {selected !== '' ? getModalContent() : null}
      <div
        style={{
          display: 'flex',
          marginTop: 10,
          marginRight: 10,
          justifyContent: 'end',
          width: '100%',
          gap: "2rem"
        }}
      >
        <Button
          variant="primary grey"
          action={() => {
            setSelected('');
            setView(false);
          }}
          text="Cancel"
        />
        <Button
          variant="primary"
          action={() => saveData()}
          text={existingData ? 'Save Card' : 'Add Card'}
        />
      </div>
    </CustomDialog>
  );
};

function AnalyticsRange({ data, setData }) {
  return (
    <>
      <h4 className="font-bold text-md mt-3">View Type</h4>
      <div style={{ display: 'flex', marginTop: 5 }}>
        <div style={{ width: '25%' }}>
          <Radio
            option={{ value: 'this-week', label: 'This Week' }}
            name="viewType"
            checked={data['viewType'] === 'this-week'}
            onChange={(i, checked, value) =>
              setData({ ...data, viewType: 'this-week' })
            }
          />
        </div>
        <div style={{ width: '25%' }}>
          <Radio
            option={{ value: 'last-week', label: 'Last Week' }}
            name="viewType"
            checked={data['viewType'] === 'last-week'}
            onChange={(i, checked, value) =>
              setData({ ...data, viewType: 'last-week' })
            }
          />
        </div>
        <div style={{ width: '25%' }}>
          <Radio
            option={{ value: 'this-month', label: 'This Month' }}
            name="viewType"
            checked={data['viewType'] === 'this-month'}
            onChange={(i, checked, value) =>
              setData({ ...data, viewType: 'this-month' })
            }
          />
        </div>
        <div style={{ width: '25%' }}>
          <Radio
            option={{ value: 'last-month', label: 'Last Month' }}
            name="viewType"
            checked={data['viewType'] === 'last-month'}
            onChange={(i, checked, value) =>
              setData({ ...data, viewType: 'last-month' })
            }
          />
        </div>
      </div>
    </>
  );
}

function TotalDataShowComponent({ data, setData }) {
  return (
    <>
      <h4 className="font-bold text-md mt-3 mb-2">How many to show?</h4>
      <TextInput
        type="number"
        min={1}
        max={20}
        value={data['total']}
        onChange={(n, value) => {
          if (value >= 20) setData({ ...data, total: 20 });
          else setData({ ...data, total: value });
        }}
      />
    </>
  );
}

function CheckBoxOptions({ data, setData, title, list, keyVal, label }) {
  return (
    <div className="mt-3">
      <h4 className="font-bold text-md mt-3 mb-2">{title}</h4>
      <Grid cols="4">
        {list?.map((item) => (
          <div>
            <Checkbox
              name="options"
              onChange={(i, checked, option) => {
                if (data['options'].includes(item[keyVal]))
                  setData({
                    ...data,
                    options: data['options'].filter(
                      (opt) => opt !== item[keyVal]
                    ),
                  });
                else {
                  setData({
                    ...data,
                    options: [...data['options'], item[keyVal]],
                  });
                }
              }}
              checked={data['options'].includes(item[keyVal])}
              option={item[label]}
            />
          </div>
        ))}
      </Grid>
    </div>
  );
}

const SelectSequence = (props) => {
  const [list, setList] = useState([])
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const fetchLists = async () => {
      const response = await axios.get(`/api/sequences?userID=${props.data?.users?.value || authContext?.user?.id}&limit=10`);
      setList(response?.data?.data);
    }
    fetchLists();
    fetchData('')
  }, [props.data?.users?.value])

  async function fetchData(inputValue) {
    try {
      const url = `/api/sequences?userID=${props.data?.users?.value || authContext?.user?.id}&limit=10&search=${inputValue}`
      let result = await Axios.get(url);
      const data = result?.data?.data;
      setList(data);
      return [{ id: 'none', name: 'All Sequence Stats' }, ...data];
    } catch (err) {
      console.log('Error in getting sequences ', err);
      return [];
    }
  }

  return <div style={{ width: '97%' }}>
    <AsyncSelect
      placeholder="Select Sequence"
      defaultOptions={[{ id: 'none', name: 'All Sequence Stats' }, ...list]}
      value={props.data?.sequence || { id: 'none', name: 'All Sequence Stats' }}
      isClearable
      loadOptions={fetchData} // Function to fetch list options asynchronously
      getOptionValue={(option) => option.id} // Define how to get the value of each option
      getOptionLabel={(option) => option.name} // Define how to get the label of each option
      onChange={(selected) => {
        if (selected && selected.id === 'none') props.setData({ ...props.data, sequence: null });
        else if (selected) props.setData({
            ...props.data,
            sequence: { id: selected.id, name: selected.name },
          });
        else props.setData({ ...props.data, sequence: null });
      }}
      noOptionsMessage={() => 'No Results, Type to Search'}
    />
  </div>
}