import React from 'react'
import isDecimal from 'utils/isDecimal'

const Percentage = ({value,className}) => {

  const percent = isDecimal(value);

  if(!percent) return;

  return (
    <span className={className} style={{marginLeft:'6px'}}>({percent}%)</span>
  )
}

export default Percentage