/***
 *
 *   CARD
 *   Universal container for grouping UI components together
 *
 *   PROPS
 *   title: title string (optional)
 *   loading: boolean to toggle the loading animation (optional)
 *   shadow: apply a box shadow
 *   center: center align the card
 *   noPadding: remove the padding
 *   restrictWidth: restrict the width of the card on large screens
 *
 **********/

import React from 'react';
import { Loader, ClassHelper } from 'components/lib';
import Style from './card.tailwind.js';

export function Card(props) {
  const cardStyle = ClassHelper(Style, {
    shadow: props.shadow,
    center: props.center,
    noPadding: props.noPadding,
    loading: props.loading,
    className: props.className,
    last: props.last,
    restrictWidth: props.restrictWidth,
  });

  return (
    <section
      style={{ ...props.style }}
      className={cardStyle}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
    >
      {props.title && (
        <header className={Style.header}>
          <h1 className={Style.title}>{props.title}</h1>
          {props.close ? (
            props.closeBtn ? (
              props.closeBtn
            ) : (
              <button
                type="button"
                onClick={() => props.onClose()}
                style={{
                  float: 'right',
                  marginTop: '-30px',
                  cursor: 'pointer',
                }}
                className="px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 hover:shadow-lg focus:bg-red-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-700 active:shadow-lg transition duration-150 ease-in-out"
              >
                Close
              </button>
            )
          ) : null}
        </header>
      )}

      {props.loading ? <Loader /> : props.children}
    </section>
  );
}
