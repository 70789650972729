import { useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { UilDownloadAlt, UilUpload } from '@iconscout/react-unicons'
import { WhitelabelContext } from 'app/whitelabel';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

function MyDropzone(props) {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    props.onSelected(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const deviceWidth = useWindowResize();

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <div className="csRow">
          <div className={props.fullWidth ? '' : props?.paddingZero && deviceWidth < 500 ? '' : 'csCol'}></div>
          <div className="csCol" style={{ padding: props?.fullWidth ? 0 : '0 15px' }}>
            <div
              style={{
                height: deviceWidth <= 991 ? 320 : 'unset',
                width: deviceWidth <= 991 ? 320 : 'unset',
                border: '1px solid rgb(222,222,222)',
                cursor: 'pointer',
                borderRadius: 10,
                textAlign: 'center',
                margin: '50px 0',
                padding: deviceWidth <= 991 ? '70px 20px' : '100px',
                marginInline: props?.fullWidth ? 'auto' : '',
              }}
            >
              <div style={{ display: 'flex' }}>
                <span style={{ display: 'block', margin: 'auto' }}>
                  <UilUpload size={64} />
                </span>
              </div>
              <p style={{ marginTop: 25 }}>Drop the file</p>
            </div>
          </div>
          <div className={props.fullWidth ? '' : 'csCol'}></div>
        </div>
      ) : (
        <div className="csRow">
          <div className={props.fullWidth ? '' : props?.paddingZero && deviceWidth < 500 ? '' : 'csCol'}></div>
          <div className="csCol" style={{ padding: props?.fullWidth ? 0 : '0 15px' }}>
            <div
              style={{
                height: deviceWidth <= 991 ? 320 : 'unset',
                width: deviceWidth <= 991 ? 320 : 'unset',
                border: '1px solid #d7d7d7',
                cursor: 'pointer',
                borderRadius: 10,
                textAlign: 'center',
                margin: '50px 0 0',
                padding: deviceWidth <= 991 ? '70px 20px' : '100px',
                backgroundColor: "#F2F2F2",
                boxShadow: "-2px 2px 4px 0px #0000000D",
                marginInline: props?.fullWidth ? 'auto' : '',
              }}
            >
              <div style={{ display: 'flex' }}>
                <span style={{ display: 'block', margin: 'auto' }}>
                  <UilUpload className="csUploadIcon" size={64} color="#A0A0A0" />
                </span>
              </div>
              <p style={{ marginTop: 25, color: "#A0A0A0", fontSize: 20 }}>
                Drag 'n' Drop a file here, or click to select file
              </p>
            </div>
          </div>
          <div className={props.fullWidth ? '' : 'csCol'}></div>
        </div>
      )}
    </div>
  );
}

let csvType = {
  unsubscribed: {
    file: 'https://salesblink.io/sample-file/manage-unsubscribed-sample.csv',

  },
  list: {
    file: 'https://salesblink.io/sample-file/salesblink-list-sample.csv',

  },
  sender: {
    file: 'https://salesblink.io/sample-file/salesblink-email-sender-sample.csv',

  }
};

export default function Upload(props) {
  const { whitelabelDetails } = useContext(WhitelabelContext);

  const text = props.type === 'unsubscribed';
  return (
    <div style={{ color: "#3A3A3A" }}>
      {props.type !== 'list' &&
        <h2 style={{ textAlign: 'center', fontSize: 24, marginTop: 40, marginBottom: 10, fontWeight: 500 }}>
          Upload Your CSV File
        </h2>
      }
      {props.type === 'unsubscribed' && (
        <p style={{ textAlign: 'center', fontSize: 16 }}>
          {`Upload a CSV file with email addresses or domains, ${whitelabelDetails?.agencyName} will
          automatically detect them & unsubscribe them.`}
          <br />
          {`${whitelabelDetails?.agencyName} blocks email senders from sending any email to these email
          addresses and domains.`}
        </p>
      )}
      {props.type === "sender" && (
        <p style={{ textAlign: 'center', fontSize: 16 }}>
          Upload a CSV file with details of upto 100 SMTP/IMAP email senders to connect all at once.
        </p>
      )}
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        <a
          style={{
            color: '#0066FF',
            textDecoration: 'underline',
            marginLeft: 5,
            fontSize: 16,
            display: 'inline-flex',
            gap: '5px',
            alignItems: "flex-end"

          }}
          href={csvType[props.type]?.file}
          download
        >
          <UilDownloadAlt />
          Download Sample File

        </a>
      </div>
      <MyDropzone
        onSelected={(acceptedFiles) => props.onSelected(acceptedFiles)}
        fullWidth={props.fullWidth}
        paddingZero={props?.paddingZero || false}
      />
    </div>
  );
}
