/***
 *
 *   BILLING
 *   Change subscription, update card details or view past invoices
 *
 **********/

import { Animate, AuthContext, Card, SubSubNav, useAPI } from 'components/lib';
import { Fragment, useContext, useEffect, useState } from 'react';
import { BillingCard } from './card';
import { BillingInvoices } from './invoices';
import { BillingPlan } from './newplan';
import Axios from 'axios';
import {
  UilInvoice,
  UilCreditCard,
  UilUsdCircle,
  UilQuestionCircle,
  UilExternalLinkAlt
} from '@iconscout/react-unicons';
import CustomButton from 'components/custom/customButton';
import { Row, Col } from 'react-grid-system';
import Swal from 'sweetalert2';
import { Tooltip } from 'react-tooltip';

const ltd = {
  blink_personalized: 2000,
  blink_sequence: 5,
  blink_email_template: 25,
  monthly_email_send: 50000
}

const calculateLtd = (coupons, type) => {
  if(coupons <= 3) return ltd[type] * coupons
  else return ltd[type] * (coupons + 1)
}

function CouponCard({ children, id, onClick }) {
  return (
    <div
      className="bg-blue-100 border-blue-900 p-3 mb-2"
      style={{ borderRadius: 9 }}
      id={id}
      onClick={() => {
        if(onClick) onClick()
      }}
    >
      {children}
    </div>
  );
}

export function Billing(props) {
  // state/context
  const context = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState('Plan');
  const [showTab, setShowTab] = useState(true);
  const [coupons, setCoupons] = useState("")
  const [planUsage, setPlanUsage] = useState(null);
  const [allowedWorkspaces, setAllowedWorkspaces] = useState(0);

  // fetch subscription
  const subscription = useAPI('/api/account/subscription');
  const isPaid = context.user.plan === 'free' ? false : true;
  const labels = ['Plan'];
  if (isPaid) labels.push('Card', 'Invoices');

  const workspacesByCodes = {
    1: 1,
    2: 1,
    3: 3,
    4: 5,
    5: 10,
    6: 20,
    7: 30,
    8: 40,
    9: 50,
    10: 100
  };

  useEffect(() => {
    if(subscription?.data?.status === 'ltd') {
      getActivePlans();
      const numberOfCoupons = subscription?.data?.coupons.length;
      if (numberOfCoupons === 0) setAllowedWorkspaces(0);
      else if (numberOfCoupons > 9) setAllowedWorkspaces(100);
      else setAllowedWorkspaces(workspacesByCodes[numberOfCoupons]);
    }
  }, [subscription])

  async function getActivePlans() {
    try {
      let res = await Axios({
        method: 'GET',
        url: `/api/account/plan`,
      });
      // console.log("PLANS ", res.data.data)
      setPlanUsage(res.data.data);
    } catch (err) {}
  }

  const userCount = (coupons) => {
    if (coupons > 0 && coupons <= 3) return coupons * 5;
    else if (coupons >= 4 && coupons <= 9) return coupons * 5 + 5;
    else if (coupons >= 10) return 75;
  };

  const applyCoupon = async () => {
    if(coupons.trim() === "") return
    let couponsArr = coupons.split(",");
    let totalCoupons = subscription?.data?.coupons.length + couponsArr.length
    if(couponsArr.length > 10 || totalCoupons > 10) return Swal.fire({
      icon: 'error',
      title: `You can redeem max 10 codes`,
      html: "",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: '#0066FF',
    })
    try {
      let res = await Axios({
        method: 'POST',
        url: `/api/account/add-custom-coupon`,
        data: {
          accountID: context.user.account_id,
          coupon: coupons,
        },
      });

      if (res.data.couponValid === false) return Swal.fire({
          icon: 'error',
          title: `Sorry!`,
          html: res.data.err,
          showCancelButton: true,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066FF',
        })

      return Swal.fire({
        icon: 'success',
        title: ``,
        html: `<h5>Coupon Applied Successfully!</h5>`,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      }).then((result) => {
        window.location.reload();
      });

      context.user.coupons.push(coupons);
      localStorage.setItem('user', JSON.stringify(context.user));
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: `Error!`,
        html: `<h5>Something Went Wrong.</h5>`,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      })
    }
  }

  return (
    <Fragment>
      <Animate>
        {/* {subscription?.data?.status !== 'ltd' && (
          <TabView name="Billing" labels={labels}>
            <BillingPlan subscription={subscription} />

            {isPaid && <BillingCard />}

            {isPaid && <BillingInvoices />}
          </TabView>
        )} */}
        {subscription?.data?.status !== 'ltd' && (
          <div style={{ marginTop: showTab ? 18 : 0 }}>
            {showTab && (
              <SubSubNav
                selected={selectedTab}
                onChange={(selectedItem) => setSelectedTab(selectedItem.title)}
                menuitems={[
                  {
                    icon: 'unicons',
                    image: <UilUsdCircle />,
                    title: 'Plan',
                  },
                  {
                    icon: 'unicons',
                    image: <UilCreditCard />,
                    title: 'Card',
                  },
                  {
                    icon: 'unicons',
                    image: <UilInvoice />,
                    title: 'Invoice',
                  },
                ]}
              />
            )}

            {selectedTab === 'Plan' && (
              <BillingPlan
                subscription={subscription}
                setShowTab={setShowTab}
              />
            )}

            {isPaid && selectedTab === 'Card' && <BillingCard />}

            {isPaid && selectedTab === 'Invoice' && <BillingInvoices />}
          </div>
        )}

        {subscription?.data?.status === 'ltd' && (
          <Row>
            <Col xs={12} md={6} lg={4}>
              <Card
                loading={subscription.loading || subscription.data.loading}
                restrictWidth
              >
                <h2 style={{ fontSize: 18, marginBottom: 10 }}>LifeTime Benefits</h2>
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard id="personalized-email">
                    {planUsage?.personalizedEmail?.used > calculateLtd(subscription?.data?.coupons.length, "blink_personalized") ? calculateLtd(subscription?.data?.coupons.length, "blink_personalized") : planUsage?.personalizedEmail?.used}/{calculateLtd(subscription?.data?.coupons.length, "blink_personalized")} BlinkGPT AI Personalized Emails (per month)
                  </CouponCard>
                )}
                <Tooltip anchorSelect={`#personalized-email`}>
                  {planUsage?.personalizedEmail?.used > calculateLtd(subscription?.data?.coupons.length, "blink_personalized") ? calculateLtd(subscription?.data?.coupons.length, "blink_personalized") : planUsage?.personalizedEmail?.used} is used out of {calculateLtd(subscription?.data?.coupons.length, "blink_personalized")}
                </Tooltip>
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard id="ai-sequence">
                    {planUsage?.chatGPTSequence?.used > calculateLtd(subscription?.data?.coupons.length, "blink_sequence") ? calculateLtd(subscription?.data?.coupons.length, "blink_sequence") : planUsage?.chatGPTSequence?.used}/{calculateLtd(subscription?.data?.coupons.length, "blink_sequence")} BlinkGPT AI Sequences (per month)
                  </CouponCard>
                )}
                <Tooltip anchorSelect={`#ai-sequence`}>
                  {planUsage?.chatGPTSequence?.used > calculateLtd(subscription?.data?.coupons.length, "blink_sequence") ? calculateLtd(subscription?.data?.coupons.length, "blink_sequence") : planUsage?.chatGPTSequence?.used} is used out of {calculateLtd(subscription?.data?.coupons.length, "blink_sequence")}
                </Tooltip>
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard id="ai-email-template">
                    {planUsage?.chatGPTEmailTemplate?.used > calculateLtd(subscription?.data?.coupons.length, "blink_email_template") ? calculateLtd(subscription?.data?.coupons.length, "blink_email_template") : planUsage?.chatGPTEmailTemplate?.used}/{calculateLtd(subscription?.data?.coupons.length, "blink_email_template")} BlinkGPT AI Email Templates (per month)
                  </CouponCard>
                )}
                <Tooltip anchorSelect={`#ai-email-template`}>
                  {planUsage?.chatGPTEmailTemplate?.used > calculateLtd(subscription?.data?.coupons.length, "blink_email_template") ? calculateLtd(subscription?.data?.coupons.length, "blink_email_template") : planUsage?.chatGPTEmailTemplate?.used} is used out of {calculateLtd(subscription?.data?.coupons.length, "blink_email_template")}
                </Tooltip>
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard id="ai-email-template">
                    {planUsage?.emailVerifyLimit?.used}/{planUsage?.emailVerifyLimit?.limit} Email Verifications
                  </CouponCard>
                )}
                <Tooltip anchorSelect={`#ai-email-template`}>
                  You have verified {planUsage?.emailVerifyLimit?.used} emails out of {planUsage?.emailVerifyLimit?.limit} so far.
                </Tooltip>
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard id="monthly-email-sending">
                    {subscription?.data?.coupons.length === 1 ? `${planUsage?.emailSendLimit?.used}/` : ""}{subscription?.data?.coupons.length > 1 ? "Unlimited" : subscription?.data?.coupons.length * 50000} Monthly Email Sending
                  </CouponCard>
                )}
                {subscription?.data?.coupons.length === 1 && <Tooltip anchorSelect={`#monthly-email-sending`}>
                  {planUsage?.emailSendLimit?.used} is used out of {subscription?.data?.coupons.length * 50000}
                </Tooltip>}
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard>Unlimited Warmup Email Address</CouponCard>
                )}
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard>Unlimited Email Sequences</CouponCard>
                )}
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard>Unlimited Active Recipients</CouponCard>
                )}
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard>{allowedWorkspaces} Workspace{allowedWorkspaces > 1 ? 's' : ''}</CouponCard>
                )}
                {1710906920000 > subscription?.data?.date_created && <CouponCard id="prospect-credit" >
                  <a href="/leads" target='_blank' className='csCustomeLink'>
                    Unlimited Prospecting Credits (Old Feature)
                    <UilExternalLinkAlt size={16} className="csLinkIcon" />
                  </a>
                </CouponCard>}
                <Tooltip anchorSelect={`#prospect-credit`}>
                  We have sunset this feature, you can export unlimited leads till 31st December 2024
                </Tooltip>
                {subscription?.data?.coupons.length >= 3 && (
                  <CouponCard>
                    <a href="/account/profile" target='_blank' className='csCustomeLink'>
                      Bring your Own API Key
                      <UilExternalLinkAlt size={16} className="csLinkIcon" />
                    </a>
                  </CouponCard>
                )}
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard>Automated Followups</CouponCard>
                )}
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard>Email Personalization</CouponCard>
                )}
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard>Unlimited Email Tracking</CouponCard>
                )}
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard>Meeting Scheduler</CouponCard>
                )}
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard>Unibox (Unified Inbox)</CouponCard>
                )}
                {subscription?.data?.coupons.length > 0 && (
                  <CouponCard>Integrations</CouponCard>
                )}
              </Card>
            </Col>
            <Col xs={12} md={6} lg={4} className='addTopMargin'>
              <Card
                loading={subscription.loading || subscription.data.loading}
                restrictWidth
              >
                <h2 style={{ fontSize: 18, marginBottom: 10 }}>Codes Used</h2>
                {subscription?.data?.coupons.map((coupon) => (
                  <CouponCard>{coupon}</CouponCard>
                ))}
              </Card>
            </Col>
            <Col xs={12} md={6} lg={4} className='addTopMargin'>
              <Card
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <label for="coupon-code" className='flex mb-1'>
                  Coupon Code
                  <UilQuestionCircle
                    size={18}
                    id={'coupon-code-help'}
                    style={{ marginLeft: 6, marginTop: 2, cursor: 'pointer' }}
                  />
                  <Tooltip anchorSelect={`#coupon-code-help`} place={"top"}>
                    Enter your coupons separated by commas Ex. coupon1,coupon2,coupon3 etc
                  </Tooltip>
                </label>
                <input
                  placeholder="COUPON1,COUPON2,etc..."
                  className="relative block w-full p-3 rounded border bordeer-solid border-slate-300 appearance-none"
                  type="text"
                  name="code"
                  id="coupon-code"
                  onChange={(e) => setCoupons(e.target.value)}
                />
                <CustomButton
                  className="mt-2"
                  title="Apply Coupon(s)"
                  onClick={(e) => applyCoupon()}
                  variant="secondary"
                />
                <CustomButton
                  className="mt-2"
                  title="Buy More"
                  onClick={(e) => window.open("https://go.salesblink.io/ltd", '_blank')}
                  variant="primary"
                />
              </Card>
              <Card
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <label className='flex mb-1 text-center font-semibold'>
                  Need help with setup or anything else?
                </label>
                <CustomButton
                  className="mt-2"
                  title="Book a demo"
                  onClick={(e) => window.open("https://salesblink.io/book-a-demo", '_blank')}
                  variant="primary green"
                />
              </Card>
            </Col>
          </Row>
        )}
      </Animate>
    </Fragment>
  );
}
