import { Account } from 'views/account';
import { Upgrade } from 'views/account/upgrade';
import Subscribed from 'views/account/billing/subscribed';
import ConfirmPlanUpdate from 'views/account/billing/confirm_plan_update';

const Routes = [
  {
    path: '/account',
    view: Account,
    layout: 'app',
    permission: 'user',
    title: 'Your Account',
  },
  {
    path: '/account/profile',
    view: Account,
    layout: 'app',
    permission: 'user',
    title: 'Your Profile',
  },
  {
    path: '/account/workspace',
    view: Account,
    layout: 'app',
    permission: 'user',
    title: 'Workspaces',
  },
  {
    path: '/account/whitelabel',
    view: Account,
    layout: 'app',
    permission: 'user',
    title: 'Whitelabel',
  },
  {
    path: '/account/integration',
    view: Account,
    layout: 'app',
    permission: 'user',
    title: 'Integrations',
  },
  {
    path: '/account/integration/:integrationName',
    view: Account,
    layout: 'app',
    permission: 'user',
    title: 'Your Integrations',
  },
  {
    path: '/account/password',
    view: Account,
    layout: 'app',
    permission: 'user',
    title: 'Change Password',
  },
  {
    path: '/account/2fa',
    view: Account,
    layout: 'app',
    permission: 'user',
    title: 'Manage 2FA',
  },
  {
    path: '/account/billing',
    view: Account,
    layout: 'app',
    permission: 'admin',
    title: 'Billing',
  },
  {
    path: '/account/billing/:id/confirm',
    view: ConfirmPlanUpdate,
    layout: 'app',
    permission: 'admin',
    title: 'Billing',
  },
  {
    path: '/account/upgrade',
    view: Upgrade,
    layout: 'app',
    permission: 'admin',
    title: 'Upgrade',
  },
  {
    path: '/account/users',
    view: Account,
    layout: 'app',
    permission: 'admin',
    title: 'Users',
  },
  {
    path: '/account/apikeys/*',
    view: Account,
    layout: 'app',
    permission: 'developer',
    title: 'API Keys',
  },
  {
    path: '/subscribed',
    view: Subscribed,
    layout: 'app',
    permission: 'admin',
    title: 'Subscribed',
  },
];

export default Routes;
