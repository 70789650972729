import { useState } from 'react';
import { PhoneInput as ReactPhoneInput } from 'react-international-phone';
import { Label, Error } from 'components/lib';
import { PhoneNumberUtil } from 'google-libphonenumber';
import Style from './input.tailwind.js';
import 'react-international-phone/style.css';
import { UilCheck } from '@iconscout/react-unicons'

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export function PhoneInput(props) {
  const country = JSON.parse(localStorage.getItem("user_ip"))?.country_code?.toLowerCase?.() || 'us';
  const [error, setError] = useState(
    props.errorMessage || 'Please provide a phone number'
  );

  function validate(number) {
    const valid = isPhoneValid(number);

    !valid && setError('Please enter a valid phone number.');
    props.onChange?.(props.name, number, valid);
  }

  return (
    <div className={Style.input} key={country}>
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}
      <ReactPhoneInput
        defaultCountry={country}
        value={props.value || ''}
        onChange={(number) => props.onChange?.(props.name, number, undefined)}
        style={{ height: 50 }}
        countrySelectorStyleProps={{ buttonStyle: { marginTop: 'unset', height: "100%" } }}
        inputStyle={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgb(249, 249, 249)',
        }}
        disableFormatting={true}
        forceDialCode={true}
        onBlur={(event) => validate(event.target.value)}
      />

      {props.valid === false && <Error message={error} />}
      {props.valid === true && (
        <UilCheck 
          color="#8CC57D"
          className={Style.successIcon}
          size={16}
        />
      )}
    </div>
  );
}
