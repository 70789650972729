import { UilInfoCircle, UilSync, UilPlusCircle, UilPen, UilPlus, UilTimes } from '@iconscout/react-unicons';
import Axios from 'axios';
import CustomButton from 'components/custom/customButton';
import { Radio, Select, TextInput, WhitelabelContext } from 'components/lib';
import SourceConditions from 'components/sourceconditions/sourceconditions';
import { useContext, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import { useFlowchartData } from './FlowchartContext';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

const AttributeCondition = (props) => {
  // console.log(props?.data)
  return (
    <div className="triggerBox mt-2" style={{ borderBottomWidth: 1, borderBottomColor: '#d7d7d7', paddingBottom: 8 }}>
      <select
        onChange={(e) =>
          props.onChange(props?.data?.id, 'list', e.target?.value)
        }
        value={props?.data?.list}
        className="triggerBoxMargin"
        style={{ backgroundColor: '#f9f9f9' }}
      >
        <option value={''}>Select Variable</option>
        {props?.fields
          ? props?.fields?.map((field, i) => (
              <option value={field} key={i}>
                {field.replaceAll('_', ' ')}
              </option>
            ))
          : null}
      </select>
      <select
        onChange={(e) =>
          props.onChange(props?.data?.id, 'condition', e.target?.value)
        }
        value={props?.data?.condition}
        className="triggerBoxMargin"
        style={{ backgroundColor: '#f9f9f9' }}
      >
        <option value="">Filter Criteria</option>
        <option value="exists">Exists</option>
        <option value="contains">Contains</option>
        <option value="does_not_contain">Does not Contain</option>
      </select>
      {props?.data?.condition !== 'exists' && (
        <input
          onChange={(e) =>
            props.onChange(props?.data?.id, 'value', e.target?.value)
          }
          value={props?.data?.value}
          className="triggerBoxMargin py-1 px-3"
          style={{ backgroundColor: '#f9f9f9', borderRadius: 8 }}
          placeholder="Data"
        />
      )}
      {props?.data?.condition === 'exists' && (
        <input className="triggerBoxMargin  py-1 px-3" disabled style={{ backgroundColor: '#f9f9f9', borderRadius: 8 }} />
      )}
      <button
        className="closeButton flex justify-center items-center"
        appearance="primary"
        onClick={() => props.onDelete(props?.data?.id)}
      >
        <UilTimes />
      </button>
    </div>
  );
};

const AttributeConditions = (props) => {
  const [fields, setFields] = useState([]);
  const [attributesData, setAttributesData] = useState([
    { id: `attr-${new Date().getTime()}`, list: '', condition: '', value: '' },
  ]);

  function addNewRow() {
    setAttributesData((prevAttributesData) => [
      ...prevAttributesData,
      {
        id: `attr-${new Date().getTime()}`,
        list: '',
        condition: '',
        value: '',
      },
    ]);
  }

  function removeRow(id) {
    setAttributesData((prevAttributesData) => {
      return prevAttributesData.filter((oldAttr) => oldAttr.id !== id);
    });
  }

  function updateValue(id, listName, value) {
    setAttributesData((prevAttributesData) => {
      return prevAttributesData.map((oldAttr) => {
        if (oldAttr.id === id) oldAttr[listName] = value;
        return oldAttr;
      });
    });
  }

  const fetchListFields = async () => {
    try {
      let data = props?.data?.lists;
      let ids = [];
      if (data.value) ids.push(data.value.value);
      if (ids.length > 0) {
        let idsQuery = ids.reduce(
          (item, accumulator) => item + ',' + accumulator
        );
        let url = `/api/list/attributes?ids=${idsQuery}`;
        let result = await Axios.get(url);
        if (result?.data?.fields) setFields(result?.data?.fields);
      }
    } catch (err) {
      console.log('Error in getting ', err);
    }
  };

  useEffect(() => {
    if (props?.data?.lists?.value?.value) fetchListFields();
    if (props?.data?.attribute && props?.data?.attribute.length !== 0)
      setAttributesData(props?.data?.attribute);
  }, [props?.data?.lists]);

  useEffect(() => {
    props.onChange('attribute', attributesData);
  }, [attributesData]);

  return (
    <div>
      {attributesData.map((attributeData, i) => (
        <AttributeCondition
          key={i}
          fields={fields}
          onChange={(id, listName, value) => updateValue(id, listName, value)}
          onDelete={(id) => removeRow(id)}
          data={attributeData}
        />
      ))}
      <CustomButton
        title="Add New"
        icon={<UilPlus size={18} style={{ marginTop: -2 }} />}
        className="layout-reverse"
        style={{ padding: '5px 10px' }}
        variant="secondary"
        onClick={() => addNewRow()}
      />
    </div>
  );
};

function GetField({
  fieldType,
  nodeType,
  onChange,
  data,
  dataChanged,
  onConditionsChange,
}) {
  const flowchartData = useFlowchartData();
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTask, setLoadingTask] = useState(false);
  const [loadingIntegrationInfo, setLoadingIntegrationInfo] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [integrationPipedriveOwner, setIntegrationPipedriveOwner] = useState(
    []
  );
  const [integrationPipedrivePipelines, setIntegrationPipedrivePipelines] =
    useState([]);
  const [integrationPipedriveStages, setIntegrationPipedriveStages] = useState(
    []
  );
  const [integrationHubspotOwner, setIntegrationHubspotOwner] = useState([]);
  const [integrationHubspotLists, setIntegrationHubspotLists] = useState([]);
  const [integrationHubspotPipelines, setIntegrationHubspotPipelines] =
    useState([]);
  const [integrationHubspotStages, setIntegrationHubspotStages] = useState([]);
  const [integrationZohoOwner, setIntegrationZohoOwner] = useState([]);
  const [integrationZohoPipelines, setIntegrationZohoPipelines] = useState([]);
  const [integrationZohoStages, setIntegrationZohoStages] = useState([]);
  const [integrationError, setIntegrationError] = useState(null);
  const [conditions, setConditions] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [taskTemplates, setTaskTemplates] = useState([
    { name: 'No template found', _id: '' },
  ]);
  const [listAttributes, setListAttributes] = useState([]);
  const [taskConditions, setTaskConditions] = useState(null);
  const [showFollowUp, setShowFollowUp] = useState(true);
  const [conditionFor, setConditionFor] = useState(false);
  const listSelect = useRef(null);
  const integrationSelect = useRef(null);
  const integrationPipedriveOwnerSelect = useRef(null);
  const integrationHubspotOwnerSelect = useRef(null);

  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const { whitelabelDetails } = useContext(WhitelabelContext);
  const deviceWidth = useWindowResize();

  const onTaskTypeSelected = (value) => {
    if (!value) return;
    let currentTaskType = taskTypes.find((taskType) => taskType._id === value);
    setSelectedTaskType(currentTaskType);
    if (currentTaskType?.allow_templates)
      fetchTaskTemplates(currentTaskType._id);
  };

  const fetchData = async (url) => {
    try {
      let result = await Axios.get(url);
      return result.data.data;
    } catch (err) {
      return [];
    }
  };

  const fetchTaskTemplates = async (taskTypeID) => {
    try {
      setLoadingTask(true);
      let result = await Axios.get(
        `/api/template?taskType=${taskTypeID}&type=task`
      );
      setTaskTemplates(result?.data?.data);
      setLoadingTask(false);
    } catch (err) {
      console.log('Error in fetching task templates');
      setLoadingTask(false);
    }
  };

  useEffect(() => {
    onTaskTypeSelected(flowchartData?.tasktype);
  }, [flowchartData?.showCreateTaskTemplate]);

  useEffect(() => {
    fetchList('')
    fetchTemplates('')
  }, [])

  useEffect(() => {
    if(data?.integrations?.value?.length > 0 && !loadingIntegrationInfo) {
      for(const _integration of data.integrations.value) {
        if(_integration.key === "pipedrive") {
          if(data['pipedrive_type'] === 'deal' && (!integrationPipedrivePipelines || integrationPipedrivePipelines.length === 0)) fetchIntegrationsData('pipedrive', 'pipeline')
          fetchIntegrationsData('pipedrive', 'owner');
        }
        if(_integration.key === "hubspot") {
          if(data['hubspot_type'] === 'deal' && (!integrationHubspotPipelines || integrationHubspotPipelines.length === 0)) fetchIntegrationsData('hubspot', 'pipeline')
          if(data['hubspot_type'] === 'list' && (!integrationHubspotLists || integrationHubspotLists.length === 0)) fetchIntegrationsData('hubspot', 'lists')
        }
        if(_integration.key === "zoho-crm") {
          if(data['zoho_type'] === 'person' && (!integrationZohoOwner || integrationZohoOwner.length === 0)) fetchIntegrationsData('zoho-crm', 'owner')
        }
      }
    } else if(integrations.length > 0 && !data?.integrations?.value) onChange('integrations', [{
      key: integrations[0].type,
      label: integrations[0].name,
      value: integrations[0]._id
    }], 'integrationLabel');

    if(!data?.delay_unit?.value) onChange('delay_unit', 'days', 'Days')
  }, [data, integrations])

  async function fetchList(inputValue) {
    try {
      let data = await fetchData(`/api/list?limit=10&search=${inputValue}`);
      // Transform the fetched data into options array
      const options = data.map((item) => ({
        key: item.id,
        label: item.name,
        value: item.id,
      }));
      setListData(options);

      return options;
    } catch (err) {
      return [];
    }
  }

  async function fetchIntegrations() {
    try {
      setLoading(true);
      let data = await fetchData('/api/integration');
      if (data.length > 0) setIntegrations(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchIntegrationsData(type, info, extra) {
    setIntegrationError(null);
    try {
      setLoadingIntegrationInfo(true);
      let result = await Axios.get(
        `/api/integration/${type}/info?type=${info}&extra=${extra}`
      );
      let data = result.data.data;
      if (data.length > 0) {
        if (type === 'pipedrive') {
          if (info === 'pipeline') setIntegrationPipedrivePipelines(data);
          else if (info === 'stage') setIntegrationPipedriveStages(data);
          else if (info === 'owner') setIntegrationPipedriveOwner(data);
        }
        if (type === 'hubspot') {
          if (info === 'pipeline') setIntegrationHubspotPipelines(data);
          else if (info === 'stage') setIntegrationHubspotStages(data);
          else if (info === 'owner') setIntegrationHubspotOwner(data);
          else if (info === 'lists') setIntegrationHubspotLists(data);
        }
        if (type === 'zoho-crm') {
          if (info === 'owner') setIntegrationZohoOwner(data);
        }
      }
      setLoadingIntegrationInfo(false);
    } catch (err) {
      // console.log("TEST ", err.response)
      if (err.response?.status === 403)
        setIntegrationError(err.response.data.message);
      setLoadingIntegrationInfo(false);
    }
  }

  async function fetchTemplates(inputValue) {
    try {
      let data = await fetchData(
        `/api/template?type=Email&limit=10&search=${inputValue}`
      );
      // Transform the fetched data into options array
      const options = data.map((item) => ({
        key: item._id,
        label: item.name,
        value: item._id,
      }));
      setTemplates(options)

      return options;
    } catch (err) {
      return [];
    }
  }

  async function fetchTaskTypes() {
    try {
      setLoading(true);
      let data = await fetchData('/api/task-types');
      if (data.length > 0) setTaskTypes(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  let fetchAPIData = async () => {
    let data = [];
    switch (fieldType) {
      case 'lists':
        data = await fetchData('/api/list?limit=10');
        if (data.length > 0) setListData(data.map((item) => ({
          key: item.id,
          label: item.name,
          value: item.id,
        })));
        break;

      case 'integrations':
        data = await fetchData('/api/integration');
        if (data.length > 0) setIntegrations(data);
        break;

      case 'email_templates':
        data = await fetchData('/api/template?type=Email&limit=10');
        if (data?.length > 0) setTemplates(data.map((item) => ({
          key: item._id,
          label: item.name,
          value: item._id,
        })));
        break;

      case 'tasktype':
        data = await fetchData('/api/task-types');
        if (data.length > 0) setTaskTypes(data);
        break;

      case 'task_templates':
        data = await fetchData('/api/template?type=task');
        if (data.length > 0) setTemplates(data);
        break;

      case 'send_as':
        if (flowchartData?.editNode) {
          if (!flowchartData?.isFirstEmailNode(flowchartData?.editNode?.id))
            setShowFollowUp(false);
        } else {
          if (!flowchartData?.isFirstEmailNode(flowchartData?.selectedNode?.id))
            setShowFollowUp(false);
          else onChange('send_as', 'followup', 'RE: Follow Up')
        }
        break;

      case 'conditions':
       
        if (flowchartData?.editNode) {
          let parentNodeType = flowchartData?.getParentNodeType(flowchartData?.editNode?.id)
          if(parentNodeType === "delay") {
            let parentNodeID = flowchartData?.getParentNodeID(flowchartData?.editNode?.id)
            parentNodeType = flowchartData?.getParentNodeType(parentNodeID)
          }
          setConditionFor(parentNodeType)
        } else {
          let parentNodeType = flowchartData?.getParentNodeType(flowchartData?.selectedNode?.id)
          if(parentNodeType === "delay") {
            let parentNodeID = flowchartData?.getParentNodeID(flowchartData?.selectedNode?.id)
            parentNodeType = flowchartData?.getParentNodeType(parentNodeID)
          }
          setConditionFor(parentNodeType)
        }
         data = await fetchData('/api/task-conditions/');
        if (data.length > 0) setTaskConditions(data);
        break;

      case 'action_conditions':
        let parentNodeID = flowchartData?.getParentNodeID(
          flowchartData?.selectedNode?.id
        );
        let parentNode = flowchartData?.getNodeByID(parentNodeID);
        data = await fetchData(
          `/api/task-conditions/${parentNode?.data?.tasktype?.value}`
        );
        if (data.length > 0) setTaskConditions(data);
        break;

      case 'attribute':
        data = await fetchData('/api/list?limit=10');
        if (data.length > 0) setListData(data.map((item) => ({
          key: item.id,
          label: item.name,
          value: item.id,
        })));
        break;

      default:
        return;
    }
  };

  useEffect(() => {
    fetchAPIData();
  }, [dataChanged]);

  function showReplyWarning(nType, conditions) {
    let show = false;
    if(nType === "ifelse") {
      if(conditions?.length > 0) {
        for(let andCond of conditions) {
          if(andCond?.conditions?.length > 0) {
            for(let orCond of andCond?.conditions) {
              if(orCond?.type === "email_reply") show = true;
            }
          }
        }
      }
    }

    return show
  }

  switch (fieldType) {
    case 'lists':
      return (
        <div>
          <div className='flex mb-2' style={{ flexDirection: deviceWidth > 991 ? 'row' : 'column' }}>
            <div className='font-semibold' style={{ paddingTop: 20 }}>
              <label>Select your List{(nodeType === "source_entry_condition" || nodeType === "source_entry_list") ? `(s)`: ""}</label>
            </div>
            <div style={{ marginLeft: deviceWidth > 991 ? 'auto' : 0 }}>
              <CustomButton
                title="New List"
                icon={<UilPlusCircle />}
                className="layout-reverse"
                style={{ marginRight: 5 }}
                variant="secondary"
                onClick={() => window.open('/import-list/new', '_blank')}
              />
            </div>
          </div>
          <AsyncSelect
            styles={{
              control: () => ({
                padding: 5,
                width: '100%',
                borderRadius: 5,
                display: 'flex',
                border: '1px solid #d7d7d7',
                backgroundColor: '#f9f9f9'
              }),
            }}
            placeholder="Search for lists"
            value={data?.lists?.value}
            defaultOptions={listData}
            getOptionValue={(option) => option.value} // Define how to get the value of each option
            getOptionLabel={(option) => option.label} // Define how to get the label of each option
            isClearable
            isMulti={nodeType === 'source_entry_attribute' ? false : true}
            loadOptions={fetchList} // Function to fetch sequence options asynchronously
            onChange={(values, currentValue) => {
              if(nodeType === "move_contact_to_list" && values?.length > 1) return onChange('lists', [values[values?.length - 1]], 'listLabel');
              if(nodeType !== 'source_entry_attribute' && values?.length > 0) return onChange('lists', values, 'listLabel');
              if (
                !currentValue?.option?.value &&
                nodeType !== 'source_entry_attribute'
              )
                values = [];
              onChange('lists', values, 'listLabel');
            }}
            noOptionsMessage={() => 'No Results, Type to Search'}
          />
        </div>
      );
    case 'integrations':
      return (
        <div>
          <div className='flex mb-2' style={{ flexDirection: deviceWidth > 991 ? 'row' : 'column' }}>
            <div className='font-semibold' style={{ paddingTop: 20 }}>
              <label>Select Integration</label>
            </div>
            <div style={{ marginLeft: deviceWidth > 991 ? 'auto' : 0 }}>
              <CustomButton
                title="Connect CRM"
                icon={<UilPlusCircle />}
                className="layout-reverse"
                style={{ marginRight: 5 }}
                variant="secondary"
                onClick={() => window.open('/account/integration', '_blank')}
              />
            </div>
          </div>
          <Row>
            <Col xs={10} md={11}>
              <ReactSelect
                ref={integrationSelect}
                styles={{
                  control: () => ({
                    padding: 5,
                    width: '100%',
                    borderRadius: 5,
                    display: 'flex',
                    border: '1px solid #d7d7d7',
                    backgroundColor: '#f9f9f9'
                  }),
                }}
                isMulti={true}
                placeholder="Select an Integration"
                value={data?.integrations?.value}
                options={integrations.map((integrationItem) => ({
                  key: integrationItem.type,
                  label: integrationItem.name,
                  value: integrationItem._id,
                }))}
                onChange={(values, currentValue) => {
                  // if (!currentValue?.option?.value) values = []
                  if (values.length < 2) {
                    onChange('integrations', values, 'integrationLabel');
                    setIntegrationError(null)
                  }
                }}
              />
            </Col>
            <Col xs={2} md={1}>
              <UilSync
                style={{
                  marginTop: 8,
                  cursor: `${loading ? 'not-allowed' : 'pointer'}`,
                }}
                id="refresh-integration"
                className={`${loading ? 'rotate' : ''}`}
                onClick={() => {
                  if (!loading) fetchIntegrations();
                }}
              />
              <Tooltip anchorSelect="#refresh-integration" place="top">
                Refresh
              </Tooltip>
            </Col>
          </Row>
          {integrationError && (
            <div className="mt-1 mb-1 py-2">
              <span className="text-red-500 mr-1">{integrationError}</span>
              <a
                href="/account/integration"
                target="_blank"
                className="rounded background-transparent underline font-bold outline-none ease-linear transition-all duration-150"
                style={{ color: '#0066FF' }}
              >
                Click Here
              </a>
            </div>
          )}
          {data?.integrations?.value.length > 0 ? (
            <div>
              {data?.integrations?.value.map((integration) => {
                if (integration.key === 'pipedrive') {
                  return (
                    <div style={{ marginTop: 10 }} key={integration.key}>
                      <div>Source:</div>
                      <Row style={{ marginTop: 5 }}>
                        <Col>
                          {/* <TextInput name={"pipedrive_api_key"} value={data['pipedrive_api_key']} onChange={(field, value) => onChange(field, value)} placeholder="API Key" /> */}
                          <Radio
                            option={{ value: 'deal', label: 'Deals' }}
                            name="pipedrive_type"
                            checked={data['pipedrive_type'] === 'deal'}
                            onChange={(i, checked, value) =>
                              onChange('pipedrive_type', 'deal')
                            }
                          />
                        </Col>
                        <Col>
                          <Radio
                            option={{ value: 'person', label: 'Persons' }}
                            name="pipedrive_type"
                            checked={data['pipedrive_type'] === 'person'}
                            onChange={(i, checked, value) =>
                              onChange('pipedrive_type', 'person')
                            }
                          />
                        </Col>
                        <Col>
                          {/* <Radio 
                                                option={{ value: "lead", label: "Leads" }}
                                                name="pipedrive_type"
                                                checked={data['pipedrive_type'] === "lead"}
                                                onChange={(i, checked, value) => onChange('pipedrive_type', 'lead')}
                                            /> */}
                        </Col>
                      </Row>
                      <div>Filter Leads</div>
                      <Row style={{ marginTop: 5 }}>
                        <Col>
                          {/* <TextInput name={"pipedrive_api_key"} value={data['pipedrive_api_key']} onChange={(field, value) => onChange(field, value)} placeholder="API Key" /> */}
                          <Radio
                            option={{
                              value: 'next',
                              label: 'Leads added from now on',
                            }}
                            name="pipedrive_permission"
                            checked={data['pipedrive_permission'] === 'next'}
                            onChange={(i, checked, value) =>
                              onChange('pipedrive_permission', 'next')
                            }
                          />
                        </Col>
                        <Col>
                          <Radio
                            option={{
                              value: 'previous',
                              label: 'Leads added previously',
                            }}
                            name="pipedrive_permission"
                            checked={
                              data['pipedrive_permission'] === 'previous'
                            }
                            onChange={(i, checked, value) =>
                              onChange('pipedrive_permission', 'previous')
                            }
                          />
                        </Col>
                        <Col>
                          <Radio
                            option={{ value: 'all', label: 'All Leads' }}
                            name="pipedrive_permission"
                            checked={data['pipedrive_permission'] === 'all'}
                            onChange={(i, checked, value) =>
                              onChange('pipedrive_permission', 'all')
                            }
                          />
                        </Col>
                      </Row>
                      {data['pipedrive_type'] === 'deal' && (
                        <Row style={{ marginTop: 10 }}>
                          <Col>
                            <Row>
                              <Col xs={10} md={11}>
                                <ReactSelect
                                  styles={{
                                    control: () => ({
                                      padding: 5,
                                      width: '100%',
                                      borderRadius: 5,
                                      display: 'flex',
                                      border: '1px solid #d7d7d7',
                                      backgroundColor: '#f9f9f9'
                                    }),
                                  }}
                                  placeholder="Select Pipeline"
                                  value={data?.pipedrive_pipeline}
                                  options={integrationPipedrivePipelines.map(
                                    (integrationItem) => ({
                                      key: integrationItem.id,
                                      label: integrationItem.name,
                                      value: integrationItem.id,
                                    })
                                  )}
                                  onChange={(values, currentValue) => {
                                    onChange('pipedrive_pipeline', values);
                                    onChange('pipedrive_stage', null);
                                    fetchIntegrationsData(
                                      'pipedrive',
                                      'stage',
                                      values?.value
                                    );
                                  }}
                                />
                              </Col>
                              <Col xs={2} md={1}>
                                <UilSync
                                  style={{
                                    marginTop: 8,
                                    cursor: `${
                                      loadingIntegrationInfo
                                        ? 'not-allowed'
                                        : 'pointer'
                                    }`,
                                  }}
                                  id="refresh-integration-pipedrive-pipeline"
                                  className={`${
                                    loadingIntegrationInfo ? 'rotate' : ''
                                  }`}
                                  onClick={() => {
                                    if (!loadingIntegrationInfo)
                                      fetchIntegrationsData(
                                        'pipedrive',
                                        'pipeline'
                                      );
                                  }}
                                />
                                <Tooltip
                                  anchorSelect="#refresh-integration-pipedrive-pipeline"
                                  place="top"
                                >
                                  Refresh
                                </Tooltip>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: 10 }}>
                              <Col xs={10} md={11}>
                                <ReactSelect
                                  styles={{
                                    control: () => ({
                                      padding: 5,
                                      width: '100%',
                                      borderRadius: 5,
                                      display: 'flex',
                                      border: '1px solid #d7d7d7',
                                      backgroundColor: '#f9f9f9'
                                    }),
                                  }}
                                  placeholder="Select Stage"
                                  value={data?.pipedrive_stage}
                                  options={integrationPipedriveStages.map(
                                    (integrationItem) => ({
                                      key: integrationItem.id,
                                      label: integrationItem.name,
                                      value: integrationItem.id,
                                    })
                                  )}
                                  onChange={(values, currentValue) => {
                                    onChange('pipedrive_stage', values);
                                  }}
                                />
                              </Col>
                              <Col xs={2} md={1}>
                                <UilSync
                                  style={{
                                    marginTop: 8,
                                    cursor: `${
                                      loadingIntegrationInfo
                                        ? 'not-allowed'
                                        : 'pointer'
                                    }`,
                                  }}
                                  id="refresh-integration-pipedrive-stage"
                                  className={`${
                                    loadingIntegrationInfo ? 'rotate' : ''
                                  }`}
                                  onClick={() => {
                                    if (!loadingIntegrationInfo)
                                      fetchIntegrationsData(
                                        'pipedrive',
                                        'stage',
                                        data?.pipedrive_pipeline?.value
                                      );
                                  }}
                                />
                                <Tooltip
                                  anchorSelect="#refresh-integration-pipedrive-stage"
                                  place="top"
                                >
                                  Refresh
                                </Tooltip>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <Row style={{ marginTop: 10 }}>
                        <Col xs={10} md={11}>
                          <ReactSelect
                            ref={integrationPipedriveOwnerSelect}
                            styles={{
                              control: () => ({
                                padding: 5,
                                width: '100%',
                                borderRadius: 5,
                                display: 'flex',
                                border: '1px solid #d7d7d7',
                                backgroundColor: '#f9f9f9'
                              }),
                            }}
                            placeholder="Owner"
                            value={data?.pipedrive_owner}
                            options={integrationPipedriveOwner.map(
                              (integrationItem) => ({
                                key: integrationItem.id,
                                label: integrationItem.name,
                                value: integrationItem.id,
                              })
                            )}
                            onChange={(values, currentValue) => {
                              onChange('pipedrive_owner', values);
                            }}
                          />
                        </Col>
                        <Col xs={2} md={1}>
                          <UilSync
                            style={{
                              marginTop: 8,
                              cursor: `${
                                loadingIntegrationInfo
                                  ? 'not-allowed'
                                  : 'pointer'
                              }`,
                            }}
                            id="refresh-integration-pipedrive-owner"
                            className={`${
                              loadingIntegrationInfo ? 'rotate' : ''
                            }`}
                            onClick={() => {
                              if (!loadingIntegrationInfo)
                                fetchIntegrationsData('pipedrive', 'owner');
                            }}
                          />
                          <Tooltip
                            anchorSelect="#refresh-integration-pipedrive-owner"
                            place="top"
                          >
                            Refresh
                          </Tooltip>
                        </Col>
                      </Row>
                    </div>
                  );
                }
                if (integration.key === 'hubspot') {
                  return (
                    <div style={{ marginTop: 10 }} key={integration.key}>
                      <div>Source:</div>
                      <Row style={{ marginTop: 5 }}>
                        <Col>
                          <Radio
                            option={{ value: 'deal', label: 'Deals' }}
                            name="hubspot_type"
                            checked={data['hubspot_type'] === 'deal'}
                            onChange={(i, checked, value) =>
                              onChange('hubspot_type', 'deal')
                            }
                          />
                        </Col>
                        <Col>
                          <Radio
                            option={{ value: 'list', label: 'Lists' }}
                            name="hubspot_type"
                            checked={data['hubspot_type'] === 'list'}
                            onChange={(i, checked, value) => {
                              onChange('hubspot_type', 'list');
                              fetchIntegrationsData('hubspot', 'lists');
                            }}
                          />
                        </Col>
                        <Col>
                          {/* <Radio 
                                                option={{ value: "lead", label: "Leads" }}
                                                name="hubspot_type"
                                                checked={data['hubspot_type'] === "lead"}
                                                onChange={(i, checked, value) => onChange('hubspot_type', 'lead')}
                                            /> */}
                        </Col>
                      </Row>
                      <div>Filter Leads</div>
                      <Row style={{ marginTop: 5 }}>
                        <Col>
                          <Radio
                            option={{
                              value: 'next',
                              label: 'Leads added from now on',
                            }}
                            name="hubspot_permission"
                            checked={data['hubspot_permission'] === 'next'}
                            onChange={(i, checked, value) =>
                              onChange('hubspot_permission', 'next')
                            }
                          />
                        </Col>
                        <Col>
                          <Radio
                            option={{
                              value: 'previous',
                              label: 'Leads added previously',
                            }}
                            name="hubspot_permission"
                            checked={data['hubspot_permission'] === 'previous'}
                            onChange={(i, checked, value) =>
                              onChange('hubspot_permission', 'previous')
                            }
                          />
                        </Col>
                        <Col>
                          <Radio
                            option={{ value: 'all', label: 'All Leads' }}
                            name="hubspot_permission"
                            checked={data['hubspot_permission'] === 'all'}
                            onChange={(i, checked, value) =>
                              onChange('hubspot_permission', 'all')
                            }
                          />
                        </Col>
                      </Row>
                      {data['hubspot_type'] === 'deal' && (
                        <Row style={{ marginTop: 10 }}>
                          <Col>
                            <Row>
                              <Col xs={10} md={11}>
                                <ReactSelect
                                  styles={{
                                    control: () => ({
                                      padding: 5,
                                      width: '100%',
                                      borderRadius: 5,
                                      display: 'flex',
                                      border: '1px solid #d7d7d7',
                                      backgroundColor: '#f9f9f9'
                                    }),
                                  }}
                                  placeholder="Select Pipeline"
                                  value={data?.hubspot_pipeline}
                                  options={integrationHubspotPipelines.map(
                                    (integrationItem) => ({
                                      key: integrationItem.id,
                                      label: integrationItem.name,
                                      value: integrationItem.id,
                                    })
                                  )}
                                  onChange={(values, currentValue) => {
                                    onChange('hubspot_pipeline', values);
                                    onChange('hubspot_stage', null);
                                    fetchIntegrationsData(
                                      'hubspot',
                                      'stage',
                                      values?.value
                                    );
                                  }}
                                />
                              </Col>
                              <Col xs={2} md={1}>
                                <UilSync
                                  style={{
                                    marginTop: 8,
                                    cursor: `${
                                      loadingIntegrationInfo
                                        ? 'not-allowed'
                                        : 'pointer'
                                    }`,
                                  }}
                                  id="refresh-integration-hubspot-pipeline"
                                  className={`${
                                    loadingIntegrationInfo ? 'rotate' : ''
                                  }`}
                                  onClick={() => {
                                    if (!loadingIntegrationInfo)
                                      fetchIntegrationsData(
                                        'hubspot',
                                        'pipeline'
                                      );
                                  }}
                                />
                                <Tooltip
                                  anchorSelect="#refresh-integration-hubspot-pipeline"
                                  place="top"
                                >
                                  Refresh
                                </Tooltip>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: 10 }}>
                              <Col xs={10} md={11}>
                                <ReactSelect
                                  styles={{
                                    control: () => ({
                                      padding: 5,
                                      width: '100%',
                                      borderRadius: 5,
                                      display: 'flex',
                                      border: '1px solid #d7d7d7',
                                      backgroundColor: '#f9f9f9'
                                    }),
                                  }}
                                  placeholder="Select Stage"
                                  value={data?.hubspot_stage}
                                  options={integrationHubspotStages.map(
                                    (integrationItem) => ({
                                      key: integrationItem.id,
                                      label: integrationItem.name,
                                      value: integrationItem.id,
                                    })
                                  )}
                                  onChange={(values, currentValue) => {
                                    onChange('hubspot_stage', values);
                                  }}
                                />
                              </Col>
                              <Col xs={2} md={1}>
                                <UilSync
                                  style={{
                                    marginTop: 8,
                                    cursor: `${
                                      loadingIntegrationInfo
                                        ? 'not-allowed'
                                        : 'pointer'
                                    }`,
                                  }}
                                  id="refresh-integration-hubspot-stage"
                                  className={`${
                                    loadingIntegrationInfo ? 'rotate' : ''
                                  }`}
                                  onClick={() => {
                                    if (!loadingIntegrationInfo)
                                      fetchIntegrationsData(
                                        'hubspot',
                                        'stage',
                                        data?.hubspot_pipeline?.value
                                      );
                                  }}
                                />
                                <Tooltip
                                  anchorSelect="#refresh-integration-hubspot-stage"
                                  place="top"
                                >
                                  Refresh
                                </Tooltip>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                      {/* <Row style={{ marginTop: 10 }}>
                                        <Col xs={10} md={11}>
                                            <ReactSelect ref={integrationHubspotOwnerSelect} styles={{
                                                control: () => ({
                                                    padding: 5,
                                                    width: "100%",
                                                    borderRadius: 5,
                                                    display: "flex",
                                                    border: "1px solid rgb(241 245 249)"
                                                })
                                            }} placeholder="Owner" value={data?.hubspot_owner} options={integrationHubspotOwner.map(integrationItem => ({ key: integrationItem.id, label: integrationItem.name, value: integrationItem.id }))} onChange={(values, currentValue) => {
                                                onChange("hubspot_owner", values)
                                            }} />
                                        </Col>
                                        <Col xs={2} md={1}>
                                            <UilSync style={{ marginTop: 8, cursor: `${loadingIntegrationInfo ? "not-allowed" : "pointer"}` }} id="refresh-integration-hubspot-owner" className={`${loadingIntegrationInfo ? "rotate" : ""}`} onClick={() => {
                                                if (!loadingIntegrationInfo) fetchIntegrationsData('hubspot', 'owner')
                                            }} />
                                            <Tooltip anchorSelect="#refresh-integration-hubspot-owner" place="top">
                                                Refresh
                                            </Tooltip>
                                        </Col>
                                    </Row> */}
                      {data['hubspot_type'] === 'list' && (
                        <Row style={{ marginTop: 10 }}>
                          <Col xs={10} md={11}>
                            <ReactSelect
                              styles={{
                                control: () => ({
                                  padding: 5,
                                  width: '100%',
                                  borderRadius: 5,
                                  display: 'flex',
                                  border: '1px solid #d7d7d7',
                                  backgroundColor: '#f9f9f9'
                                }),
                              }}
                              placeholder="Lists"
                              value={data?.hubspot_list}
                              options={integrationHubspotLists.map(
                                (integrationItem) => ({
                                  key: integrationItem.id,
                                  label: integrationItem.name,
                                  value: integrationItem.id,
                                })
                              )}
                              onChange={(values, currentValue) => {
                                onChange('hubspot_list', values);
                              }}
                            />
                          </Col>
                          <Col xs={2} md={1}>
                            <UilSync
                              style={{
                                marginTop: 8,
                                cursor: `${
                                  loadingIntegrationInfo
                                    ? 'not-allowed'
                                    : 'pointer'
                                }`,
                              }}
                              id="refresh-integration-hubspot-lists"
                              className={`${
                                loadingIntegrationInfo ? 'rotate' : ''
                              }`}
                              onClick={() => {
                                if (!loadingIntegrationInfo)
                                  fetchIntegrationsData('hubspot', 'lists');
                              }}
                            />
                            <Tooltip
                              anchorSelect="#refresh-integration-hubspot-lists"
                              place="top"
                            >
                              Refresh
                            </Tooltip>
                          </Col>
                        </Row>
                      )}
                    </div>
                  );
                }
                if (integration.key === 'zoho-crm') {
                  return (
                    <div style={{ marginTop: 10 }} key={integration.key}>
                      <div>Source:</div>
                      <Row style={{ marginTop: 5 }}>
                        {/* <Col>
                                            <Radio
                                                option={{ value: "deal", label: "Deals" }}
                                                name="zoho_type"
                                                checked={data['zoho_type'] === "deal"}
                                                onChange={(i, checked, value) => onChange('zoho_type', 'deal')}
                                            />
                                        </Col> */}
                        <Col>
                          <Radio
                            option={{ value: 'person', label: 'Persons' }}
                            name="zoho_type"
                            checked={data['zoho_type'] === 'person'}
                            onChange={(i, checked, value) =>
                              onChange('zoho_type', 'person')
                            }
                          />
                        </Col>
                        <Col>
                          {/* <Radio 
                                                option={{ value: "lead", label: "Leads" }}
                                                name="zoho_type"
                                                checked={data['zoho_type'] === "lead"}
                                                onChange={(i, checked, value) => onChange('zoho_type', 'lead')}
                                            /> */}
                        </Col>
                      </Row>
                      <div>Filter Leads</div>
                      <Row style={{ marginTop: 5 }}>
                        <Col>
                          <Radio
                            option={{
                              value: 'next',
                              label: 'Leads added from now on',
                            }}
                            name="zoho_permission"
                            checked={data['zoho_permission'] === 'next'}
                            onChange={(i, checked, value) =>
                              onChange('zoho_permission', 'next')
                            }
                          />
                        </Col>
                        <Col>
                          <Radio
                            option={{
                              value: 'previous',
                              label: 'Leads added previously',
                            }}
                            name="zoho_permission"
                            checked={data['zoho_permission'] === 'previous'}
                            onChange={(i, checked, value) =>
                              onChange('zoho_permission', 'previous')
                            }
                          />
                        </Col>
                        <Col>
                          <Radio
                            option={{ value: 'all', label: 'All Leads' }}
                            name="zoho_permission"
                            checked={data['zoho_permission'] === 'all'}
                            onChange={(i, checked, value) =>
                              onChange('zoho_permission', 'all')
                            }
                          />
                        </Col>
                      </Row>
                      {/* {data['zoho_type'] === "deal" && <Row style={{ marginTop: 10 }}>
                                        <Col>
                                            <Row>
                                                <Col xs={10} md={11}>
                                                    <ReactSelect styles={{
                                                        control: () => ({
                                                            padding: 5,
                                                            width: "100%",
                                                            borderRadius: 5,
                                                            display: "flex",
                                                            border: "1px solid rgb(241 245 249)"
                                                        })
                                                    }} placeholder="Select Pipeline" value={data?.zoho_pipeline} options={integrationZohoPipelines.map(integrationItem => ({ key: integrationItem.id, label: integrationItem.name, value: integrationItem.id }))} onChange={(values, currentValue) => {
                                                        onChange("zoho_pipeline", values)
                                                        onChange("zoho_stage", null)
                                                        fetchIntegrationsData('zoho', 'stage', values?.value)
                                                    }} />
                                                </Col>
                                                <Col xs={2} md={1}>
                                                    <UilSync style={{ marginTop: 8, cursor: `${loadingIntegrationInfo?"not-allowed":"pointer"}` }} id="refresh-integration-zoho-pipeline" className={`${loadingIntegrationInfo?"rotate":""}`} onClick={() => {
                                                        if(!loadingIntegrationInfo) fetchIntegrationsData('zoho', 'pipeline')
                                                    }}/>
                                                    <Tooltip anchorSelect="#refresh-integration-zoho-pipeline" place="top">
                                                        Refresh
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: 10 }}>
                                                <Col xs={10} md={11}>
                                                    <ReactSelect styles={{
                                                        control: () => ({
                                                            padding: 5,
                                                            width: "100%",
                                                            borderRadius: 5,
                                                            display: "flex",
                                                            border: "1px solid rgb(241 245 249)"
                                                        })
                                                    }} placeholder="Select Stage" value={data?.zoho_stage} options={integrationZohoStages.map(integrationItem => ({ key: integrationItem.id, label: integrationItem.name, value: integrationItem.id }))} onChange={(values, currentValue) => {
                                                        onChange("zoho_stage", values)
                                                    }} />
                                                </Col>
                                                <Col xs={2} md={1}>
                                                    <UilSync style={{ marginTop: 8, cursor: `${loadingIntegrationInfo?"not-allowed":"pointer"}` }} id="refresh-integration-zoho-stage" className={`${loadingIntegrationInfo?"rotate":""}`} onClick={() => {
                                                        if(!loadingIntegrationInfo) fetchIntegrationsData('zoho', 'stage', data?.zoho_pipeline?.value)
                                                    }}/>
                                                    <Tooltip anchorSelect="#refresh-integration-zoho-stage" place="top">
                                                        Refresh
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>} */}
                      <Row style={{ marginTop: 10 }}>
                        <Col xs={10} md={11}>
                          <ReactSelect
                            styles={{
                              control: () => ({
                                padding: 5,
                                width: '100%',
                                borderRadius: 5,
                                display: 'flex',
                                border: '1px solid #d7d7d7',
                                backgroundColor: '#f9f9f9'
                              }),
                            }}
                            placeholder="Select a View"
                            value={data?.zoho_owner}
                            options={integrationZohoOwner.map(
                              (integrationItem) => ({
                                key: integrationItem.id,
                                label: integrationItem.name,
                                value: integrationItem.id,
                              })
                            )}
                            onChange={(values, currentValue) => {
                              onChange('zoho_owner', values); // zoho_owner == zoho_contacts_view
                            }}
                          />
                        </Col>
                        <Col xs={2} md={1}>
                          <UilSync
                            style={{
                              marginTop: 8,
                              cursor: `${
                                loadingIntegrationInfo
                                  ? 'not-allowed'
                                  : 'pointer'
                              }`,
                            }}
                            id="refresh-integration-zoho-owner"
                            className={`${
                              loadingIntegrationInfo ? 'rotate' : ''
                            }`}
                            onClick={() => {
                              if (!loadingIntegrationInfo)
                                fetchIntegrationsData('zoho-crm', 'owner');
                            }}
                          />
                          <Tooltip
                            anchorSelect="#refresh-integration-zoho-owner"
                            place="top"
                          >
                            Refresh
                          </Tooltip>
                        </Col>
                      </Row>
                    </div>
                  );
                }
              })}
            </div>
          ) : null}
        </div>
      );
    case 'conditions':
      return (
        <div>
          <SourceConditions
            taskConditions={taskConditions}
            pharse="Conditions"
            label={nodeType === 'ifelse' ? 'Trigger Event(s)' : ''}
            defaultValue={data?.conditions}
            onChange={(conditions) => onConditionsChange(conditions)}
            conditionFor={conditionFor}
          />
          {showReplyWarning(nodeType, data?.conditions) ? <div style={{ color: "#d95565", marginTop: 5 }}>
            Minimum wait time to check replies is 3 hours.
          </div>:""}
        </div>
      );
    case 'action_conditions':
      return (
        <div>
          {/* { key: "c", value: "Email Replied", label: "Email Replied" }, */}
          <Select
            default={flowchartData?.editNode?.data?.action_conditions}
            name="action_conditions"
            selectStyle={{ border: '1px solid #d7d7d7' }}
            onChange={(field, value, valid, label) =>
              onChange(field, value, label)
            }
            placeholder="Event"
            options={
              taskConditions
                ? taskConditions?.map((taskCondition) => ({
                    key: taskCondition._id,
                    value: taskCondition._id,
                    label: taskCondition.name,
                  }))
                : [
                    {
                      key: 'a',
                      value: 'Email Opened',
                      label: 'Last Email is Opened',
                    },
                    {
                      key: 'b',
                      value: 'Email Clicked',
                      label: 'Link in Last Email is Clicked',
                    },
                    {
                      key: 'c',
                      value: 'Email Replied',
                      label: 'Last Email gets a Reply',
                    },
                  ]
            }
          ></Select>
        </div>
      );
    case 'conditionType':
      return (
        <div>
          <div>Source Condition</div>
          <Select
            defaultValue={'and'}
            name="conditionType"
            onChange={(field, value, valid, label) =>
              onChange(field, value, label)
            }
            selectStyle={{ border: '1px solid #d7d7d7' }}
            placeholder="Event"
            options={[
              { key: 'a', value: 'or', label: 'Or' },
              { key: 'b', value: 'and', label: 'And' },
            ]}
          ></Select>
        </div>
      );
    case 'email_templates':
      return (
        <div>
          <div id='email-template-customize' className='flex mb-2' style={{ flexDirection: deviceWidth > 991 ? 'row' : 'column' }}>
            <div className='font-semibold' style={{ paddingTop: 20 }}>
              <label>Email Template</label>
            </div>
            <div className="flex" style={{ marginLeft: deviceWidth > 991 ? 'auto' : 0 }}>
              {data?.email_templates &&
                data?.email_templates?.value !== 'unselected' &&
                data?.email_templates?.value !== '' && (<CustomButton
                iconOnly={true}
                icon={<UilPen />}
                style={{ marginRight: 5 }}
                variant="secondary"
                id="edit-email-template"
                onClick={() => {
                  flowchartData?.setShowCreateTemplate(true);
                  flowchartData?.setEditTemplateID(
                    data?.email_templates?.value
                  );
                }}
              />)}
              <Tooltip anchorSelect="#edit-email-template" place="top">
                Edit Template
              </Tooltip>
              <CustomButton
                title="New Template"
                icon={<UilPlusCircle />}
                className="layout-reverse"
                style={{ marginRight: 5, marginLeft: 10 }}
                variant="secondary"
                onClick={() => flowchartData?.setShowCreateTemplate(true)}
              />
            </div>
          </div>
          <AsyncSelect
            styles={{
              control: () => ({
                padding: 5,
                width: '100%',
                borderRadius: 5,
                display: 'flex',
                border: '1px solid #d7d7d7',
                backgroundColor: '#f9f9f9'
              }),
            }}
            placeholder="Search for an Email Template"
            name="email_templates"
            value={data?.email_templates}
            defaultOptions={templates}
            getOptionValue={(option) => option.value} // Define how to get the value of each option
            getOptionLabel={(option) => option.label} // Define how to get the label of each option
            isClearable
            loadOptions={fetchTemplates} // Function to fetch sequence options asynchronously
            onChange={(values, currentValue) =>
              onChange('email_templates', values?.value, values?.label)
            }
            noOptionsMessage={() => 'No Results, Type to Search'}
          />
          {/* {flowchartData?.editNode?.data?.email_templates && <div className="mt-1 mb-1 py-2">
                    <a role="button" onClick={() => {
                        flowchartData?.setShowCreateTemplate(true)
                        flowchartData?.setEditTemplateID(flowchartData?.editNode?.data?.email_templates?.value)
                    }} className="text-white rounded text-blue-500 background-transparent underline font-bold outline-none ease-linear transition-all duration-150">Edit Selected Template</a>
                </div>} */}
        </div>
      );
    case 'send_as':
      return (
        <>
          {showFollowUp && (
            <div>
              <div className='font-semibold'>Send Email As</div>
              <Select
                value={data?.send_as?.value}
                name="send_as"
                selectStyle={{ border: '1px solid #d7d7d7' }}
                onChange={(field, value, valid, label) =>
                  onChange(field, value, label)
                }
                options={[
                  { key: 'f', value: 'new email', label: 'New Email' },
                  { key: 'g', value: 'followup', label: 'RE: Follow Up' },
                ]}
              ></Select>
              {data?.send_as?.value === "followup" && <div style={{ fontSize: 15, marginTop: -10 }}>
                <em>
                  Since you are sending the email as “RE: Follow Up“,
                  <br />
                  Subject Line of this template will be ignored & follow-up email will be sent in same thread as a reply to the last email.
                </em>
              </div>}
            </div>
          )}
        </>
      );
    case 'tasktype':
      return (
        <div>
          <div className='flex mb-2' style={{ flexDirection: deviceWidth > 991 ? 'row' : 'column' }}>
            <div className='font-semibold' style={{ paddingTop: 20 }}>
              <label>Task Type</label>
            </div>
            <div style={{ marginLeft: deviceWidth > 991 ? 'auto' : 0 }}>
              <CustomButton
                title="Task Type"
                icon={<UilPlusCircle />}
                className="layout-reverse"
                style={{ marginRight: 5 }}
                variant="secondary"
                onClick={() => window.open('/task-types', '_blank')}
              />
            </div>
          </div>
          <Row>
            <Col xs={10} md={11}>
              <Select
                name="tasktype"
                selectStyle={{ border: '1px solid #d7d7d7' }}
                options={taskTypes.map((taskType) => ({
                  key: taskType._id,
                  label: taskType.name,
                  value: taskType._id,
                }))}
                onChange={(field, value, valid, label) => {
                  if (value !== '') {
                    onChange(field, value, label);
                    onTaskTypeSelected(value);
                  }
                }}
                placeholder="Event"
              ></Select>
            </Col>
            <Col xs={2} md={1}>
              <UilSync
                style={{
                  marginTop: 15,
                  cursor: `${loading ? 'not-allowed' : 'pointer'}`,
                }}
                id="refresh-task-type"
                className={`${loading ? 'rotate' : ''}`}
                onClick={() => {
                  if (!loading) fetchTaskTypes();
                }}
              />
              <Tooltip anchorSelect="#refresh-task-type" place="top">
                Refresh
              </Tooltip>
            </Col>
          </Row>

          {/* {
                    selectedTaskType?.task_attributes?.map((taskAttr, i) => {
                        let taskAttrName = taskAttr.attribute.replaceAll(" ", "_")
                        return <div key={i}>
                            <div>{taskAttr.attribute}</div>
                            <TextInput name={taskAttrName} value={data[taskAttrName]} onChange={(field, value) => onChange(field, value)} />
                        </div>
                    })
                } */}

          {selectedTaskType?.allow_templates && (
            <>
              <div className='flex mb-2 mt-2'>
                <div className='font-semibold' style={{ paddingTop: 20 }}>
                  <label>Task Template</label>
                </div>
                <div className="ml-auto">
                  <CustomButton
                    title="New Template"
                    icon={<UilPlusCircle />}
                    className="layout-reverse"
                    style={{ marginRight: 5 }}
                    variant="secondary"
                    onClick={() => flowchartData?.setShowCreateTaskTemplate(true)}
                  />
                </div>
              </div>
              <Row>
                <Col xs={10} md={11}>
                  <Select
                    name="task_templates"
                    onChange={(field, value, valid, label) =>
                      onChange(field, value, label)
                    }
                    selectStyle={{ border: '1px solid #d7d7d7' }}
                    placeholder="Event"
                    options={taskTemplates.map((template) => ({
                      key: template.id,
                      label: template.name,
                      value: template._id,
                    }))}
                  ></Select>
                </Col>
                <Col xs={2} md={1}>
                  <UilSync
                    style={{
                      marginTop: 15,
                      cursor: `${loadingTask ? 'not-allowed' : 'pointer'}`,
                    }}
                    id="refresh-task-template"
                    className={`${loadingTask ? 'rotate' : ''}`}
                    onClick={() => {
                      if (!loadingTask)
                        fetchTaskTemplates(selectedTaskType?._id);
                    }}
                  />
                  <Tooltip anchorSelect="#refresh-task-template" place="top">
                    Refresh
                  </Tooltip>
                </Col>
              </Row>
            </>
          )}
        </div>
      );
    case 'delay':
      return (
        <div>
          <div style={{ display: 'flex' }}>
            {nodeType === 'ifelse' ? `Stop waiting for the trigger after` : 'Wait For'}
            {nodeType === 'ifelse' && (
              <UilInfoCircle
                id="select-time-unit"
                size={20}
                style={{ marginLeft: 5, marginTop: 3 }}
              />
            )}
          </div>
          <TextInput
            name="delay"
            type="number"
            min={1}
            value={data.delay}
            onChange={(field, value) => onChange(field, value)}
          />
          <Tooltip anchorSelect="#select-time-unit" place="right">
            {whitelabelDetails?.agencyName} will wait for this long to check for trigger before moving on to next "No" case, 
            <br /> "Yes" cases run immediately as they happen.
          </Tooltip>
        </div>
      );
    case 'delay_unit':
      return (
        <div>
          <div>Wait Type</div>
          <Select
            name="delay_unit"
            selectStyle={{ border: '1px solid #d7d7d7' }}
            value={data?.delay_unit?.value}
            onChange={(field, value, valid, label) =>
              onChange(field, value, label)
            }
            options={[
              { key: 'a', value: 'minutes', label: 'Minutes' },
              { key: 'b', value: 'hours', label: 'Hours' },
              { key: 'c', value: 'days', label: 'Days' },
            ]}
          ></Select>
        </div>
      );
    case 'attribute':
      if (data?.lists?.value?.value)
        return (
          <div>
            <div style={{ marginTop: 10 }} className='font-semibold'>{whitelabelDetails?.agencyName} Variables</div>
            <AttributeConditions
              onChange={(field, value) => onChange(field, value)}
              data={data}
            />
          </div>
        );
      else return <div></div>;

    default:
      return <div>Other</div>;
  }
}

export default function CustomizeNode({
  selectedNode,
  type,
  onSave,
  dataChanged,
}) {
  const [data, setData] = useState({});
  const flowchartData = useFlowchartData();
  const [firstNode, setFirstNode] = useState(null);
  const [conditionFor, setConditionFor] = useState(false);

  const updateData = (field, value, label) => {
    console.log("DATA ", field, value, label)
    if (field === 'tasktype' && value) flowchartData?.setTaskType(value);
    setData((oldData) => ({
      ...oldData,
      [field]: label ? { label, value } : value,
    }));
  };

  useEffect(() => {
    if (flowchartData?.editNode) setConditionFor(flowchartData?.getParentNodeType(flowchartData?.editNode?.id))
    else setConditionFor(flowchartData?.getParentNodeType(flowchartData?.selectedNode?.id))

    if (!flowchartData.editNode) return;
    if (flowchartData?.editNode) setData(flowchartData?.editNode?.data);
    if (flowchartData.isFirstNode(flowchartData.editNode.id))
      setFirstNode(true);
  }, [flowchartData.editNode]);

  useEffect(() => {
    if (!selectedNode.fields) onSave && onSave({}, selectedNode.group);
  }, selectedNode);

  const areConditionsValid = (andConditions) => {
    if(conditionFor === "email" || conditionFor === "task") return true
    if (!andConditions) return false;

    for (let _andCondition of andConditions) {
      if (_andCondition.conditions.length === 0) return false;
      for (let _orCondition of _andCondition?.conditions) {
        if (!_orCondition?.type || !_orCondition?.value) return false;
        if (_orCondition?.type === '' || _orCondition?.value === '')
          return false;
      }
    }

    return true;
  };

  const validateData = () => {
    for (let field of selectedNode.fields) {
      if (
        field === 'lists' &&
        (data[field]?.value?.length > 0 || data['lists']?.value?.value)
      )
        continue;
      if (field === 'delay' && data[field] && data[field]?.length > 0) continue;
      if (
        field === 'delay_unit' &&
        data[field] &&
        data[field]?.value.length > 0 &&
        data[field]?.value !== 'unselected'
      )
        continue;
      if (field === 'create_email_template') continue;
      if (
        field === 'email_templates' &&
        data[field] &&
        data[field]?.value.length > 0 &&
        data[field]?.value !== 'unselected'
      )
        continue;
      if (
        field === 'send_as' &&
        data[field] &&
        data[field]?.value.length > 0 &&
        data[field]?.value !== 'unselected'
      )
        continue;
      if (
        field === 'tasktype' &&
        data[field] &&
        data[field]?.value.length > 0 &&
        data[field]?.value !== 'unselected'
      )
        continue;
      if (field === 'attribute') {
        let validAttr = 0;
        for (let item of data[field]) {
          if (item.list !== '' && item.condition !== '') validAttr++;
        }
        if (data[field].length === validAttr) continue;
      }
      if (
        field === 'conditions' &&
        data[field] &&
        areConditionsValid(data[field]) === true
      )
        continue;
      if (field === 'action_conditions' && data[field]) continue;
      let isFirstEmailNode = !flowchartData?.isNodeTypeInBranch(
        flowchartData?.selectedNode?.id,
        'email'
      );
      if (field === 'send_as' && isFirstEmailNode) continue;
      if (field === 'integrations' && data[field]?.value?.length > 0) {
        for (let item of data[field].value) {
          if (item.key === 'pipedrive') {
            if (
              !data.pipedrive_type ||
              !data.pipedrive_permission ||
              !data.pipedrive_owner
            )
              return Swal.fire({
                icon: 'info',
                title: ``,
                html: `<h5>Select All Pipedrive Settings!</h5>`,
                showCancelButton: true,
                confirmButtonText: `Ok`,
                confirmButtonColor: '#0066FF',
              });
            if (data.pipedrive_type === 'deal') {
              if (!data.pipedrive_stage || !data.pipedrive_pipeline)
                return Swal.fire({
                  icon: 'info',
                  title: ``,
                  html: `<h5>Select All Pipedrive Deal Settings!</h5>`,
                  showCancelButton: true,
                  confirmButtonText: `Ok`,
                  confirmButtonColor: '#0066FF',
                });
            }
          }
          if (item.key === 'zoho-crm') {
            if (!data.zoho_type || !data.zoho_permission || !data.zoho_owner)
              return Swal.fire({
                icon: 'info',
                title: ``,
                html: `<h5>Select All Zoho CRM Settings!</h5>`,
                showCancelButton: true,
                confirmButtonText: `Ok`,
                confirmButtonColor: '#0066FF',
              });
          }
          if (item.key === 'hubspot') {
            // console.log("HS ", data)
            if (!data.hubspot_type || !data.hubspot_permission)
              return Swal.fire({
                icon: 'info',
                title: ``,
                html: `<h5>Select All HubSpot Settings!</h5>`,
                showCancelButton: true,
                confirmButtonText: `Ok`,
                confirmButtonColor: '#0066FF',
              });
            if (data.hubspot_type === 'deal') {
              if (!data.hubspot_stage || !data.hubspot_pipeline)
                return Swal.fire({
                  icon: 'info',
                  title: ``,
                  html: `<h5>Select All HubSpot Deal Settings!</h5>`,
                  showCancelButton: true,
                  confirmButtonText: `Ok`,
                  confirmButtonColor: '#0066FF',
                });
            }
            if (data.hubspot_type === 'list') {
              if (!data.hubspot_list)
                return Swal.fire({
                  icon: 'info',
                  title: ``,
                  html: `<h5>Select All HubSpot List Settings!</h5>`,
                  showCancelButton: true,
                  confirmButtonText: `Ok`,
                  confirmButtonColor: '#0066FF',
                });
            }
          }
        }
        continue;
      }
      Swal.fire({
        icon: 'info',
        title: ``,
        html: `<h5>Invalid Values Found, Please check all fields!</h5>`,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
      return false;
    }
    return true;
  };

  function showButton() {
    if((selectedNode?.type === "source_entry_list" || selectedNode?.type === "source_entry_condition" || selectedNode?.type === "source_entry_attribute" || selectedNode?.type === "move_contact_to_list") && (!data?.lists || data?.lists?.value?.length === 0)) return null
    if(selectedNode?.type === "source_entry_integration" && (!data?.integrations || data?.integrations?.value?.length === 0)) return null
    if(selectedNode?.type === "email" && (!data?.email_templates || !data?.email_templates?.value)) return null
    if(selectedNode?.type === "task" && (!data?.tasktype || !data?.tasktype?.value || data?.tasktype?.value === "" || data?.tasktype?.value === "unselected")) return null

    return <CustomButton
      image={flowchartData.editNode ? 'edit' : 'save'}
      title={flowchartData.editNode ? 'Update' : 'Insert'}
      style={{ marginLeft: 'auto' }}
      variant="primary"
      onClick={() => {
        if (validateData() === true) {
          onSave &&
            onSave(data, selectedNode.group, flowchartData.editNode);
        }
      }}
    />
  }

  return (
    <div style={{ padding: 10 }}>
      <h1 className="text-2xl text-modal-header">
        {flowchartData.editNode ? 'Edit' : ''} {selectedNode?.label}
      </h1>
      <p className="text-modal-description">{selectedNode?.desc}</p>
      <hr className="mt-3 mb-3" />
      <div style={{ paddingLeft: 15, height: "73vh",
        maxHeight: "73vh", overflow: 'auto', 
        paddingBottom: 20 }}>
        {/* <h2 style={{ fontSize: 20, fontWeight: 600 }}>{selectedNode?.label}</h2> */}
        {selectedNode?.group === 'source' && flowchartData.nodes.length > 0 && (
          <>
            {firstNode === false && (
              <div style={{ overflowY: 'scroll' }}>
                Condition Type
                <Select
                  defaultValue=""
                  selectStyle={{ border: '1px solid #d7d7d7' }}
                  placeholder="Select Node Type"
                  options={[
                    { key: 'and', value: 'and', label: 'And' },
                    { key: 'or', value: 'or', label: 'Or' },
                  ]}
                ></Select>
              </div>
            )}
          </>
        )}
        {selectedNode?.fields?.map((fieldType, i) => (
          <div key={i} style={{ marginTop: 10 }}>
            <GetField
              dataChanged={dataChanged}
              data={data}
              onConditionsChange={(conditions) => {
                setData((oldData) => {
                  let _data = { ...oldData };
                  _data['conditions'] = conditions;
                  return _data;
                });
              }}
              onChange={(field, value, label) =>
                updateData(field, value, label)
              }
              fieldType={fieldType}
              nodeType={selectedNode?.type}
            />
          </div>
        ))}
        <div style={{ textAlign: 'right', marginTop: 10, marginRight: 5 }}>
          {showButton()}
        </div>
      </div>
    </div>
  );
}
