const Style = {
  message: 'relative p-8 overflow-hidden w-full rounded mb-4',
  title: '',
  blueBg: 'bg-blue',
  blueText: 'text-blue',
  greenBg: 'bg-green',
  greenText: 'text-green',
  orangeBg: 'bg-orange',
  orangeText: 'text-orange',
  redBg: 'bg-red',
  redText: 'text-red',
  icon: '',
  content: 'float-left mt-6 width-auto overflow-hidden md:float-none md:mt-0',
  close:
    'absolute top-5 right-5 opacity-60 w-4 h-4 cursor-pointer z-1 bg-transparent ease-in-out duration-300 hover:opacity-90',
  btn: 'mt-3',
};

export default Style;
