import {
    UilClock,
    UilEnvelopeCheck,
    UilMessage,
    UilEnvelopeOpen,
    UilFile,
    UilEnvelopeReceive,
    UilLink,
    UilTimesCircle,
    UilPlus,
    UilCheck,
    UilTimes,
    UilQuestion,
    UilExternalLinkAlt,
    UilEnvelopeTimes
} from '@iconscout/react-unicons';
import Axios from 'axios';
import CustomButton from 'components/custom/customButton';
import {
    Animate,
    AuthContext,
    Badge,
    Checkbox,
    CustomDropdown,
    Loader,
    TextInput,
    useNavigate,
} from 'components/lib';
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Link, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { fabric } from 'fabric';
import Swal from 'sweetalert2';
import { DateTime } from 'luxon';
import timezones from '../../assets/data/timezone';
import tracking_app_urls from '../../assets/data/tracking_links';
import emptyBoxImg from '../../assets/empty_box.png';
import './activities.scss';
import { getQuarter, getTimeOfDay } from 'utils/time_formatter';

const outcomeJson = {
    'interested': {
        name: "Interested",
        color: 'success'
    },
    'not-interested': {
        name: "Not Interested",
        color: 'danger'
    },
    'automatic-response': {
        name: "Automatic Response",
        color: 'danger'
    },
    'meeting-request': {
        name: "Meeting Request",
        color: 'success'
    },
    'out-of-office': {
        name: "Out of Office",
        color: 'success'
    },
    'do-not-contact': {
        name: "Do not contact",
        color: 'danger'
    },
    'wrong-person': {
        name: "Wrong Person",
        color: 'danger'
    },
    'closed': {
        name: "Closed",
        color: 'success'
    }
}

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
});

function titleCase(str) {
    var splitStr = str.toLowerCase().split('_');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join('_');
}

const getSingleBraceMacroValue = (macro, prospectData, task) => {
    let macroKeys = macro.replaceAll('{', '').replaceAll('}', '').trim().split('|');
    macroKeys = macroKeys.map((macroKey) => macroKey.trim());
  
    for (let macroKey of macroKeys) {
        macroKey = titleCase(macroKey);
        let upperCaseMacroKey = macroKey.toUpperCase();
    
        if (prospectData[macroKey]) return prospectData[macroKey];
        else if (prospectData[upperCaseMacroKey]) return prospectData[upperCaseMacroKey];
        else if (macroKey == 'Unsubscribe_Link') {
            let trackingDomain = tracking_app_urls[
                Math.floor(Math.random() * tracking_app_urls.length)
            ];
            return `https://${trackingDomain}/unsubscribe/${task?.id}`;
        } else {
            let newMacroKey = [...macroKeys]
            let selectKey = newMacroKey[Math.floor(Math.random() * newMacroKey.length)];
            let titleCaseSelectedKey = titleCase(selectKey);
            let upperCaseSelectedKey = selectKey.toUpperCase();
    
            if (prospectData[titleCaseSelectedKey]) return prospectData[titleCaseSelectedKey];
            else if (prospectData[upperCaseSelectedKey]) return prospectData[upperCaseSelectedKey];
            else if (newMacroKey.length === 1) return "";
            else return macroKeys[macroKeys.length - 1];
        }
    }
    return '';
};

const getMacroValue = (macro, prospectData, task) => {
    macro = macro.replaceAll('{{', '').replaceAll('}}', '')
    let insideMacros = macro.match(/{.*?}/g);
    // console.log("INITIAL MACRO ", macro)
    if(insideMacros?.length > 0) {
        const uniqueSet = new Set(insideMacros);
        const newArray = Array.from(uniqueSet);
    
        newArray.map((nMacro) => {
            // console.log("INSIDE MACROS ", nMacro)
            macro = macro.replaceAll(nMacro, getSingleBraceMacroValue(nMacro, prospectData, task));
        });
        // console.log("ULTIMATE MACRO ", macro)
    }
    let macroKeys = macro.replaceAll('{', '').replaceAll('}', '').trim().split('|');
    macroKeys = macroKeys.map((macroKey) => macroKey.trim());
  
    for (let macroKey of macroKeys) {
        macroKey = titleCase(macroKey);
        let upperCaseMacroKey = macroKey.toUpperCase();
    
        if (macroKey == 'Unsubscribe_Link') {
            let trackingDomain = tracking_app_urls[
                Math.floor(Math.random() * tracking_app_urls.length)
            ];
    
            if(prospectData['Unsubscribe_Link'] && prospectData['Unsubscribe_Link'] != '') trackingDomain = prospectData['Unsubscribe_Link']
            return `https://${trackingDomain}/unsubscribe/${task?.id}`;
        } 
        else if (prospectData[macroKey]) return prospectData[macroKey];
        else if (prospectData[upperCaseMacroKey]) return prospectData[upperCaseMacroKey];
        else {
            let newMacroKey = [...macroKeys]
            let selectKey = newMacroKey[Math.floor(Math.random() * newMacroKey.length)];
            let titleCaseSelectedKey = titleCase(selectKey);
            let upperCaseSelectedKey = selectKey.toUpperCase();
    
            if (prospectData[titleCaseSelectedKey]) return prospectData[titleCaseSelectedKey];
            else if (prospectData[upperCaseSelectedKey]) return prospectData[upperCaseSelectedKey];
            else if (newMacroKey.length === 1) return "";
            else return macroKeys[macroKeys.length - 1];
        }
    }
    return '';
};
  
export default function Activities(props) {
    const [list, setList] = useState(null);
    const [contact, setContact] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [sequences, setSequences] = useState([]);
    const [senders, setSenders] = useState([]);
    const [templates, setTempates] = useState([]);
    const [tasks, setTasks] = useState([]);
    // filter out only the needed sequences and sort them in descending order on `scheduledAt` property
    const sequenceNeeded = tasks.map(task => ({...sequences.find(seq => task._id === seq.id), tasks: task.tasks}));
    const sortedSequencesNeeded = sequenceNeeded.sort((a, b) => b.scheduledAt - a.scheduledAt);
  
    const [ownedBy, setOwnedBy] = useState('');
    const authContext = useContext(AuthContext);
  
    useEffect(() => {
      setOwnedBy(authContext.user.id);
    }, []);
  
    useEffect(() => {
      if(ownedBy && ownedBy.length>0)  fetchSenders();
    }, [ownedBy])
    
    let { contactID } = useParams();
    let navigator = useNavigate();

    useEffect(() => {
        fetchSequences()
        fetchTemplates()
    }, [])
  
    useEffect(() => {
        if (!contactID) return;
        fetchContact();
    }, [contactID]);

    useEffect(() => {
        if (!contactID) return;
        fetchTasks();
    }, [])
  
    async function updateContact(body) {
        setSaving(true)
        try {
            await Axios.patch(`/api/list/contacts/${contactID}`, body);
            setSaving(false)
            Toast.fire({ icon: 'success', title: 'Contact details updated.' });
            setContact(prev => ({...prev, ...body}));
        } catch (err) {
            setSaving(false)
            // console.log('Error in updating contact!');
            Toast.fire({ icon: 'error', title: 'Unable to save contacts.' });
        }
    }
  
    async function fetchContact() {
        try {
            let result = await Axios.get(`/api/list/contacts/${contactID}`);
            setContact({
                ...result.data.data,
                Sb_Day_Of_Week: DateTime.now().toFormat('EEEE'),
                Sl_Time_Of_Day: getTimeOfDay(DateTime.now().toFormat('H')),
                Sb_Current_Quarter: getQuarter(DateTime.now().month),
                Sb_Next_Quarter: getQuarter(DateTime.now().plus({ months: 3 }).month),
                Sb_Current_Year: DateTime.now().toFormat('yyyy'),
                Sb_Next_Year: DateTime.now().plus({ years: 1 }).toFormat('yyyy'),
                Sb_Current_Month: DateTime.now().toFormat('LLLL'),
                Sb_Next_Month: DateTime.now().plus({ months: 1 }).toFormat('LLLL')
            });
            setList(result.data.list);
            setLoading(false)
        } catch (err) {
            console.log('Error in Fetching Contact! ', err);
        }
    }

    async function fetchSequences() {
        try {
            const result = await Axios.get(`/api/sequences-all`);
            // console.log("SEQ ", result);
            setSequences(result?.data?.data);
        } catch (err) {
            console.log('Error in getting sequences ', err);
        }
    }

    async function fetchSenders() {
        try {
            let result;
            if((authContext.user.permission === 'owner' || authContext.user.permission === 'admin')){
                result = await Axios.get(`/api/senders`);
            }else {
                result = await Axios.get(`/api/senders?ownedby=${ownedBy}`);
            }
            // console.log("SEQ ", result);
            setSenders(result?.data?.data);
        } catch (err) {
            console.log('Error in getting sequences ', err);
        }
    }

    const fetchTemplates = async () => {
        try {
          let result = await Axios.get('/api/template?limit=100');
        //   console.log("Template ", result.data.data);
          setTempates(result?.data?.data);
        } catch (err) {
          console.log('Error in Fetching templates ', err);
        }
    };

    async function fetchTasks() {
        try {
            const res = await Axios({
                url: `/api/activity-tasks?contact=${contactID}`,
                method: 'get',
            });
            // console.log(res.data.data);
            setTasks(res?.data?.data);
        } catch (error) {
            console.log('ERROR: ', error);
        }
    }

    function formatDate(value) {
        const date = DateTime.fromMillis(value);

        const day = date.day;
        const suffix =
          day % 10 === 1 && day !== 11
            ? 'st'
            : day % 10 === 2 && day !== 12
            ? 'nd'
            : day % 10 === 3 && day !== 13
            ? 'rd'
            : 'th';

        return date.toFormat(`d`) + suffix + date.toFormat(` MMM yyyy`);
    }
  
    if(loading) return <Loader />

    return (
        <Animate type="pop">
            <Row className="csRow contactActivitySection">
                <Col xs={12} lg={5}>
                    <h2>Edit Lead Details</h2>
                    {contact && <div id='lead-dates' className='flex' style={{ fontSize: 15, fontStyle: 'italic' }}>
                        <div>
                            Date Added: {formatDate(contact?.created_date)}
                        </div>
                        {contact?.last_modified && <div id='last-updated'>
                            Last Updated: {formatDate(contact.last_modified)}
                        </div>}
                    </div>}
                    <div id='is-in-list'>
                        <i>Is in list: <Link to={`/outreach/lists/${list?.id}`} target='_blank' className='csLink external-link'>{list?.name}<UilExternalLinkAlt /></Link></i>
                    </div>
                    {contact && list && (
                        <ContactEdit
                            onSave={updateContact}
                            contact={contact}
                            list={list}
                            saving={saving}
                        />
                    )}
                </Col>
                <Col id='single-activities' xs={12} lg={7} style={{ position: 'relative' }} >
                    <h2>Lead Activity</h2>
                    {tasks.length === 0 ? contact ? 
                        <div className='csCard mt-4'>
                            <div style={{ fontSize: 14 }}>
                                <span>
                                    <b>Lead added on</b> <i>{formatDate(contact?.created_date)}</i> in <Link to={`/outreach/lists/${list?.id}`} target='_blank' className='csLink external-link'>{list?.name}<UilExternalLinkAlt /></Link>
                                </span>
                            </div>
                        </div>
                    : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', width: "100%" }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        margin: 'auto',
                                        marginTop: 50,
                                        marginBottom: 50,
                                    }}
                                >
                                    <img
                                        style={{ margin: 'auto', height: 200 }}
                                        src={emptyBoxImg}
                                    />
                                    No Activities Found.
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {sortedSequencesNeeded.map(sequence => <SequenceView
                                key={sequence._id}
                                sequence={sequence}
                                templates={templates}
                                senders={senders}
                                tasks={sequence.tasks}
                                contact={contact}
                                />)}
                            <div className='csCard mt-4'>
                                <div style={{ fontSize: 14 }}>
                                    <span>
                                        <b>Lead added on</b> <i>{formatDate(contact?.created_date)}</i> in <Link to={`/outreach/lists/${list?.id}`} target='_blank' className='csLink external-link'>{list?.name}<UilExternalLinkAlt /></Link>
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </Col>
            </Row>
        </Animate>
    );
}

function SequenceView(props) {
    
    const removeFromSequence = async () => {
        try {
            await Axios.delete(`/api/sequences/${props.sequence?.id}/${props.contact?._id}`);
            Toast.fire({ icon: 'success', title: 'Contact removed from sequence' });
            window.location.reload();
        } catch (err) {
            Toast.fire({ icon: 'error', title: 'Unable to remove contact.' });
        }
    }

    return <div className='mt-3'>
        <div className='flex'>
            <h3 className='mb-2 flex'> <UilMessage style={{ marginRight: 5 }} /> Sequence: <a href={`/outreach/sequences/${props.sequence?.id}/reports`} className='csCustomeLink' target='_blank' style={{ marginTop: 2, marginLeft: 3 }}>{props.sequence?.name} <UilExternalLinkAlt size={14} className="csLinkIcon" /></a></h3>
            <div style={{ marginLeft: 'auto' }}>
                <CustomDropdown
                    right={true}
                    items={[
                        {
                            title: "Remove from Sequence",
                            action: () => {
                                removeFromSequence()
                            },
                        }
                    ]}
                    align="left"
                />
            </div>
        </div>
        <div className='csCard'>
            {props.tasks?.map(task => {
                if(task.task_type === "email") return <EmailView 
                    key={task._id}
                    sender={props.senders?.find(s => s.id === task.sender)}
                    template={props.templates?.find(t => t._id === task?.data?.email_templates?.value)}
                    task={task}
                    contact={props.contact}
                    sequence={props.sequence}
                    isLast={task._id === props.tasks[props.tasks.length - 1]._id}
                />
                if(task.task_type === "reply") return <EmailView 
                    key={task._id}
                    sender={props.senders?.find(s => s.id === task.sender)}
                    template={props.templates?.find(t => t._id === task?.data?.email_templates?.value)}
                    task={task}
                    contact={props.contact}
                    sequence={props.sequence}
                    isLast={task._id === props.tasks[props.tasks.length - 1]._id}
                />
                if(task.task_type === "task") return <TaskView 
                    key={task._id}
                    sender={props.senders?.find(s => s.id === task.sender)}
                    template={props.templates?.find(t => t._id === task?.data?.task_templates?.value)}
                    task={task}
                    contact={props.contact}
                    sequence={props.sequence}
                    isLast={task._id === props.tasks[props.tasks.length - 1]._id}
                />
                
                return null
            })}
        </div>
    </div>
}

function TaskView(props) {
    function makeEmail(text) {
        let textMacros = text?.match(/{{.*?}}/g);
    
        if (textMacros) {
            textMacros.map((macro) => {
                text = text.replaceAll(macro, getMacroValue(macro, props.contact, props.task));
            });
        }
    
        return text;
    }

    return <div style={{ borderBottom: `${props.isLast?0:1}px solid #3c3c3c`, paddingBottom: props.isLast?0:10, marginBottom: props.isLast?0:10 }}>
        <div style={{ fontSize: 12 }}>
            <b>Template:</b> {props.template?.name}
        </div>
        <div className='mt-2'>
            {props.template?.structure?.map((attribute) => {
                if (attribute.type === 'input' || attribute.type === 'textarea') {
                    return (
                        <div style={{ margin: 6, marginTop: 5 }}>
                            <p>
                                <b>{attribute.attribute}: </b>
                                {makeEmail(attribute.answer)}
                            </p>
                        </div>
                    );
                } else if (attribute.type === 'checkboxes') {
                    return (
                        <div style={{ margin: 6, marginTop: 15 }}>
                            <h2 className="text-xl">{attribute.attribute}</h2>
                            <h2 className="text-xl">
                                {attribute.options.map((option) => (
                                    <Checkbox
                                        name="tasks"
                                        onChange={(i, checked, opt) =>
                                            console.log(attribute.id, 'answer', option)
                                        }
                                        checked={attribute?.answer?.find((opt) => opt === option)}
                                        option={option}
                                    />
                                ))}
                            </h2>
                        </div>
                    );
                }
            })}
        </div>
        <div className='flex mt-2'>
            {props.task.completed ? <div>
                <span className="csBadge success csCenter" style={{ padding: 5 }} id={`sent-${props.task._id}`}>
                    <UilEnvelopeCheck
                        size={16}
                    />
                </span>
                <Tooltip anchorSelect={`#sent-${props.task._id}`} place="top">
                    Task Completed
                </Tooltip>
            </div> : <div>
                <span className="csBadge warning csCenter" style={{ padding: 5 }} id={`scheduled-${props.task._id}`}>
                    <UilClock
                        size={16}
                    />
                </span>
                <Tooltip anchorSelect={`#scheduled-${props.task._id}`} place="top">
                    Task Scheduled
                </Tooltip>
            </div>}
        </div>
    </div>
}

function EmailView(props) {
    const [content, setContent] = useState('');

    useEffect(() => {
        let addImageToContent = async () => {
            let initialHTML = props.task?.data?.email?.body || props.template?.content;
            let newPersonalizedImages = [];
            if(!props.task?.data?.email?.body && props.template?.image_state) newPersonalizedImages = JSON.parse(
                JSON.stringify(props.template?.image_state)
            );
            for (let personalizedImage of newPersonalizedImages) {
                if (!personalizedImage.imageState) continue;
                personalizedImage.data = await getBase64DataURL(
                    personalizedImage.imageState
                );
                // Replace Image in content
                let newData = `<img data-id="${personalizedImage.id}" src="${personalizedImage.data}" ${personalizedImage.width? `width=${personalizedImage.width}` :""} ${personalizedImage.height? `height=${personalizedImage.height}` :""}>`;
                initialHTML = initialHTML.replace(
                    `<image_dynamic id="${personalizedImage.id}" ${personalizedImage.width? `width=${personalizedImage.width}` :""} ${personalizedImage.height? `height=${personalizedImage.height}` :""}>`,
                    newData
                );
            }
            setContent(makeEmail(initialHTML));
        };
        addImageToContent();
    }, [props.template?.content]);

    const getBase64DataURL = (imageState) => {
        if (!imageState) return;
        return new Promise((resolve, reject) => {
            if (!imageState) return resolve(false);
        
            let imageStateObj = JSON.parse(makeEmail(imageState));
            let fabricInstance = new fabric.StaticCanvas(null, {
                width: imageStateObj.canvasWidth,
                height: imageStateObj.canvasHeight,
            });
            fabricInstance.loadFromJSON(imageStateObj.canvas, function () {
                resolve(fabricInstance.toDataURL());
            });
        });
    };

    function makeEmail(text) {
        let textMacros = text?.match(/{{.*?}}/g);
    
        if (textMacros) {
            textMacros.map((macro) => {
                text = text.replaceAll(macro, getMacroValue(macro, { ...props.contact, Name_Of_Sender: props.sender?.senderName || "" }, props.task));
            });
        }
    
        return text;
    }

    const getDate = () => {
        if(!props.sequence?.timezone) return ""

        let zoneInfo = timezones.find((t) => t.id === props.sequence?.timezone);
        if(!zoneInfo && props.sequence?.timezone.includes('/')) zoneInfo = { utc: [props.sequence?.timezone] }
        let scheduledDate = DateTime.fromMillis(props.task.scheduled_time);
        if (zoneInfo) scheduledDate = scheduledDate.setZone(zoneInfo.utc[0]);

        const day = scheduledDate.day;
        const suffix =
            day % 10 === 1 && day !== 11
                ? 'st'
                : day % 10 === 2 && day !== 12
                ? 'nd'
                : day % 10 === 3 && day !== 13
                ? 'rd'
                : 'th';
        return <div style={{ fontSize: 12, marginTop: 10 }}>
            {scheduledDate.toFormat(`d`) + suffix + scheduledDate.toFormat(` MMM yyyy, hh:mm a`)}
        </div>
    }

    return <div style={{ borderBottom: `${props.isLast?0:1}px solid #3c3c3c`, paddingBottom: props.isLast?0:10, marginBottom: props.isLast?0:10 }}>
        {props.task.task_type === "email" && <div style={{ fontSize: 12 }}>
            <a className='csCustomeLink flex' href='/outreach/templates' target='_blank' style={{ fontSize: 13, marginBottom: 5 }}><UilFile size={14} style={{ marginTop: 2 }} /> {props.template?.name} <UilExternalLinkAlt size={13} className="csLinkIcon" /></a>            
            <div style={{ paddingRight: 10, marginRight: 10 }}>
                from <b>{props.sender?.alias || props.sender?.microsoft_email || props.sender?.google_email || props.sender?.smtpImapEmail || 'Sender was Removed'}</b>
            </div>
            <div style={{ paddingRight: 10, marginRight: 10 }}>
                to <b>{props.task.email || props.contact.Email}</b>
            </div>
        </div>}
        {(props.task.task_type === "reply" ||props.task.task_type === "forwarding") && <div style={{ fontSize: 12 }}>
            {props.task.task_type === "reply" && `${props.sender?.alias || props.sender?.microsoft_email || props.sender?.google_email || props.sender?.smtpImapEmail || ""} replied to ${props.task.email}`}
            {props.task.task_type === "forwarding" && `${props.sender?.alias || props.sender?.microsoft_email || props.sender?.google_email || props.sender?.smtpImapEmail || ""} forwarded to ${props.task.email}`}
        </div>}
        <div className='mt-2' style={{ fontSize: 14 }}>
            {makeEmail(props.task?.data?.is_followup ? props.task?.data?.subject : props.template?.subject_line)}
            <div
              className="mt-2" style={{ fontSize: 14 }}
              dangerouslySetInnerHTML={{ __html: content }}
            />
            {props.task.task_type === "email" ? <div className='flex mt-2'>
                {props.task.completed ? <div>
                    <span className="csBadge success csCenter" style={{ padding: 5 }} id={`sent-${props.task._id}`}>
                        <UilEnvelopeCheck
                            size={16}
                        />
                    </span>
                    <Tooltip anchorSelect={`#sent-${props.task._id}`} place="top">
                        Email Sent
                    </Tooltip>
                </div> : props.task?.error ? <div>
                    <span className="csBadge danger csCenter" style={{ padding: 5 }} id={`error-${props.task._id}`}>
                    <UilEnvelopeTimes
                        size={16}
                    />
                    </span>
                    <Tooltip anchorSelect={`#error-${props.task._id}`} place="top">
                        {props.task.error?.message?.message || props.task.error?.message || 'Error'}
                    </Tooltip>
                </div> : <div>
                    <span className="csBadge warning csCenter" style={{ padding: 5 }} id={`scheduled-${props.task._id}`}>
                        <UilClock
                            size={16}
                        />
                    </span>
                    <Tooltip anchorSelect={`#scheduled-${props.task._id}`} place="top">
                        Email Scheduled
                    </Tooltip>
                </div>}
                {props.task.opened && <div className='ml-2'>
                    <span className="csBadge success csCenter" style={{ padding: 5 }} id={`opened-${props.task._id}`}>
                        <UilEnvelopeOpen
                            size={16}
                        />
                    </span>
                    <Tooltip anchorSelect={`#opened-${props.task._id}`} place="top">
                        Email Opened
                    </Tooltip>
                </div>}
                {props.task.clicked && <div className='ml-2'>
                    <span className="csBadge success csCenter" style={{ padding: 5 }} id={`clicked-${props.task._id}`}>
                        <UilLink
                            size={16}
                        />
                    </span>
                    <Tooltip anchorSelect={`#clicked-${props.task._id}`} place="top">
                        Link in Email Clicked
                    </Tooltip>
                </div>}
                {props.task.replied && <div className='ml-2'>
                    <span className="csBadge success csCenter" style={{ padding: 5 }} id={`replied-${props.task._id}`}>
                        <UilEnvelopeReceive
                            size={16}
                        />
                    </span>
                    <Tooltip anchorSelect={`#replied-${props.task._id}`} place="top">
                        Email Received a Reply
                    </Tooltip>
                </div>}
                {props.task.outcome && <div className='ml-2' style={{ marginTop: 5 }}>
                    {props.sender?.alias || props.sender?.microsoft_email || props.sender?.google_email || props.sender?.smtpImapEmail || ""} marked this contact as "<span className={`text-${outcomeJson[props.task.outcome]?.color}`}>{outcomeJson[props.task.outcome]?.name}</span>"
                </div>}
                <div style={{ marginLeft: 'auto' }}>
                    {getDate()}
                </div>
            </div> : <div className='flex mt-2'>
                <div style={{ marginLeft: 'auto' }}>
                    {getDate()}
                </div>
            </div>}
        </div>
    </div>
}
  
function ContactEdit({ list, contact, onSave: save, saving }) {
    const [contactDetails, setContactDetails] = useState(null);
    const [contactFields, setContactFields] = useState([]);
    const [newInfoChanged, setNewInfoChanged] = useState(false);

    useEffect(() => {
      if (!contactDetails) return;

      const listCols = list.views.find(
        (view) => view.name === 'Complete View'
      ).columns;
      const customCols = contact.custom_fields;

      // re-create the original object as a source of truth
      const original = Object.fromEntries([
        ...listCols.map((col) => [col, contact[col]]),
        ...customCols.map((col) => [col, contact[col]]),
      ]);

      // compare both objects
      // compare keys
      if (Object.keys(original).length !== Object.keys(contactDetails).length)
        return setNewInfoChanged(true);

      // compare values
      for (const [key, value] of Object.entries(original))
        if (value !== contactDetails[key]) return setNewInfoChanged(true);

      return setNewInfoChanged(false);
    }, [contactDetails, contact]);

    useEffect(() => {
        if(contact.custom_fields) setContactFields(contact.custom_fields);
    }, [contact])

    useEffect(() => {
        let newFields = {}
        let newFieldsFromList = getContactDetails({ list, contact })
        let newFieldsFromContact = getContactDetails({ contact })
        newFields = { ...newFieldsFromList, ...newFieldsFromContact }
        setContactDetails(newFields)
    }, [contactFields])

    function getContactDetails({ list, contact }) {
        if(list) {
            const cols = list.views.find(
                (view) => view.name === 'Complete View'
            ).columns;
            return Object.fromEntries(cols.map((col) => [col, contact[col]]));
        }
        return Object.fromEntries(contactFields.map(field => !contact[field] ? [field, contactDetails[field]] : [field, contact[field]]));
    }
  
    function handleInputChange(event) {
        const { name, value } = event.target;
        setContactDetails((previousValue) => ({ ...previousValue, [name]: value }));
    }
  
    const saveList = async () => {
        if (!contactDetails.Email || contactDetails.Email === '')
          return Swal.fire('Oops', 'Email is required', 'error');

        // save contact
        save({
          ...contactDetails,
          custom_fields: contactFields,
        });
    };
  
    return (
      <div className="editContactSection">
        <Row className="inputs">
          {contactDetails &&
            Object.entries(contactDetails).map(([columnName, value]) => {
              return (
                <Col
                  xs={12}
                  lg={columnName === 'Email' ? 12 : 6}
                  key={columnName}
                  className="input"
                >
                  <label>
                    {columnName.replace(/_/g, ' ')}
                    {columnName === 'Email' &&
                      contact?.verification_required &&
                      (function () {
                        // check accuracy and display things accordingly
                        if (!contact?.accuracy || contact?.accuracy === '0')
                          return (
                            <UilClock
                              color="#3A3A3A"
                              size="20"
                              data-tooltip-content="This email address is in queue to be verified."
                              data-tooltip-id="email-tooltip"
                            />
                          );
                        else if (Number(contact?.accuracy) >= 99)
                          return (
                            <UilCheck
                              color="#3AC569"
                              size="20"
                              data-tooltip-content="Email is Valid."
                              data-tooltip-id="email-tooltip"
                            />
                          );
                        else if (
                          Number(contact?.accuracy) >= 70 &&
                          Number(contact?.accuracy) <= 80
                        )
                          return (
                            <UilQuestion
                              color="#F9C00C"
                              size="20"
                              data-tooltip-content="This email address is linked to an accept-all domain. There is no definitive way to determine whether this email is valid or invalid."
                              data-tooltip-id="email-tooltip"
                            />
                          );
                        else if (
                          Number(contact?.accuracy) >= 1 &&
                          Number(contact?.accuracy) <= 20
                        )
                          return (
                            <UilTimes
                              color="#E53A40"
                              size="20"
                              data-tooltip-content="Email seems to be Invalid."
                              data-tooltip-id="email-tooltip"
                            />
                          );

                        return (
                          <UilClock
                            color="#3A3A3A"
                            size="20"
                            data-tooltip-content="This email address is in queue to be verified."
                            data-tooltip-id="email-tooltip"
                          />
                        );
                      })()}
                    {contactFields.includes(columnName) && (
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          setContactFields((previousContacts) =>
                            previousContacts.filter((f) => f !== columnName)
                          );
                        }}
                      >
                        <UilTimesCircle
                          style={{
                            display: 'inline-block',
                            color: '#ea6266',
                          }}
                          id="custom-variable"
                        />
                        <Tooltip anchorSelect="#custom-variable">
                            Remove Variable
                        </Tooltip>
                      </span>
                    )}
                  </label>
                  <input
                    name={columnName}
                    value={value}
                    onChange={handleInputChange}
                    placeholder={columnName.replace(/_/g, ' ')}
                  />
                </Col>
              );
            })}
        </Row>
        <AddCustomField setContactFields={setContactFields} />
        <div data-tooltip-content={!newInfoChanged ? "Edit Contact to Update" : ""} data-tooltip-id="update-button">
          <CustomButton
            title="Update Lead Details"
            variant="primary"
            onClick={saveList}
            style={{ width: '100%', marginTop: 20 }}
            disabled={!newInfoChanged}
          />
        </div>
        <Tooltip
          place="top"
          id="update-button"
          style={{ zIndex: 1, maxWidth: 300 }}
        />
        <Tooltip
          place="right"
          id="email-tooltip"
          style={{ zIndex: 1, maxWidth: 300 }}
        />
      </div>
    );
}

function AddCustomField({ setContactFields }) {
  const [fieldName, setFieldName] = useState('');

  const addOption = () => {
    setContactFields((oldFieldNames) => {
      if (oldFieldNames.includes(fieldName)) return oldFieldNames;
      return [...oldFieldNames, fieldName.replaceAll(' ', '_')];
    });
    setFieldName('');
  };

  return (
    <div>
      <div className="flex" style={{ width: '100%' }}>
        <div style={{ width: '100%' }}>
          <TextInput
            value={fieldName}
            label="Add another variable"
            placeholder="Field Name"
            onChange={(e, value) => {
              setFieldName(value);
            }}
          />
        </div>
        <div style={{ marginLeft: 10, marginTop: 30, width: '40%' }}>
          <CustomButton
            title="Add Variable"
            variant="secondary"
            style={{ minWidth: 'max-content', padding: '10px', margin: 0 }}
            icon={<UilPlus />}
            onClick={addOption}
            disabled={!fieldName.length}
          />
        </div>
      </div>
    </div>
  );
}