import { createContext, useContext, useState } from 'react';

const PixieContext = createContext();

export function usePixieContext() {
  return useContext(PixieContext);
}

export function PixieProvider({ children }) {
  const [isVisible, setIsVisible] = useState(false);
  const [imageState, setImageState] = useState(null); // { id, data }
  const [done, setDone] = useState(null); // { id, data }

  const close = () => {
    setDone(null);
    setImageState(null);
    setIsVisible(false);
  };

  return (
    <PixieContext.Provider
      value={{
        isVisible,
        setIsVisible,
        imageState,
        setImageState,
        done,
        setDone,
        close,
      }}
    >
      {children}
    </PixieContext.Provider>
  );
}
