exports.spam_words = [
  {
    '#': 1,
    Keyword: 'Access',
    Category: 'Urgency',
  },
  {
    '#': 2,
    Keyword: 'Access now',
    Category: 'Urgency',
  },
  {
    '#': 3,
    Keyword: 'Act',
    Category: 'Urgency',
  },
  {
    '#': 4,
    Keyword: 'Act immediately',
    Category: 'Urgency',
  },
  {
    '#': 5,
    Keyword: 'Act now',
    Category: 'Urgency',
  },
  {
    '#': 6,
    Keyword: 'Act now!',
    Category: 'Urgency',
  },
  {
    '#': 7,
    Keyword: 'Action',
    Category: 'Urgency',
  },
  {
    '#': 8,
    Keyword: 'Action required',
    Category: 'Urgency',
  },
  {
    '#': 9,
    Keyword: 'Apply here',
    Category: 'Urgency',
  },
  {
    '#': 10,
    Keyword: 'Apply now',
    Category: 'Urgency',
  },
  {
    '#': 11,
    Keyword: 'Apply now!',
    Category: 'Urgency',
  },
  {
    '#': 12,
    Keyword: 'Apply online',
    Category: 'Urgency',
  },
  {
    '#': 13,
    Keyword: 'Become a member',
    Category: 'Urgency',
  },
  {
    '#': 14,
    Keyword: "Before it's too late",
    Category: 'Urgency',
  },
  {
    '#': 15,
    Keyword: 'Being a member',
    Category: 'Urgency',
  },
  {
    '#': 16,
    Keyword: 'Buy',
    Category: 'Urgency',
  },
  {
    '#': 17,
    Keyword: 'Buy direct',
    Category: 'Urgency',
  },
  {
    '#': 18,
    Keyword: 'Buy now',
    Category: 'Urgency',
  },
  {
    '#': 19,
    Keyword: 'Buy today',
    Category: 'Urgency',
  },
  {
    '#': 20,
    Keyword: 'Call',
    Category: 'Urgency',
  },
  {
    '#': 21,
    Keyword: 'Call free',
    Category: 'Urgency',
  },
  {
    '#': 22,
    Keyword: 'Call free/now',
    Category: 'Urgency',
  },
  {
    '#': 23,
    Keyword: 'Call me',
    Category: 'Urgency',
  },
  {
    '#': 24,
    Keyword: 'Call now',
    Category: 'Urgency',
  },
  {
    '#': 25,
    Keyword: 'Call now!',
    Category: 'Urgency',
  },
  {
    '#': 26,
    Keyword: 'Can we have a minute of your time?',
    Category: 'Urgency',
  },
  {
    '#': 27,
    Keyword: 'Cancel now',
    Category: 'Urgency',
  },
  {
    '#': 28,
    Keyword: 'Cancellation required',
    Category: 'Urgency',
  },
  {
    '#': 29,
    Keyword: 'Claim now',
    Category: 'Urgency',
  },
  {
    '#': 30,
    Keyword: 'Click',
    Category: 'Urgency',
  },
  {
    '#': 31,
    Keyword: 'Click below',
    Category: 'Urgency',
  },
  {
    '#': 32,
    Keyword: 'Click here',
    Category: 'Urgency',
  },
  {
    '#': 33,
    Keyword: 'Click me to download',
    Category: 'Urgency',
  },
  {
    '#': 34,
    Keyword: 'Click now',
    Category: 'Urgency',
  },
  {
    '#': 35,
    Keyword: 'Click this link',
    Category: 'Urgency',
  },
  {
    '#': 36,
    Keyword: 'Click to get',
    Category: 'Urgency',
  },
  {
    '#': 37,
    Keyword: 'Click to remove',
    Category: 'Urgency',
  },
  {
    '#': 38,
    Keyword: 'Contact us immediately',
    Category: 'Urgency',
  },
  {
    '#': 39,
    Keyword: 'Deal ending soon',
    Category: 'Urgency',
  },
  {
    '#': 40,
    Keyword: 'Do it now',
    Category: 'Urgency',
  },
  {
    '#': 41,
    Keyword: 'Do it today',
    Category: 'Urgency',
  },
  {
    '#': 42,
    Keyword: "Don't delete",
    Category: 'Urgency',
  },
  {
    '#': 43,
    Keyword: "Don't hesitate",
    Category: 'Urgency',
  },
  {
    '#': 44,
    Keyword: "Don't waste time",
    Category: 'Urgency',
  },
  {
    '#': 45,
    Keyword: 'Don’t delete',
    Category: 'Urgency',
  },
  {
    '#': 46,
    Keyword: 'Exclusive deal',
    Category: 'Urgency',
  },
  {
    '#': 47,
    Keyword: 'Expire',
    Category: 'Urgency',
  },
  {
    '#': 48,
    Keyword: 'Expires today',
    Category: 'Urgency',
  },
  {
    '#': 49,
    Keyword: 'Final call',
    Category: 'Urgency',
  },
  {
    '#': 50,
    Keyword: 'For instant access',
    Category: 'Urgency',
  },
  {
    '#': 51,
    Keyword: 'For Only',
    Category: 'Urgency',
  },
  {
    '#': 52,
    Keyword: 'For you',
    Category: 'Urgency',
  },
  {
    '#': 53,
    Keyword: 'Friday before [holiday]',
    Category: 'Urgency',
  },
  {
    '#': 54,
    Keyword: 'Get it away',
    Category: 'Urgency',
  },
  {
    '#': 55,
    Keyword: 'Get it now',
    Category: 'Urgency',
  },
  {
    '#': 56,
    Keyword: 'Get now',
    Category: 'Urgency',
  },
  {
    '#': 57,
    Keyword: 'Get paid',
    Category: 'Urgency',
  },
  {
    '#': 58,
    Keyword: 'Get started',
    Category: 'Urgency',
  },
  {
    '#': 59,
    Keyword: 'Get started now',
    Category: 'Urgency',
  },
  {
    '#': 60,
    Keyword: 'Great offer',
    Category: 'Urgency',
  },
  {
    '#': 61,
    Keyword: 'Hurry up',
    Category: 'Urgency',
  },
  {
    '#': 62,
    Keyword: 'Immediately',
    Category: 'Urgency',
  },
  {
    '#': 63,
    Keyword: 'Info you requested',
    Category: 'Urgency',
  },
  {
    '#': 64,
    Keyword: 'Information you requested',
    Category: 'Urgency',
  },
  {
    '#': 65,
    Keyword: 'Instant',
    Category: 'Urgency',
  },
  {
    '#': 66,
    Keyword: 'Limited time',
    Category: 'Urgency',
  },
  {
    '#': 67,
    Keyword: 'New customers only',
    Category: 'Urgency',
  },
  {
    '#': 68,
    Keyword: 'Now',
    Category: 'Urgency',
  },
  {
    '#': 69,
    Keyword: 'Now only',
    Category: 'Urgency',
  },
  {
    '#': 70,
    Keyword: 'Offer expires',
    Category: 'Urgency',
  },
  {
    '#': 71,
    Keyword: 'Once in lifetime',
    Category: 'Urgency',
  },
  {
    '#': 72,
    Keyword: 'Only',
    Category: 'Urgency',
  },
  {
    '#': 73,
    Keyword: 'Order now',
    Category: 'Urgency',
  },
  {
    '#': 74,
    Keyword: 'Order today',
    Category: 'Urgency',
  },
  {
    '#': 75,
    Keyword: 'Please read',
    Category: 'Urgency',
  },
  {
    '#': 76,
    Keyword: 'Purchase now',
    Category: 'Urgency',
  },
  {
    '#': 77,
    Keyword: 'Sign up free',
    Category: 'Urgency',
  },
  {
    '#': 78,
    Keyword: 'Sign up free today',
    Category: 'Urgency',
  },
  {
    '#': 79,
    Keyword: 'Supplies are limited',
    Category: 'Urgency',
  },
  {
    '#': 80,
    Keyword: 'Take action',
    Category: 'Urgency',
  },
  {
    '#': 81,
    Keyword: 'Take action now',
    Category: 'Urgency',
  },
  {
    '#': 82,
    Keyword: 'This won’t last',
    Category: 'Urgency',
  },
  {
    '#': 83,
    Keyword: 'Time limited',
    Category: 'Urgency',
  },
  {
    '#': 84,
    Keyword: 'Today',
    Category: 'Urgency',
  },
  {
    '#': 85,
    Keyword: 'Top urgent',
    Category: 'Urgency',
  },
  {
    '#': 86,
    Keyword: 'Trial',
    Category: 'Urgency',
  },
  {
    '#': 87,
    Keyword: 'Urgent',
    Category: 'Urgency',
  },
  {
    '#': 88,
    Keyword: 'What are you waiting for?',
    Category: 'Urgency',
  },
  {
    '#': 89,
    Keyword: 'While supplies last',
    Category: 'Urgency',
  },
  {
    '#': 90,
    Keyword: 'You are a winner',
    Category: 'Urgency',
  },
  {
    '#': 91,
    Keyword: '0 down',
    Category: 'Shady',
  },
  {
    '#': 92,
    Keyword: 'All',
    Category: 'Shady',
  },
  {
    '#': 93,
    Keyword: 'All natural',
    Category: 'Shady',
  },
  {
    '#': 94,
    Keyword: 'All natural/new',
    Category: 'Shady',
  },
  {
    '#': 95,
    Keyword: 'All new',
    Category: 'Shady',
  },
  {
    '#': 96,
    Keyword: 'All-natural',
    Category: 'Shady',
  },
  {
    '#': 97,
    Keyword: 'All-new',
    Category: 'Shady',
  },
  {
    '#': 98,
    Keyword: 'Allowance',
    Category: 'Shady',
  },
  {
    '#': 99,
    Keyword: 'As seen on',
    Category: 'Shady',
  },
  {
    '#': 100,
    Keyword: 'As seen on Oprah',
    Category: 'Shady',
  },
  {
    '#': 101,
    Keyword: 'At no cost',
    Category: 'Shady',
  },
  {
    '#': 102,
    Keyword: 'Auto email removal',
    Category: 'Shady',
  },
  {
    '#': 103,
    Keyword: 'Avoice bankruptcy',
    Category: 'Shady',
  },
  {
    '#': 104,
    Keyword: 'Avoid',
    Category: 'Shady',
  },
  {
    '#': 105,
    Keyword: 'Beneficial offer',
    Category: 'Shady',
  },
  {
    '#': 106,
    Keyword: 'Beneficiary',
    Category: 'Shady',
  },
  {
    '#': 107,
    Keyword: 'Bill 1618',
    Category: 'Shady',
  },
  {
    '#': 108,
    Keyword: 'Brand new pager',
    Category: 'Shady',
  },
  {
    '#': 109,
    Keyword: 'Bulk email',
    Category: 'Shady',
  },
  {
    '#': 110,
    Keyword: 'Buying judgements',
    Category: 'Shady',
  },
  {
    '#': 111,
    Keyword: 'Buying judgments',
    Category: 'Shady',
  },
  {
    '#': 112,
    Keyword: 'Cable converter',
    Category: 'Shady',
  },
  {
    '#': 113,
    Keyword: 'Calling creditors',
    Category: 'Shady',
  },
  {
    '#': 114,
    Keyword: 'Can you help us?',
    Category: 'Shady',
  },
  {
    '#': 115,
    Keyword: 'Cancel at any time',
    Category: 'Shady',
  },
  {
    '#': 116,
    Keyword: 'Cannot be combined',
    Category: 'Shady',
  },
  {
    '#': 117,
    Keyword: 'Celebrity',
    Category: 'Shady',
  },
  {
    '#': 118,
    Keyword: 'Cell phone cancer scam',
    Category: 'Shady',
  },
  {
    '#': 119,
    Keyword: 'Certified',
    Category: 'Shady',
  },
  {
    '#': 120,
    Keyword: 'Chance',
    Category: 'Shady',
  },
  {
    '#': 121,
    Keyword: 'Cheap',
    Category: 'Shady',
  },
  {
    '#': 122,
    Keyword: 'Cheap meds',
    Category: 'Shady',
  },
  {
    '#': 123,
    Keyword: 'Cialis',
    Category: 'Shady',
  },
  {
    '#': 124,
    Keyword: 'Claims',
    Category: 'Shady',
  },
  {
    '#': 125,
    Keyword: 'Claims not to be selling anything',
    Category: 'Shady',
  },
  {
    '#': 126,
    Keyword: 'Claims to be in accordance with some spam law',
    Category: 'Shady',
  },
  {
    '#': 127,
    Keyword: 'Claims to be legal',
    Category: 'Shady',
  },
  {
    '#': 128,
    Keyword: 'Clearance',
    Category: 'Shady',
  },
  {
    '#': 129,
    Keyword: 'Collect',
    Category: 'Shady',
  },
  {
    '#': 130,
    Keyword: 'Collect child support',
    Category: 'Shady',
  },
  {
    '#': 131,
    Keyword: 'Compare',
    Category: 'Shady',
  },
  {
    '#': 132,
    Keyword: 'Compare now',
    Category: 'Shady',
  },
  {
    '#': 133,
    Keyword: 'Compare online',
    Category: 'Shady',
  },
  {
    '#': 134,
    Keyword: 'Compare rates',
    Category: 'Shady',
  },
  {
    '#': 135,
    Keyword: 'Compete for your business',
    Category: 'Shady',
  },
  {
    '#': 136,
    Keyword: 'Confidentiality',
    Category: 'Shady',
  },
  {
    '#': 137,
    Keyword: 'Congratulations',
    Category: 'Shady',
  },
  {
    '#': 138,
    Keyword: 'Consolidate debt and credit',
    Category: 'Shady',
  },
  {
    '#': 139,
    Keyword: 'Consolidate your debt',
    Category: 'Shady',
  },
  {
    '#': 140,
    Keyword: 'Copy accurately',
    Category: 'Shady',
  },
  {
    '#': 141,
    Keyword: 'Copy DVDs',
    Category: 'Shady',
  },
  {
    '#': 142,
    Keyword: 'COVID',
    Category: 'Shady',
  },
  {
    '#': 143,
    Keyword: 'Cures',
    Category: 'Shady',
  },
  {
    '#': 144,
    Keyword: 'Cures baldness',
    Category: 'Shady',
  },
  {
    '#': 145,
    Keyword: 'Diagnostic',
    Category: 'Shady',
  },
  {
    '#': 146,
    Keyword: 'DIAGNOSTICS',
    Category: 'Shady',
  },
  {
    '#': 147,
    Keyword: 'Diet',
    Category: 'Shady',
  },
  {
    '#': 148,
    Keyword: 'Dig up dirt on friends',
    Category: 'Shady',
  },
  {
    '#': 149,
    Keyword: 'Direct email',
    Category: 'Shady',
  },
  {
    '#': 150,
    Keyword: 'Direct marketing',
    Category: 'Shady',
  },
  {
    '#': 151,
    Keyword: 'Eliminate debt',
    Category: 'Shady',
  },
  {
    '#': 152,
    Keyword: 'Explode your business',
    Category: 'Shady',
  },
  {
    '#': 153,
    Keyword: 'Fast viagra delivery',
    Category: 'Shady',
  },
  {
    '#': 154,
    Keyword: 'Finance',
    Category: 'Shady',
  },
  {
    '#': 155,
    Keyword: 'Financial',
    Category: 'Shady',
  },
  {
    '#': 156,
    Keyword: 'Financial advice',
    Category: 'Shady',
  },
  {
    '#': 157,
    Keyword: 'Financial independence',
    Category: 'Shady',
  },
  {
    '#': 158,
    Keyword: 'Financially independent',
    Category: 'Shady',
  },
  {
    '#': 159,
    Keyword: 'For new customers only',
    Category: 'Shady',
  },
  {
    '#': 160,
    Keyword: 'Foreclosure',
    Category: 'Shady',
  },
  {
    '#': 161,
    Keyword: 'Free',
    Category: 'Shady',
  },
  {
    '#': 162,
    Keyword: 'Free access/money/gift',
    Category: 'Shady',
  },
  {
    '#': 163,
    Keyword: 'Free bonus',
    Category: 'Shady',
  },
  {
    '#': 164,
    Keyword: 'Free cell phone',
    Category: 'Shady',
  },
  {
    '#': 165,
    Keyword: 'Free DVD',
    Category: 'Shady',
  },
  {
    '#': 166,
    Keyword: 'Free grant money',
    Category: 'Shady',
  },
  {
    '#': 167,
    Keyword: 'Free information',
    Category: 'Shady',
  },
  {
    '#': 168,
    Keyword: 'Free installation',
    Category: 'Shady',
  },
  {
    '#': 169,
    Keyword: 'Free Instant',
    Category: 'Shady',
  },
  {
    '#': 170,
    Keyword: 'Free iPhone',
    Category: 'Shady',
  },
  {
    '#': 171,
    Keyword: 'Free laptop',
    Category: 'Shady',
  },
  {
    '#': 172,
    Keyword: 'Free leads',
    Category: 'Shady',
  },
  {
    '#': 173,
    Keyword: 'Free Macbook',
    Category: 'Shady',
  },
  {
    '#': 174,
    Keyword: 'Free offer',
    Category: 'Shady',
  },
  {
    '#': 175,
    Keyword: 'Free priority mail',
    Category: 'Shady',
  },
  {
    '#': 176,
    Keyword: 'Free sample',
    Category: 'Shady',
  },
  {
    '#': 177,
    Keyword: 'Free website',
    Category: 'Shady',
  },
  {
    '#': 178,
    Keyword: 'Free!',
    Category: 'Shady',
  },
  {
    '#': 179,
    Keyword: 'Get',
    Category: 'Shady',
  },
  {
    '#': 180,
    Keyword: 'Gift card',
    Category: 'Shady',
  },
  {
    '#': 181,
    Keyword: 'Gift certificate',
    Category: 'Shady',
  },
  {
    '#': 182,
    Keyword: 'Gift included',
    Category: 'Shady',
  },
  {
    '#': 183,
    Keyword: 'Give it away',
    Category: 'Shady',
  },
  {
    '#': 184,
    Keyword: 'Giving away',
    Category: 'Shady',
  },
  {
    '#': 185,
    Keyword: 'Giving it away',
    Category: 'Shady',
  },
  {
    '#': 186,
    Keyword: 'Gold',
    Category: 'Shady',
  },
  {
    '#': 187,
    Keyword: 'Great',
    Category: 'Shady',
  },
  {
    '#': 188,
    Keyword: 'Great deal',
    Category: 'Shady',
  },
  {
    '#': 189,
    Keyword: 'Greetings of the day',
    Category: 'Shady',
  },
  {
    '#': 190,
    Keyword: 'Growth hormone',
    Category: 'Shady',
  },
  {
    '#': 191,
    Keyword: 'Guarantee',
    Category: 'Shady',
  },
  {
    '#': 192,
    Keyword: 'Guaranteed deposit',
    Category: 'Shady',
  },
  {
    '#': 193,
    Keyword: 'Guaranteed income',
    Category: 'Shady',
  },
  {
    '#': 194,
    Keyword: 'Guaranteed payment',
    Category: 'Shady',
  },
  {
    '#': 195,
    Keyword: 'Have you been turned down?',
    Category: 'Shady',
  },
  {
    '#': 196,
    Keyword: 'Hello (with no name included)',
    Category: 'Shady',
  },
  {
    '#': 197,
    Keyword: 'Hidden charges',
    Category: 'Shady',
  },
  {
    '#': 198,
    Keyword: 'Hidden costs',
    Category: 'Shady',
  },
  {
    '#': 199,
    Keyword: 'Hidden fees',
    Category: 'Shady',
  },
  {
    '#': 200,
    Keyword: 'High score',
    Category: 'Shady',
  },
  {
    '#': 201,
    Keyword: 'Home based business',
    Category: 'Shady',
  },
  {
    '#': 202,
    Keyword: 'Home mortgage',
    Category: 'Shady',
  },
  {
    '#': 203,
    Keyword: 'Human',
    Category: 'Shady',
  },
  {
    '#': 204,
    Keyword: 'Human growth hormone',
    Category: 'Shady',
  },
  {
    '#': 205,
    Keyword: 'If only it were that easy',
    Category: 'Shady',
  },
  {
    '#': 206,
    Keyword: 'Important information',
    Category: 'Shady',
  },
  {
    '#': 207,
    Keyword: 'Important notification',
    Category: 'Shady',
  },
  {
    '#': 208,
    Keyword: 'Instant weight loss',
    Category: 'Shady',
  },
  {
    '#': 209,
    Keyword: 'Insurance Lose weight',
    Category: 'Shady',
  },
  {
    '#': 210,
    Keyword: 'Internet marketing',
    Category: 'Shady',
  },
  {
    '#': 211,
    Keyword: 'Investment decision',
    Category: 'Shady',
  },
  {
    '#': 212,
    Keyword: 'Invoice',
    Category: 'Shady',
  },
  {
    '#': 213,
    Keyword: 'It’s effective',
    Category: 'Shady',
  },
  {
    '#': 214,
    Keyword: 'Job alert',
    Category: 'Shady',
  },
  {
    '#': 215,
    Keyword: 'Junk',
    Category: 'Shady',
  },
  {
    '#': 216,
    Keyword: 'Lambo',
    Category: 'Shady',
  },
  {
    '#': 217,
    Keyword: 'Laser printer',
    Category: 'Shady',
  },
  {
    '#': 218,
    Keyword: 'Last Day',
    Category: 'Shady',
  },
  {
    '#': 219,
    Keyword: 'Legal',
    Category: 'Shady',
  },
  {
    '#': 220,
    Keyword: 'Legal notice',
    Category: 'Shady',
  },
  {
    '#': 221,
    Keyword: 'Life',
    Category: 'Shady',
  },
  {
    '#': 222,
    Keyword: 'Life insurance',
    Category: 'Shady',
  },
  {
    '#': 223,
    Keyword: 'Lifetime access',
    Category: 'Shady',
  },
  {
    '#': 224,
    Keyword: 'Lifetime deal',
    Category: 'Shady',
  },
  {
    '#': 225,
    Keyword: 'Limited',
    Category: 'Shady',
  },
  {
    '#': 226,
    Keyword: 'Limited amount',
    Category: 'Shady',
  },
  {
    '#': 227,
    Keyword: 'Limited number',
    Category: 'Shady',
  },
  {
    '#': 228,
    Keyword: 'Limited offer',
    Category: 'Shady',
  },
  {
    '#': 229,
    Keyword: 'Limited supply',
    Category: 'Shady',
  },
  {
    '#': 230,
    Keyword: 'Limited time offer',
    Category: 'Shady',
  },
  {
    '#': 231,
    Keyword: 'Limited time only',
    Category: 'Shady',
  },
  {
    '#': 232,
    Keyword: 'Loan',
    Category: 'Shady',
  },
  {
    '#': 233,
    Keyword: 'Long distance phone number',
    Category: 'Shady',
  },
  {
    '#': 234,
    Keyword: 'Long distance phone offer',
    Category: 'Shady',
  },
  {
    '#': 235,
    Keyword: 'Lose weight',
    Category: 'Shady',
  },
  {
    '#': 236,
    Keyword: 'Lose weight fast',
    Category: 'Shady',
  },
  {
    '#': 237,
    Keyword: 'Lose weight spam',
    Category: 'Shady',
  },
  {
    '#': 238,
    Keyword: 'Lottery',
    Category: 'Shady',
  },
  {
    '#': 239,
    Keyword: 'Lower interest rate',
    Category: 'Shady',
  },
  {
    '#': 240,
    Keyword: 'Lower interest rates',
    Category: 'Shady',
  },
  {
    '#': 241,
    Keyword: 'Lower monthly payment',
    Category: 'Shady',
  },
  {
    '#': 242,
    Keyword: 'Lower your mortgage rate',
    Category: 'Shady',
  },
  {
    '#': 243,
    Keyword: 'Lowest insurance rates',
    Category: 'Shady',
  },
  {
    '#': 244,
    Keyword: 'Lowest interest rate',
    Category: 'Shady',
  },
  {
    '#': 245,
    Keyword: 'Lowest rate',
    Category: 'Shady',
  },
  {
    '#': 246,
    Keyword: 'Lowest rates',
    Category: 'Shady',
  },
  {
    '#': 247,
    Keyword: 'Luxury',
    Category: 'Shady',
  },
  {
    '#': 248,
    Keyword: 'Luxury car',
    Category: 'Shady',
  },
  {
    '#': 249,
    Keyword: 'Mail in order form',
    Category: 'Shady',
  },
  {
    '#': 250,
    Keyword: 'Main in order form',
    Category: 'Shady',
  },
  {
    '#': 251,
    Keyword: 'Mark this as not junk',
    Category: 'Shady',
  },
  {
    '#': 252,
    Keyword: 'Mass email',
    Category: 'Shady',
  },
  {
    '#': 253,
    Keyword: 'Medical',
    Category: 'Shady',
  },
  {
    '#': 254,
    Keyword: 'Medicine',
    Category: 'Shady',
  },
  {
    '#': 255,
    Keyword: 'Meet girls',
    Category: 'Shady',
  },
  {
    '#': 256,
    Keyword: 'Meet me',
    Category: 'Shady',
  },
  {
    '#': 257,
    Keyword: 'Meet singles',
    Category: 'Shady',
  },
  {
    '#': 258,
    Keyword: 'Meet women',
    Category: 'Shady',
  },
  {
    '#': 259,
    Keyword: 'Member',
    Category: 'Shady',
  },
  {
    '#': 260,
    Keyword: 'Member stuff',
    Category: 'Shady',
  },
  {
    '#': 261,
    Keyword: 'Message contains disclaimer',
    Category: 'Shady',
  },
  {
    '#': 262,
    Keyword: 'Message from',
    Category: 'Shady',
  },
  {
    '#': 263,
    Keyword: 'Millionaire',
    Category: 'Shady',
  },
  {
    '#': 264,
    Keyword: 'Millions',
    Category: 'Shady',
  },
  {
    '#': 265,
    Keyword: 'MLM',
    Category: 'Shady',
  },
  {
    '#': 266,
    Keyword: 'Multi-level marketing',
    Category: 'Shady',
  },
  {
    '#': 267,
    Keyword: 'Name',
    Category: 'Shady',
  },
  {
    '#': 268,
    Keyword: 'Near you',
    Category: 'Shady',
  },
  {
    '#': 269,
    Keyword: 'Never before',
    Category: 'Shady',
  },
  {
    '#': 270,
    Keyword: 'New',
    Category: 'Shady',
  },
  {
    '#': 271,
    Keyword: 'New domain extensions',
    Category: 'Shady',
  },
  {
    '#': 272,
    Keyword: 'Nigerian',
    Category: 'Shady',
  },
  {
    '#': 273,
    Keyword: 'No age restrictions',
    Category: 'Shady',
  },
  {
    '#': 274,
    Keyword: 'No catch',
    Category: 'Shady',
  },
  {
    '#': 275,
    Keyword: 'No claim forms',
    Category: 'Shady',
  },
  {
    '#': 276,
    Keyword: 'No cost',
    Category: 'Shady',
  },
  {
    '#': 277,
    Keyword: 'No credit check',
    Category: 'Shady',
  },
  {
    '#': 278,
    Keyword: 'No credit experience',
    Category: 'Shady',
  },
  {
    '#': 279,
    Keyword: 'No deposit required',
    Category: 'Shady',
  },
  {
    '#': 280,
    Keyword: 'No disappointment',
    Category: 'Shady',
  },
  {
    '#': 281,
    Keyword: 'No experience',
    Category: 'Shady',
  },
  {
    '#': 282,
    Keyword: 'No fees',
    Category: 'Shady',
  },
  {
    '#': 283,
    Keyword: 'No gimmick',
    Category: 'Shady',
  },
  {
    '#': 284,
    Keyword: 'No hidden',
    Category: 'Shady',
  },
  {
    '#': 285,
    Keyword: 'No hidden costs',
    Category: 'Shady',
  },
  {
    '#': 286,
    Keyword: 'No hidden fees',
    Category: 'Shady',
  },
  {
    '#': 287,
    Keyword: 'No hidden сosts',
    Category: 'Shady',
  },
  {
    '#': 288,
    Keyword: 'No interest',
    Category: 'Shady',
  },
  {
    '#': 289,
    Keyword: 'No interests',
    Category: 'Shady',
  },
  {
    '#': 290,
    Keyword: 'No inventory',
    Category: 'Shady',
  },
  {
    '#': 291,
    Keyword: 'No investment',
    Category: 'Shady',
  },
  {
    '#': 292,
    Keyword: 'No investment required',
    Category: 'Shady',
  },
  {
    '#': 293,
    Keyword: 'No medical exams',
    Category: 'Shady',
  },
  {
    '#': 294,
    Keyword: 'No middleman',
    Category: 'Shady',
  },
  {
    '#': 295,
    Keyword: 'No obligation',
    Category: 'Shady',
  },
  {
    '#': 296,
    Keyword: 'No payment required',
    Category: 'Shady',
  },
  {
    '#': 297,
    Keyword: 'No purchase necessary',
    Category: 'Shady',
  },
  {
    '#': 298,
    Keyword: 'No questions asked',
    Category: 'Shady',
  },
  {
    '#': 299,
    Keyword: 'No selling',
    Category: 'Shady',
  },
  {
    '#': 300,
    Keyword: 'No strings attached',
    Category: 'Shady',
  },
  {
    '#': 301,
    Keyword: 'No-obligation',
    Category: 'Shady',
  },
  {
    '#': 302,
    Keyword: 'Nominated bank account',
    Category: 'Shady',
  },
  {
    '#': 303,
    Keyword: 'Not intended',
    Category: 'Shady',
  },
  {
    '#': 304,
    Keyword: 'Not junk',
    Category: 'Shady',
  },
  {
    '#': 305,
    Keyword: 'Not scam',
    Category: 'Shady',
  },
  {
    '#': 306,
    Keyword: 'Not spam',
    Category: 'Shady',
  },
  {
    '#': 307,
    Keyword: 'Notspam',
    Category: 'Shady',
  },
  {
    '#': 308,
    Keyword: 'Number 1',
    Category: 'Shady',
  },
  {
    '#': 309,
    Keyword: 'Obligation',
    Category: 'Shady',
  },
  {
    '#': 310,
    Keyword: 'Off',
    Category: 'Shady',
  },
  {
    '#': 311,
    Keyword: 'Off everything',
    Category: 'Shady',
  },
  {
    '#': 312,
    Keyword: 'Off shore',
    Category: 'Shady',
  },
  {
    '#': 313,
    Keyword: 'Offer extended',
    Category: 'Shady',
  },
  {
    '#': 314,
    Keyword: 'Offers',
    Category: 'Shady',
  },
  {
    '#': 315,
    Keyword: 'Offshore',
    Category: 'Shady',
  },
  {
    '#': 316,
    Keyword: 'One hundred percent',
    Category: 'Shady',
  },
  {
    '#': 317,
    Keyword: 'One-time',
    Category: 'Shady',
  },
  {
    '#': 318,
    Keyword: 'Online biz opportunity',
    Category: 'Shady',
  },
  {
    '#': 319,
    Keyword: 'Online degree',
    Category: 'Shady',
  },
  {
    '#': 320,
    Keyword: 'Online income',
    Category: 'Shady',
  },
  {
    '#': 321,
    Keyword: 'Online job',
    Category: 'Shady',
  },
  {
    '#': 322,
    Keyword: 'Open',
    Category: 'Shady',
  },
  {
    '#': 323,
    Keyword: 'Opportunity',
    Category: 'Shady',
  },
  {
    '#': 324,
    Keyword: 'Opt-in',
    Category: 'Shady',
  },
  {
    '#': 325,
    Keyword: 'Order',
    Category: 'Shady',
  },
  {
    '#': 326,
    Keyword: 'Order shipped by',
    Category: 'Shady',
  },
  {
    '#': 327,
    Keyword: 'Order status',
    Category: 'Shady',
  },
  {
    '#': 328,
    Keyword: 'Orders shipped by',
    Category: 'Shady',
  },
  {
    '#': 329,
    Keyword: 'Orders shipped by shopper',
    Category: 'Shady',
  },
  {
    '#': 330,
    Keyword: 'Outstanding value',
    Category: 'Shady',
  },
  {
    '#': 331,
    Keyword: 'Outstanding values',
    Category: 'Shady',
  },
  {
    '#': 332,
    Keyword: 'Password',
    Category: 'Shady',
  },
  {
    '#': 333,
    Keyword: 'Passwords',
    Category: 'Shady',
  },
  {
    '#': 334,
    Keyword: 'Pay your bills',
    Category: 'Shady',
  },
  {
    '#': 335,
    Keyword: 'Per day/per week/per year',
    Category: 'Shady',
  },
  {
    '#': 336,
    Keyword: 'Per month',
    Category: 'Shady',
  },
  {
    '#': 337,
    Keyword: 'Perfect',
    Category: 'Shady',
  },
  {
    '#': 338,
    Keyword: 'Performance',
    Category: 'Shady',
  },
  {
    '#': 339,
    Keyword: 'Phone',
    Category: 'Shady',
  },
  {
    '#': 340,
    Keyword: 'Please',
    Category: 'Shady',
  },
  {
    '#': 341,
    Keyword: 'Please open',
    Category: 'Shady',
  },
  {
    '#': 342,
    Keyword: 'Presently',
    Category: 'Shady',
  },
  {
    '#': 343,
    Keyword: 'Print form signature',
    Category: 'Shady',
  },
  {
    '#': 344,
    Keyword: 'Print from signature',
    Category: 'Shady',
  },
  {
    '#': 345,
    Keyword: 'Print out and fax',
    Category: 'Shady',
  },
  {
    '#': 346,
    Keyword: 'Priority mail',
    Category: 'Shady',
  },
  {
    '#': 347,
    Keyword: 'Privately owned funds',
    Category: 'Shady',
  },
  {
    '#': 348,
    Keyword: 'Prizes',
    Category: 'Shady',
  },
  {
    '#': 349,
    Keyword: 'Problem with shipping',
    Category: 'Shady',
  },
  {
    '#': 350,
    Keyword: 'Problem with your order',
    Category: 'Shady',
  },
  {
    '#': 351,
    Keyword: 'Produced and sent out',
    Category: 'Shady',
  },
  {
    '#': 352,
    Keyword: 'Profit',
    Category: 'Shady',
  },
  {
    '#': 353,
    Keyword: 'Promise you',
    Category: 'Shady',
  },
  {
    '#': 354,
    Keyword: 'Purchase',
    Category: 'Shady',
  },
  {
    '#': 355,
    Keyword: 'Pure Profits',
    Category: 'Shady',
  },
  {
    '#': 356,
    Keyword: 'Quotes',
    Category: 'Shady',
  },
  {
    '#': 357,
    Keyword: 'Rate',
    Category: 'Shady',
  },
  {
    '#': 358,
    Keyword: 'Real thing',
    Category: 'Shady',
  },
  {
    '#': 359,
    Keyword: 'Rebate',
    Category: 'Shady',
  },
  {
    '#': 360,
    Keyword: 'Reduce debt',
    Category: 'Shady',
  },
  {
    '#': 361,
    Keyword: 'Refinance home',
    Category: 'Shady',
  },
  {
    '#': 362,
    Keyword: 'Refinanced home',
    Category: 'Shady',
  },
  {
    '#': 363,
    Keyword: 'Refund',
    Category: 'Shady',
  },
  {
    '#': 364,
    Keyword: 'Regarding',
    Category: 'Shady',
  },
  {
    '#': 365,
    Keyword: 'Removal instructions',
    Category: 'Shady',
  },
  {
    '#': 366,
    Keyword: 'Removes',
    Category: 'Shady',
  },
  {
    '#': 367,
    Keyword: 'Removes wrinkles',
    Category: 'Shady',
  },
  {
    '#': 368,
    Keyword: 'Replica watches',
    Category: 'Shady',
  },
  {
    '#': 369,
    Keyword: 'Request',
    Category: 'Shady',
  },
  {
    '#': 370,
    Keyword: 'Request now',
    Category: 'Shady',
  },
  {
    '#': 371,
    Keyword: 'Request today',
    Category: 'Shady',
  },
  {
    '#': 372,
    Keyword: 'Requires initial investment',
    Category: 'Shady',
  },
  {
    '#': 373,
    Keyword: 'Requires investment',
    Category: 'Shady',
  },
  {
    '#': 374,
    Keyword: 'Reverses aging',
    Category: 'Shady',
  },
  {
    '#': 375,
    Keyword: 'Risk free',
    Category: 'Shady',
  },
  {
    '#': 376,
    Keyword: 'Rolex',
    Category: 'Shady',
  },
  {
    '#': 377,
    Keyword: 'Round the world',
    Category: 'Shady',
  },
  {
    '#': 378,
    Keyword: 'S 1618',
    Category: 'Shady',
  },
  {
    '#': 379,
    Keyword: 'Safeguard notice',
    Category: 'Shady',
  },
  {
    '#': 380,
    Keyword: 'Sale',
    Category: 'Shady',
  },
  {
    '#': 381,
    Keyword: 'Sales',
    Category: 'Shady',
  },
  {
    '#': 382,
    Keyword: 'Save',
    Category: 'Shady',
  },
  {
    '#': 383,
    Keyword: 'Save $, Save €',
    Category: 'Shady',
  },
  {
    '#': 384,
    Keyword: 'Save big',
    Category: 'Shady',
  },
  {
    '#': 385,
    Keyword: 'Save big month',
    Category: 'Shady',
  },
  {
    '#': 386,
    Keyword: 'Save money',
    Category: 'Shady',
  },
  {
    '#': 387,
    Keyword: 'Save now',
    Category: 'Shady',
  },
  {
    '#': 388,
    Keyword: 'Score with babes',
    Category: 'Shady',
  },
  {
    '#': 389,
    Keyword: 'Search engine optimisation',
    Category: 'Shady',
  },
  {
    '#': 390,
    Keyword: 'Section 301',
    Category: 'Shady',
  },
  {
    '#': 391,
    Keyword: 'See for yourself',
    Category: 'Shady',
  },
  {
    '#': 392,
    Keyword: 'Seen on',
    Category: 'Shady',
  },
  {
    '#': 393,
    Keyword: 'Serious',
    Category: 'Shady',
  },
  {
    '#': 394,
    Keyword: 'Serious case',
    Category: 'Shady',
  },
  {
    '#': 395,
    Keyword: 'Serious offer',
    Category: 'Shady',
  },
  {
    '#': 396,
    Keyword: 'Serious only',
    Category: 'Shady',
  },
  {
    '#': 397,
    Keyword: 'Sex',
    Category: 'Shady',
  },
  {
    '#': 398,
    Keyword: 'Shop now',
    Category: 'Shady',
  },
  {
    '#': 399,
    Keyword: 'Shopper',
    Category: 'Shady',
  },
  {
    '#': 400,
    Keyword: 'Shopping spree',
    Category: 'Shady',
  },
  {
    '#': 401,
    Keyword: 'Snoring',
    Category: 'Shady',
  },
  {
    '#': 402,
    Keyword: 'Social security number',
    Category: 'Shady',
  },
  {
    '#': 403,
    Keyword: 'Soon',
    Category: 'Shady',
  },
  {
    '#': 404,
    Keyword: 'Spam',
    Category: 'Shady',
  },
  {
    '#': 405,
    Keyword: 'Spam free',
    Category: 'Shady',
  },
  {
    '#': 406,
    Keyword: 'Special deal',
    Category: 'Shady',
  },
  {
    '#': 407,
    Keyword: 'Special discount',
    Category: 'Shady',
  },
  {
    '#': 408,
    Keyword: 'Special for you',
    Category: 'Shady',
  },
  {
    '#': 409,
    Keyword: 'Special offer',
    Category: 'Shady',
  },
  {
    '#': 410,
    Keyword: 'Stainless steel',
    Category: 'Shady',
  },
  {
    '#': 411,
    Keyword: 'Stock alert',
    Category: 'Shady',
  },
  {
    '#': 412,
    Keyword: 'Stock disclaimer statement',
    Category: 'Shady',
  },
  {
    '#': 413,
    Keyword: 'Stock pick',
    Category: 'Shady',
  },
  {
    '#': 414,
    Keyword: 'Stocks/stock pick/stock alert',
    Category: 'Shady',
  },
  {
    '#': 415,
    Keyword: 'Stop calling me',
    Category: 'Shady',
  },
  {
    '#': 416,
    Keyword: 'Stop emailing me',
    Category: 'Shady',
  },
  {
    '#': 417,
    Keyword: 'Stop further distribution',
    Category: 'Shady',
  },
  {
    '#': 418,
    Keyword: 'Stop snoring',
    Category: 'Shady',
  },
  {
    '#': 419,
    Keyword: 'Strong buy',
    Category: 'Shady',
  },
  {
    '#': 420,
    Keyword: 'Stuff on sale',
    Category: 'Shady',
  },
  {
    '#': 421,
    Keyword: 'Subject to',
    Category: 'Shady',
  },
  {
    '#': 422,
    Keyword: 'Subject to cash',
    Category: 'Shady',
  },
  {
    '#': 423,
    Keyword: 'Subscribe',
    Category: 'Shady',
  },
  {
    '#': 424,
    Keyword: 'Subscribe for free',
    Category: 'Shady',
  },
  {
    '#': 425,
    Keyword: 'Subscribe now',
    Category: 'Shady',
  },
  {
    '#': 426,
    Keyword: 'Super promo',
    Category: 'Shady',
  },
  {
    '#': 427,
    Keyword: 'Supplies',
    Category: 'Shady',
  },
  {
    '#': 428,
    Keyword: 'Tack action now',
    Category: 'Shady',
  },
  {
    '#': 429,
    Keyword: 'Talks about hidden charges',
    Category: 'Shady',
  },
  {
    '#': 430,
    Keyword: 'Talks about prizes',
    Category: 'Shady',
  },
  {
    '#': 431,
    Keyword: 'Tells you it’s an ad',
    Category: 'Shady',
  },
  {
    '#': 432,
    Keyword: 'Terms',
    Category: 'Shady',
  },
  {
    '#': 433,
    Keyword: 'The best rates',
    Category: 'Shady',
  },
  {
    '#': 434,
    Keyword: 'The email asks for a credit card',
    Category: 'Shady',
  },
  {
    '#': 435,
    Keyword: 'The following form',
    Category: 'Shady',
  },
  {
    '#': 436,
    Keyword:
      "They make a claim or claims that they're in accordance with spam law",
    Category: 'Shady',
  },
  {
    '#': 437,
    Keyword: 'They try to keep your money no refund',
    Category: 'Shady',
  },
  {
    '#': 438,
    Keyword: 'They’re just giving it away',
    Category: 'Shady',
  },
  {
    '#': 439,
    Keyword: "This isn't junk",
    Category: 'Shady',
  },
  {
    '#': 440,
    Keyword: "This isn't spam",
    Category: 'Shady',
  },
  {
    '#': 441,
    Keyword: 'This isn’t a scam',
    Category: 'Shady',
  },
  {
    '#': 442,
    Keyword: 'This isn’t junk',
    Category: 'Shady',
  },
  {
    '#': 443,
    Keyword: 'This isn’t spam',
    Category: 'Shady',
  },
  {
    '#': 444,
    Keyword: 'Timeshare',
    Category: 'Shady',
  },
  {
    '#': 445,
    Keyword: 'Timeshare offers',
    Category: 'Shady',
  },
  {
    '#': 446,
    Keyword: 'Traffic',
    Category: 'Shady',
  },
  {
    '#': 447,
    Keyword: 'Trial unlimited',
    Category: 'Shady',
  },
  {
    '#': 448,
    Keyword: 'U.S. dollars',
    Category: 'Shady',
  },
  {
    '#': 449,
    Keyword: 'Undisclosed',
    Category: 'Shady',
  },
  {
    '#': 450,
    Keyword: 'Undisclosed recipient',
    Category: 'Shady',
  },
  {
    '#': 451,
    Keyword: 'University diplomas',
    Category: 'Shady',
  },
  {
    '#': 452,
    Keyword: 'Unsecured credit',
    Category: 'Shady',
  },
  {
    '#': 453,
    Keyword: 'Unsecured debt',
    Category: 'Shady',
  },
  {
    '#': 454,
    Keyword: 'Unsolicited',
    Category: 'Shady',
  },
  {
    '#': 455,
    Keyword: 'Unsubscribe',
    Category: 'Shady',
  },
  {
    '#': 456,
    Keyword: 'Urgent response',
    Category: 'Shady',
  },
  {
    '#': 457,
    Keyword: 'US dollars / Euros',
    Category: 'Shady',
  },
  {
    '#': 458,
    Keyword: 'Vacation',
    Category: 'Shady',
  },
  {
    '#': 459,
    Keyword: 'Vacation offers',
    Category: 'Shady',
  },
  {
    '#': 460,
    Keyword: 'Valium',
    Category: 'Shady',
  },
  {
    '#': 461,
    Keyword: 'Viagra',
    Category: 'Shady',
  },
  {
    '#': 462,
    Keyword: 'Vicodin',
    Category: 'Shady',
  },
  {
    '#': 463,
    Keyword: 'VIP',
    Category: 'Shady',
  },
  {
    '#': 464,
    Keyword: 'Visit our website',
    Category: 'Shady',
  },
  {
    '#': 465,
    Keyword: 'Wants credit card',
    Category: 'Shady',
  },
  {
    '#': 466,
    Keyword: 'Warranty expired',
    Category: 'Shady',
  },
  {
    '#': 467,
    Keyword: 'We hate spam',
    Category: 'Shady',
  },
  {
    '#': 468,
    Keyword: 'We honor all',
    Category: 'Shady',
  },
  {
    '#': 469,
    Keyword: 'Website visitors',
    Category: 'Shady',
  },
  {
    '#': 470,
    Keyword: 'Weekend getaway',
    Category: 'Shady',
  },
  {
    '#': 471,
    Keyword: 'Weight loss',
    Category: 'Shady',
  },
  {
    '#': 472,
    Keyword: 'What’s keeping you?',
    Category: 'Shady',
  },
  {
    '#': 473,
    Keyword: 'While available',
    Category: 'Shady',
  },
  {
    '#': 474,
    Keyword: 'While in stock',
    Category: 'Shady',
  },
  {
    '#': 475,
    Keyword: 'While stocks last',
    Category: 'Shady',
  },
  {
    '#': 476,
    Keyword: 'While you sleep',
    Category: 'Shady',
  },
  {
    '#': 477,
    Keyword: 'Who really wins?',
    Category: 'Shady',
  },
  {
    '#': 478,
    Keyword: 'Win',
    Category: 'Shady',
  },
  {
    '#': 479,
    Keyword: 'Winner',
    Category: 'Shady',
  },
  {
    '#': 480,
    Keyword: 'Winning',
    Category: 'Shady',
  },
  {
    '#': 481,
    Keyword: 'Won',
    Category: 'Shady',
  },
  {
    '#': 482,
    Keyword: 'Xanax',
    Category: 'Shady',
  },
  {
    '#': 483,
    Keyword: 'XXX',
    Category: 'Shady',
  },
  {
    '#': 484,
    Keyword: 'You have been chosen',
    Category: 'Shady',
  },
  {
    '#': 485,
    Keyword: 'You have been selected',
    Category: 'Shady',
  },
  {
    '#': 486,
    Keyword: 'Your chance',
    Category: 'Shady',
  },
  {
    '#': 487,
    Keyword: 'Your status',
    Category: 'Shady',
  },
  {
    '#': 488,
    Keyword: 'Zero chance',
    Category: 'Shady',
  },
  {
    '#': 489,
    Keyword: 'Zero percent',
    Category: 'Shady',
  },
  {
    '#': 490,
    Keyword: 'Zero risk',
    Category: 'Shady',
  },
  {
    '#': 491,
    Keyword: '#1',
    Category: 'Over Promise',
  },
  {
    '#': 492,
    Keyword: '%',
    Category: 'Over Promise',
  },
  {
    '#': 493,
    Keyword: '% free',
    Category: 'Over Promise',
  },
  {
    '#': 494,
    Keyword: '% Satisfied',
    Category: 'Over Promise',
  },
  {
    '#': 495,
    Keyword: '0%',
    Category: 'Over Promise',
  },
  {
    '#': 496,
    Keyword: '0% risk',
    Category: 'Over Promise',
  },
  {
    '#': 497,
    Keyword: '100%',
    Category: 'Over Promise',
  },
  {
    '#': 498,
    Keyword: '100% free',
    Category: 'Over Promise',
  },
  {
    '#': 499,
    Keyword: '100% more',
    Category: 'Over Promise',
  },
  {
    '#': 500,
    Keyword: '100% off',
    Category: 'Over Promise',
  },
  {
    '#': 501,
    Keyword: '100% satisfied',
    Category: 'Over Promise',
  },
  {
    '#': 502,
    Keyword: '99.90%',
    Category: 'Over Promise',
  },
  {
    '#': 503,
    Keyword: '99%',
    Category: 'Over Promise',
  },
  {
    '#': 504,
    Keyword: 'Access for free',
    Category: 'Over Promise',
  },
  {
    '#': 505,
    Keyword: 'Additional income',
    Category: 'Over Promise',
  },
  {
    '#': 506,
    Keyword: 'Amazed',
    Category: 'Over Promise',
  },
  {
    '#': 507,
    Keyword: 'Amazing',
    Category: 'Over Promise',
  },
  {
    '#': 508,
    Keyword: 'Amazing offer',
    Category: 'Over Promise',
  },
  {
    '#': 509,
    Keyword: 'Amazing stuff',
    Category: 'Over Promise',
  },
  {
    '#': 510,
    Keyword: 'Be amazed',
    Category: 'Over Promise',
  },
  {
    '#': 511,
    Keyword: 'Be surprised',
    Category: 'Over Promise',
  },
  {
    '#': 512,
    Keyword: 'Be your own boss',
    Category: 'Over Promise',
  },
  {
    '#': 513,
    Keyword: 'Believe me',
    Category: 'Over Promise',
  },
  {
    '#': 514,
    Keyword: 'Best bargain',
    Category: 'Over Promise',
  },
  {
    '#': 515,
    Keyword: 'Best deal',
    Category: 'Over Promise',
  },
  {
    '#': 516,
    Keyword: 'Best offer',
    Category: 'Over Promise',
  },
  {
    '#': 517,
    Keyword: 'Best price',
    Category: 'Over Promise',
  },
  {
    '#': 518,
    Keyword: 'Best rates',
    Category: 'Over Promise',
  },
  {
    '#': 519,
    Keyword: 'Big bucks',
    Category: 'Over Promise',
  },
  {
    '#': 520,
    Keyword: 'Bonus',
    Category: 'Over Promise',
  },
  {
    '#': 521,
    Keyword: 'Boss',
    Category: 'Over Promise',
  },
  {
    '#': 522,
    Keyword: 'Can’t live without',
    Category: 'Over Promise',
  },
  {
    '#': 523,
    Keyword: 'Cancel',
    Category: 'Over Promise',
  },
  {
    '#': 524,
    Keyword: 'Consolidate debt',
    Category: 'Over Promise',
  },
  {
    '#': 525,
    Keyword: 'Double your cash',
    Category: 'Over Promise',
  },
  {
    '#': 526,
    Keyword: 'Double your income',
    Category: 'Over Promise',
  },
  {
    '#': 527,
    Keyword: 'Drastically reduced',
    Category: 'Over Promise',
  },
  {
    '#': 528,
    Keyword: 'Earn extra cash',
    Category: 'Over Promise',
  },
  {
    '#': 529,
    Keyword: 'Earn money',
    Category: 'Over Promise',
  },
  {
    '#': 530,
    Keyword: 'Eliminate bad credit',
    Category: 'Over Promise',
  },
  {
    '#': 531,
    Keyword: 'Expect to earn',
    Category: 'Over Promise',
  },
  {
    '#': 532,
    Keyword: 'Extra',
    Category: 'Over Promise',
  },
  {
    '#': 533,
    Keyword: 'Extra cash',
    Category: 'Over Promise',
  },
  {
    '#': 534,
    Keyword: 'Extra income',
    Category: 'Over Promise',
  },
  {
    '#': 535,
    Keyword: 'Fantastic',
    Category: 'Over Promise',
  },
  {
    '#': 536,
    Keyword: 'Fantastic deal',
    Category: 'Over Promise',
  },
  {
    '#': 537,
    Keyword: 'Fantastic offer',
    Category: 'Over Promise',
  },
  {
    '#': 538,
    Keyword: 'FAST',
    Category: 'Over Promise',
  },
  {
    '#': 539,
    Keyword: 'Fast cash',
    Category: 'Over Promise',
  },
  {
    '#': 540,
    Keyword: 'Financial freedom',
    Category: 'Over Promise',
  },
  {
    '#': 541,
    Keyword: 'Free access',
    Category: 'Over Promise',
  },
  {
    '#': 542,
    Keyword: 'Free consultation',
    Category: 'Over Promise',
  },
  {
    '#': 543,
    Keyword: 'Free gift',
    Category: 'Over Promise',
  },
  {
    '#': 544,
    Keyword: 'Free hosting',
    Category: 'Over Promise',
  },
  {
    '#': 545,
    Keyword: 'Free info',
    Category: 'Over Promise',
  },
  {
    '#': 546,
    Keyword: 'Free investment',
    Category: 'Over Promise',
  },
  {
    '#': 547,
    Keyword: 'Free membership',
    Category: 'Over Promise',
  },
  {
    '#': 548,
    Keyword: 'Free money',
    Category: 'Over Promise',
  },
  {
    '#': 549,
    Keyword: 'Free preview',
    Category: 'Over Promise',
  },
  {
    '#': 550,
    Keyword: 'Free quote',
    Category: 'Over Promise',
  },
  {
    '#': 551,
    Keyword: 'Free trial',
    Category: 'Over Promise',
  },
  {
    '#': 552,
    Keyword: 'Full refund',
    Category: 'Over Promise',
  },
  {
    '#': 553,
    Keyword: 'Get out of debt',
    Category: 'Over Promise',
  },
  {
    '#': 554,
    Keyword: 'Giveaway',
    Category: 'Over Promise',
  },
  {
    '#': 555,
    Keyword: 'Guaranteed',
    Category: 'Over Promise',
  },
  {
    '#': 556,
    Keyword: 'Increase sales',
    Category: 'Over Promise',
  },
  {
    '#': 557,
    Keyword: 'Increase traffic',
    Category: 'Over Promise',
  },
  {
    '#': 558,
    Keyword: 'Incredible deal',
    Category: 'Over Promise',
  },
  {
    '#': 559,
    Keyword: 'Join billions',
    Category: 'Over Promise',
  },
  {
    '#': 560,
    Keyword: 'Join millions',
    Category: 'Over Promise',
  },
  {
    '#': 561,
    Keyword: 'Join millions of Americans',
    Category: 'Over Promise',
  },
  {
    '#': 562,
    Keyword: 'Join thousands',
    Category: 'Over Promise',
  },
  {
    '#': 563,
    Keyword: 'Lower rates',
    Category: 'Over Promise',
  },
  {
    '#': 564,
    Keyword: 'Lowest price',
    Category: 'Over Promise',
  },
  {
    '#': 565,
    Keyword: 'Make money',
    Category: 'Over Promise',
  },
  {
    '#': 566,
    Keyword: 'Million',
    Category: 'Over Promise',
  },
  {
    '#': 567,
    Keyword: 'Million dollars',
    Category: 'Over Promise',
  },
  {
    '#': 568,
    Keyword: 'Miracle',
    Category: 'Over Promise',
  },
  {
    '#': 569,
    Keyword: 'Money back',
    Category: 'Over Promise',
  },
  {
    '#': 570,
    Keyword: 'Month trial offer',
    Category: 'Over Promise',
  },
  {
    '#': 571,
    Keyword: 'More Internet Traffic',
    Category: 'Over Promise',
  },
  {
    '#': 572,
    Keyword: 'Number one',
    Category: 'Over Promise',
  },
  {
    '#': 573,
    Keyword: 'Once in a lifetime',
    Category: 'Over Promise',
  },
  {
    '#': 574,
    Keyword: 'One hundred percent guaranteed',
    Category: 'Over Promise',
  },
  {
    '#': 575,
    Keyword: 'One time',
    Category: 'Over Promise',
  },
  {
    '#': 576,
    Keyword: 'Pennies a day',
    Category: 'Over Promise',
  },
  {
    '#': 577,
    Keyword: 'Potential earnings',
    Category: 'Over Promise',
  },
  {
    '#': 578,
    Keyword: 'Prize',
    Category: 'Over Promise',
  },
  {
    '#': 579,
    Keyword: 'Promise',
    Category: 'Over Promise',
  },
  {
    '#': 580,
    Keyword: 'Pure profit',
    Category: 'Over Promise',
  },
  {
    '#': 581,
    Keyword: 'Risk-free',
    Category: 'Over Promise',
  },
  {
    '#': 582,
    Keyword: 'Satisfaction guaranteed',
    Category: 'Over Promise',
  },
  {
    '#': 583,
    Keyword: 'Save big money',
    Category: 'Over Promise',
  },
  {
    '#': 584,
    Keyword: 'Save up to',
    Category: 'Over Promise',
  },
  {
    '#': 585,
    Keyword: 'Special promotion',
    Category: 'Over Promise',
  },
  {
    '#': 586,
    Keyword: 'The best',
    Category: 'Over Promise',
  },
  {
    '#': 587,
    Keyword: 'Thousands',
    Category: 'Over Promise',
  },
  {
    '#': 588,
    Keyword: 'Unbeatable offer',
    Category: 'Over Promise',
  },
  {
    '#': 589,
    Keyword: 'Unbelievable',
    Category: 'Over Promise',
  },
  {
    '#': 590,
    Keyword: 'Unlimited',
    Category: 'Over Promise',
  },
  {
    '#': 591,
    Keyword: 'Unlimited trial',
    Category: 'Over Promise',
  },
  {
    '#': 592,
    Keyword: 'Wonderful',
    Category: 'Over Promise',
  },
  {
    '#': 593,
    Keyword: 'You will not believe your eyes',
    Category: 'Over Promise',
  },
  {
    '#': 594,
    Keyword: '$$$',
    Category: 'Money',
  },
  {
    '#': 595,
    Keyword: '€€€',
    Category: 'Money',
  },
  {
    '#': 596,
    Keyword: '£££',
    Category: 'Money',
  },
  {
    '#': 597,
    Keyword: '50% off',
    Category: 'Money',
  },
  {
    '#': 598,
    Keyword: 'A few bob',
    Category: 'Money',
  },
  {
    '#': 599,
    Keyword: 'Accept cash cards',
    Category: 'Money',
  },
  {
    '#': 600,
    Keyword: 'Accept credit cards',
    Category: 'Money',
  },
  {
    '#': 601,
    Keyword: 'Affordable',
    Category: 'Money',
  },
  {
    '#': 602,
    Keyword: 'Affordable deal',
    Category: 'Money',
  },
  {
    '#': 603,
    Keyword: 'Avoid bankruptcy',
    Category: 'Money',
  },
  {
    '#': 604,
    Keyword: 'Bad credit',
    Category: 'Money',
  },
  {
    '#': 605,
    Keyword: 'Bank',
    Category: 'Money',
  },
  {
    '#': 606,
    Keyword: 'Bankruptcy',
    Category: 'Money',
  },
  {
    '#': 607,
    Keyword: 'Bargain',
    Category: 'Money',
  },
  {
    '#': 608,
    Keyword: 'Billing',
    Category: 'Money',
  },
  {
    '#': 609,
    Keyword: 'Billing address',
    Category: 'Money',
  },
  {
    '#': 610,
    Keyword: 'Billion',
    Category: 'Money',
  },
  {
    '#': 611,
    Keyword: 'Billion dollars',
    Category: 'Money',
  },
  {
    '#': 612,
    Keyword: 'Billionaire',
    Category: 'Money',
  },
  {
    '#': 613,
    Keyword: 'Card accepted',
    Category: 'Money',
  },
  {
    '#': 614,
    Keyword: 'Cards accepted',
    Category: 'Money',
  },
  {
    '#': 615,
    Keyword: 'Cash',
    Category: 'Money',
  },
  {
    '#': 616,
    Keyword: 'Cash bonus',
    Category: 'Money',
  },
  {
    '#': 617,
    Keyword: 'Cash out',
    Category: 'Money',
  },
  {
    '#': 618,
    Keyword: 'Cash-out',
    Category: 'Money',
  },
  {
    '#': 619,
    Keyword: 'Cashcashcash',
    Category: 'Money',
  },
  {
    '#': 620,
    Keyword: 'Casino',
    Category: 'Money',
  },
  {
    '#': 621,
    Keyword: 'Cents on the dollar',
    Category: 'Money',
  },
  {
    '#': 622,
    Keyword: 'Check',
    Category: 'Money',
  },
  {
    '#': 623,
    Keyword: 'Check or money order',
    Category: 'Money',
  },
  {
    '#': 624,
    Keyword: 'Claim your discount',
    Category: 'Money',
  },
  {
    '#': 625,
    Keyword: 'Cost',
    Category: 'Money',
  },
  {
    '#': 626,
    Keyword: 'Costs',
    Category: 'Money',
  },
  {
    '#': 627,
    Keyword: 'Credit',
    Category: 'Money',
  },
  {
    '#': 628,
    Keyword: 'Credit bureaus',
    Category: 'Money',
  },
  {
    '#': 629,
    Keyword: 'Credit card',
    Category: 'Money',
  },
  {
    '#': 630,
    Keyword: 'Credit card offers',
    Category: 'Money',
  },
  {
    '#': 631,
    Keyword: 'Credit or Debit',
    Category: 'Money',
  },
  {
    '#': 632,
    Keyword: 'Deal',
    Category: 'Money',
  },
  {
    '#': 633,
    Keyword: 'Debt',
    Category: 'Money',
  },
  {
    '#': 634,
    Keyword: 'Discount',
    Category: 'Money',
  },
  {
    '#': 635,
    Keyword: 'Dollars',
    Category: 'Money',
  },
  {
    '#': 636,
    Keyword: 'Double your',
    Category: 'Money',
  },
  {
    '#': 637,
    Keyword: 'Double your wealth',
    Category: 'Money',
  },
  {
    '#': 638,
    Keyword: 'Earn',
    Category: 'Money',
  },
  {
    '#': 639,
    Keyword: 'Earn $',
    Category: 'Money',
  },
  {
    '#': 640,
    Keyword: 'Earn cash',
    Category: 'Money',
  },
  {
    '#': 641,
    Keyword: 'Earn extra income',
    Category: 'Money',
  },
  {
    '#': 642,
    Keyword: 'Earn from home',
    Category: 'Money',
  },
  {
    '#': 643,
    Keyword: 'Earn monthly',
    Category: 'Money',
  },
  {
    '#': 644,
    Keyword: 'Earn per month',
    Category: 'Money',
  },
  {
    '#': 645,
    Keyword: 'Earn per week',
    Category: 'Money',
  },
  {
    '#': 646,
    Keyword: 'Earn your degree',
    Category: 'Money',
  },
  {
    '#': 647,
    Keyword: 'Easy income',
    Category: 'Money',
  },
  {
    '#': 648,
    Keyword: 'Easy terms',
    Category: 'Money',
  },
  {
    '#': 649,
    Keyword: 'F r e e',
    Category: 'Money',
  },
  {
    '#': 650,
    Keyword: 'For free',
    Category: 'Money',
  },
  {
    '#': 651,
    Keyword: 'For just $',
    Category: 'Money',
  },
  {
    '#': 652,
    Keyword: 'For just $ (amount)',
    Category: 'Money',
  },
  {
    '#': 653,
    Keyword: 'For just $xxx',
    Category: 'Money',
  },
  {
    '#': 654,
    Keyword: 'Get Money',
    Category: 'Money',
  },
  {
    '#': 655,
    Keyword: 'Get your money',
    Category: 'Money',
  },
  {
    '#': 656,
    Keyword: 'Hidden assets',
    Category: 'Money',
  },
  {
    '#': 657,
    Keyword: 'Huge discount',
    Category: 'Money',
  },
  {
    '#': 658,
    Keyword: 'Income',
    Category: 'Money',
  },
  {
    '#': 659,
    Keyword: 'Income from home',
    Category: 'Money',
  },
  {
    '#': 660,
    Keyword: 'Increase revenue',
    Category: 'Money',
  },
  {
    '#': 661,
    Keyword: 'Increase sales/traffic',
    Category: 'Money',
  },
  {
    '#': 662,
    Keyword: 'Increase your chances',
    Category: 'Money',
  },
  {
    '#': 663,
    Keyword: 'Initial investment',
    Category: 'Money',
  },
  {
    '#': 664,
    Keyword: 'Instant earnings',
    Category: 'Money',
  },
  {
    '#': 665,
    Keyword: 'Instant income',
    Category: 'Money',
  },
  {
    '#': 666,
    Keyword: 'Insurance',
    Category: 'Money',
  },
  {
    '#': 667,
    Keyword: 'Investment',
    Category: 'Money',
  },
  {
    '#': 668,
    Keyword: 'Investment advice',
    Category: 'Money',
  },
  {
    '#': 669,
    Keyword: 'Lifetime',
    Category: 'Money',
  },
  {
    '#': 670,
    Keyword: 'Loans',
    Category: 'Money',
  },
  {
    '#': 671,
    Keyword: 'Make $',
    Category: 'Money',
  },
  {
    '#': 672,
    Keyword: 'Money',
    Category: 'Money',
  },
  {
    '#': 673,
    Keyword: 'Money making',
    Category: 'Money',
  },
  {
    '#': 674,
    Keyword: 'Money-back guarantee',
    Category: 'Money',
  },
  {
    '#': 675,
    Keyword: 'Money-making',
    Category: 'Money',
  },
  {
    '#': 676,
    Keyword: 'Monthly payment',
    Category: 'Money',
  },
  {
    '#': 677,
    Keyword: 'Mortgage',
    Category: 'Money',
  },
  {
    '#': 678,
    Keyword: 'Mortgage rates',
    Category: 'Money',
  },
  {
    '#': 679,
    Keyword: 'Offer',
    Category: 'Money',
  },
  {
    '#': 680,
    Keyword: 'One hundred percent free',
    Category: 'Money',
  },
  {
    '#': 681,
    Keyword: 'Only $',
    Category: 'Money',
  },
  {
    '#': 682,
    Keyword: 'Price',
    Category: 'Money',
  },
  {
    '#': 683,
    Keyword: 'Price protection',
    Category: 'Money',
  },
  {
    '#': 684,
    Keyword: 'Prices',
    Category: 'Money',
  },
  {
    '#': 685,
    Keyword: 'Profits',
    Category: 'Money',
  },
  {
    '#': 686,
    Keyword: 'Quote',
    Category: 'Money',
  },
  {
    '#': 687,
    Keyword: 'Rates',
    Category: 'Money',
  },
  {
    '#': 688,
    Keyword: 'Refinance',
    Category: 'Money',
  },
  {
    '#': 689,
    Keyword: 'Save $',
    Category: 'Money',
  },
  {
    '#': 690,
    Keyword: 'Serious cash',
    Category: 'Money',
  },
  {
    '#': 691,
    Keyword: 'Subject to credit',
    Category: 'Money',
  },
  {
    '#': 692,
    Keyword: 'US dollars',
    Category: 'Money',
  },
  {
    '#': 693,
    Keyword: 'Why pay more?',
    Category: 'Money',
  },
  {
    '#': 694,
    Keyword: 'Your income',
    Category: 'Money',
  },
  {
    '#': 695,
    Keyword: 'Acceptance',
    Category: 'Unnatural',
  },
  {
    '#': 696,
    Keyword: 'Accordingly',
    Category: 'Unnatural',
  },
  {
    '#': 697,
    Keyword: 'Account-based marketing (ABM)',
    Category: 'Unnatural',
  },
  {
    '#': 698,
    Keyword: 'Accounts',
    Category: 'Unnatural',
  },
  {
    '#': 699,
    Keyword: 'Addresses',
    Category: 'Unnatural',
  },
  {
    '#': 700,
    Keyword: 'Addresses on CD',
    Category: 'Unnatural',
  },
  {
    '#': 701,
    Keyword: 'Beverage',
    Category: 'Unnatural',
  },
  {
    '#': 702,
    Keyword: 'Confidentiality on all orders',
    Category: 'Unnatural',
  },
  {
    '#': 703,
    Keyword: 'Confidentially on all orders',
    Category: 'Unnatural',
  },
  {
    '#': 704,
    Keyword: 'Content marketing',
    Category: 'Unnatural',
  },
  {
    '#': 705,
    Keyword: 'Dear [email address]',
    Category: 'Unnatural',
  },
  {
    '#': 706,
    Keyword: 'Dear [email/friend/somebody]',
    Category: 'Unnatural',
  },
  {
    '#': 707,
    Keyword: 'Dear [first name]',
    Category: 'Unnatural',
  },
  {
    '#': 708,
    Keyword: 'Dear [wrong name]',
    Category: 'Unnatural',
  },
  {
    '#': 709,
    Keyword: 'Digital marketing',
    Category: 'Unnatural',
  },
  {
    '#': 710,
    Keyword: 'Dormant',
    Category: 'Unnatural',
  },
  {
    '#': 711,
    Keyword: 'Email extractor',
    Category: 'Unnatural',
  },
  {
    '#': 712,
    Keyword: 'Email harvest',
    Category: 'Unnatural',
  },
  {
    '#': 713,
    Keyword: 'Email marketing',
    Category: 'Unnatural',
  },
  {
    '#': 714,
    Keyword: 'Extract email',
    Category: 'Unnatural',
  },
  {
    '#': 715,
    Keyword: 'Form',
    Category: 'Unnatural',
  },
  {
    '#': 716,
    Keyword: 'Freedom',
    Category: 'Unnatural',
  },
  {
    '#': 717,
    Keyword: 'Friend',
    Category: 'Unnatural',
  },
  {
    '#': 718,
    Keyword: 'Here',
    Category: 'Unnatural',
  },
  {
    '#': 719,
    Keyword: 'Hidden',
    Category: 'Unnatural',
  },
  {
    '#': 720,
    Keyword: 'Home',
    Category: 'Unnatural',
  },
  {
    '#': 721,
    Keyword: 'Home based',
    Category: 'Unnatural',
  },
  {
    '#': 722,
    Keyword: 'Home employment',
    Category: 'Unnatural',
  },
  {
    '#': 723,
    Keyword: 'Home-based',
    Category: 'Unnatural',
  },
  {
    '#': 724,
    Keyword: 'Home-based business',
    Category: 'Unnatural',
  },
  {
    '#': 725,
    Keyword: 'Homebased business',
    Category: 'Unnatural',
  },
  {
    '#': 726,
    Keyword: 'If you no longer wish to receive',
    Category: 'Unnatural',
  },
  {
    '#': 727,
    Keyword: 'Important information regarding',
    Category: 'Unnatural',
  },
  {
    '#': 728,
    Keyword: 'In accordance with laws',
    Category: 'Unnatural',
  },
  {
    '#': 729,
    Keyword: 'Increase your sales',
    Category: 'Unnatural',
  },
  {
    '#': 730,
    Keyword: 'Internet market',
    Category: 'Unnatural',
  },
  {
    '#': 731,
    Keyword: 'Leave',
    Category: 'Unnatural',
  },
  {
    '#': 732,
    Keyword: 'Lose',
    Category: 'Unnatural',
  },
  {
    '#': 733,
    Keyword: 'Maintained',
    Category: 'Unnatural',
  },
  {
    '#': 734,
    Keyword: 'Marketing',
    Category: 'Unnatural',
  },
  {
    '#': 735,
    Keyword: 'Marketing solution',
    Category: 'Unnatural',
  },
  {
    '#': 736,
    Keyword: 'Marketing solutions',
    Category: 'Unnatural',
  },
  {
    '#': 737,
    Keyword: 'Medium',
    Category: 'Unnatural',
  },
  {
    '#': 738,
    Keyword: 'Message contains',
    Category: 'Unnatural',
  },
  {
    '#': 739,
    Keyword: 'Multi level marketing',
    Category: 'Unnatural',
  },
  {
    '#': 740,
    Keyword: 'Never',
    Category: 'Unnatural',
  },
  {
    '#': 741,
    Keyword: 'One time mailing',
    Category: 'Unnatural',
  },
  {
    '#': 742,
    Keyword: 'Online marketing',
    Category: 'Unnatural',
  },
  {
    '#': 743,
    Keyword: 'Online pharmacy',
    Category: 'Unnatural',
  },
  {
    '#': 744,
    Keyword: 'Opt in',
    Category: 'Unnatural',
  },
  {
    '#': 745,
    Keyword: 'Per day',
    Category: 'Unnatural',
  },
  {
    '#': 746,
    Keyword: 'Per week',
    Category: 'Unnatural',
  },
  {
    '#': 747,
    Keyword: 'Pre-approved',
    Category: 'Unnatural',
  },
  {
    '#': 748,
    Keyword: 'Problem',
    Category: 'Unnatural',
  },
  {
    '#': 749,
    Keyword: 'Removal',
    Category: 'Unnatural',
  },
  {
    '#': 750,
    Keyword: 'Remove',
    Category: 'Unnatural',
  },
  {
    '#': 751,
    Keyword: 'Reserves the right',
    Category: 'Unnatural',
  },
  {
    '#': 752,
    Keyword: 'Reverses',
    Category: 'Unnatural',
  },
  {
    '#': 753,
    Keyword: 'Sample',
    Category: 'Unnatural',
  },
  {
    '#': 754,
    Keyword: 'Satisfaction',
    Category: 'Unnatural',
  },
  {
    '#': 755,
    Keyword: 'Score',
    Category: 'Unnatural',
  },
  {
    '#': 756,
    Keyword: 'Search engine',
    Category: 'Unnatural',
  },
  {
    '#': 757,
    Keyword: 'Search engine listings',
    Category: 'Unnatural',
  },
  {
    '#': 758,
    Keyword: 'Search engines',
    Category: 'Unnatural',
  },
  {
    '#': 759,
    Keyword: 'Sent in compliance',
    Category: 'Unnatural',
  },
  {
    '#': 760,
    Keyword: 'Solution',
    Category: 'Unnatural',
  },
  {
    '#': 761,
    Keyword: 'Stop',
    Category: 'Unnatural',
  },
  {
    '#': 762,
    Keyword: 'Success',
    Category: 'Unnatural',
  },
  {
    '#': 763,
    Keyword: 'Teen',
    Category: 'Unnatural',
  },
  {
    '#': 764,
    Keyword: 'Terms and conditions',
    Category: 'Unnatural',
  },
  {
    '#': 765,
    Keyword: 'Warranty',
    Category: 'Unnatural',
  },
  {
    '#': 766,
    Keyword: 'Web traffic',
    Category: 'Unnatural',
  },
  {
    '#': 767,
    Keyword: 'Wife',
    Category: 'Unnatural',
  },
  {
    '#': 768,
    Keyword: 'Work at home',
    Category: 'Unnatural',
  },
  {
    '#': 769,
    Keyword: 'Work from home',
    Category: 'Unnatural',
  },
  {
    '#': 770,
    Keyword: 'Payment',
    Category: 'Money',
  },
  {
    '#': 771,
    Keyword: 'Transaction',
    Category: 'Money',
  },
  {
    '#': 772,
    Keyword: 'statement',
    Category: 'Money',
  },
  {
    '#': 773,
    Keyword: 'Bitcoin',
    Category: 'Money',
  },
  {
    '#': 774,
    Keyword: 'PayPal',
    Category: 'Money',
  },
];
