import React, { useState } from 'react'
import { Card, Loader, TextInput, UpgradePlan, useAPI, useRouteCheck } from 'components/lib'
import CustomButton from 'components/custom/customButton'
import Axios from 'axios';
import Toast from 'utils/toast';

export default function Leads() {
    const [domain, setDomain] = useState("")
    const [loading, setLoading] = useState(false)
    const auth = useAPI('/api/auth');
    const allowRoute = useRouteCheck(
        '/ltd-leads',
        auth.data?.plan,
        auth.data?.subscription,
        auth.data?.expired
    );

    const formatData = (data, field) => {
        if(data[field]) return data[field].replaceAll(',', ' ')
        return data[field] || ""
    }

    const getLeads = async () => {
        setLoading(true)
        try {
            const response = await Axios.get(`/api/people/bulk?search=${domain}`);
            if (response.data?.data.length > 0) {
                const csvContent = "data:text/csv;charset=utf-8," + [{
                    first_name: 'First Name',
                    last_name: 'Last Name',
                    job_title: 'Job Title',
                    business_email: 'Business Email',
                    headline: 'Headline',
                    description: 'Description',
                    personal_email: 'Personal Email',
                    phone: 'Phone',
                    social_url: 'Social URL',
                    company_name: 'Company Name',
                    city: 'City',
                    linkedin_id: 'Linkedin ID',
                    industry: 'Industry',
                    company_type: 'Company Type',
                    company_domain: 'Company Domain',
                    company_industry: 'Company Industry',
                    location: 'Location',
                    company_address: 'Company Address',
                    company_country: 'Company Country',
                    company_linkedin_url: 'Company Linkedin URL'
                }, ...response.data?.data].map(employee => {
                    // Ensure all required fields are present in each employee object
                    const newEmployee = {
                        first_name: formatData(employee, 'first_name'),
                        last_name: formatData(employee, 'last_name'),
                        job_title: formatData(employee, 'job_title'),
                        business_email: formatData(employee, 'business_email'),
                        headline: formatData(employee, 'headline'),
                        description: formatData(employee, 'description'),
                        personal_email: formatData(employee, 'personal_email'),
                        phone: formatData(employee, 'phone'),
                        social_url: formatData(employee, 'social_url'),
                        company_name: formatData(employee, 'company_name'),
                        city: formatData(employee, 'city'),
                        linkedin_id: employee['linkedin_id'] || '',
                        industry: formatData(employee, 'industry'),
                        company_type: formatData(employee, 'company_type'),
                        company_domain: formatData(employee, 'company_domain'),
                        company_industry: formatData(employee, 'company_industry'),
                        location: formatData(employee, 'location'),
                        company_address: formatData(employee, 'company_address'),
                        company_country: formatData(employee, 'company_country'),
                        company_linkedin_url: formatData(employee, 'company_linkedin_url'),
                    };
                    // Convert each employee object to a CSV row
                    return Object.values(newEmployee).join(',');
                }).join('\n');
                
                setLoading(false)

                // Create a link element and trigger the download
                const encodedUri = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "employees.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                Toast.fire({
                    icon: 'error',
                    title: "No data found",
                });
            }
            setLoading(false)
        } catch (err) {
            // Handle errors
            setLoading(false)
            console.error('Error:', err);
            Toast.fire({
                icon: 'error',
                title: err?.response?.data?.err || 'Unable to download leads.',
            });
        }
    };

    if (!allowRoute) return auth.loading ? <Loader /> : <UpgradePlan />;

    return (
        <div className='m-auto'>
            {loading && <div
                className="csFormLoader"
                style={{ background: `rgba(255, 255, 255, 0.5)`, marginTop: 80 }}
            >
                <Loader />
            </div>}
            <Card style={{ width: '40%', margin: 'auto' }}>
                <div className='font-bold mb-2 text-center text-lg'>
                    Lead Prospecting Feature
                </div>
                <div className='mb-2 text-center'>
                    We have sunset this feature & it is available till 31st December 2024 for our early LTD Buyers.
                    <br />
                    Data is updated on 5th of every Month.
                </div>
                <TextInput
                  value={domain}
                  onChange={(name, value) => setDomain(value)}
                  placeholder="Search by Domain"
                />
                <CustomButton
                  className="mt-2 m-auto"
                  title="Download Data"
                  disabled={loading}
                  onClick={(e) => {
                    getLeads()
                  }}
                  variant="primary green"
                />
            </Card>
        </div>
    )
}
