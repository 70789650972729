import { Tooltip } from 'react-tooltip';
import { getBezierPath } from 'reactflow';
import { useFlowchartData } from './FlowchartContext';
import { UilPlus } from '@iconscout/react-unicons';

const foreignObjectSize = 40;

export default function CustomEdge({
  id,
  source,
  sourceX,
  sourceY,
  target,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}) {
  const flowchartData = useFlowchartData();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div>
          <div
            id="add-node-between"
            className='foreign-object-button'
            style={{
              background: '#f2f2f2',
              width: 40,
              border: '3px solid #03A6FF',
              color: '#03A6FF',
              borderRadius: 5,
              cursor: 'pointer',
            }}
            onClick={(event) => {
              flowchartData?.showEdgeAddDialog({
                id,
                source,
                sourceX,
                sourceY,
                sourcePosition,
                target,
                targetX,
                targetY,
                targetPosition,
              });
            }}
          >
            <UilPlus style={{ cursor: 'pointer' }} size={24} />
          </div>
          <Tooltip anchorSelect="#add-node-between">
            Add Block in Between
          </Tooltip>
        </div>
      </foreignObject>
    </>
  );
}
