import Axios from 'axios';
import {
  CustomTable,
  Grid,
  Loader,
  PageTitle,
  UpgradePlan,
  useAPI,
  useRouteCheck,
} from 'components/lib';
import { UilSync, UilEdit, UilAnalytics, UilMessage, UilClock, UilLink, UilExternalLinkAlt, UilEnvelopeReceive, UilEnvelopeOpen, } from '@iconscout/react-unicons';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import timezones from '../../assets/data/timezone';
import EmptyState from '../../assets/undraw_no_data_re_kwbl.svg'
import './cockpit.scss'
import { Link } from 'react-router-dom';
import Toast from 'utils/toast';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

function findDate(type) {
  let today = DateTime.now();
  let start = '';
  let end = '';
  switch (type) {
    case 'this-week':
      start = today.startOf('week');
      end = today.endOf('week');
      break;
    case 'last-week':
      start = today.minus({ weeks: 1 }).startOf('week');
      end = today.minus({ weeks: 1 }).endOf('week');
      break;
    case 'this-month':
      start = today.startOf('month');
      end = today.endOf('month');
      break;
    case 'last-month':
      start = today.minus({ months: 1 }).startOf('month');
      end = today.minus({ months: 1 }).endOf('month');
      break;
    default:
      break;
  }
  if (start !== '' && end !== '') return `${start.ts}-${end.ts}`;
}

// cockpit

export default function Cockpit(props) {
  const [items, setItems] = useState([]);
  const [sequences, setSequences] = useState([]);
  const auth = useAPI('/api/auth');
  const allowRoute = useRouteCheck(
    window.location.pathname,
    auth.data?.plan,
    auth.data?.subscription,
    auth.data?.expired
  );

  useEffect(() => {
    getUseCockpit();
    fetchSequences();
    const workspace_localstorage_data = localStorage.getItem('workspace-activity');
    if (workspace_localstorage_data) {
      Toast.fire({ icon: 'success', title: JSON.parse(workspace_localstorage_data) });
      localStorage.removeItem('workspace-activity');
    }
  }, []);

  useEffect(() => {
    let phoneRequired = sessionStorage.getItem('phone');
    if(auth.data?.plan === "trialing") {
      if (phoneRequired) window.location.href = '/signup/phone'; 
    } else if(phoneRequired && auth.data?.plan !== "trialing") window.location.href = '/signup/phone'; 
  }, [auth.data]);

  const getUseCockpit = async () => {
    try {
      const res = await Axios({
        url: '/api/cockpit',
        method: 'get',
      });
      // console.log("RESP ", res.data)
      setItems(res.data.data?.structure);
    } catch (err) {
      console.log('ERROR ', err.response);
    }
  };

  async function fetchSequences() {
    try {
      let result = await Axios.get('/api/sequences');
      setSequences(result?.data?.data);
    } catch (err) {
      console.log('Error in getting sequences ', err);
    }
  }

  if (!allowRoute) {
    if (auth.loading) return <Loader />;
    else return <UpgradePlan />;
  }

  return (
    <div className='cockpitView'>
      <div
        style={{
          display: 'flex',
          marginTop: 10,
          marginBottom: 10,
          width: '100%',
        }}
      >
        <PageTitle 
          title="Dashboard"
          description="Your Sales at a Glance"
          tooltip="Edit the dashboard to add blocks."
        />
        <button
          style={{ border: `3px solid #F9C00C`, padding: "2px 7px", borderRadius: 5, marginLeft: 'auto' }}
          onClick={() => props.onCustomize()}
        >
          <UilEdit size={25} color="#F9C00C" />
        </button>
      </div>
      <div className='csCockpitRow'>
        {items.map((item, index) => {
          const commonStyle = { marginTop: 10, marginBottom: 10 };
          
          if (item.slug === 'my-sequence' || item.slug.includes('team-member-sequences')) {
            return (
              <div key={index} className="gridItem" style={commonStyle}>
                <MySequence data={item} />
              </div>
            );
          } else if (item.slug === 'replies' || item.slug === 'opens' || item.slug === 'clicks') {
            return (
              <div key={index} className="gridItem" style={commonStyle}>
                <LogView data={item} sequences={sequences} />
              </div>
            );
          } else if (item.slug.includes('my-scheduled-emails')) {
            return (
              <div key={index} className="gridItem" style={commonStyle}>
                <LogView data={item} sequences={sequences} sequence={item.sequence?.id} />
              </div>
            );
          } else if (item.slug.includes('email-stats')) {
            return (
              <div key={index} className="gridItem" style={commonStyle}>
                <EmailStats data={item} />
              </div>
            );
          } else if (item.slug.includes('task-stats')) {
            return (
              <div key={index} className="gridItem" style={commonStyle}>
                <TaskStats data={item} />
              </div>
            );
          } else if (item.slug.includes('team-email-activity') || item.slug.includes('team-member-particular-sequence-activity')) {
            return (
              <div key={index} className="gridItem" style={commonStyle}>
                <EmailStats data={item} />
              </div>
            );
          }
          return null; 
        })}
      </div>
    </div>
  );
}

// my sequence

function MySequence({ data }) {
  const [activeSequences, setActiveSequences] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) getActiveSequences();
  }, [data]);

  const getActiveSequences = async () => {
    try {
      let url = `/api/active-sequences?total=${data.total}`
      if(data?.users?.value) url = `/api/active-sequences?userID=${data.users.value}&total=${data.total}`
      const res = await Axios({
        url: url,
        method: 'get',
      });
      // console.log("RESP ", res.data)
      setActiveSequences(res.data.data);
      setLoading(false);
    } catch (err) {
      console.log('ERROR ', err.response);
    }
  };

  const activeSequenceColumns = [
    {
      Header: 'Sequence Name',
      Footer: 'Sequence Name',
      accessor: 'name',
      Cell: ({row, value}) => {
        const { original } = row
        if(!original.paused) return (
          <a
            href={`/outreach/sequences/${original.id}/stats`}
            className="csCustomeLink"
            target='_blank'
          >
            {value} <UilExternalLinkAlt size={16} className="csLinkIcon" />{' '}
            <span className="csLinkEmpty"></span>
          </a>
        );
        return (
          <a
            href={`/outreach/sequences/${original.id}${(original.blinkgpt && (!original.flowchart?.nodes || original.flowchart?.nodes?.length === 0))?"/generate":""}`}
            className="csCustomeLink"
            target='_blank'
          >
            {value} <UilExternalLinkAlt size={16} className="csLinkIcon" />{' '}
            <span className="csLinkEmpty"></span>
          </a>
        );
      },
      disableSortBy: true,
    },
    {
      Header: <span className='csCenter'>Leads</span>,
      accessor: 'contacts',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return <span className='csBadge info csCenter' style={{ width: 100, margin: 'auto' }}>{value}</span>;
      },
    },
    {
      Header: <span className='csCenter'>Status</span>,
      accessor: "paused",
      disableSortBy: true,
      Cell: ({row, value}) => {
        if (value)
          return <span className="csBadge warning csCenter">Paused</span>;
        return <span className="csBadge success csCenter">Running</span>;
      }
    }
  ];

  return <div>
    <div className='csCardTitle' style={{ marginBottom: 10 }}>
      <UilMessage />
      {data.title}
      <Link to={"/outreach/sequences"} target='_blank' style={{marginLeft: 10}} className='csLink'><UilExternalLinkAlt/></Link>
    </div>
    {data.slug.includes('team-member-sequences') && data.users && (
      <i style={{ fontSize: 14, fontWeight: 400, marginLeft: 5 }}>
        ({data.users.email})
      </i>
    )}
    {activeSequences.length > 0 ? (
      <CustomTable
        pagination={false}
        hasFooter={false}
        columns={activeSequenceColumns}
        data={activeSequences}
        noBottomMargin={true}
      />
    ) : (
      <div
        onClick={() => {}}
        style={{ display: 'flex', textAlign: 'center' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            marginBottom: 20,
            marginTop: 10,
            width: '100%'
          }}
          className='csCard'
        >
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150" height="150" style={{ margin: 'auto' }} viewBox="0 0 100 100">
            <circle cx="52" cy="52" r="44" opacity=".35"></circle><circle cx="50" cy="50" r="44" fill="#f2f2f2"></circle><ellipse cx="50.016" cy="50.026" fill="#d9eeff" rx="38.017" ry="38.026"></ellipse><path fill="#70bfff" d="M50.189,66.359c-3.776,0-6.66-1.4-8.651-4.203c-1.991-2.802-2.987-6.799-2.987-11.992 c0-5.191,0.996-9.152,2.987-11.881c1.991-2.728,4.875-4.093,8.651-4.093c3.776,0,6.659,1.365,8.651,4.093 c1.991,2.729,2.987,6.69,2.987,11.881c0,5.193-0.995,9.19-2.987,11.992C56.849,64.959,53.965,66.359,50.189,66.359z M50.189,60.784c1.711,0,2.979-0.847,3.806-2.544c0.825-1.696,1.239-4.387,1.239-8.076c0-3.687-0.414-6.342-1.239-7.964 c-0.826-1.622-2.095-2.435-3.806-2.435s-2.98,0.812-3.806,2.435c-0.826,1.622-1.239,4.277-1.239,7.964 c0,3.688,0.413,6.379,1.239,8.076C47.209,59.937,48.478,60.784,50.189,60.784z"></path><path fill="#0080FF" d="M50,89c-21.505,0-39-17.495-39-39s17.495-39,39-39s39,17.495,39,39S71.505,89,50,89z M50,14 c-19.851,0-36,16.149-36,36s16.149,36,36,36s36-16.149,36-36S69.851,14,50,14z"></path>
          </svg>
          <span>No sequence found</span>
        </div>
      </div>
    )}
  </div>
}

function LogView({ data, sequences, sequence }) {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) getLogs();
  }, [data]);

  const getLogs = async () => {
    try {
      let type = '';
      if (data.slug === 'replies') type = 'reply';
      else if (data.slug === 'clicks') type = 'click';
      else if (data.slug === 'opens') type = 'open';
      else if (data.slug.includes('my-scheduled-emails')) type = 'outreach';
      if (type === '') return;

      let url = `/api/logs?limit=${data.total}&page=0&type=${type}`;
      if (sequence) url += `&sequence=${sequence.id}`;
      const res = await Axios({
        url: url,
        method: 'get',
      });
      setLogs(res?.data?.data);
      setLoading(false);
    } catch (err) {
      console.log('ERROR ', err.response);
    }
  };

  const logsColumns = [
    {
      Header: 'Email',
      Footer: 'Email',
      accessor: 'email',
      Cell: ({ row, value }) => (
        <Link
          to={`/contact/${row.original?.contact_id?._id}`}
          target="_blank"
          className="external-link csLink"
        >
          {value}
          <UilExternalLinkAlt />
        </Link>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Time',
      Footer: 'Time',
      accessor: 'time',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { original } = row;
        let seqInfo = sequences?.find((_seq) => _seq.id === original?.sequence);
        let zoneInfo = timezones.find((t) => t.id === seqInfo?.timezone);
        if(!zoneInfo && seqInfo?.timezone.includes('/')) zoneInfo = { utc: [seqInfo?.timezone] }
        // console.log(zoneInfo.utc[0])
        let scheduledDate = DateTime.fromMillis(value);
        if (zoneInfo) scheduledDate.setZone(zoneInfo.utc[0]);

        const day = scheduledDate.day;
        const suffix =
          day % 10 === 1 && day !== 11
            ? 'st'
            : day % 10 === 2 && day !== 12
            ? 'nd'
            : day % 10 === 3 && day !== 13
            ? 'rd'
            : 'th';

        return (
          scheduledDate.toFormat(`d`) +
          suffix +
          scheduledDate.toFormat(` MMM yyyy, hh:mm a`)
        );
      }, // formatting date
    },
  ];

  return (
    <div title={data.title} loading={loading}>
      <div className='csCardTitle'>
        {data.slug === 'opens' && <UilEnvelopeOpen />}
        {data.slug === 'clicks' && <UilLink />}
        {data.slug === 'replies' && <UilEnvelopeReceive />}
        {data.slug.includes('my-scheduled-emails') && <UilClock />}
        {data.title}
        <Link to={`/outreach/reports?event=${(function() {
          if(data.slug === 'opens') return "open";
          if(data.slug === 'clicks') return "click";
          if(data.slug === 'replies') return "reply";
          if(data.slug.includes('my-scheduled-emails')) return "outreach";
        })()}`} target='_blank' className='csLink' style={{marginLeft: 10}}><UilExternalLinkAlt/></Link>
      </div>
      {data.sequence && (
        <div style={{ marginTop: -5, display: 'flex' }}>
          <i style={{ fontSize: 14, fontWeight: 400 }}>
            ({data.sequence.name})
          </i>
        </div>
      )}
      {logs.length > 0 ? (
        <CustomTable
          pagination={false}
          hasFooter={false}
          columns={logsColumns}
          data={logs}
          noBottomMargin={true}
        />
      ) : (
        <div
          onClick={() => {}}
          style={{ display: 'flex', textAlign: 'center' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              marginBottom: 20,
              marginTop: 10,
              width: '100%'
            }}
            className='csCard'
          >
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150" height="150" style={{ margin: 'auto' }} viewBox="0 0 100 100">
              <circle cx="52" cy="52" r="44" opacity=".35"></circle><circle cx="50" cy="50" r="44" fill="#f2f2f2"></circle><ellipse cx="50.016" cy="50.026" fill="#d9eeff" rx="38.017" ry="38.026"></ellipse><path fill="#70bfff" d="M50.189,66.359c-3.776,0-6.66-1.4-8.651-4.203c-1.991-2.802-2.987-6.799-2.987-11.992 c0-5.191,0.996-9.152,2.987-11.881c1.991-2.728,4.875-4.093,8.651-4.093c3.776,0,6.659,1.365,8.651,4.093 c1.991,2.729,2.987,6.69,2.987,11.881c0,5.193-0.995,9.19-2.987,11.992C56.849,64.959,53.965,66.359,50.189,66.359z M50.189,60.784c1.711,0,2.979-0.847,3.806-2.544c0.825-1.696,1.239-4.387,1.239-8.076c0-3.687-0.414-6.342-1.239-7.964 c-0.826-1.622-2.095-2.435-3.806-2.435s-2.98,0.812-3.806,2.435c-0.826,1.622-1.239,4.277-1.239,7.964 c0,3.688,0.413,6.379,1.239,8.076C47.209,59.937,48.478,60.784,50.189,60.784z"></path><path fill="#0080FF" d="M50,89c-21.505,0-39-17.495-39-39s17.495-39,39-39s39,17.495,39,39S71.505,89,50,89z M50,14 c-19.851,0-36,16.149-36,36s16.149,36,36,36s36-16.149,36-36S69.851,14,50,14z"></path>
            </svg>
            <span>No logs found</span>
          </div>
        </div>
      )}
    </div>
  );
}

const sleep = () => {
  return new Promise(resolve => setTimeout(resolve, 500));
};

// email stats

function EmailStats({ data }) {
  const [sent, setSent] = useState(undefined);
  const [click, setClick] = useState(undefined);
  const [reply, setReply] = useState(undefined);
  const [unsubscribed, setUnsubscribed] = useState(undefined);
  const [open, setOpen] = useState(undefined);
  const [bounced, setBounced] = useState(undefined);
  const [outbox, setOutbox] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log("DATA ", data)
    const fetchData = async () => {
      setLoading(true);
      if (data) {
        for (let i = 0; i < data.options.length; i++) {
          getLogsCount(data.options[i]);
        }
      }
      await sleep();
      setLoading(false);
    };
  
    fetchData();
  }, []);

  const getLogsCount = async (type) => {
    try {
      let message = '';
      if (type === 'sent') message = 'Sent';

      if (type === 'replies') type = 'reply';
      if (type === 'opens') type = 'open';
      if (type === 'clicks') type = 'click';
      if (type === 'bounced') type = 'bounce';

      let url = `/api/logs/count?date=${findDate(data.viewType)}`;
      if (message !== '') url += `&message=${message}&type=outreach`;
      else url += `&type=${type}`;

      if(data.slug.includes('team-member-particular-sequence-activity')) {
        let users = data.users.value;
        url += `&ownedby=${users}`;
      } else if(data.users.length > 0) {
        let users = data.users.map((user) => user.value);
        url += `&ownedby=${users.join(',')}`;
      }

      if (type === 'outbox') {
        url = `/api/tasks-to-verify?date=${findDate(data.viewType)}`
        let users = '';
        if(data.slug.includes('team-member-particular-sequence-activity')) {
          users = data.users.value;
          url += `&ownedby=${users}`;
        } else if(data.users.length > 0) {
          users = data.users.map((user) => user.value);
          url += `&ownedby=${users.join(',')}`;
        }
      }

      if (data.sequence) url += `&sequence=${data.sequence.id}`

      const res = await Axios({
        url: url,
        method: 'get',
      });
      if (type === 'sent') setSent(res?.data?.data);
      if (type === 'reply') setReply(res?.data?.data);
      if (type === 'open') setOpen(res?.data?.data);
      if (type === 'click') setClick(res?.data?.data);
      if (type === 'unsubscribed') setUnsubscribed(res?.data?.data);
      if (type === 'bounce') setBounced(res?.data?.data);
      if (type === 'outbox') setOutbox(res?.data?.count);
    } catch (err) {
      console.log('ERROR ', err.response);
    }
  };

  return (
    <div title={data.title} className='csCard'>
      <div className='csCardTitle'>
        <UilAnalytics />
        {data.title}
      </div>
      <div>
        {data.users.length > 0 && (
          <i style={{ fontSize: 14, fontWeight: 400, marginLeft: 5, marginTop: 10 }}>
            ({data.users.map((user, index) => <span key={index}>
              {user.email || user.label}
              {index < data.users.length - 1 && ", "}
            </span>)})
          </i>
        )}
        {data.slug.includes('team-member-particular-sequence-activity') &&
          <i style={{ fontSize: 14, fontWeight: 400, marginLeft: 5, marginTop: 10 }}>
            ({data.users.email})
          </i>
        }
        {data.sequence && <i style={{ fontSize: 14, fontWeight: 400, marginLeft: 5, marginTop: 10 }}>({data.sequence.name})</i>}
      </div>
      <div style={{ marginTop: 10 }}>
        <Grid cols="3">
          {data?.options.map((opt, index) => {
            if (opt === 'replies')
              return (
                <StatBox key={`replies${index}`} title="Replies" value={reply} loading={loading} />
              );
            if (opt === 'opens')
              return <StatBox key={`opens${index}`} title="Opens" value={open} loading={loading} />;
            if (opt === 'clicks')
              return (
                <StatBox key={`clicks${index}`} title="Clicks" value={click} loading={loading} />
              );
            if (opt === 'unsubscribed')
              return (
                <StatBox
                  key={`unsubscribed${index}`}
                  title="Unsubscribes"
                  value={unsubscribed}
                  loading={loading}
                />
              );
            if (opt === 'sent')
              return <StatBox key={`sent${index}`} title="Sent" value={sent} loading={loading} />;
            if (opt === 'bounced')
              return (
                <StatBox
                  key={`bounced${index}`}
                  title="Bounced"
                  value={bounced}
                  loading={loading}
                />
              );
            if (opt === 'outbox') return <StatBox key={`outbox${index}`} title="Outbox" value={outbox} loading={loading} />;
          })}
        </Grid>
      </div>
    </div>
  );
}

function TaskStats({ data }) {
  const [tasks, setTasks] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log("DATA ", data)
    if (data) getAllLogs();
  }, []);

  const getAllLogs = async () => {
    setLoading(true);
    let dataArr = [];
    for (let i = 0; i < data.options.length; i++) {
      let dataItem = await getLogsCount(data.options[i]);
      dataArr.push(dataItem);
    }
    setTasks(dataArr);
    setLoading(false);
  };

  const getLogsCount = async (taskId) => {
    try {
      let url = `/api/cockpit-logs/count?date=${findDate(
        data.viewType
      )}&type=task&task=${taskId}`;

      if(data.sequence) url += `&sequence=${data.sequence.id}`;

      if (data.users.length > 0) {
        let users = data.users.map((user) => user.value);
        url += `&ownedby=${users.join(',')}`;
      }

      const res = await Axios({
        url: url,
        method: 'get',
      });
      // console.log(res?.data, taskId)
      return {
        title: res?.data?.taskTypes?.name,
        count: res?.data?.data,
      };
    } catch (err) {
      // console.log("ERROR ", err.response)
      return false;
    }
  };

  return (
    <div title={data.title} className='csCard'>
      <div className='csCardTitle'>
        <UilAnalytics />
        Task Stats
      </div>
      {data.sequence && <div>
        <i style={{ fontSize: 14, fontWeight: 400, marginLeft: 5, marginTop: 10 }}>({data.sequence.name})</i>
      </div>}
      <div style={{ marginTop: 10 }}>
        <Grid cols="3">
          {tasks?.map((opt) => {
            if (opt) return <StatBox title={opt.title} value={opt.count} loading={loading} />;
          })}
        </Grid>
      </div>
    </div>
  );
}

function StatBox({ title, value, icon, style, loading = false }) {
  const deviceWidth = useWindowResize();
  return <div className='statBox' style={{ ...style, width: deviceWidth > 768 ? '32%' : '48%' }}>
    <h1>
      {icon} {loading ? <UilSync className='spinner' style={{ marginInline: 'auto', marginBottom: 24 }} /> : value}
    </h1>
    <p>
      {title}
    </p>
  </div>
}

function MyMeetings({ title, data }) {
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) getMeetings();
  }, [data]);

  const getMeetings = async () => {
    try {
      const res = await Axios({
        url: '/api/meet/links',
        method: 'get',
      });
      // console.log("DATA ", res?.data?.events)
      setMeetings(res?.data?.events);
      setLoading(false);
    } catch (err) {
      console.log('ERROR ', err.response);
    }
  };

  const meetingsColumns = [
    {
      Header: 'Title',
      Footer: 'Title',
      accessor: 'title',
      disableSortBy: true,
    },
    {
      Header: 'Link',
      Footer: 'Link',
      accessor: 'link',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return (
          <a href={value} target="_blank">
            Join Meeting
          </a>
        );
      },
    },
  ];

  return (
    <div title={data.title} loading={loading}>
      <div className='csCardTitle'>
        <UilAnalytics />
        {data.title}
      </div>
      {meetings.length > 0 ? (
        <CustomTable
          pagination={false}
          hasFooter={false}
          columns={meetingsColumns}
          data={meetings}
          noBottomMargin={true}
        />
      ) : (
        <div
          onClick={() => {}}
          style={{ display: 'flex', textAlign: 'center' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              marginBottom: 10,
              marginTop: 10,
              width: '100%'
            }}
            className='csCard'
          >
            <img
              style={{ margin: 'auto', height: 200 }}
              src={EmptyState}
            />
            <span style={{ marginTop: 20 }}>No Upcoming Meetings Found</span>
          </div>
        </div>
      )}
    </div>
  );
}
