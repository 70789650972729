import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import axios from 'axios';
import { Loader } from 'components/lib';
import tracking_app_urls from 'assets/data/tracking_links';

const PreviewEmail = ({sender,to,subject,body,contact,emailSender,senders}) => {

    const [signature,setSignature] = useState("");
    const [meetLink,setMeetLink] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [content,setContent] = useState('');
    const [updatedContact,setUpdatedContact] = useState({
        ...contact,
        Sb_Day_Of_Week: DateTime.now().toFormat('EEEE'),
        Sl_Time_Of_Day: getTimeOfDay(DateTime.now().toFormat('H')),
        Sb_Current_Quarter: getQuarter(DateTime.now().month),
        Sb_Next_Quarter: getQuarter(DateTime.now().plus({ months: 3 }).month),
        Sb_Current_Year: DateTime.now().toFormat('yyyy'),
        Sb_Next_Year: DateTime.now().plus({ years: 1 }).toFormat('yyyy'),
        Sb_Current_Month: DateTime.now().toFormat('LLLL'),
        Sb_Next_Month: DateTime.now().plus({ months: 1 }).toFormat('LLLL'), })


    useEffect(()=>{
        const fetchData = async () => {
            setIsLoading(true)
            await getSignature(sender?.id);
            await fetchMeetingLink();
            setIsLoading(false)

        };
        fetchData();
        // setContent(makeEmail(body))
    },[])

    useEffect(()=>{
            setContent(makeEmail(body));
    },[signature,meetLink])

    function makeBodyTemplate(){
        return makeEmail(body)
    }

    function getTimeOfDay(hour) {
        if (hour >= 5 && hour < 12) return 'Morning';
        else if (hour >= 12 && hour < 18) return 'Afternoon';
        else if (hour >= 18 && hour < 21) return 'Evening';
        else return 'Night';
    }

    function getQuarter(month) {
        if (month >= 1 && month <= 3) return 'Q1';
        else if (month >= 4 && month <= 6) return 'Q2';
        else if (month >= 7 && month <= 9) return 'Q3';
        else return 'Q4';
    }

    function makeEmail(text) {
        let textMacros = text.match(/{{.*?}}/g);

        if (textMacros) {
        textMacros.map((macro) => {
            text = text.replaceAll(macro, getMacroValue(macro, updatedContact));
        });
         }
        return text;
    }

    function titleCase(str) {
        var splitStr = str.toLowerCase().split('_');
        for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join('_');
    }

    const getSingleBraceMacroValue = (macro, prospectData, task) => {
        let macroKeys = macro
        .replaceAll('{', '')
        .replaceAll('}', '')
        .trim()
        .split('|');
        macroKeys = macroKeys.map((macroKey) => macroKey.trim());
    
        for (let macroKey of macroKeys) {
        macroKey = titleCase(macroKey);
        let upperCaseMacroKey = macroKey.toUpperCase();
    
        if (prospectData[macroKey]) return prospectData[macroKey];
        else if (prospectData[upperCaseMacroKey]) return prospectData[upperCaseMacroKey];
        else if (macroKey == 'Unsubscribe_Link') {
            let trackingDomain = tracking_app_urls[
                Math.floor(Math.random() * tracking_app_urls.length)
              ];
    
            return `https://${trackingDomain}/unsubscribe/${task?.id}`;
        } else {
            let newMacroKey = [...macroKeys]
            let selectKey = newMacroKey[Math.floor(Math.random() * newMacroKey.length)];
            let titleCaseSelectedKey = titleCase(selectKey);
            let upperCaseSelectedKey = selectKey.toUpperCase();
    
            if (prospectData[titleCaseSelectedKey]) return prospectData[titleCaseSelectedKey];
            else if (prospectData[upperCaseSelectedKey]) return prospectData[upperCaseSelectedKey];
            else if (newMacroKey.length === 1) return "";
            else return macroKeys[macroKeys.length - 1];
        }
        }
        return '';
    };

    const getMacroValue = (macro, prospectData, task) => {
        prospectData['Name_Of_Sender'] = sender?.senderName || "";
        prospectData['Email_Signature_Of_Sender'] = signature || "";
        prospectData['My_Meeting_Link'] = `<a href=${meetLink} target="_blank">${meetLink}</a>` || "";

        macro = macro.replaceAll('{{', '').replaceAll('}}', '')
        let insideMacros = macro.match(/{.*?}/g);
        // console.log("INITIAL MACRO ", macro)
        if(insideMacros?.length > 0) {
        const uniqueSet = new Set(insideMacros);
        const newArray = Array.from(uniqueSet);
    
        newArray.map((nMacro) => {
            // console.log("INSIDE MACROS ", nMacro)
            macro = macro.replaceAll(nMacro, getSingleBraceMacroValue(nMacro, prospectData, task));
        });
        // console.log("ULTIMATE MACRO ", macro)
        }
        let macroKeys = macro
        .replaceAll('{', '')
        .replaceAll('}', '')
        .trim()
        .split('|');
        macroKeys = macroKeys.map((macroKey) => macroKey.trim());
    
        for (let macroKey of macroKeys) {
        macroKey = titleCase(macroKey);
        let upperCaseMacroKey = macroKey.toUpperCase();
    
        if (macroKey == 'Unsubscribe_Link') {
            let trackingDomain = tracking_app_urls[
                Math.floor(Math.random() * tracking_app_urls.length)
              ];
    
            if(prospectData['Unsubscribe_Link'] && prospectData['Unsubscribe_Link'] != '') trackingDomain = prospectData['Unsubscribe_Link']
            return `https://${trackingDomain}/unsubscribe/${task?.id}`;
        } 
        else if (prospectData[macroKey]) return prospectData[macroKey];
        else if (prospectData[upperCaseMacroKey]) return prospectData[upperCaseMacroKey];
        else {
            let newMacroKey = [...macroKeys]
            let selectKey = newMacroKey[Math.floor(Math.random() * newMacroKey.length)];
            let titleCaseSelectedKey = titleCase(selectKey);
            let upperCaseSelectedKey = selectKey.toUpperCase();
    
            if (prospectData[titleCaseSelectedKey]) return prospectData[titleCaseSelectedKey];
            else if (prospectData[upperCaseSelectedKey]) return prospectData[upperCaseSelectedKey];
            else if (newMacroKey.length === 1) return "";
            else return macroKeys[macroKeys.length - 1];
        }
        }
        return '';
    };

    const getSignature = async (senderID) => {
        try {
            const res = await axios.get(`/api/signature/${senderID}/sender`
            );
            if(res?.data?.data) setSignature(res?.data?.data?.content);
        } catch (error) {
            console.log('ERROR: ', error);
        }
    };

    const fetchMeetingLink =  async() => {
        try {
        const {data} = await axios.get('/api/meet');
        setMeetLink(`https://meet.salesblink.io/${data?.data?.meeting_slug}`)
        } catch (error) {
        console.log('error',error)
        }
    }


  if(isLoading) return <div className='' style={{height:'100px'}}><Loader/></div>

  return (
    <div>
        <div className="flex items-center to px-4" style={{gap:8}}>
            <p className='font-medium'>To:</p>
            <p>{to}</p>
        </div>
        <div className="flex items-center from px-4" style={{gap:8}}>
            <p className='font-medium'>From:</p>
            <p>{sender.senderName}  {sender.email}</p>
        </div>
        <div className="flex items-center subject  p-4 mt-4" style={{backgroundColor:"#e6e6e6",gap:8,borderRadius:10}}>
            <p className='font-medium'>Subject:</p>
            <p>{subject}</p>
        </div>
        <div className="body p-4 mt-4 emailView" style={{backgroundColor:"#e6e6e6",borderRadius:10}}>
            <p className='font-medium'>Body:</p>
            <div dangerouslySetInnerHTML={{__html:content}} className='mt-2'></div>
        </div>
    </div>
  )
}

export default PreviewEmail