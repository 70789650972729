import { useEffect } from "react";

export function Favicon({ url }) {
    useEffect(() => {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/png';
      link.rel = 'shortcut icon';
      link.href = url;
      document.getElementsByTagName('head')[0].appendChild(link);
    }, [url]);
  
    return null;
}