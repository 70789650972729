import React from 'react';
import './customButton.scss';

/*
    props
    -----
    type: link
    onClick: () => {},
    image: "imagename",
    title: Button Label,
    primary: true | false (default)
    classes: layout-reverse (reverses the image and title position)
    variant: danger | success | default (default)
*/
export default function CustomButton(props) {
  return (
    <button
      onClick={() => !props.disabled && props.onClick()}
      className={`customButton mt-2 py-2 px-4 ${
        props.className ? props.className : ''
      } ${props.variant ? props.variant : ''} ${
        props.disabled ? 'disabled' : ''
      }`}
      disabled={props.disabled}
      style={{ ...props.style }}
      id={props.id || ''}
    >
      {props.icon && <div>{props.icon}</div>}
      {!props.iconOnly && (
        <span style={{ display: 'block' }}>{props.title}</span>
      )}
    </button>
  );
}
