/***
 *
 *   UPGRADE
 *   Upgrade from a free to paid plan
 *
 **********/

import { Animate, Card, CardInput, Checkbox, usePlans } from 'components/lib';
import { useEffect, useState } from 'react';

export function Upgrade(props) {
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [cost, setCost] = useState(0);

  // context/data
  const plans = usePlans();
  const list = plans?.data?.list;

  let plan = new URLSearchParams(window.location.search).get('plan');

  if (list) {
    // remove free & set selected
    const index = list?.findIndex((x) => x.value === 'free');
    index > -1 && list.splice(index, 1);
    plan = plan ? plan : list[0].value;
  }

  useEffect(() => {
    let price = 0;
    for (let i = 0; i < selectedPlan.length; i++) {
      price += selectedPlan[i].price;
    }
    setCost(price);
  }, [selectedPlan]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Animate>
      <Card
        restrictWidth
        title="Upgrade Your Account"
        loading={plans.loading}
        className="upgrade-form"
      >
        <form onSubmit={handleSubmit}>
          <CardInput />
          <button type="submit">Pay</button>
        </form>
        {list?.map((item) => {
          if (item.name !== 'Trial') {
            return (
              <Checkbox
                name="billing"
                onChange={(i, checked, option) => {
                  let selected = selectedPlan.find(
                    (p) => p.value === item.value
                  );
                  if (selected)
                    setSelectedPlan(
                      selectedPlan.filter((p) => p.value !== item.value)
                    );
                  else {
                    let interval = selectedPlan.find(
                      (p) => p.interval === item.interval
                    );
                    if (selectedPlan.length === 0 || interval)
                      setSelectedPlan([...selectedPlan, item]);
                  }
                }}
                checked={
                  selectedPlan.find((p) => p.value === item.value)
                    ? true
                    : false
                }
                option={item.label}
              />
            );
          }
        })}
        <div>${cost}</div>
      </Card>
    </Animate>
  );
}
