import Axios from 'axios';
import * as chrono from 'chrono-node';
import { Button, Select, TextInput } from 'components/lib';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import Swal from 'sweetalert2';
import './viewseditor.scss';
import { UilPlus, UilBars, UilTrashAlt } from '@iconscout/react-unicons'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export default function ViewsEditor({
  view,
  allFields,
  onClose,
  taskTypes,
  onSave,
}) {
  const [viewObject, setViewObject] = useState(null);
  const [type, setType] = useState('');

  const [options, setOptions] = useState(null);
  const [task, setTask] = useState('');

  useEffect(() => {
    if (type !== '') {
      let opts = taskTypes.find((t) => t._id == type);
      setOptions(opts?.task_attributes);
      setTask(opts?._id);
    }
  }, [type]);

  useEffect(() => {
    setViewObject(view);
    if (view?.uid !== '') setType(view?.uid);
  }, []);

  let findDuplicates = (arr) =>
    arr?.filter((item, index) => arr.indexOf(item) != index);

  function updateViewObjectColumns(columns) {
    console.log('COLA ', columns);
    setViewObject({ ...viewObject, columns });
  }

  async function saveList() {
    try {
      if (findDuplicates(viewObject?.columns).length > 0)
        return Toast.fire({
          icon: 'error',
          title: "Can't add the same field twice!",
        });
      if (viewObject?.name.length < 3)
        return Toast.fire({
          icon: 'error',
          title: 'Please enter a valid list name.',
        });
      if (viewObject?.columns.length < 1)
        return Toast.fire({
          icon: 'error',
          title: 'Atleast one column is required.',
        });
      let body = {
        type: 'task',
        name: viewObject?.name,
        uid: task,
        columns: viewObject?.columns.map((col) => col.replace(' ', '_')),
      };

      let link = '/api/views';
      let method = 'post';
      if (!viewObject?.new) {
        console.log('Test New ', viewObject);
        link = '/api/views/' + viewObject?._id;
        method = 'put';
      }
      await Axios({
        url: link,
        method: method,
        data: body,
      });
      onSave(viewObject?.new);
    } catch (err) {
      console.log('Error in saving view ', err);
      return Toast.fire({ icon: 'error', title: 'Error in saving List!' });
    }
  }

  return (
    <div>
      <h2>
        <b>Editing View</b> {viewObject?.name}
      </h2>
      <TextInput
        required={true}
        label="View Name"
        value={viewObject?.name}
        onChange={(name, value) => {
          setViewObject((oldViewObj) => ({ ...oldViewObj, name: value }));
        }}
        placeholder="Enter a View Name"
      />
      <Select
        options={[{ _id: '', name: 'Select Task Type' }].concat(taskTypes)}
        value={type}
        onChange={(n, value) => setType(value)}
        valueField="_id"
        labelField="name"
      />
      <Row>
        <Col sm={12}>
          {options && viewObject && (
            <CustomFields
              options={options}
              setColumns={updateViewObjectColumns}
              columns={viewObject.columns}
              task={task}
            />
          )}
        </Col>
      </Row>
      <Row>
        <div
          style={{
            display: 'flex',
            margin: '0 16px',
            marginTop: 11,
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <Button
            style={{ marginLeft: 'auto', marginRight: 5 }}
            variant="primary grey"
            text="Cancel"
            action={() => onClose()}
            />
          <Button
            variant="primary"
            style={{ marginLeft: 15 }}
            text="Save"
            action={() => saveList()}
          />
        </div>
      </Row>
    </div>
  );
}

function CustomFields({ options, columns, setColumns, task }) {
  const [fields, setFields] = useState([]);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  useEffect(() => {
    setFields([]);
  }, [task]);

  useEffect(() => {
    if (!columns) return;
    let newCol = columns?.map((column) => {
      let newField = {
        id: `i-${Math.floor(Math.random() * 1000)}`,
        type: column.replace('_', ' '),
        task: task,
      };
      return newField;
    });
    setFields(newCol);
  }, []);

  function addCustomField(type) {
    let newField = {
      id: `i-${Math.floor(Math.random() * 1000)}`,
      type: type || options.length > 0 ? options[0].attribute : '',
      task: task,
    };
    setFields((oldFields) => [...oldFields, newField]);
  }

  function deleteItem(id) {
    setFields((oldFields) => oldFields.filter((field) => field.id !== id));
  }

  useEffect(() => {
    let newColumns = fields.map((fields) => fields.type);
    setColumns(newColumns);
  }, [fields]);

  function updateField(id, key, value) {
    setFields((oldFields) => {
      let newFields = oldFields.map((field) => {
        if (field.id === id) {
          field[key] = value;
        }
        return field;
      });
      return newFields;
    });
  }

  function handleSort() {
    let _fields = [...fields];
    const draggedItemContent = _fields.splice(dragItem.current, 1)[0];
    _fields.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    setFields(_fields);
  }

  function onDragEnd(e, index) {
    handleSort();
  }

  return (
    <div className="csCustomFieldsContainer">
      {fields?.map((field, index) => (
        <CustomFieldItem
          key={index}
          options={options}
          dragStart={(e) => (dragItem.current = index)}
          dragEnter={(e) => (dragOverItem.current = index)}
          dragEnd={(e) => onDragEnd(e, index)}
          onChange={updateField}
          id={field.id}
          type={field.type}
          onDelete={(id) => deleteItem(id)}
        />
      ))}
      <div onClick={() => addCustomField()} className="csCustomFieldsAddButton">
        <UilPlus size={24} style={{ marginRight: 10 }} />
        Add Field
      </div>
    </div>
  );
}

function CustomFieldItem(props) {
  return (
    <div
      onDragOver={(e) => e.preventDefault()}
      onDragStart={props.dragStart}
      onDragEnter={props.dragEnter}
      onDragEnd={props.dragEnd}
      draggable
    >
      <div className="csRow">
        <div className="csCustomFieldButton csCustomHandle csCustomFieldItemDrag">
          <UilBars style={{ margin: 'auto' }} size={20} />
        </div>
        <div className="csCustomFieldItemCol">
          <select
            onChange={(e) => props.onChange(props.id, 'type', e.target.value)}
            className="csCustomFieldButton"
            style={{ width: '100%' }}
          >
            {props?.options?.map((option) => (
              <option
                key={option.attribute}
                selected={option.attribute === props.type}
              >
                {option?.attribute?.replace('_', ' ')}
              </option>
            ))}
          </select>
        </div>
        <div
          onClick={() => props.onDelete(props.id)}
          className="csCustomFieldButton csCustomFieldItemRemove"
        >
          <UilTrashAlt style={{ margin: 'auto' }} size={20} />
        </div>
      </div>
    </div>
  );
}

export function SnoozeTask(props) {
  const [value, setValue] = useState(new Date());
  const [custom, setCustom] = useState('');

  const snoozeTaskForLater = () => {
    let snoozeTillTime = value;
    if (custom.trim() !== '') {
      let cusTime = chrono.parseDate(custom, new Date(), { forwardDate: true });
      if (cusTime) snoozeTillTime = cusTime;
    }
    props.onSave(
      props.selectedTask.id,
      { scheduled_time: new Date(snoozeTillTime).getTime() },
      'Task snoozed for the time.'
    );
    props.onClose();
  };

  return (
    <div>
      <h2>
        <b>Snooze Until</b>
      </h2>
      <Row>
        <Col sm={12} style={{ marginTop: 10 }}>
          <TextInput
            required={true}
            value={custom}
            onChange={(name, val) => {
              setCustom(val);
            }}
            placeholder="mon, jan 15 or jan 16 12:00 pm"
          />
        </Col>
        <Col sm={12} style={{ marginTop: 10, textAlign: 'center' }}>
          <strong style={{}}>OR</strong>
        </Col>
        <Col sm={12} style={{ margin: '10px 0' }}>
          <TextInput
            type="datetime-local"
            value={value}
            onChange={(name, val) => {
              setValue(val);
            }}
          />
        </Col>
      </Row>
      <Row>
        <div
          style={{
            display: 'flex',
            margin: '0 16px',
            marginTop: 11,
            justifyContent: 'end',
            width: '100%',
            gap: "2rem"
          }}
        >
          <Button
            variant="primary grey"
            action={() => props.onClose()}
            text="Cancel"
            />
          <Button
            variant="primary"
            action={() => snoozeTaskForLater()}
            text="Save"
          />
        </div>
      </Row>
    </div>
  );
}
