import React, { useEffect, useState } from 'react';
import './sourceconditions.scss';
import { UilTimes } from '@iconscout/react-unicons'

export default function Condition(props) {
  const [conditionType, setConditionType] = useState(null);
  const [conditionValue, setConditionValue] = useState(null);

  useEffect(() => {
    if(props.values?.type) setConditionType(props.values.type)
    if(props.values?.value) setConditionValue(props.values.value)
  }, [props.values])

  useEffect(() => {
    if (
      props.values.type === conditionType &&
      props.values.value === conditionValue
    )
      return;
    props.onChange({
      id: props.values.id,
      type: conditionType,
      value: conditionValue,
    });
  }, [conditionType, conditionValue]);

  return (
    <div key={props.id} className="triggerBox">
      <select
        className="triggerBoxMargin"
        style={{ backgroundColor: '#f9f9f9' }}
        onChange={(event) => setConditionType(event.target.value)}
        value={conditionType}
      >
        <option value="">Select Event Trigger</option>
        {(!props.conditionFor || props.conditionFor === 'source_entry_list' || props.conditionFor === 'source_entry_condition' || props.conditionFor === 'source_entry_attribute'|| props.conditionFor === 'source_entry_integration' || props.conditionFor === 'email') && <option value="email_open">Email Open</option>}
        {(!props.conditionFor || props.conditionFor === 'source_entry_list' || props.conditionFor === 'source_entry_condition' || props.conditionFor === 'source_entry_attribute'|| props.conditionFor === 'source_entry_integration' || props.conditionFor === 'email') && <option value="email_reply">Email Reply</option>}
        {(!props.conditionFor || props.conditionFor === 'source_entry_list' || props.conditionFor === 'source_entry_condition' || props.conditionFor === 'source_entry_attribute'|| props.conditionFor === 'source_entry_integration' || props.conditionFor === 'email') && <option value="email_link_click">Email Link Click</option>}
        {(!props.conditionFor || props.conditionFor === 'source_entry_list' || props.conditionFor === 'source_entry_condition' || props.conditionFor === 'source_entry_attribute'|| props.conditionFor === 'source_entry_integration' || props.conditionFor === 'task') && <optgroup label="Task Conditions">
          {props?.taskConditions?.map((taskCondition) => (
            <option value={taskCondition._id}>{taskCondition.name}</option>
          ))}
        </optgroup>}
      </select>
      {(props.conditionFor !== 'email' && props.conditionFor !== 'task') && <select
        className="triggerBoxMargin"
        style={{ backgroundColor: '#f9f9f9' }}
        onChange={(event) => setConditionValue(event.target.value)}
        value={conditionValue}
      >
        <option value="">- Select Value -</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>}
      <div className='flex gap-2 w-full'>
        <button
          className="closeButton"
          style={{ flexGrow: 1 }}
          appearance="primary"
          onClick={() => props.onAdd()}
        >
          +OR
        </button>
        <button
          className="closeButton flex justify-center items-center"
          style={{ flexGrow: 1 }}
          appearance="primary"
          onClick={() => props.onDelete(props?.values?.id)}
        >
          <UilTimes />
        </button>
      </div>
    </div>
  );
}
