import Axios from 'axios';
import { Button, TextInput } from 'components/lib';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import Swal from 'sweetalert2';
import './viewseditor.scss';
import { UilPlus, UilBars, UilTrashAlt } from '@iconscout/react-unicons'

export default function ViewsEditor({
  view,
  allFields,
  onClose,
  listID,
  onSave,
  name
}) {
  const [viewObject, setViewObject] = useState(null);

  useEffect(() => {
    setViewObject(view);
  }, []);

  let findDuplicates = (arr) =>
    arr?.filter((item, index) => arr.indexOf(item) != index);

  function updateViewObjectColumns(columns) {
    setViewObject((oldVO) => ({
      name: oldVO.name,
      columns,
      new: viewObject.new,
    }));
  }

  async function saveList() {
    if (!viewObject || !viewObject?.name || viewObject?.name.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter a valid view name',
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
      return;
    }
    if (
      !viewObject ||
      !viewObject?.columns ||
      viewObject?.columns?.length === 0
    ) {
      Swal.fire({
        icon: 'error',
        title: 'At least one column is required!',
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
      return;
    }
    if (findDuplicates(viewObject?.columns).length > 0) {
      Swal.fire({
        icon: 'error',
        title: "Can't add the same field twice!",
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
      return;
    }
    try {
      let body = {
        id: listID,
        name: viewObject?.name,
        columns: viewObject?.columns.map((col) => col.replace(' ', '_')),
      };

      await Axios.post(`/api/list/${listID}/views`, body);
      if (onSave) onSave(viewObject?.name);
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error in saving List!',
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
      console.log('Error in saving view ', err);
    }
  }

  return (
    <div>
      <h1 className="text-modal-header">{viewObject?.new ? `Create New View ${name ? "for " + name : ""}` : 'Editing View'}</h1>
      <p className="text-modal-description">
        Create views to view specific details of your leads.
      </p>
      <div style={{ marginLeft: 15, marginRight: 15 }}>
        {viewObject?.new && (
          <TextInput
            required={true}
            label="View Name"
            value={viewObject?.name}
            onChange={(name, value) => {
              setViewObject((oldViewObj) => ({ ...oldViewObj, name: value }));
            }}
            placeholder="Enter a View Name"
          />
        )}
        <Row>
          <Col sm={12} style={{ display: 'inherit' }}>
            {allFields && viewObject && (
              <CustomFields
                options={allFields}
                setColumns={updateViewObjectColumns}
                columns={viewObject.columns}
              />
            )}
          </Col>
        </Row>

        {/* do not remove marginInline else it'll overflow */}
        <Row
          justify="flex-end"
          style={{ marginTop: 32, marginInline: 0, gap: 20 }}
        >
          <Button
            variant="primary grey"
            text="Cancel"
            action={() => onClose()}
          />
          <Button
            text="Save View"
            variant="primary"
            action={() => saveList()}
          />
        </Row>
      </div>
    </div>
  );
}

function CustomFields({ options, columns, setColumns }) {
  const [fields, setFields] = useState([]);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  useEffect(() => {
    if (!columns) return;
    columns?.forEach((column) => {
      addCustomField(column);
    });
  }, []);

  function addCustomField(type) {
    let newField = {
      id: `i-${Math.floor(Math.random() * 1000)}`,
      type: type || 'First Name',
    };
    setFields((oldFields) => [...oldFields, newField]);
  }

  function deleteItem(id) {
    setFields((oldFields) => oldFields.filter((field) => field.id !== id));
  }

  useEffect(() => {
    let newColumns = fields.map((fields) => fields.type);
    setColumns(newColumns);
  }, [fields]);

  function updateField(id, key, value) {
    setFields((oldFields) => {
      let newFields = oldFields.map((field) => {
        if (field.id === id) {
          field[key] = value;
        }
        return field;
      });
      return newFields;
    });
  }

  function handleSort() {
    let _fields = [...fields];
    const draggedItemContent = _fields.splice(dragItem.current, 1)[0];
    _fields.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    setFields(_fields);
  }

  function onDragEnd(e, index) {
    handleSort();
  }

  return (
    <div className="csCustomFieldsContainer">
      {fields?.map((field, index) => (
        <CustomFieldItem
          options={options}
          dragStart={(e) => (dragItem.current = index)}
          dragEnter={(e) => (dragOverItem.current = index)}
          dragEnd={(e) => onDragEnd(e, index)}
          onChange={updateField}
          id={field.id}
          type={field.type}
          onDelete={(id) => deleteItem(id)}
        />
      ))}
      <div onClick={() => addCustomField()} className="csCustomFieldsAddButton">
        <UilPlus size={24} style={{ marginRight: 10 }} />
        Add Column
      </div>
    </div>
  );
}

function CustomFieldItem(props) {
  return (
    <div
      onDragOver={(e) => e.preventDefault()}
      onDragStart={props.dragStart}
      onDragEnter={props.dragEnter}
      onDragEnd={props.dragEnd}
      draggable
      style={{ width: '100%' }}
    >
      <div className="csRow">
        <div className="csCustomFieldButton csCustomHandle csCustomFieldItemDrag">
          <UilBars style={{ margin: 'auto' }} size={20} />
        </div>
        <div className="csCustomFieldItemCol">
          <select
            onChange={(e) => props.onChange(props.id, 'type', e.target.value)}
            className="csCustomFieldButton"
            style={{ width: '100%' }}
          >
            {props?.options?.map((option) => (
              <option selected={option === props.type}>
                {option?.replace('_', ' ')}
              </option>
            ))}
          </select>
        </div>
        <div
          onClick={() => props.onDelete(props.id)}
          className="csCustomFieldButton csCustomFieldItemRemove"
        >
          <UilTrashAlt style={{ margin: 'auto' }} size={20} />
        </div>
      </div>
    </div>
  );
}
