import React from 'react';
import Style from './helper.tailwind.js';
import { UilQuestionCircle } from '@iconscout/react-unicons'

export function Helper(props) {
  return (
    <div className={Style.helper}>
      <UilQuestionCircle 
        color="#73B0F4"
        size={20}
        className={Style.icon}
      />

      {props.url ? (
        <a href={props.url} className={Style.link}>
          {props.text}
        </a>
      ) : (
        <span>{props.text}</span>
      )}
    </div>
  );
}
