import {
  UilQuestionCircle,
  UilSync,
  UilUploadAlt,
  UilRedo,
  UilExclamationTriangle,
  UilTimes,
  UilImage,
  UilLinkBroken,
  UilBracketsCurly,
  UilAngleRight,  
} from '@iconscout/react-unicons';
import axios from 'axios';
import BundledEditor from 'components/custom/buldledEditor';
import CustomModal from 'components/custom/customModal';
import {
  Label,
  Loader,
  Button,
  TextInput,
  useAPI,
  useNavigate,
  Dropdown,
  CustomTable,
  AuthContext,
} from 'components/lib';
import CustomButton from 'components/custom/customButton';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import './createTemplate.scss';
import { usePixieContext } from './pixieContext';
import CustomDialog from 'components/custom/customDialog';
import { Row, Col } from 'react-grid-system';
import { fabric } from 'fabric';
import { useDropzone } from 'react-dropzone';
import { readingTime } from 'reading-time-estimator';
import spam_checker from '../../data/spam_checker';
import { Tooltip } from 'react-tooltip';
import AsyncSelect from 'react-select/async';
import RCSelect from 'react-select';
import BlinkGPT from "../../assets/BlinkGPT-filled.png";
import languagesList from "../../data/languages";
import PreviewEmail from './previewEmail';
import frames from 'data/framesData';
import { components } from 'react-select';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

function generateRandomId(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';

  for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
  }

  return randomId;
}

function SpamChecker({ blogContent }) {
  const ERRORS = [
    {
      title: 'Urgency',
      icon: '🚫',
      backgroundColor: '#F5D3D4',
      borderColor: '#EF898C',
      count: 0,
      words: [],
    },
    {
      title: 'Shady',
      icon: '🕵🏽‍♂️',
      backgroundColor: '#FED3B0',
      borderColor: '#FDA761',
      count: 0,
      words: [],
    },
    {
      title: 'Over Promise',
      icon: '😈',
      backgroundColor: '#FBD6E5',
      borderColor: '#F484B2',
      count: 0,
      words: [],
    },
    {
      title: 'Money',
      icon: '🤑',
      backgroundColor: '#C7EFEA',
      borderColor: '#51E3D1',
      count: 0,
      words: [],
    },
    {
      title: 'Unnatural',
      icon: '🤖',
      backgroundColor: '#E3CCF8',
      borderColor: '#9F52E6',
      count: 0,
      words: [],
    },
  ];
  const [errors, setErrors] = useState(ERRORS);
  const [score, setScore] = useState({
    overallScore: 'start writing',
    words: 0,
    readTime: '0 seconds',
    errorsCount: 0,
  });
  
  useEffect(() => {
    const content = (blogContent || '')?.replace(/<\/?[^>]+(>|$)/g, '')?.replaceAll('&nbsp;', ' ')?.replaceAll('\n', ' ');
    const words = content.split(' ');
    const score = ['Perfect', 'Good', 'Good', 'Okay', 'Okay'];
    const spamWords = [];

    // find all the spam words
    for (const word of words) {
      const spamWord = spam_checker.spam_words.find(
        (spam_word) => spam_word.Keyword.toLowerCase() === word.toLowerCase()
      );

      if (spamWord) spamWords.push(spamWord);
    }

    // create errros out of all the spam word
    for (const spamWord of spamWords) {
      const category = ERRORS.find(
        (error) => error.title === spamWord.Category
      );
      category.count += 1;
      category.words.push(spamWord.Keyword.toLowerCase());
    }

    let overAllScore = () => {
      if(words.length < 30) return "Write More..."
      if(spamWords.length === 0 || (spamWords.length > 0 && spamWords.length < 12)) {
        if(words.length >= 30 && words.length < 200) return "Perfect"
        if(words.length >= 200 && words.length < 500) return "Good"
      } else if(spamWords.length >= 12 && spamWords.length < 25) {
        if(words.length >= 30 && words.length < 200) return "Good"
        if(words.length >= 200 && words.length < 500) return "Poor"
      } else if(spamWords.length >= 25) return "Poor"
      return "Good"
    }

    setErrors(ERRORS);
    setScore({
      score,
      overallScore: overAllScore(),
      words: words.length - 1,
      readTime: readingTime(content, 150)?.text?.replace(' read', ''),
    });
  }, [blogContent]);

  return (
    <div className="spamBody">
      <h2>Spam Checker</h2>
      <p>
        Reads through your email template body and checks for keywords that
        increase chances of landing in spam.
      </p>
      <br />
      <div className="spamFlexBox mb-4">
        <div className="spamInfoTitle">Overall Score</div>
        <div className="spamInfoValue">{score.overallScore}</div>
      </div>
      <div className="spamFlexBox mb-4">
        <div className="spamInfoTitle">Words</div>
        <div className="spamInfoValue">{score.words}</div>
      </div>
      <div className="spamFlexBox">
        <div className="spamInfoTitle">Read Time</div>
        <div className="spamInfoValue">{score.readTime}</div>
      </div>
      <br />
      <div>
        {errors.map((error, index) => (
          <div key={index}>
            <div
              className="spamCheckerBox"
              key={error.title}
              id={error.title.replaceAll(' ', '_')}
              style={{
                backgroundColor: error.backgroundColor,
                borderColor: error.borderColor,
              }}
            >
              {error.icon}
              <span style={{ marginLeft: 5 }}> {error.title} </span>
              <span
                style={{
                  marginLeft: 'auto',
                  borderRadius: 5,
                  border: `1px solid ${error.borderColor}`,
                  backgroundColor: '#f9f9f9',
                  padding: '1px 7px',
                  marginTop: '-5px',
                }}
              >
                {error.count}
              </span>
            </div>
            {<Tooltip anchorSelect={`#${error.title.replaceAll(' ', '_')}`} place="bottom">
                {error.words.length > 0 ? error.words.join(',') : `No ${error.title} Keywords in Email.`}
              </Tooltip>}
          </div>
        ))}
      </div>
    </div>
  );
}

let TEN_MB = 10485760;

function MyDropzone({ items, onChange, setItems }) {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    if (acceptedFiles[0].size > TEN_MB)
      return Swal.fire({
        icon: 'error',
        title: `Oops! File should be smaller than 10 MB`,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      })
        .then((result) => {})
        .catch((err) => {
          console.log('Error in saving ', err);
        });

    setItems((oldItems) => [...oldItems, acceptedFiles[0]]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  if (items.length === 3) {
    return <></>;
  }

  return (
    <div className="attachmentDropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <div>
          <div
            className="attachmentsFileDropper"
            style={{
              height: 170,
              border: '1px solid #D7D7D7',
              cursor: 'pointer',
              borderRadius: 5,
              textAlign: 'center',
              padding: '10px 25px',
              backgroundColor: '#f2f2f2',
            }}
          >
            <div style={{ display: 'flex' }}>
              <span style={{ display: 'block', margin: 'auto' }}>
                <UilUploadAlt size={28} />
              </span>
            </div>
            <p style={{ marginTop: 25, fontSize: 16, fontWeight: 600 }}>
              Drop the file here
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              border: '1px solid #D7D7D7',
              display: 'flex',
              cursor: 'pointer',
              borderRadius: 5,
              textAlign: 'center',
              padding: '10px 20px',
              backgroundColor: '#f2f2f2',
            }}
          >
            <div style={{ display: 'flex' }}>
              <span style={{ display: 'block', margin: 'auto' }}>
                <UilUploadAlt size={28} />
              </span>
            </div>
            <div>
              <p style={{ marginLeft: 10, fontSize: 16, fontWeight: 600 }}>
                Drag 'n' Drop upto 3 files as attachments.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function Attachments(props) {
  const removeAttachment = (name) => {
    props.onRemove(name);
    props.onChange((oldItems) => oldItems.filter((_item) => _item.name !== name));
  };

  function formatSize(count, decimal = 0, level = 0) {
    let unitList = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PT'];

    if (count >= 1024.0 && level + 1 < unitList.length) {
      return formatSize(count / 1024, decimal, ++level);
    }
    return `${decimal ? count.toFixed(decimal) : Math.round(count)}${
      unitList[level]
    }`;
  }

  return (
    <div className="attachmentContainer">
      <div style={{ marginBottom: 10 }}>
        <b>Add Attachments</b>
      </div>
      {props.items?.map((item) => (
        <div className="attachmentItem">
          <div className="attachmentItemContent">
            <div>
              <b>{item.name}</b>
            </div>
            <div>{formatSize(item.size)}</div>
          </div>
          <div>
            <button className='flex items-center justify-center' onClick={() => removeAttachment(item.name)}>
              <UilTimes />
            </button>
          </div>
        </div>
      ))}
      {props.items.length >= 3 && <div className='font-semibold text-center'>
        Max 3 attachments in an email allowed.
      </div>}
      <MyDropzone items={props.items} setItems={(val) => {
        if(props.items.length >= 3) return Toast.fire({ icon: 'error', title: 'You can add max 3 attachments.' });
        props.onChange(val)
        props.onNew()
      }} />
    </div>
  );
}

function SendTestEmail({ close, emailBody, emailSubject, personalizedImages, lists, fetchLists, defaultEmail, templateID, attachments }) {
  const [emailSender, setEmailSender] = useState('');
  const [senders, setSenders] = useState([]);
  const [email, setEmail] = useState('');
  const [list, setList] = useState(null);
  const [columns, setColumns] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState([]);
  const [loading, setLoading] = useState(false);
  const [personalizedImageData, setPersonalizedImageData] = useState(personalizedImages)
  const [isPreview,setIsPreview] = useState(false);

  const [ownedBy, setOwnedBy] = useState('');
  const authContext = useContext(AuthContext);

  useEffect(() => {
    setOwnedBy(authContext.user.id);
  }, []);

  useEffect(() => {
    if(ownedBy && ownedBy.length>0) reloadSenders('');
  }, [ownedBy])

  const getContent = () => {
    let content = emailBody;
    let newPersonalizedImages = [];
    if(personalizedImageData && personalizedImageData.length > 0) {
      for (let personalizedImage of personalizedImageData) {
        let newImageData = personalizedImage;
        let dataToReplace = new RegExp(
          `<img[^>]*?data-id="${personalizedImage.id}"[^>]*?>`,
          'g'
        );
        let match = dataToReplace.exec(content);
        let width, height;
        if (match) {
          // Regular expressions to extract width and height attributes
          const widthRegex = /width="(\d+)"/;
          const heightRegex = /height="(\d+)"/;

          // Extract width and height using regular expressions
          const widthMatch = match[0].match(widthRegex);
          const heightMatch = match[0].match(heightRegex);

          if (widthMatch && heightMatch) {
            width = parseInt(widthMatch[1]);
            height = parseInt(heightMatch[1]);
            newImageData['width'] = width;
            newImageData['height'] = height;
          }
        }
        content = content?.replace(
          dataToReplace,
          `<image_dynamic id="${personalizedImage.id}" ${width? `width=${width}` :""} ${height? `height=${height}` :""}>`
        );
        newPersonalizedImages.push(newImageData)
      }
      setPersonalizedImageData(oldPersonalizedImages => newPersonalizedImages)
    }

    return content;
  };

  const sendEmail = async () => {
    if (!emailSubject || emailSubject === '')
      return Toast.fire({ icon: 'error', title: 'Email subject required.' });
    if (!emailBody || emailBody === '')
      return Toast.fire({ icon: 'error', title: 'Email body required.' });
    if (emailSender === '')
      return Toast.fire({ icon: 'error', title: 'Email sender required.' });
    if (email === '')
      return Toast.fire({ icon: 'error', title: 'Email required.' });
    
    let regEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if(!regEmail.test(email)) return Toast.fire({ icon: 'error', title: 'Invalid email.' });

    setLoading(true);
    let data = {
      email,
      sender: emailSender,
      emailBody: getContent(emailBody),
      emailSubject,
    }
    
    if(contact.length > 0) data.contact = contact[0]._id

    if(attachments.length > 0) data.testAttachments = true

    if (personalizedImageData) data.imageState = personalizedImageData.map((personalizedImage) => ({
          id: personalizedImage.id,
          imageState: personalizedImage.imageState,
          width: personalizedImage.width,
          height: personalizedImage.height
        }));
    try {
      await axios.post(`/api/template/${templateID || 'testid'}/send-test-email`, data);
      Toast.fire({ icon: 'success', title: 'Test email sent, check your inbox/spam.' });
      setLoading(false);
      close();
    } catch (err) {
      setLoading(false);
      return Toast.fire({ icon: 'error', title: 'Test email not sent. Please try again.' });
    }
  };

  useEffect(() => {
    if (senders)
      setEmailSender(senders.length > 0 ? senders[0]._id : '');
    if(defaultEmail) setEmail(defaultEmail)
  }, [senders]);

  async function reloadSenders(inputValue) {
    try {
      let result
      if((authContext.user.permission === 'owner' || authContext.user.permission === 'admin')){
        result = await axios.get(`/api/senders?limit=10&skip=0&search=${inputValue}`);
      } else {
        result = await axios.get(`/api/senders?limit=10&skip=0&search=${inputValue}&ownedby=${ownedBy}`);
      }
      if (result?.data?.success) {
        let senderList = result.data.data.map((sender) => ({
          key: sender.id,
          label: `${
            sender.alias ||
            sender.microsoft_email ||
            sender.google_email ||
            sender.smtpImapEmail
          }`,
          value: sender._id,
          senderName:sender.senderName,
          id:sender.id
        }))
        setSenders(senderList);
        return senderList
      }

      return []
    } catch (err) {
      return []
    }
  }

  const fetchContacts = async (inputValue) => {
    try {
      let result = await axios.get(
        `/api/list/${list}/contacts?limit=10&search=${inputValue}`
      );
      const data = result?.data?.data;
      setContacts(data);
      return data;
    } catch (err) {
      console.log('Error in Fetching contacts ', err);
      return [];
    }
  };

  useEffect(()=>{
    if(list && list.length>0){
      fetchContacts('')
    }
  },[list])

  

  if (loading) return <Loader />;
  return (
    <div style={{ padding: 15 }}>
      {!isPreview &&
      <>
        <Row>       
          <Col xs={12} lg={12}>
            <div>
              <label style={{ marginTop: 10, display: "flex" }}>
                Select Email Sender(s)  
              </label>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <AsyncSelect
                  placeholder="Type to Search Sender"
                  styles={{
                    control: () => ({
                      padding: 5,
                      width: 750,
                      borderRadius: 5,
                      display: 'flex',
                      border: '1px solid #d7d7d7'
                    }),
                  }}
                  value={senders.find((item)=>{if(item.value === emailSender){
                    return item
                  }})}
                  defaultOptions={senders}
                  loadOptions={reloadSenders}
                  onChange={(values, currentValue) => setEmailSender(values.value)}
                  noOptionsMessage={() => 'Search for an Email Sender'}
                />
              </div>
            </div>

          </Col>
        </Row>
        <Row style={{ marginTop: 15 }}>
          <Col xs={12} lg={12}>
            <Label text="Send Test Email To:" />
            <TextInput
              type="email@company.com"
              value={email}
              onChange={(n, value) => setEmail(value)}
            />
          </Col>
          <Col xs={12} lg={12} className='mt-2'>
            <Label text="Select a List to load Variables" />
            <AsyncSelect
              styles={{
                control: () => ({
                  padding: 1,
                  minWidth: 500,
                  width: "100%",
                  maxWidth: 800,
                  borderRadius: 5,
                  backgroundColor: '#f9f9f9',
                  display: 'flex',
                  border: '1px solid #D7D7D7',
                }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999, // Adjust this value to be higher than the modal's zIndex
                }),
              }}
              className="bg-gray-100 hover:bg-gray-200 mb-2 mr-2 text-gray-800 rounded-full inline-flex"
              placeholder="Select a List to load Variables"
              value={lists.find((item)=>{if(item.id === list){
                return item
              }})}
              defaultOptions={lists}
              isClearable
              menuPortalTarget={document.body}
              loadOptions={fetchLists} // Function to fetch list options asynchronously
              getOptionValue={(option) => option?.id} // Define how to get the value of each option
              getOptionLabel={(option) => option?.name} // Define how to get the label of each option
              onChange={(values, currentValue) => {
                setContact([])
                setList(values?.id);
                let currentView = values?.views?.find(
                  (viewItem) => viewItem.name === 'Complete View'
                );
                let cols = []

                currentView?.columns?.forEach((currentViewItem) => {
                  cols.push({
                    Header: currentViewItem.replace('_', ' '),
                    Footer: currentViewItem.replace('_', ' '),
                    disableSortBy: true,
                    accessor: currentViewItem,
                    Cell: ({ row, value }) => {
                      return value
                    },
                  });
                  return;
                });

                setColumns(cols)
                  
          
              }}
              noOptionsMessage={() => 'No Results, Type to Search'}
            />
          </Col>
          {list && contacts.length > 0 && <Col xs={12} lg={12}>
            <Label text="Search for a lead email to load data" />
            <AsyncSelect
              styles={{
                control: () => ({
                  padding: 1,
                  minWidth: 500,
                  width: "100%",
                  maxWidth: 800,
                  borderRadius: 5,
                  backgroundColor: '#f9f9f9',
                  display: 'flex',
                  border: '1px solid #D7D7D7',
                }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999, // Adjust this value to be higher than the modal's zIndex
                }),
              }}
              className="bg-gray-100 hover:bg-gray-200 mb-2 mr-2 text-gray-800 rounded-full inline-flex"
              placeholder="Search for a lead email to load data"
              defaultOptions={contacts}
              value={contact.length > 0 ? contact[0] : null}
              isClearable
              menuPortalTarget={document.body}
              loadOptions={fetchContacts} // Function to fetch contacts options asynchronously
              getOptionValue={(option) => option?._id} // Define how to get the value of each option
              getOptionLabel={(option) => option?.Email} // Define how to get the label of each option
              onChange={(values, currentValue) => {
                if(values) setContact([values]);
                else setContact([]);
              }}
              noOptionsMessage={() => 'No Results, Type to Search'}
            />
          </Col>}
          {contact.length > 0 && <Col xs={12} lg={12}>
            <CustomTable
              hasFooter={false}
              columns={columns}
              data={contact}
              onClick={(row) => {}}
            />
          </Col>}
        </Row>
      </>
      }
      {
        isPreview && 
        <PreviewEmail 
        subject={emailSubject} 
        body={emailBody} 
        sender={{
          email:senders.find(email=>email.value===emailSender)?.label,senderName:senders.find(email=>email.value===emailSender)?.senderName,
          id:senders.find(email=>email.value===emailSender)?.id}} 
        to={email} 
        contact={contact[0]}
        contacts={contacts}
        emailSender={emailSender}
        senders={senders}
        />
      }
      <div className='flex justify-between' style={{ marginTop: '1rem' }}>
        <Button
          variant="secondary"
          action={() => setIsPreview(!isPreview)}
          text={isPreview ? "Back" : "Preview Email"}
          />
        <Button
          variant="primary"
          action={() => sendEmail()}
          text="Send Test Email"
          />
      </div>
    </div>
  );
}

export default function CreateTemplate(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [spintaxValue, setSpintaxValue] = useState('');
  const [isSpintaxModalOpen, setIsSpintaxModalOpen] = useState(false);
  const [senderData, setSenderData] = useState({});
  const [templateID, setTemplateID] = useState(null);
  const [selectedList, setSelectedList] = useState(null);
  const [personalizedImages, setPersonalizedImages] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const pixieContext = usePixieContext();
  const [initialContent, setInitialContent] = useState('');
  const [lists, setLists] = useState([]);
  const [crmVariables, setCrmVariables] = useState([]);
  const [sendTestEmailModal, setSendTestEmailModal] = useState(false);
  const [attachmentChanged, setAttachmentChanged] = useState(false);
  const [previousContent, setPreviousContent] = useState('');

  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [loadingImprove, setLoadingImprove] = useState(false);


  const [selectedLanguage,setSelectedLanguage] = useState("English");

  const UNSUBSCRIBE_LINK = `<a href="{{unsubscribe_link}}">Click Here to Unsubscribe</a>`;

  const editorRef = useRef(null);
  const [blogContent, setBlogContent] = useState('');
  const [macros, setMacros] = useState([]);

  const subjectRef = useRef(null);
  const [isSubjectActive,setIsSubjectActive] = useState(false)

  const [promptText, setPromptText] = useState('');
  const [showExample, setShowExample] = useState(false);

  const [jobPosition, setJobPosition] = useState('');
  const [description, setDescription] = useState('');
  const [generatedTemplates, setGeneratedTemplates] = useState([]);

  const [insertImageDialog, setInsertImageDialog] = useState(false);
  const [showPresetTemplates, setShowPresetTemplates] = useState(false);
  const [showAITemplates, setShowAITemplates] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [removeArray, setRemoveArray] = useState([]);
  const [connectedIntegrations, setConnectedIntegration] = useState([]);
  const [planUsage, setPlanUsage] = useState(null);
  const [chatGPTUsed, setChatGPTUsed] = useState(0);
  const user = useAPI('/api/user');
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname.includes('/outreach/sequences')) {
      (async function () {
        const sequenceId = window.location.pathname.split('/')[3];

        // fetch sequence
        try {
          const response = await axios.get(`/api/sequences/${sequenceId}`);
          setData((prevData) => ({
            ...prevData,
            name: response.data.data.name,
          }));
        } catch (error) {
          console.log(error.message);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (pixieContext?.isVisible === false) setInsertImageDialog(false);
  }, [pixieContext?.isVisible]);

  useEffect(() => {
    if (!personalizedImages || personalizedImages.length === 0) return;
    setPersonalizedImages((_personalizedImages) => {
      _personalizedImages = _personalizedImages.filter((_personalizedImage) =>
        blogContent?.includes(`data-id="${_personalizedImage.id}"`)
      );
      return _personalizedImages;
    });
  }, [blogContent]);

  useEffect(() => {
    getActivePlans();
    fetchLists('');
    getAllConnectedIntegration()
    fetchCrmVariables()
  }, []);

  useEffect(() => {
    if (selectedList === '') return;
    setMacros(generateMacrosForList(selectedList));
  }, [selectedList, lists]);

  async function getActivePlans() {
    try {
      let res = await axios.get(`/api/account/plan`);
      // console.log("DATA ", res.data)
      setPlanUsage(res.data.data);
      setChatGPTUsed(res.data.data?.chatGPTEmailTemplate?.used || 0);
    } catch (err) {
      console.log('ERR ', err);
    }
  }

  function insertText(text, html) {
    if (!editorRef.current && !subjectRef.current) return;
    if(isSubjectActive && subjectRef.current){
       const input = subjectRef.current;
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const newValue = input.value.substring(0, start) + text + input.value.substring(end);

      setData(prevData => ({ ...prevData, subject_line: newValue }));
    }
    else{
      if (html) editorRef.current.execCommand('mceInsertContent', false, text);
      else editorRef.current.execCommand('InsertText', false, text);
    }
  }

  function replaceText(oldText, newText) {
    setBlogContent((oldBlogContent) =>
      oldBlogContent?.replace(oldText, newText)
    );
  }

  const getPreviousImage = (imageID) => {
    for (let personalizedImage of personalizedImages) {
      if (personalizedImage.id === imageID) return personalizedImage;
    }
    return false;
  };

  const fetchLists = async (inputValue) => {
    try {
      let result = await axios.get(
        `/api/list?limit=10&search=${inputValue}&integration=true`
      );
      const data = result?.data?.data;
      setLists(data);
      return data;
    } catch (err) {
      console.log('Error in Fetching lists ', err);
      return [];
    }
  };

  const fetchCrmVariables = async () => {
    try {
      let result = await axios.get(`/api/integration/variables`);
      setCrmVariables(result?.data?.data);
    } catch (err) {
      console.log('Error in crm variables ', err);
    }
  };

  const generateMacrosForList = (listID) => {
    let _selectedList = lists.find((list) => list?.id === listID);
    let fields = _selectedList?.views[0]?.columns;
    if (!fields) return [];
    let _macros = [];
    for (let field of fields) {
      _macros.push(`{{ ${field.toLowerCase()} }}`);
    }
    return _macros;
  };

  useEffect(() => {
    let addImageToContent = async () => {
      let initialHTML = initialContent;
      let newPersonalizedImages = JSON.parse(
        JSON.stringify(personalizedImages)
      );
      for (let personalizedImage of newPersonalizedImages) {
        if (!personalizedImage.imageState) continue;
        personalizedImage.data = await getBase64DataURL(
          personalizedImage.imageState
        );
        // Replace Image in content
        let newData = `<img data-id="${personalizedImage.id}" src="${personalizedImage.data}" ${personalizedImage.width? `width=${personalizedImage.width}` :""} ${personalizedImage.height? `height=${personalizedImage.height}` :""}>`;
        initialHTML = initialHTML?.replace(
          `<image_dynamic id="${personalizedImage.id}" ${personalizedImage.width? `width=${personalizedImage.width}` :""} ${personalizedImage.height? `height=${personalizedImage.height}` :""}>`,
          newData
        );
      }
      setPersonalizedImages(newPersonalizedImages);
      setBlogContent(initialHTML);
    };
    addImageToContent();
  }, [initialContent]);

  const getBase64DataURL = (imageState) => {
    if (!imageState) return;
    return new Promise((resolve, reject) => {
      if (!imageState) return resolve(false);

      let imageStateObj = JSON.parse(imageState);
      const {canvasWidth,canvasHeight} = imageStateObj;
      const frameInfo = imageStateObj.editor.frame;
      let fabricInstance = new fabric.StaticCanvas(null, {
        width: imageStateObj.canvasWidth,
        height: imageStateObj.canvasHeight,
      });
      fabricInstance.loadFromJSON(imageStateObj.canvas, function () {
        if(!frameInfo) return resolve(fabricInstance.toDataURL());
            
            generateFrame(canvasWidth,canvasHeight,fabricInstance,imageStateObj,frameInfo)
            .then((finalImageUrl)=>resolve(finalImageUrl))
            .catch(reject);
      });
    });
  };

  // for generating frames
const generateFrame = (canvasWidth,canvasHeight,fabricInstance,imageStateObj,frameInfo) => {
  return new Promise((resolve,reject) => {

    const frameParts = frames[frameInfo.name];
    const frameSize = Math.ceil((frameInfo.sizePercent / 100) * Math.min(canvasWidth,canvasHeight));

      const defaultProperty = {
        fill: 'transparent',
        strokeWidth: 0,
        selectable: false,
        evented: false,
      }
      const parts = {
            topLeft: new fabric.Rect({
                ...defaultProperty,
                left: 0,
                top: 0,
                width: frameSize,
                height: frameSize,
                name:'top-left',
            }),
            topRight: new fabric.Rect({
                ...defaultProperty,
                left: canvasWidth  - frameSize,
                top: 0,
                width: frameSize,
                height: frameSize,
                name:'top-right'

            }),
            top: new fabric.Rect({
                ...defaultProperty,
                left: frameSize,
                top: 0,
                width: canvasWidth - 2 * frameSize,
                height: frameSize,
                name:'top'
            }),
            bottomLeft: new fabric.Rect({
                ...defaultProperty,
                left: 0,
                top: canvasHeight - frameSize,
                width: frameSize,
                height: frameSize,
                name:'bottom-left'
            }),
            bottomRight: new fabric.Rect({
                ...defaultProperty,
                left: canvasWidth - frameSize,
                top: canvasHeight - frameSize,
                width: frameSize,
                height: frameSize,
                name:'bottom-right'
            }),
            bottom: new fabric.Rect({
                ...defaultProperty,
                left: frameSize,
                top: canvasHeight - frameSize,
                width: canvasWidth - 2 * frameSize,
                height: frameSize,
                name:'bottom'
            }),
            left: new fabric.Rect({
                ...defaultProperty,
                left: 0,
                top: frameSize,
                width: frameSize,
                height: canvasHeight - 2 * frameSize,
                name:'left'
            }),
            right: new fabric.Rect({
                ...defaultProperty,
                left: canvasWidth - frameSize,
                top: frameSize,
                width: frameSize,
                height: canvasHeight - 2 * frameSize,
                name:'right'
            })
        };

      // Add rectangles to canvas
      Object.values(parts).forEach(part => fabricInstance.add(part));

      const framePartPromises = Object.keys(frameParts).map(part => {
        return new Promise((resolve, reject) => {
                fabric.Image.fromURL(frameParts[part], img => {
                    resolve({ part, img });
                });
            });
        });

        Promise.all(framePartPromises).then(frameImages => {
            frameImages.forEach(({ part, img }) => {     

              const targetPart = parts[part];
                  if (targetPart) {

                      const patternSourceCanvas = new fabric.StaticCanvas(null,{
                        width:targetPart.width,
                        height:targetPart.height
                      });
        
                      img.set({
                          left: targetPart.left,
                          top: targetPart.top,
                          scaleX: targetPart.width / img.width,
                          scaleY:targetPart.height / img.height,
                      });

                      patternSourceCanvas.add(img);
                      patternSourceCanvas.renderAll()

                      const pattern = new fabric.Pattern({
                          source: patternSourceCanvas.getElement(),
                          repeat: 'no-repeat'
                      });

                      targetPart.set('fill', pattern);
                  }
              fabricInstance.add(img);
            });

            fabricInstance.renderAll();

            const finalImageURL = fabricInstance.toDataURL();
            resolve(finalImageURL)
        });
  })
}

  useEffect(() => {
    if (!pixieContext || !pixieContext.done) return;
    let previousImage = getPreviousImage(pixieContext.done.id);

    let newData = `<img data-id="${pixieContext.done.id}" src="${pixieContext.done.data}" />`;

    if (!previousImage) {
      insertText(newData, true);
      setPersonalizedImages((oldImages) => [
        ...oldImages,
        { ...pixieContext.done },
      ]);
    } else {
      let oldData = new RegExp(`<img.+data-id="${previousImage.id}.+>`, 'g');

      replaceText(oldData, newData);
      setPersonalizedImages((oldImages) => {
        let newImages = oldImages.filter(
          (oldImage) => oldImage.id !== pixieContext.done.id
        );
        return [...newImages, { ...pixieContext.done }];
      });
    }

    setInsertImageDialog(false);
    pixieContext.close();
  }, [pixieContext?.done]);

  useEffect(() => {
    const pd = props.data;
    if (pd) {
      setSenderData({
        id: pd.id,
        service: pd.serviceName,
        name: pd.senderName,
        smtp_username: pd.smtpUsername,
        smtp_imap_email: pd.smtpImapEmail,
        smtp_password: pd.smtpImapPassword,
        smtp_host: pd.smtpHost,
        smtp_port: pd.smtpPort,
        imap_host: pd.imapHost,
        imap_port: pd.smtpPort,
        warmup: pd.warmupEnabled,
      });
    }
  }, [props.data]);

  useEffect(() => {
    let setSelectedTemplateData = async () => {
      if (!props?.selected) return;
      setAttachments(props.selected.attachments || [])
      setData({
        name: props?.selected.name,
        type: props?.selected.type,
        subject_line: props?.selected.subject_line,
      });
      if (props?.selected?.selected_list)
        setSelectedList(props.selected.selected_list);
      if (props?.selected?.image_state) {
        // Set Data URL
        setPersonalizedImages(props?.selected?.image_state);
      }

      setTemplateID(props?.selected?._id);
      setInitialContent(props?.selected?.content);
      setBlogContent(props?.selected?.content);
    };
    setSelectedTemplateData();
  }, [props?.selected]);

  const handleChange = (name, value) => {
    setData((dt) => {
      let oldData = JSON.parse(JSON.stringify(dt));
      oldData[name] = value;
      return oldData;
    });
  };

  const getContent = () => {
    let content = blogContent;
    let newPersonalizedImages = [];
    if(personalizedImages.length > 0) {
      for (let personalizedImage of personalizedImages) {
        let newImageData = personalizedImage
        // let dataToReplace = new RegExp(
        //   `<img.+data-id="${personalizedImage.id}.+>`,
        //   'g'
        // );
        let dataToReplace = new RegExp(`<img [^>]*data-id="${personalizedImage.id}"[^>]*>`, 'g')
        let match = dataToReplace.exec(content)
        let width, height;
        if (match) {
          // Regular expressions to extract width and height attributes
          const widthRegex = /width="(\d+)"/;
          const heightRegex = /height="(\d+)"/;
  
          // Extract width and height using regular expressions
          const widthMatch = match[0].match(widthRegex);
          const heightMatch = match[0].match(heightRegex);
  
          if (widthMatch && heightMatch) {
            width = parseInt(widthMatch[1]);
            height = parseInt(heightMatch[1]);
            newImageData['width'] = width;
            newImageData['height'] = height;
          }
        }
        content = content?.replace(
          dataToReplace,
          `<image_dynamic id="${personalizedImage.id}" ${width? `width=${width}` :""} ${height? `height=${height}` :""}>`
        );
        newPersonalizedImages.push(newImageData)
      }
    } 
    // else {
    //   const regex = /<img[^>]*data-id="([^"]*)"(?:[^>]*>)?/g;
    //   const dataIds = [];
    //   let match;
  
    //   while ((match = regex.exec(content)) !== null) {
    //     dataIds.push(match[1]);
    //   }
  
    //   for (let dataId of dataIds) {
    //     let dataToReplace = new RegExp(`<img [^>]*data-id="${dataId}"[^>]*>`, 'g')
    //     let match = dataToReplace.exec(content)
  
    //     let width, height;
    //     if (match) {
    //       // Regular expressions to extract width and height attributes
    //       const widthRegex = /width="(\d+)"/;
    //       const heightRegex = /height="(\d+)"/;
    //       const srcRegex = /src="([^"]*)"/;
  
    //       // Extract width and height using regular expressions
    //       const widthMatch = match[0].match(widthRegex);
    //       const heightMatch = match[0].match(heightRegex);
    //       const srcMatch = match[0].match(srcRegex);
  
    //       let newImageData = {
    //         id: dataId,
    //       }
  
    //       if (widthMatch && heightMatch) {
    //         width = parseInt(widthMatch[1]);
    //         height = parseInt(heightMatch[1]);
    //         newImageData['width'] = width;
    //         newImageData['height'] = height;
    //       }
  
    //       if(srcMatch?.length > 0) {
    //         newImageData['data'] = srcMatch[1];
    //         newImageData['imageState'] = JSON.stringify({
    //           "canvas": {
    //             "version": "5.3.0",
    //             "objects": [
    //               {
    //                 "type": "image",
    //                 "version": "5.3.0",
    //                 "originX": "center",
    //                 "originY": "center",
    //                 "left": 250,
    //                 "top": 250,
    //                 "fill": "#1565C0",
    //                 "stroke": "#000",
    //                 "strokeWidth": 0,
    //                 "strokeDashArray": null,
    //                 "strokeLineCap": "butt",
    //                 "strokeDashOffset": 0,
    //                 "strokeLineJoin": "miter",
    //                 "strokeUniform": false,
    //                 "strokeMiterLimit": 4,
    //                 "scaleX": 1,
    //                 "scaleY": 1,
    //                 "angle": 0,
    //                 "flipX": false,
    //                 "flipY": false,
    //                 "opacity": 1,
    //                 "shadow": null,
    //                 "visible": true,
    //                 "backgroundColor": null,
    //                 "fillRule": "nonzero",
    //                 "paintFirst": "fill",
    //                 "globalCompositeOperation": "source-over",
    //                 "skewX": 0,
    //                 "skewY": 0,
    //                 "erasable": true,
    //                 "cropX": 0,
    //                 "cropY": 0,
    //                 "selectable": false,
    //                 "evented": false,
    //                 "lockMovementX": true,
    //                 "lockMovementY": true,
    //                 "lockRotation": true,
    //                 "lockScalingX": true,
    //                 "lockScalingY": true,
    //                 "lockUniScaling": true,
    //                 "hasControls": false,
    //                 "hasBorders": false,
    //                 "hasRotatingPoint": false,
    //                 "name": "mainImage",
    //                 "data": {
    //                   "id": generateRandomId(9)
    //                 },
    //                 "src": srcMatch[1],
    //                 "crossOrigin": null,
    //                 "filters": []
    //               }
    //             ]
    //           },
    //           "editor": {
    //             "frame": null,
    //             "zoom": 1,
    //             "activeObjectId": null
    //           },
    //         })
    //         content = content?.replace(
    //           dataToReplace,
    //           `<image_dynamic id="${dataId}" ${width? `width=${width}` :""} ${height? `height=${height}` :""}>`
    //         );
    //         newPersonalizedImages.push(newImageData)
    //       } else continue
    //     }
    //   }
    // }

    setPersonalizedImages(oldPersonalizedImages => newPersonalizedImages)
    return content;
  };

  const uploadFiles = async (tempID) => {
    try {
      if(attachments.length === 0) return
      let uploadData = new FormData();
      for (let attachment of attachments) {
        uploadData.append('attachment', attachment);
      }
      for (let removeArrayItem of removeArray) {
        uploadData.append('remove', removeArrayItem);
      }

      await axios.post(`/api/template/upload/${tempID || templateID}`, uploadData);
    } catch (error) {
      console.log('Error in Uploading File! ', error);
      Swal.fire({
        icon: 'error',
        title: `Error uploading attachments`,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
    }
  };

  const handleSubmit = async () => {
    if (!data?.name || data?.name.trim() === '')
      return Swal.fire({
        icon: 'error',
        title: `Oops! Name is Required`,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });

    if (!data?.subject_line || data?.subject_line.trim() === '')
      return Swal.fire({
        icon: 'error',
        title: `Oops! Subject is Required`,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
    try {
      setLoading(true);

      let res;
      let title = 'Template Created!';

      data.content = getContent(blogContent);
      data.type = 'Email';
      if (selectedList) data.selected_list = selectedList;
      if (personalizedImages?.length > 0)
        data.image_state = personalizedImages.map((personalizedImage) => ({
          id: personalizedImage.id,
          imageState: personalizedImage.imageState,
          width: personalizedImage.width,
          height: personalizedImage.height
        }));

      if (props.onSubmit) {
        let result = await props.onSubmit(data);
        if (!result) setLoading(false);
      } else {
        if (!templateID) {
          res = await axios.post('/api/template', data);
          await uploadFiles(res.data.data?._id);
        } else {
          res = await axios.put(`/api/template/${templateID}`, data);
          await uploadFiles();
        }

        setLoading(false);
        if (!templateID) {
          Swal.fire({
            icon: 'success',
            title: title,
            showCancelButton: false,
            confirmButtonText: `Ok`,
            confirmButtonColor: '#0066FF',
          });
        }
        props.onClose();
      }
    } catch (err) {
      setLoading(false);
      console.log('Error in saving template ', err);
      Swal.fire({
        icon: 'error',
        title: `Oops! Error in updating template`,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
    }
  };

  useEffect(() => {
    if (insertImageDialog && personalizedImages?.length === 0)
      pixieContext?.setIsVisible(true);
  }, [insertImageDialog]);

  const getAllConnectedIntegration = async () => {
    try {
      let res = await axios.get(`/api/integration/`);
      setConnectedIntegration(res.data.data)
    } catch (err) {}
  }

  const generateEmail = async () => {
    if (
      !jobPosition ||
      jobPosition.trim() === '' ||
      !description ||
      description.trim() === ''
    )
      return Swal.fire({
        icon: 'error',
        title: `Oops! Job Position and Description is Required`,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
    try {
      setGenerateLoading(true);
      let res = await axios.post('/api/ai-templates', {
        recipient_job_position: jobPosition,
        description: description,
      });
      // console.log("TEST ", res.data.data)
      setGeneratedTemplates([
        ...generatedTemplates,
        res.data.data[0],
        res.data.data[1],
      ]);
      setGenerateLoading(false);
    } catch (err) {
      setGenerateLoading(false);
      Toast.fire({
        icon: 'error',
        title: err?.response?.data?.message || 'Unable to generate email.',
      });
    }
  };

  const updateSelectedAi = async (id) => {
    try {
      await axios.put(`/api/ai-templates/${id}`, {});
    } catch (err) {
      // console.log("Error in creating sequence ", err)
    }
  };

  const generateEmailSubjectBody = async () => {
    if (!planUsage || chatGPTUsed >= planUsage.chatGPTEmailTemplate?.limit)
      return Swal.fire({
        icon: 'warning',
        title:
          'You have exceeded your monthly limit, Contact Support for help.',
        showCancelButton: false,
        confirmButtonText: `Okay`,
        confirmButtonColor: '#0066FF',
      });

    if (promptText.length <= 10)
      return Swal.fire({
        icon: 'info',
        title: 'Write more words!',
        showCancelButton: true,
        confirmButtonText: `Show Examples`,
        cancelButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      }).then(async (result) => {
        if (result.isConfirmed) setShowExample(true);
      });

    if (promptText.length > 500)
      return Swal.fire({
        icon: 'info',
        title: 'Offer cannot be more than 500 characters',
      });

    setLoadingGenerate(true);
    try {
      let res = await axios.post('/api/chat-gpt-template', {
        prompt: promptText,
        language:selectedLanguage
      });
      // console.log("TEST ", res.data.data)
      handleChange('subject_line', res.data.data?.subject?.replaceAll('Subject:\n',''));
      setBlogContent(res.data.data.body);
      setChatGPTUsed(chatGPTUsed + 1);
      setLoadingGenerate(false);
    } catch (err) {
      console.log('Error in creating sequence ', err);
      setLoadingGenerate(false);
    }
  };

  const improveEmailBody = async (type, tone) => {
    if (!planUsage || chatGPTUsed >= planUsage?.chatGPTEmailTemplate?.limit)
      return Swal.fire({
        icon: 'warning',
        title:
          'You have exceeded your monthly limit, Contact Support for help.',
        showCancelButton: false,
        confirmButtonText: `Okay`,
        confirmButtonColor: '#0066FF',
      });

    if (blogContent?.length <= 20)
      return Swal.fire({
        icon: 'info',
        title: 'Write an email first.',
      });

    if (blogContent?.length > 5000)
      return Swal.fire({
        icon: 'info',
        title: 'Email should be at less than 5000 characters to improve.',
      });

    setLoadingImprove(true);

    let url = ``
    let rawBody = {
      template: blogContent,
      language:selectedLanguage
    }

    if(type === "expand") url = "/api/chat-gpt-template-expand"
    if(type === "shorten") url = "/api/chat-gpt-template-shorten"
    if(type === "rephrase") url = "/api/chat-gpt-template-rephrase"
    if(type === "update-tone") {
      url = "/api/chat-gpt-template-update-tone"
      rawBody['tone'] = tone
    }
    if(type === "persuasive") {
      url = "/api/chat-gpt-template-persuasive"
      rawBody['tone'] = tone
    }

    try {
      let res = await axios.post(url, rawBody);
      // console.log("TEST ", res.data.data)
      setPreviousContent(blogContent);
      setBlogContent(res.data.data?.body);
      setData({ ...data, subject_line: res.data.data?.subject })
      setChatGPTUsed(chatGPTUsed + 1);
      setLoadingImprove(false);
    } catch (err) {
      console.log('Error in generating template ', err);
      setLoadingImprove(false);
    }
  };

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div onMouseEnter={() => { setIsOpen(true); setSelectedList(props.data.id); }} style={{ position: 'relative' }}>
          <span>{props.data.name}</span>
          {isOpen && selectedList === props.data.id &&
            <div style={{ borderRadius: '5px', color: 'black', position: 'absolute', left: '105%', top: '-12px', zIndex: 10000, backgroundColor: 'white', paddingBlock: '4px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)' }}>
              {macros?.map((macro, i) => (
                <div
                  key={i}
                  onClick={() => {
                    if (isSpintaxModalOpen) setSpintaxValue(prev => prev + macro.match(/\{(.*)\}/)[1] + ' | ');
                    else insertText(macro);
                  }}
                  style={{ margin: 0, minWidth: '200px' }}
                  className="bg-[#f9f9f9] hover:bg-blue-200 text-gray-800 py-2 px-3 inline-flex items-center"
                >
                  <UilAngleRight />{macro}
                </div>
              ))}
            </div>
          }
        </div>
      </components.Option>
    );
  };

  return (
    <div
      id='template-editor-container'
      style={{
        backgroundColor: '#f9f9f9',
        zIndex: 0,
        borderRadius: 5,
        position: props.position,
        left: props.left || 0,
        right: 0,
        bottom: 0,
        top: props.top || 60,
        overflowY: 'auto',
        width: props.width,
        marginTop: props.marginTop,
      }}
    >
      {sendTestEmailModal && (
        <CustomModal scroll={true} style={{ maxWidth: 700 }}>
          <div className='sequenceSettingsDialog'>
            <div style={{ display: 'flex', margin: '0 15px 0 0' }}>
              <div>
                <div>
                  <h1 className='text-2xl text-modal-header'>Send Test Email</h1>
                  <p className='text-modal-description'>Send an email to test if variables are set correctly.</p>
                </div>
                {(attachments.length > 0 && attachmentChanged) && <p className='flex csBadge warning mb-2' style={{ marginLeft: 15, marginRight: 10 }}>
                  <UilExclamationTriangle size={16} style={{ marginTop: 3, marginRight: 5 }} />
                  <span>
                    To test attachments, save the template first.
                  </span>
                </p>}
              </div>
              <div className='ml-auto'>
                <button
                  onClick={() => setSendTestEmailModal(false)}
                  className='customButton secondary red'
                  style={{ 
                    padding: 3,
                    marginLeft: 'auto',
                    marginTop: 10
                  }}
                >
                  <UilTimes size={24} />
                </button>
              </div>
            </div>
          </div>
          <SendTestEmail
            close={() => setSendTestEmailModal(false)}
            emailBody={blogContent}
            emailSubject={data?.subject_line}
            personalizedImages={personalizedImages}
            attachments={attachments}
            defaultEmail={user?.data?.email}
            templateID={templateID}
            lists={lists}
            fetchLists={fetchLists}
          />
        </CustomModal>
      )}
      {showExample && (
        <CustomModal
          title="Offer Examples for Writing Cold Emails"
          close={true}
          onClose={() => setShowExample(false)}
          width={300}
        >
          <p className="text-modal-description">
            You can write your offer in one line & BlinkGPT AI will draft a cold email for you.
          </p>
          <div
            style={{
              paddingLeft: 15,
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <em>👉 I want to sell digital marketing services</em>
            <em>👉 I sell CRM software for real estate agents</em>
            <em>👉 I sell lead generation services to SaaS companies</em>
            <em>👉 We sell a live chat solution for B2B Companies across Europe</em>
            <em>👉 My business helps with logistics for companies across USA</em>
            <em>👉 My startup provides health insurance to businesses</em>
          </div>
          <div
            style={{
              display: 'flex',
              margin: '0 16px',
              marginTop: 11,
              justifyContent: 'end',
              width: '100%',
            }}
          >
            <Button
              style={{ marginRight: '2rem', marginBlock: '1rem 0.5rem' }}
              variant="primary"
              action={() => setShowExample(false)}
              text="Okay, Got it"
            />
          </div>
        </CustomModal>
      )}
      {showAITemplates && (
        <CustomModal
          close={true}
          onClose={() => setShowAITemplates(false)}
          height={400}
          width={1100}
        >
          <TextInput
            required={true}
            label="Job Position"
            name="job_position"
            value={jobPosition}
            onChange={(name, value) => setJobPosition(value)}
            placeholder="Enter Job Position"
          />
          <TextInput
            required={true}
            label="Description"
            name="description"
            value={description}
            onChange={(name, value) => setDescription(value)}
            placeholder="Enter Description"
          />
          <div>
            <button
              className="px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out"
              style={{
                cursor: `${generateLoading ? 'not-allowed' : 'pointer'}`,
              }}
              onClick={() => {
                if (!generateLoading) generateEmail();
              }}
            >
              {generateLoading ? (
                <UilSync size="16" className="rotate" />
              ) : generatedTemplates.length > 0 ? (
                'Generate More Email'
              ) : (
                'Generate Email'
              )}
            </button>
          </div>
          <Row style={{ maxHeight: 200, overflowY: 'auto', marginTop: 10 }}>
            {generatedTemplates?.map((tmp) => (
              <Col xs={12} lg={12}>
                <textarea
                  disabled
                  style={{
                    border: '1px solid #b2b2b2',
                    borderRadius: 5,
                    boxShadow: '2px 2px 8px 5px white',
                    width: '100%',
                    resize: 'none',
                    height: 150,
                  }}
                >
                  {tmp.email}
                </textarea>
                <button
                  className="px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out"
                  style={{ marginBottom: 10 }}
                  onClick={() => {
                    updateSelectedAi(tmp.id);
                    setBlogContent(tmp.email?.replace(/\n/g, '<br />'));
                    setShowAITemplates(false);
                  }}
                >
                  Insert
                </button>
              </Col>
            ))}
          </Row>
        </CustomModal>
      )}
      <Row>
        <Col id='entire-editor-container'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {showPresetTemplates && (
              <CustomModal
                close={true}
                onClose={() => setShowPresetTemplates(false)}
                height={800}
                width={1100}
              >
                Preset Templates
              </CustomModal>
            )}
            {loading && (
              <div
                className="csFormLoader"
                style={{
                  background: `rgba(255, 255, 255, ${
                    props.generate ? '0.8' : '0.5'
                  })`,
                }}
              >
                <Loader>
                  {props.generate && (
                    <div style={{ fontWeight: 'bold' }}>
                      BlinkGPT is writing followup emails & creating sequence.{' '}
                      <br />
                      This might take a few seconds.
                    </div>
                  )}
                </Loader>
              </div>
            )}

            <div>
              <TextInput
                id='template-name-input'
                required={true}
                label="Template Name"
                valid={data['name']}
                name="name"
                value={data['name']}
                onChange={(name, value) => handleChange(name, value)}
                placeholder="Enter Template Name (for internal purposes)"
              />
            </div>
            <div className="mt-3">
              <div className="flex">
                <Label text="Business Offer / What are you selling?" />
                <UilQuestionCircle
                  size={20}
                  style={{ marginLeft: 5, cursor: 'pointer' }}
                  onClick={() => setShowExample(true)}
                />
              </div>
              <div id='business-offer-container' style={{ display: 'flex' }}>
                <TextInput
                  id='business-offer-input'
                  value={promptText}
                  onChange={(name, value) => setPromptText(value)}
                  placeholder="Your Offer, Ex. SEO services for enterprises in USA"
                />

                {/* todo adding dropdown languages */}
                <RCSelect
                  id='language-input'
                  styles={{
                    control: () => ({
                      padding: '0.4rem',
                      minWidth: 200,
                      width: "100%",
                      maxWidth: 500,
                      borderRadius: 5,
                      backgroundColor: '#f9f9f9',
                      display: 'flex',
                      border: '1px solid #D7D7D7',
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999, // Adjust this value to be higher than the modal's zIndex
                    }),
                  }}
                  className="bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-full inline-flex"
                  placeholder="Select Language"
                  value={(function () {
                    const value = languagesList.find((prop) => prop?.language === selectedLanguage);
                    if (value) return value;
                    return null;
                  })()}
                  // defaultOptions={languagesList}
                  isClearable
                  menuPortalTarget={document.body}
                  options={languagesList}
                  // loadOptions={fetchLists} // Function to fetch list options asynchronously
                  getOptionValue={(option) => option.language} // Define how to get the value of each option
                  getOptionLabel={(option) => option?.language} // Define how to get the label of each option
                  onChange={(values, currentValue) => {
                    console.log(values)
                    setSelectedLanguage(values?.language);
                  }}
                  noOptionsMessage={() => 'No Results, Type to Search'}
                />
                {loadingGenerate ? (
                  <UilSync
                    size="20"
                    className={`rotate mb-4 ml-4`}
                    id="generate-loading"
                  />
                ) : (
                  <button
                    className="ml-4 csGenerateButton mb-4"
                    disabled={loadingGenerate}
                    id="generate-with-chatgpt"
                    type="button"
                    onClick={() => generateEmailSubjectBody()}
                  >
                    <span className="mr-1">
                      <img src={BlinkGPT} height={22} width={22}/>
                    </span>
                    <span style={{marginTop: 4}}>Draft Email</span>
                  </button>
                )}
                <Tooltip anchorSelect="#generate-with-chatgpt" place="top">
                  BlinkGPT AI will draft a cold email for your offering.
                </Tooltip>
                <Tooltip anchorSelect="#generate-loading" place="top">
                  BlinkGPT AI is working on it...
                </Tooltip>
              </div>
            </div>
            <div>
              <TextInput
                id='subject-line-input'
                required={true}
                label="Subject Line"
                name="subject_line"
                value={data['subject_line']}
                onChange={(name, value) => handleChange(name, value)}
                placeholder="Enter Subject Line"
                ref={subjectRef}
                onFocus={()=>setIsSubjectActive(true)}
              />
            </div>

            {/* <span
              style={{
                marginTop: 15,
                marginBottom: 7,
                display: 'block',
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              Personalize subject line & body with variables
            </span> */}

            <div id='improve-with-gpt-button' style={{ display: 'flex', gap: '1rem', alignItems: 'center', marginBottom: 5 }}>
              {previousContent !== '' && (
                <>
                  <div
                    data-tooltip-content="Undo"
                    data-tooltip-id="undo-chatgpt"
                  >
                    <CustomButton
                      id='undo-button'
                      icon={<UilRedo />}
                      variant="secondary grey"
                      onClick={() => {
                        setBlogContent(previousContent);
                        setPreviousContent('');
                      }}
                      style={{ margin: 0, height: 45, marginBlock: loadingImprove ? '1rem' : 0  }}
                      iconOnly={true}
                    />
                  </div>
                  <Tooltip id="undo-chatgpt" />
                </>
              )}

              {loadingImprove ? (
                <div>
                  <UilSync
                    id='sync-animation'
                    size="20"
                    className={`rotate flex float-right mr-2 mb-2`}
                  />
                </div>
              ) : (
                <Dropdown
                  label={"Improve with BlinkGPT"}
                  seprator={true}
                  icon={<img src={BlinkGPT} height={22} width={22}/>}
                  labelStyle={{ paddingBlock: 0, fontWeight: 600 }}
                  variant="secondary"
                  defaultAction={() => {
                    console.log("SAVED");
                  }}
                  items={[
                    {
                      title: 'More Persuasive',
                      action: () => {
                        improveEmailBody("persuasive", "more")
                      },
                    },
                    {
                      title: 'Less Persuasive',
                      action: () => {
                        improveEmailBody("persuasive", "less")
                      },
                    },
                    { type: 'separator' },
                    {
                      title: 'Expand',
                      action: () => {
                        improveEmailBody("expand")
                      },
                    },
                    {
                      title: 'Shorten',
                      action: () => {
                        improveEmailBody("shorten")
                      },
                    },
                    {
                      title: 'Rephrase',
                      action: () => {
                        improveEmailBody("rephrase")
                      },
                    },
                    { type: 'separator' },
                    {
                      title: 'Friendly Tone',
                      action: () => {
                        improveEmailBody("update-tone", "friendly")
                      },
                    },
                    {
                      title: 'Formal Tone',
                      action: () => {
                        improveEmailBody("update-tone", "formal")
                      },
                    },
                  ]}
                  align="right"
                  style={{marginBlock: '0.5rem', marginLeft: 0}}
                />
              )}
              <Tooltip anchorSelect="#improve-with-chatgpt" place="top">
                Click to improve email body with BlinkGPT
              </Tooltip>
            </div>
            <div id='editor-top-toolbar' style={{ backgroundColor: '#f2f2f2', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', flexWrap: 'wrap' }} className='px-2 py-2 flex gap-2 items-center'>
              <div>
                <RCSelect
                  styles={{
                    control: () => ({
                      padding: 1,
                      minWidth: 180,
                      width: "100%",
                      maxWidth: 500,
                      borderRadius: 5,
                      backgroundColor: '#f9f9f9',
                      display: 'flex',
                      border: '1px solid #d7d7d7'
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: '#222f3e !important',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999, // Adjust this value to be higher than the modal's zIndex
                    }),
                  }}
                  className="bg-gray-100 hover:bg-gray-200 !text-[#222f3e] rounded-full inline-flex"
                  menuPortalTarget={document.body}
                  placeholder="Default Variables"
                  options={[
                    { value: 'email_signature_of_sender', label: 'Email Signature of the Sender' },
                    { value: 'name_of_sender', label: 'Email Sender Name' },
                    { value: 'my_meeting_link', label: 'Meeting Link' },
                    { value: 'sl_time_of_day', label: 'Time of Day (Ex. Afternoon)' },
                    { value: 'sb_day_of_week', label: 'Day of Week (ex. Monday)' },
                    { value: 'sb_current_month', label: `This Month (Ex. March)` },
                    { value: 'sb_next_month', label: `Next Month (Ex. April)` },
                    { value: 'sb_current_quarter', label: 'This Quarter (Ex. Q3)' },
                    { value: 'sb_next_quarter', label: 'Next Quarter (Ex Q4)' },
                    { value: 'sb_current_year', label: `This Year (Ex. 2024)` },
                    { value: 'sb_next_year', label: `Next Year (Ex. 2025)` },
                  ]}
                  value="" 
                  onChange={(values, currentValue) => {
                    if (isSpintaxModalOpen) setSpintaxValue(prev => prev + `{${values?.value}} | `);
                    else insertText(`{{${values?.value}}}`);
                  }}
                />
              </div>
              <div>
                <AsyncSelect
                  minMenuHeight={300} 
                  menuPlacement='auto'
                  styles={{
                    control: () => ({
                      padding: 1,
                      width: 160,
                      borderRadius: 5,
                      backgroundColor: '#f9f9f9',
                      display: 'flex',
                      border: '1px solid #d7d7d7'
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      overflow: 'visible'
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: '#222f3e !important',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999, // Adjust this value to be higher than the modal's zIndex
                    }),
                  }}
                  className="bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-full inline-flex"
                  placeholder="List Variables"
                  value={(function () {
                    // const value = lists.find((prop) => prop?.id === selectedList);
                    // if (value) return value;
                    return null;
                  })()}
                  defaultOptions={[]}
                  isClearable
                  menuPortalTarget={document.body}
                  loadOptions={(inputValue) => {
                    return fetchLists(inputValue).then((options) => options.slice(0, 5));
                  }}
                  getOptionValue={(option) => option?.id} // Define how to get the value of each option
                  getOptionLabel={(option) => option?.name} // Define how to get the label of each option
                  onChange={(values, currentValue) => {
                    setSelectedList(values?.id);
                  }}
                  components={{Option: CustomOption}}
                  noOptionsMessage={() => 'No Results, Type to Search'}
                />
              </div>
              {connectedIntegrations.length > 0 && crmVariables.length > 0 && <div>
                <RCSelect 
                  styles={{
                    control: () => ({
                      padding: 1,
                      // minWidth: 180,
                      width: 170,
                      maxWidth: 500,
                      borderRadius: 5,
                      backgroundColor: '#f9f9f9',
                      display: 'flex',
                      border: '1px solid #d7d7d7'
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: '#222f3e !important',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999, // Adjust this value to be higher than the modal's zIndex
                    }),
                  }}
                  className="bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-full inline-flex"
                  menuPortalTarget={document.body}
                  placeholder="CRM Variables"
                  options={crmVariables}
                  value="" 
                  onChange={(values, currentValue) => {
                    if (isSpintaxModalOpen) setSpintaxValue(prev => prev + `{${values?.value}} | `);
                    else insertText(`{{${values?.value}}}`);
                  }}
                /> 
              </div>}
              <div onClick={() => {setIsSpintaxModalOpen(true)}} style={{ color: '#222f3e'  }} className='cursor-pointer flex items-center gap-2'>
                <div className='shrink-0'>
                  <UilBracketsCurly size={20} />
                </div>
                <p style={{overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis'}}>
                  Spintax
                </p>
              </div>
              <div onClick={() => {insertText(UNSUBSCRIBE_LINK, true)}} style={{ color: '#222f3e', maxWidth: 165  }} className='cursor-pointer flex items-center gap-2'>
                <div className='shrink-0'>
                  <UilLinkBroken size={20} />
                </div>
                <p style={{overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis'}}>
                  Unsubscribe Link
                </p>
              </div>
              <div onClick={() => {setInsertImageDialog(true)}} style={{ color: '#222f3e', maxWidth: 165  }} className='cursor-pointer flex items-center gap-2'>
                <div className='shrink-0'>
                  <UilImage size={20} />
                </div>
                <p id='personalizedImage' style={{overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis'}}>
                  Personalized Image
                </p>
              </div>
            </div>
            {isSpintaxModalOpen && <CustomModal 
              width={500}
              close={true}
              onClose={() => {
                setIsSpintaxModalOpen(false);
                setSpintaxValue('');
              }}
            >
              <div style={{ padding: 10 }} id="spintaxModal">
                <h1
                  className="text-2xl text-modal-header"
                  style={{ marginTop: '-15px', padding: 0 }}
                >
                  Spintax Builder
                </h1>
                <p className="text-modal-description" style={{ padding: 0 }}>
                  Spintax is a variation of sentences or words to send diverse email copies to your leads.
                </p>
                <hr className="mt-3 mb-3" />
                <div className="flex flex-col gap-4">
                  <div className='flex items-center gap-2' style={{ flexWrap: 'wrap' }}>
                    <RCSelect
                      styles={{
                        control: () => ({
                          padding: 1,
                          minWidth: 180,
                          width: "100%",
                          maxWidth: 500,
                          borderRadius: 5,
                          backgroundColor: '#f9f9f9',
                          display: 'flex',
                          border: '1px solid #d7d7d7'
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: '#222f3e !important',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis'
                        }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999, // Adjust this value to be higher than the modal's zIndex
                        }),
                      }}
                      className="bg-gray-100 hover:bg-gray-200 !text-[#222f3e] rounded-full inline-flex"
                      menuPortalTarget={document.body}
                      placeholder="Default Variables"
                      options={[
                        { value: 'email_signature_of_sender', label: 'Email Signature of the Sender' },
                        { value: 'name_of_sender', label: 'Email Sender Name' },
                        { value: 'my_meeting_link', label: 'Meeting Link' },
                        { value: 'sl_time_of_day', label: 'Time of Day (Ex. Afternoon)' },
                        { value: 'sb_day_of_week', label: 'Day of Week (ex. Monday)' },
                        { value: 'sb_current_month', label: `This Month (Ex. March)` },
                        { value: 'sb_next_month', label: `Next Month (Ex. April)` },
                        { value: 'sb_current_quarter', label: 'This Quarter (Ex. Q3)' },
                        { value: 'sb_next_quarter', label: 'Next Quarter (Ex Q4)' },
                        { value: 'sb_current_year', label: `This Year (Ex. 2024)` },
                        { value: 'sb_next_year', label: `Next Year (Ex. 2025)` },
                      ]}
                      value="" 
                      onChange={(values, currentValue) => {
                        if (isSpintaxModalOpen) setSpintaxValue(prev => prev + `{${values?.value}} | `);
                        else insertText(`{{${values?.value}}}`);
                      }}
                    />
                    <AsyncSelect
                      minMenuHeight={300} 
                      menuPlacement='auto'
                      styles={{
                        control: () => ({
                          padding: 1,
                          width: 160,
                          borderRadius: 5,
                          backgroundColor: '#f9f9f9',
                          display: 'flex',
                          border: '1px solid #d7d7d7'
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          overflow: 'visible'
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: '#222f3e !important',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis'
                        }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999, // Adjust this value to be higher than the modal's zIndex
                        }),
                      }}
                      className="bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-full inline-flex"
                      placeholder="List Variables"
                      value={(function () {
                        // const value = lists.find((prop) => prop?.id === selectedList);
                        // if (value) return value;
                        return null;
                      })()}
                      defaultOptions={[]}
                      isClearable
                      menuPortalTarget={document.body}
                      loadOptions={(inputValue) => {
                        return fetchLists(inputValue).then((options) => options.slice(0, 5));
                      }}
                      getOptionValue={(option) => option?.id} // Define how to get the value of each option
                      getOptionLabel={(option) => option?.name} // Define how to get the label of each option
                      onChange={(values, currentValue) => {
                        setSelectedList(values?.id);
                      }}
                      components={{Option: CustomOption}}
                      noOptionsMessage={() => 'No Results, Type to Search'}
                    />
                    {connectedIntegrations.length > 0 && crmVariables.length > 0 && <RCSelect 
                      styles={{
                        control: () => ({
                          padding: 1,
                          // minWidth: 180,
                          width: 170,
                          maxWidth: 500,
                          borderRadius: 5,
                          backgroundColor: '#f9f9f9',
                          display: 'flex',
                          border: '1px solid #d7d7d7'
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: '#222f3e !important',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis'
                        }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999, // Adjust this value to be higher than the modal's zIndex
                        }),
                      }}
                      className="bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-full inline-flex"
                      menuPortalTarget={document.body}
                      placeholder="CRM Variables"
                      options={crmVariables}
                      value="" 
                      onChange={(values, currentValue) => {
                        if (isSpintaxModalOpen) setSpintaxValue(prev => prev + `{${values?.value}} | `);
                        else insertText(`{{${values?.value}}}`);
                      }}
                    />}
                  </div>
                  <textarea className='rounded-md p-2' placeholder='Add " | " separated text. &#10;Hey there! | Hello there! | Hey, Happy Holidays!' value={spintaxValue} onChange={(e) => setSpintaxValue(e.target.value)} rows={10} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '1rem',
                    gap: '2rem',
                    justifyContent: 'end',
                    width: '100%',
                  }}
                >
                  <Button
                    className='custom-buttons'
                    variant="primary grey"
                    action={() => {
                      setIsSpintaxModalOpen(false);
                      setSpintaxValue('');
                    }}
                    text="Cancel"
                  />
                  <Button
                    className='custom-buttons'
                    variant="primary"
                    action={() => {
                      if (spintaxValue.trim().length) {
                        let finalSpintaxValue = spintaxValue.trim();
                        const length = finalSpintaxValue.length;
                        if (finalSpintaxValue[length-1] === '|') finalSpintaxValue = finalSpintaxValue.slice(0, length-1);
                        insertText(`{{ ${finalSpintaxValue} }}`);
                      }
                      setIsSpintaxModalOpen(false);
                      setSpintaxValue('');
                    }}
                    text={`Insert Spintax`}
                  />
                </div>
              </div>
            </CustomModal>}
            <BundledEditor
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={blogContent}
              onEditorChange={(editedContent) => setBlogContent(editedContent)}
              onFocus={()=>setIsSubjectActive(false)}
              init={{
                height: 500,
                menubar: false,
                elementpath: false,
                plugins: [
                  'advlist',
                  'anchor',
                  'autolink',
                  'image',
                  'link',
                  'lists',
                  'searchreplace',
                  'table',
                  'code',
                  'emoticons',
                  'quickbars',
                ],
                quickbars_insert_toolbar: false,
                quickbars_image_toolbar: false,
                automatic_uploads: true,
                image_description: false,
                file_picker_types: 'image',
                file_picker_callback: (cb, value, meta) => {
                  const input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');

                  input.addEventListener('change', (e) => {
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.addEventListener('load', () => {
                      /*
                        Note: Now we need to register the blob in TinyMCEs image blob
                        registry. In the next release this part hopefully won't be
                        necessary, as we are looking to handle it internally.
                      */
                      const id = 'blobid' + (new Date()).getTime();
                      const blobCache =  editorRef.current.editorUpload.blobCache;
                      const base64 = reader.result.split(',')[1];
                      const blobInfo = blobCache.create(id, file, base64);
                      blobCache.add(blobInfo);

                      /* call the callback and populate the Title field with the file name */
                      cb(blobInfo.blobUri(), { title: file.name });
                    });
                    reader.readAsDataURL(file);
                  });

                  input.click();
                },
                // setup: function(editor) {
                //   editor.on('keyup change', function() {
                //     const content = editor.getContent();
                //     const regex = /\{\{(.*?)\}\}/g;
                //     let newContent = content.replace(regex, `<span style="background-color: yellow;">{{$1}}</span>`);
                //     editor.setContent(newContent);
                //     editor.selection.select(editor.getBody(), true);
                //     editor.selection.collapse(false);
                //   });
                // },
                statusbar: false,
                toolbar_mode: 'sliding',
                toolbar_location: 'bottom',
                toolbar:
                'blocks ' +
                 'bold italic forecolor fontsize  code  link  emoticons ' +
                 ' alignleft aligncenter alignright alignjustify '+
                 ' bullist numlist  image table removeformat insertImage',
                contextmenu: 'copy paste cut | link ',
                fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',  
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
            <br />
            <div
              style={{ display: 'flex', paddingBottom: props.paddingBottom, flexWrap: 'wrap' }}
            >
              {!props.generate && (
                <button
                  className="csGenerateButton custom-buttons"
                  style={{ padding: '13px 20px', width: 170, marginLeft: 0, marginRight: '1rem' }}
                  type="button"
                  onClick={() => setSendTestEmailModal(true)}
                >
                  Test Email
                </button>
              )}
              <button
                id='discard-button'
                type="button"
                onClick={() => {
                  setData({});
                  setBlogContent('');
                  props.onClose();
                }}
                style={{
                  padding: '13px 20px',
                }}
                className="csGenerateButton danger custom-buttons"
              >
                Discard
              </button>
              {!props.generate && (
                <button
                  id='save-button'
                  className="csSaveBtn custom-buttons"
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  {templateID ? 'Update Email Template' : 'Save Email Template'}
                </button>
              )}
              {props.generate && (
                <button
                  className="csSaveBtn custom-buttons"
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  {props.save_button_text}
                </button>
              )}
              {/* style={{ marginLeft: "auto" }} */}
            </div>
          </form>
        </Col>
        {!props.generate && (
          <Col
            xs={12}
            lg={3}
            style={{ borderLeft: '1px solid #d7d7d7', paddingLeft: 15 }}
          >
            <SpamChecker blogContent={blogContent} />
            <Attachments
              items={attachments}
              onChange={setAttachments}
              onNew={() => setAttachmentChanged(true)}
              onRemove={(itemName) => {
                setRemoveArray((oldItems) => {
                  return [...oldItems, itemName];
                });
                setAttachmentChanged(true)
              }}
            />
          </Col>
        )}
      </Row>

      {insertImageDialog && personalizedImages.length > 0 && (
        <CustomDialog title="Add Image">
          <Row>
            <Col xs={12} lg={3}>
              <div
                onClick={() => {
                  pixieContext.setImageState(null);
                  pixieContext.setIsVisible(true);
                }}
                className="text-gray-500 hover:text-gray-700 bg-gray-100 p-2 rounded"
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                  borderRadius: 10,
                  cursor: 'pointer',
                }}
              >
                <div style={{ width: 79, margin: 'auto' }}>
                  <span style={{ padding: 12, display: 'flex' }}>
                    <UilImage size={50} />
                  </span>
                  <span>Create New</span>
                </div>
              </div>
            </Col>
            {personalizedImages.map((personalizedImage) => (
              <Col
                onClick={() => {
                  pixieContext.setImageState(personalizedImage);
                  pixieContext.setIsVisible(true);
                }}
                xs={12}
                lg={3}
              >
                <div
                  className="bg-gray-100 p-2 rounded"
                  style={{
                    overflow: 'hidden',
                    cursor: 'pointer',
                    borderRadius: 10,
                  }}
                >
                  <img
                    style={{ height: '100%', maxWidth: 'auto' }}
                    src={personalizedImage.data}
                  />
                </div>
              </Col>
            ))}
          </Row>
          <br />
          <div>
            <button
              onClick={() => setInsertImageDialog(false)}
              className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 hover:shadow-lg focus:bg-red-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Cancel
            </button>
          </div>
          <br />
        </CustomDialog>
      )}
    </div>
  );
}
